import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Header from "../../../../components/shared/questionnaire/outer-page/OuterPage";
import MDBox from "../../../../components/generic/MDBox";
import MDButton from "../../../../components/generic/MDButton";
import DashboardLayout from "../../../../components/refined/LayoutContainers/DashboardLayout";
import SdgDetailsInvestor from "./sections/sdg-details";
//import Uploaddetails from "./components/sections/uploaddetails"; //not used in original, not converted at this stage
import InvestorBasics from "./sections/company-summary";
import FundingMech from "./sections/funding-mechanism";
import Mandate1 from "./sections/mandate/Mandate1";
import FinancingDetails1 from "./sections/financing-details/financing-details-1";
import FinancingDetails2 from "./sections/financing-details/financing-details-2";
import OnelinerDetails from "./sections/oneliner-details";
import Mandate2 from "./sections/mandate/Mandate2";
import Mandate3 from "./sections/mandate/Mandate3";
import Mandate4_1 from "./sections/mandate/Mandate4_1";
import Mandate4_2 from "./sections/mandate/Mandate4_2";
import Mandate4_3 from "./sections/mandate/Mandate4_3";
import NeedsQuestion from "./sections/needs-details";
import MDTypography from "components/generic/MDTypography";
//import SupportDocuments from "./components/sections/uploaddetails"; //not used in original, not converted at this stage
import Footer from "components/refined/Footer";
import { gqSteps } from "../../../../services/ventureApi";
import { useSkipStep } from "context/skipStepContext";
import { useNavigate } from "react-router-dom";

import { FormData } from "./types";
import { login as loginVentureApi } from "services/ventureApi";
import { useAuth } from "context/AuthContext";

import { investorPermissions } from "dummyUsers";

const InvestorGQ: React.FC = () => {
  const { dispatch } = useAuth();
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);
  const [subStepIndex, setSubStepIndex] = useState(0);
  const [mandateSubStepIndex, setMandateSubStepIndex] = useState(0);
  const [disabledval, setDisabledval] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    entityDetails: {
      businessName: "",
      investorType: "",
      primaryInvestmentSector: "",
      inputValue: "",
      establishmentDate: "",
      businessRegistrationNumber: "",
      taxReferenceNumber: "",
      streetNo: "",
      streetName: "",
      suburb: "",
      city: "",
      zipCode: "",
      country: "",
      latitude: "",
      longitude: "",
      businessWebsite: "", // optional if included in the interface
    },
    fundingMech: {},
    sdgDetails: [],
    investmentDetails: {},
    oneLinerDetails: {},
    mandateDetails: {
      industryDetails: [
        {
          industrySection: null,
          industryDivision: null,
          industryGroup: null,
          industryClass: null,
          industrySubclass: null,
        },
      ],
      investmentGeographies: [],
      additionalInfo: {
        additionalSupportProvided: "",
        networksProvided: "",
      },
    },
    needsDetails: {
      selectedNeeds: [],
      otherInputValue: "",
    },
  });

  const navigate = useNavigate();
  const { skipStep1, handleSkipStep } = useSkipStep();
  const [skipStep2, setSkipStep2] = useState<boolean>();

  const setSelectedIndustries = (data: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      mandateDetails: {
        ...prevFormData.mandateDetails,
        industries: data,
      },
    }));
  };

  const [initialStep, setInitialStep] = useState(0);

  useEffect(() => {
    const initialState = localStorage.getItem("gq_state");
    if (initialState) {
      const stepIndex = Object.values(stepMapping).findIndex((step) => step.step === initialState);
      if (stepIndex !== -1) {
        setActiveStep(stepIndex);
        setInitialStep(stepIndex); // Set the initial step
      }
    }
  }, []);

  const getFromChild = (data: boolean) => {
    setDisabledval(data);
  };

  function getSteps() {
    return [
      "COMPANY SUMMARY",
      "FUNDING MECHANISMS",
      "SDG",
      "FUND MATURITY",
      "ONE-LINER",
      "MANDATE",
      "NEEDS",
    ];
  }

  function getStepContent(
    stepIndex: number,
    subStepIndex: number | null = null,
    mandateSubStepIndex: number | null = null
  ) {
    const stepProps = {
      formData,
      setFormData,
    };

    switch (stepIndex) {
      case 0:
        return <InvestorBasics {...stepProps} />;
      case 1:
        return <FundingMech {...stepProps} />;
      case 2:
        return <SdgDetailsInvestor {...stepProps} />;
      case 3:
        return subStepIndex === 0 ? (
          <FinancingDetails1 {...stepProps} />
        ) : (
          <FinancingDetails2 {...stepProps} />
        );
      case 4:
        return <OnelinerDetails {...stepProps} />;
      case 5:
        switch (subStepIndex) {
          case 0:
            return <Mandate1 {...stepProps} />;
          case 1:
            return <Mandate2 {...stepProps} />;
          case 2:
            return <Mandate3 {...stepProps} />;
          case 3:
            switch (mandateSubStepIndex) {
              case 0:
                return <Mandate4_1 {...stepProps} />;
              case 1:
                return <Mandate4_2 {...stepProps} />;
              case 2:
                return <Mandate4_3 {...stepProps} />;
              default:
                return null;
            }
          default:
            return null;
        }
      case 6:
        return <NeedsQuestion {...stepProps} />;

      default:
        return null;
    }
  }

  const stepMapping: Record<number, { step: keyof FormData; status: string }> = {
    0: { step: "entityDetails", status: "PENDING_GQ_INVESTOR_FUNDINGMECHANISM" },
    1: { step: "fundingMech", status: "PENDING_GQ_INVESTOR_SDGDETAILS" },
    2: { step: "sdgDetails", status: "PENDING_GQ_INVESTOR_FINANCINGDETAILS" },
    3: { step: "investmentDetails", status: "PENDING_GQ_INVESTOR_ONELINERDETAILS" },
    4: { step: "oneLinerDetails", status: "PENDING_GQ_INVESTOR_MANDATEDETAILS" },
    5: { step: "mandateDetails", status: "PENDING_GQ_INVESTOR_NEEDSDETAILS" },
    6: { step: "needsDetails", status: "INVESTOR_COMPLETED" },
  };

  //const loginResponse = localStorage.getItem("loginResponse");
  useEffect(() => {
    const initialState = localStorage.getItem("gq_state");

    console.log("initial state for Investor GQ", initialState);
    if (initialState) {
      const stepIndex = Object.values(stepMapping).findIndex((step) => step.step === initialState);
      if (stepIndex !== -1) {
        setActiveStep(stepIndex);
        setInitialStep(stepIndex); // Set the initial step
      }
    }
  }, []);

  const handleNext = async () => {
    const currentStep = stepMapping[activeStep];
    console.log(`Current Step: ${currentStep.step}`);
    console.log("Form Data:", formData);
    let response;
    try {
      console.log(`Data For API Call : for ${currentStep.step} =>`, formData[currentStep.step]);
      let formatData_currentStep = formData[currentStep.step];
      if (Array.isArray(formData[currentStep.step])) {
        formatData_currentStep = { [currentStep.step]: formData[currentStep.step] };
      }
      response = await gqSteps(currentStep.step, formatData_currentStep, currentStep.status);
      console.log(`Data for ${currentStep.step} sent to backend successfully.`);
    } catch (error) {
      console.error(`Error sending data for ${currentStep.step} to backend:`, error);
    }
    if (activeStep === 5 && response) {
      console.log("pre last step");
    }
    if (activeStep === 6 && response) {
      /*
      const entityId = response.entity.entity_id;
      const roleId = response.entity.role_id;

      await loginVentureApi({ entity_id: entityId, role_id: roleId });
      navigate("/authentication/sign-in");
      */
      const newLoginResponse = {
        user_profile: response.user_profile,
        entity: response.entity,
        entities: response.entities || [],
        state: response.state,
        landing_route: response.landing_route || "/profile/investor-profile",
      };
      console.log("new login response:", newLoginResponse);

      dispatch({
        type: "LOGIN",
        payload: {
          user: {
            username: response.user_profile.email,
            role: "Administrator",
            permissions: new Set(investorPermissions), // Update permissions to investor permissions
          },
          entities: newLoginResponse.entities,
          loginResponse: newLoginResponse, // Ensure loginResponse is updated with new response from gq
        },
      });

      // Navigate to the investor profile once the state is updated
      navigate("/profile/investor-profile");
    }

    if (activeStep === 3 && subStepIndex === 0) {
      setSubStepIndex(1);
    } else if (activeStep === 5 && subStepIndex === 3) {
      if (mandateSubStepIndex === 2) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSubStepIndex(0);
        setMandateSubStepIndex(0);
      } else {
        setMandateSubStepIndex((prevMandateSubStepIndex) => prevMandateSubStepIndex + 1);
      }
    } else if (activeStep === 5) {
      if (subStepIndex === 3) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSubStepIndex(0);
      } else {
        setSubStepIndex((prevSubStepIndex) => prevSubStepIndex + 1);
      }
    } else if (activeStep === 3 && subStepIndex === 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSubStepIndex(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 5 && subStepIndex === 3 && mandateSubStepIndex > 0) {
      setMandateSubStepIndex((prevMandateSubStepIndex) => prevMandateSubStepIndex - 1);
    } else if (activeStep === 5 && subStepIndex > 0) {
      setSubStepIndex((prevSubStepIndex) => prevSubStepIndex - 1);
    } else if (subStepIndex === 0 && activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      if (activeStep === 4 || activeStep === 5) {
        setSubStepIndex(1);
      } else {
        setSubStepIndex(0);
      }
    } else {
      setSubStepIndex((prevSubStepIndex) => prevSubStepIndex - 1);
    }
  };

  return (
    <DashboardLayout>
      <Header>
        <MDBox py={3} mb={10}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%", mt: 8 }}
          >
            <Grid sx={{ minWidth: "90%" }} item xs={12} lg={8}>
              <Card sx={{ height: "100%" }}>
                <MDBox mx={2} mt={-3}>
                  {activeStep !== steps.length - 1 ? (
                    <Stepper
                      sx={{
                        overflowX: "auto",
                        backgroundColor: "#85B526 !important",
                        backgroundImage: "none !important",
                        boxShadow: "none",
                      }}
                      activeStep={activeStep}
                      alternativeLabel
                    >
                      {steps.map((label, index) => (
                        <Step
                          sx={{
                            ".Mui-disabled": {
                              color: "#FFFFFF",
                              opacity: 0.75,
                            },
                          }}
                          key={index}
                        >
                          <StepLabel
                            sx={{
                              "& .Mui-disabled ": {
                                color: "#FFFFFF",
                              },
                              ".MuiStepIcon-root ": {
                                fill: "#FFFFFF",
                                stroke: "#FFFFFF",
                                background: "#FFFFFF",
                              },
                            }}
                          >
                            {index === activeStep
                              ? index === 3
                                ? `FUND MATURITY ${subStepIndex + 1}/2`
                                : index === 5
                                  ? `Mandate ${subStepIndex + 1}/4${
                                      subStepIndex === 3 ? `-${mandateSubStepIndex + 1}/3` : ""
                                    }`
                                  : label
                              : label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  ) : (
                    <MDBox
                      variant="contained"
                      bgColor="primary"
                      borderRadius="lg"
                      coloredShadow="primary"
                      mx={"auto"}
                      mt={-3}
                      px={2}
                      py={{
                        xs: 2,
                        sm: 2,
                        lg: 3,
                        xl: 3,
                      }}
                      maxWidth="850px"
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Completed
                      </MDTypography>
                    </MDBox>
                  )}
                </MDBox>
                <MDBox p={3}>
                  <MDBox>
                    {getStepContent(activeStep, subStepIndex, mandateSubStepIndex)}
                    <MDBox mt={2} width="100%" display="flex" justifyContent="flex-end" gap={2}>
                      {activeStep !== 0 && activeStep !== steps.length - 1 && (
                        <MDButton variant="gradient" color="light" onClick={handleBack}>
                          Back
                        </MDButton>
                      )}
                      {activeStep !== steps.length - 1 ? (
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="dark"
                          onClick={handleNext}
                        >
                          {activeStep === steps.length - 2 ? "Submit" : "Next"}
                        </MDButton>
                      ) : (
                        <MDButton
                          type="submit"
                          //disabled={!disabledval}
                          variant="gradient"
                          color="info"
                          onClick={handleNext}
                        >
                          View Your Portfolio
                        </MDButton>
                      )}
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
};

export default InvestorGQ;
