import React from "react";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDInput from "components/generic/MDInput";
import MDTooltip from "components/generic/MDTooltip";

// NewUser page components
import SignupFormField from "./SignupFormField";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

const services = [
  [
    "Strategic Planning",
    "Product Development",
    "Technical Development",
    "Operational Support",
    "Financial",
  ],
  [
    "Marketing & Strategy",
    "Sales & Acquisition",
    "HR & Talent Managing",
    "Scaling & Growing",
    "Data & Security",
  ],
  ["Customer Support & Success", "Networking / Partnerships", "Legal", "Data Analysis", "Other"],
];

interface FormData {
  values: Record<string, any>;
  setFieldValue: (field: string, value: any) => void;
}

interface SignupServiceProps {
  formData: FormData;
}

const SignupService: React.FC<SignupServiceProps> = ({ formData }) => {
  const { values, setFieldValue } = formData;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const { value, checked } = event.target;
    const field = type;

    if (checked) {
      setFieldValue(field, [...values[field], value]);
    } else {
      setFieldValue(
        field,
        values[field].filter((item: string) => item !== value)
      );
    }
  };

  const renderServiceOptions = (services: string[][], type: string) =>
    services.map((group, idx) => (
      <Grid
        key={idx}
        item
        display="flex"
        justifyContent={{ xs: "flex-start", lg: "center" }}
        alignItems="center"
        xs={12}
        lg={4}
      >
        <MDBox sx={{ width: "fit-content" }}>
          <FormGroup>
            {" "}
            {/*had to remove display="flex" here*/}
            {group.map((service, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    value={service}
                    checked={values[type] ? values[type].includes(service) : false}
                    onChange={(e) => handleCheckboxChange(e, type)}
                  />
                }
                label={service}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "15px",
                    color: "#7B809A",
                    fontWeight: "400",
                  },
                }}
              />
            ))}
          </FormGroup>
        </MDBox>
      </Grid>
    ));

  return (
    <MDBox>
      <MDBox display="flex" alignItems="center" justifyContent="space-between">
        <MDBox lineHeight={0}>
          <MDTypography variant="h5">Services</MDTypography>
        </MDBox>
        <MDTooltip
          title={
            <>
              <div>
                <p>Discover services for profile matching</p>
              </div>
            </>
          }
        >
          <InfoOutlined
            fontSize="medium"
            color="info"
            sx={{ "&:hover": { color: "info.main", cursor: "pointer" } }}
          />
        </MDTooltip>
      </MDBox>
      <MDBox>
        <MDTypography variant="button" color="text">
          Services that I might be interested in:
        </MDTypography>
        <Grid container py={2}>
          {renderServiceOptions(services, "servicesInterestedIn")}
        </Grid>
      </MDBox>
      <MDBox sx={{ borderTop: 1, borderColor: "#C7CCD0", paddingTop: "14px" }}>
        <MDTypography variant="button" color="text">
          Services that I can supply:
        </MDTypography>
        <Grid container py={2}>
          {renderServiceOptions(services, "servicesToSupply")}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default SignupService;
