import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import FormField from "layouts/pages/account/components/FormField";
import selectData from "../data/selectData";
import { useAuth } from "context/AuthContext";

const BasicInfo: React.FC = () => {
  const { loginResponse } = useAuth();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    birthMonth: "",
    birthDay: "",
    birthYear: "",
    email: "",
    location: "",
    phoneNumber: "",
    language: "English",
  });
  useEffect(() => {
    if (loginResponse?.user_profile) {
      const userProfile = loginResponse.user_profile;
      const founderDetails =
        loginResponse?.entity?.additional_info?.founderDetails?.founderDetails[0];

      setFormData({
        firstName: userProfile.first_name || "",
        lastName: userProfile.last_name || "",
        gender: founderDetails?.gender || "Male",
        birthMonth: "February", // If there's actual data for birth date, update it here
        birthDay: "1",
        birthYear: "2021",
        email: userProfile.email || "",
        location: userProfile.location || "",
        phoneNumber: userProfile.primary_phone || "",
        language: "English", // Adjust based on loginResponse, if available
      });
    }
  }, [loginResponse]);
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          {/* First Name and Last Name */}
          <Grid item xs={12} sm={6}>
            <FormField disabled label="First Name" value={formData.firstName} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField disabled label="Last Name" value={formData.lastName} />
          </Grid>

          {/* Gender and Birth Date */}

          <Grid item xs={12}>
            <Grid container spacing={3}>
              {/*
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  value={formData.gender}
                  options={selectData.gender}
                  renderInput={(params) => (
                    <FormField {...params} label="I'm" InputLabelProps={{ shrink: true }} />
                  )}
                />
              </Grid>
              */}
              <Grid item xs={12} sm={8}>
                {/*
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={5}>
                    <Autocomplete
                      value={formData.birthMonth}
                      options={selectData.birthDate}
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          label="Birth Date"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      value={formData.birthDay}
                      options={selectData.days}
                      renderInput={(params) => (
                        <FormField {...params} InputLabelProps={{ shrink: true }} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      value={formData.birthYear}
                      options={selectData.years}
                      renderInput={(params) => (
                        <FormField {...params} InputLabelProps={{ shrink: true }} />
                      )}
                    />
                  </Grid>
                </Grid>
                */}
              </Grid>
            </Grid>
          </Grid>

          {/* Email and Confirmation Email */}
          <Grid item xs={12} sm={6}>
            <FormField
              label="Email"
              value={formData.email}
              inputProps={{ type: "email" }}
              disabled
            />
          </Grid>
          {/*
          <Grid item xs={12} sm={6}>
            <FormField
              label="Confirmation Email"
              value={formData.email}
              inputProps={{ type: "email" }}
            />
          </Grid>
          */}

          {/* Location and Phone Number */}
          <Grid item xs={12} sm={6}>
            <FormField disabled label="Your Location" value={formData.location} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Phone Number"
              value={formData.phoneNumber}
              inputProps={{ type: "number" }}
              disabled
            />
          </Grid>

          {/* Language */}
          {/*
          <Grid item xs={12} md={6}>
            <Autocomplete
              value={formData.language}
              options={selectData.language}
              renderInput={(params) => (
                <FormField {...params} label="Language" InputLabelProps={{ shrink: true }} />
              )}
            />
          </Grid>
        */}
        </Grid>
      </MDBox>
    </Card>
  );
};

export default BasicInfo;
