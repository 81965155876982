/* didnt work - errs
type IndustrySubclass = {
  [key: number]: string;
};

type IndustryClass = {
  "Industry Class": string;
  Subclasses: IndustrySubclass;
};

type IndustryGroup = {
  "Industry Group": string;
  Classes: {
    [key: number]: IndustryClass;
  };
};

type IndustryDivision = {
  "Industry Division": string;
  Groups: {
    [key: number]: IndustryGroup;
  };
};

type IndustrySection = {
  "Industry Section": string;
  Divisions: {
    [key: number]: IndustryDivision;
  };
};

type IndustriesData = {
  [key: string]: IndustrySection;
};
*/
export interface Subclass {
  [key: string]: string;
}

export interface IndustryClass {
  "Industry Class": string;
  Subclasses: Subclass;
}

export interface IndustryGroup {
  "Industry Group": string;
  Classes: {
    [key: string]: IndustryClass;
  };
}

export interface IndustryDivision {
  "Industry Division": string;
  Groups: {
    [key: string]: IndustryGroup;
  };
}

export interface IndustrySection {
  "Industry Section": string;
  Divisions: {
    [key: string]: IndustryDivision;
  };
}

export interface IndustriesDataType {
  [key: string]: IndustrySection;
}

export const industriesData: IndustriesDataType = {
  "Section A": {
    "Industry Section": "Agriculture, forestry and fishing",
    Divisions: {
      1: {
        "Industry Division": "Crop and animal production, hunting and related service activities",
        Groups: {
          11: {
            "Industry Group": "Growing of non-perennial crops",
            Classes: {
              111: {
                "Industry Class":
                  "Growing of cereals (except rice), leguminous crops and oil seeds",
                Subclasses: {
                  1110: "Growing of cereals (except rice), leguminous crops and oil seeds",
                },
              },
              112: {
                "Industry Class": "Growing of rice",
                Subclasses: {
                  1120: "Growing of rice",
                },
              },
              113: {
                "Industry Class": "Growing of vegetables and melons, roots and tubers",
                Subclasses: {
                  1130: "Growing of vegetables and melons, roots and tubers",
                },
              },
              114: {
                "Industry Class": "Growing of sugar cane",
                Subclasses: {
                  1140: "Growing of sugar cane",
                },
              },
              115: {
                "Industry Class": "Growing of tobacco",
                Subclasses: {
                  1150: "Growing of tobacco",
                },
              },
              116: {
                "Industry Class": "Growing of fibre crops",
                Subclasses: {
                  1160: "Growing of fibre crops",
                },
              },
              119: {
                "Industry Class": "Growing of other non-perennial crops",
                Subclasses: {
                  1190: "Growing of other non-perennial crops",
                },
              },
            },
          },
          12: {
            "Industry Group": "Growing of perennial crops",
            Classes: {
              121: {
                "Industry Class": "Growing of grapes",
                Subclasses: {
                  1210: "Growing of grapes",
                },
              },
              122: {
                "Industry Class": "Growing of tropical and subtropical fruits",
                Subclasses: {
                  1220: "Growing of tropical and subtropical fruits",
                },
              },
              123: {
                "Industry Class": "Growing of citrus fruits",
                Subclasses: {
                  1230: "Growing of citrus fruits",
                },
              },
              124: {
                "Industry Class": "Growing of pome fruits and stone fruits",
                Subclasses: {
                  1240: "Growing of pome fruits and stone fruits",
                },
              },
              125: {
                "Industry Class": "Growing of other tree and bush fruits and nuts",
                Subclasses: {
                  1250: "Growing of other tree and bush fruits and nuts",
                },
              },
              126: {
                "Industry Class": "Growing of oleaginous fruits",
                Subclasses: {
                  1260: "Growing of oleaginous fruits",
                },
              },
              127: {
                "Industry Class": "Growing of beverage crops",
                Subclasses: {
                  1270: "Growing of beverage crops",
                },
              },
              128: {
                "Industry Class": "Growing of spices, aromatic, drug and pharmaceutical crops",
                Subclasses: {
                  1280: "Growing of spices, aromatic, drug and pharmaceutical crops",
                },
              },
              129: {
                "Industry Class": "Growing of other perennial crops",
                Subclasses: {
                  1290: "Growing of other perennial crops",
                },
              },
            },
          },
          13: {
            "Industry Group": "Plant propagation",
            Classes: {
              130: {
                "Industry Class": "Plant propagation",
                Subclasses: {
                  1300: "Plant propagation",
                },
              },
            },
          },
          14: {
            "Industry Group": "Animal production",
            Classes: {
              141: {
                "Industry Class": "Raising of cattle and buffaloes",
                Subclasses: {
                  1410: "Raising of cattle and buffaloes",
                },
              },
              142: {
                "Industry Class": "Raising of horses and other equines",
                Subclasses: {
                  1420: "Raising of horses and other equines",
                },
              },
              143: {
                "Industry Class": "Raising of camels and camelids",
                Subclasses: {
                  1430: "Raising of camels and camelids",
                },
              },
              144: {
                "Industry Class": "Raising of sheep and goats",
                Subclasses: {
                  1440: "Raising of sheep and goats",
                },
              },
              145: {
                "Industry Class": "Raising of swine/pigs",
                Subclasses: {
                  1450: "Raising of swine/pigs",
                },
              },
              146: {
                "Industry Class": "Raising of poultry",
                Subclasses: {
                  1460: "Raising of poultry",
                },
              },
              149: {
                "Industry Class": "Raising of other animals",
                Subclasses: {
                  1490: "Raising of other animals",
                },
              },
            },
          },
          15: {
            "Industry Group": "Mixed farming",
            Classes: {
              150: {
                "Industry Class": "Mixed farming",
                Subclasses: {
                  1500: "Mixed farming",
                },
              },
            },
          },
          16: {
            "Industry Group": "Support activities to agriculture and post-harvest crop activities",
            Classes: {
              161: {
                "Industry Class": "Support activities for crop production",
                Subclasses: {
                  1610: "Support activities for crop production",
                },
              },
              162: {
                "Industry Class": "Support activities for animal production",
                Subclasses: {
                  1620: "Support activities for animal production",
                },
              },
              163: {
                "Industry Class": "Post-harvest crop activities",
                Subclasses: {
                  1630: "Post-harvest crop activities",
                },
              },
              164: {
                "Industry Class": "Seed processing for propagation",
                Subclasses: {
                  1640: "Seed processing for propagation",
                },
              },
            },
          },
          17: {
            "Industry Group": "Hunting, trapping and related service activities",
            Classes: {
              170: {
                "Industry Class": "Hunting, trapping and related service activities",
                Subclasses: {
                  1700: "Hunting, trapping and related service activities",
                },
              },
            },
          },
        },
      },
      2: {
        "Industry Division": "Forestry and logging",
        Groups: {
          21: {
            "Industry Group": "Silviculture and other forestry activities",
            Classes: {
              210: {
                "Industry Class": "Silviculture and other forestry activities",
                Subclasses: {
                  2100: "Silviculture and other forestry activities",
                },
              },
            },
          },
          22: {
            "Industry Group": "Logging",
            Classes: {
              220: {
                "Industry Class": "Logging",
                Subclasses: {
                  2200: "Logging",
                },
              },
            },
          },
          23: {
            "Industry Group": "Gathering of non-wood forest products",
            Classes: {
              230: {
                "Industry Class": "Gathering of non-wood forest products",
                Subclasses: {
                  2300: "Gathering of non-wood forest products",
                },
              },
            },
          },
          24: {
            "Industry Group": "Support services to forestry",
            Classes: {
              240: {
                "Industry Class": "Support services to forestry",
                Subclasses: {
                  2400: "Support services to forestry",
                },
              },
            },
          },
        },
      },
      3: {
        "Industry Division": "Fishing and aquaculture",
        Groups: {
          31: {
            "Industry Group": "Fishing",
            Classes: {
              311: {
                "Industry Class": "Marine fishing",
                Subclasses: {
                  3110: "Marine fishing",
                },
              },
              312: {
                "Industry Class": "Freshwater fishing",
                Subclasses: {
                  3120: "Freshwater fishing",
                },
              },
            },
          },
          32: {
            "Industry Group": "Aquaculture",
            Classes: {
              321: {
                "Industry Class": "Marine aquaculture",
                Subclasses: {
                  3210: "Marine aquaculture",
                },
              },
              322: {
                "Industry Class": "Freshwater aquaculture",
                Subclasses: {
                  3220: "Freshwater aquaculture",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section B": {
    "Industry Section": "Mining and quarrying",
    Divisions: {
      5: {
        "Industry Division": "Mining of coal and lignite",
        Groups: {
          51: {
            "Industry Group": "Mining of hard coal",
            Classes: {
              510: {
                "Industry Class": "Mining of hard coal",
                Subclasses: {
                  5100: "Mining of hard coal",
                },
              },
            },
          },
          52: {
            "Industry Group": "Mining of lignite",
            Classes: {
              520: {
                "Industry Class": "Mining of lignite",
                Subclasses: {
                  5200: "Mining of lignite",
                },
              },
            },
          },
        },
      },
      6: {
        "Industry Division": "Extraction of crude petroleum and natural gas",
        Groups: {
          61: {
            "Industry Group": "Extraction of crude petroleum",
            Classes: {
              610: {
                "Industry Class": "Extraction of crude petroleum",
                Subclasses: {
                  6100: "Extraction of crude petroleum",
                },
              },
            },
          },
          62: {
            "Industry Group": "Extraction of natural gas",
            Classes: {
              620: {
                "Industry Class": "Extraction of natural gas",
                Subclasses: {
                  6200: "Extraction of natural gas",
                },
              },
            },
          },
        },
      },
      7: {
        "Industry Division": "Mining of metal ores",
        Groups: {
          71: {
            "Industry Group": "Mining of iron ores",
            Classes: {
              710: {
                "Industry Class": "Mining of iron ores",
                Subclasses: {
                  7100: "Mining of iron ores",
                },
              },
            },
          },
          72: {
            "Industry Group": "Mining of non-ferrous metal ores",
            Classes: {
              721: {
                "Industry Class": "Mining of uranium and thorium ores",
                Subclasses: {
                  7210: "Mining of uranium and thorium ores",
                },
              },
              729: {
                "Industry Class": "Mining of other non-ferrous metal ores",
                Subclasses: {
                  7291: "Mining of gold",
                  7292: "Mining of chrome",
                  7293: "Mining of copper",
                  7294: "Mining of manganese",
                  7295: "Mining of platinum group metals",
                  7299: "Other mining of non-ferrous metal ores n.e.c.",
                },
              },
            },
          },
        },
      },
      8: {
        "Industry Division": "Other mining and quarrying",
        Groups: {
          81: {
            "Industry Group": "Quarrying of stone, sand and clay",
            Classes: {
              810: {
                "Industry Class": "Quarrying of stone, sand and clay",
                Subclasses: {
                  8101: "Quarrying of dimension stone",
                  8102: "Quarrying of limestone and limeworks",
                  8109: "Other quarrying of stone, sand and clay",
                },
              },
            },
          },
          89: {
            "Industry Group": "Mining and quarrying n.e.c.",
            Classes: {
              891: {
                "Industry Class": "Mining of chemical and fertilizer minerals",
                Subclasses: {
                  8911: "Mining of phosphates",
                  8919: "Other mining of chemical and fertilizer minerals",
                },
              },
              892: {
                "Industry Class": "Extraction of peat",
                Subclasses: {
                  8920: "Extraction of peat",
                },
              },
              893: {
                "Industry Class": "Extraction of salt",
                Subclasses: {
                  8930: "Extraction of salt",
                },
              },
              899: {
                "Industry Class": "Other mining and quarrying n.e.c.",
                Subclasses: {
                  8991: "Mining of diamonds",
                  8992: "Mining of precious and semi-precious stones, except diamonds",
                  8999: "Other mining and quarrying n.e.c.",
                },
              },
            },
          },
        },
      },
      9: {
        "Industry Division": "Mining support service activities",
        Groups: {
          91: {
            "Industry Group": "Support activities for petroleum and natural gas extraction",
            Classes: {
              910: {
                "Industry Class": "Support activities for petroleum and natural gas extraction",
                Subclasses: {
                  9100: "Support activities for petroleum and natural gas extraction",
                },
              },
            },
          },
          99: {
            "Industry Group": "Support activities for other mining and quarrying",
            Classes: {
              990: {
                "Industry Class": "Support activities for other mining and quarrying",
                Subclasses: {
                  9901: "Service activities incidental to mining of minerals on a fee or contract basis",
                  9909: "Other support activities for other mining and quarrying n.e.c.",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section C": {
    "Industry Section": "Manufacturing",
    Divisions: {
      10: {
        "Industry Division": "Manufacturing of food products",
        Groups: {
          101: {
            "Industry Group": "Processing and preserving of meat",
            Classes: {
              1010: {
                "Industry Class": "Processing and preserving of meat",
                Subclasses: {
                  10101:
                    "Slaughtering, dressing and packing of livestock, including poultry and small game for\nmeat",
                  10102:
                    "Manufacture of prepared and preserved meat, including sausage; by-products (hides,\nbones, etc.)",
                  10103: "Production of lard and other edible fats",
                  10109: "Other processing and preserving of meat",
                },
              },
            },
          },
          102: {
            "Industry Group": "Processing and preserving of fish, crustaceans and molluscs",
            Classes: {
              1020: {
                "Industry Class": "Processing and preserving of fish, crustaceans and molluscs",
                Subclasses: {
                  10200: "Processing and preserving of fish, crustaceans and molluscs",
                },
              },
            },
          },
          103: {
            "Industry Group": "Processing and preserving of fruit and vegetables",
            Classes: {
              1030: {
                "Industry Class": "Processing and preserving of fruit and vegetables",
                Subclasses: {
                  10300: "Processing and preserving of fruit and vegetables",
                },
              },
            },
          },
          104: {
            "Industry Group": "Manufacture of vegetable and animal oils and fats",
            Classes: {
              1040: {
                "Industry Class": "Manufacture of vegetable and animal oils and fats",
                Subclasses: {
                  10401: "Manufacture of crude oil and oilseed cake and meal",
                  10402: "Manufacture of compound cooking fats, margarine and edible oils",
                },
              },
            },
          },
          105: {
            "Industry Group": "Manufacture of dairy products",
            Classes: {
              1050: {
                "Industry Class": "Manufacture of dairy products",
                Subclasses: {
                  10501: "Processing of fresh milk",
                  10502: "Manufacture of butter and cheese",
                  10503:
                    "Manufacture of ice cream and other edible ice (whether or not containing cream or\nchocolate)",
                  10504:
                    "Manufacture of milk powder, condensed milk and other edible milk products, e.g. ghee,\ncasein or lactose",
                },
              },
            },
          },
          106: {
            "Industry Group": "Manufacture of grain mill products, starches and starch products",
            Classes: {
              1061: {
                "Industry Class": "Manufacture of grain mill products",
                Subclasses: {
                  10611:
                    "Manufacture of flour and grain mill products, including rice and vegetable milling; grain\nmill residues",
                  10612: "Manufacture of breakfast foods",
                },
              },
              1062: {
                "Industry Class": "Manufacture of starches and starch products",
                Subclasses: {
                  10620: "Manufacture of starches and starch products",
                },
              },
            },
          },
          107: {
            "Industry Group": "Manufacture of other products",
            Classes: {
              1071: {
                "Industry Class": "Manufacture of bakery products",
                Subclasses: {
                  10710: "Manufacture of bakery products",
                },
              },
              1072: {
                "Industry Class": "Manufacture of sugar",
                Subclasses: {
                  10720: "Manufacture of sugar",
                },
              },
              1073: {
                "Industry Class": "Manufacture of cocoa, chocolate and sugar confectionery",
                Subclasses: {
                  10730: "Manufacture of cocoa, chocolate and sugar confectionery",
                },
              },
              1074: {
                "Industry Class":
                  "Manufacture of macaroni, noodles, couscous and similar farinaceous products",
                Subclasses: {
                  10740:
                    "Manufacture of macaroni, noodles, couscous and similar farinaceous products",
                },
              },
              1075: {
                "Industry Class": "Manufacture of prepared meals and dishes",
                Subclasses: {
                  10750: "Manufacture of prepared meals and dishes",
                },
              },
              1079: {
                "Industry Class": "Manufacture of other food products n.e.c.",
                Subclasses: {
                  10791: "Manufacture of coffee, coffee substitutes and tea",
                  10792: "Manufacture of nut foods",
                  10799:
                    "Manufacture of spices, condiments, vinegar, yeast, egg products, soups and other food\nproducts n.e.c.",
                },
              },
            },
          },
          108: {
            "Industry Group": "Manufacture of prepared animal feeds",
            Classes: {
              1080: {
                "Industry Class": "Manufacture of prepared animal feeds",
                Subclasses: {
                  10800: "Manufacture of prepared animal feeds",
                },
              },
            },
          },
        },
      },
      11: {
        "Industry Division": "Manufacturing of beverages",
        Groups: {
          110: {
            "Industry Group": "Manufacturing of beverages",
            Classes: {
              1101: {
                "Industry Class": "Distilling, rectifying and blending of spirits",
                Subclasses: {
                  11010: "Distilling, rectifying and blending of spirits",
                },
              },
              1102: {
                "Industry Class": "Manufacture of wines",
                Subclasses: {
                  11020: "Manufacture of wines",
                },
              },
              1103: {
                "Industry Class": "Manufacture of malt liquors and malt",
                Subclasses: {
                  11031: "Manufacture of malt liquors such as beer, ale, porter and stout",
                  11032: "Manufacture of sorghum beer",
                  11033: "Manufacture of malt",
                },
              },
              1104: {
                "Industry Class":
                  "Manufacture of soft drinks; production of mineral waters and other bottled waters",
                Subclasses: {
                  11040:
                    "Manufacture of soft drinks; production of mineral waters and other bottled waters",
                },
              },
            },
          },
        },
      },
      12: {
        "Industry Division": "Manufacturing of tobacco products",
        Groups: {
          120: {
            "Industry Group": "Manufacture of tobacco products",
            Classes: {
              1200: {
                "Industry Class": "Manufacture of tobacco products",
                Subclasses: {
                  12000: "Manufacture of tobacco products",
                },
              },
            },
          },
        },
      },
      13: {
        "Industry Division": "Manufacturing of textiles",
        Groups: {
          131: {
            "Industry Group": "Spinning, weaving and finishing of textiles",
            Classes: {
              1311: {
                "Industry Class": "Preparation and spinning of textile fibres",
                Subclasses: {
                  13111:
                    "Preparatory activities in respect of animal fibres, including washing, combing and\ncarding of wool",
                  13112: "Preparatory activities in respect of vegetable fibres",
                  13119: "Other preparation and spinning of textile fibres",
                },
              },
              1312: {
                "Industry Class": "Weaving of textiles",
                Subclasses: {
                  13120: "Weaving of textiles",
                },
              },
              1313: {
                "Industry Class": "Finishing of textiles",
                Subclasses: {
                  13130: "Finishing of textiles",
                },
              },
            },
          },
          139: {
            "Industry Group": "Manufacture of other textiles",
            Classes: {
              1391: {
                "Industry Class": "Manufacture of knitted and crocheted fabrics",
                Subclasses: {
                  13910: "Manufacture of knitted and crocheted fabrics",
                },
              },
              1392: {
                "Industry Class": "Manufacture of made-up textile articles, except apparel",
                Subclasses: {
                  13921:
                    "Manufacture of blankets, made-up furnishing articles and stuffed articles",
                  13922:
                    "Manufacture of tents, tarpaulins, sails and other canvas goods and car seat covers",
                },
              },
              1393: {
                "Industry Class": "Manufacture of carpets and rugs",
                Subclasses: {
                  13930: "Manufacture of carpets and rugs",
                },
              },
              1394: {
                "Industry Class": "Manufacture of cordage, rope, twine and netting",
                Subclasses: {
                  13940: "Manufacture of cordage, rope, twine and netting",
                },
              },
              1399: {
                "Industry Class": "Manufacture of other textiles n.e.c.",
                Subclasses: {
                  13990: "Manufacture of other textiles n.e.c.",
                },
              },
            },
          },
        },
      },
      14: {
        "Industry Division": "Manufacturing of wearing apparel",
        Groups: {
          141: {
            "Industry Group": "Manufacturing of wearing apparel, except fur apparel",
            Classes: {
              1410: {
                "Industry Class": "Manufacturing of wearing apparel, except fur apparel",
                Subclasses: {
                  14100: "Manufacturing of wearing apparel, except fur apparel",
                },
              },
            },
          },
          142: {
            "Industry Group": "Manufacture of articles of fur",
            Classes: {
              1420: {
                "Industry Class": "Manufacture of articles of fur",
                Subclasses: {
                  14200: "Manufacture of articles of fur",
                },
              },
            },
          },
          143: {
            "Industry Group": "Manufacture of knitted and crocheted apparel",
            Classes: {
              1430: {
                "Industry Class": "Manufacture of knitted and crocheted apparel",
                Subclasses: {
                  14300: "Manufacture of knitted and crocheted apparel",
                },
              },
            },
          },
        },
      },
      15: {
        "Industry Division": "Manufacturing of leather and related products",
        Groups: {
          151: {
            "Industry Group":
              "Tanning and dressing of leather; manufacture of luggage, handbags, saddlery\nand harness; dressing and dyeing of fur",
            Classes: {
              1511: {
                "Industry Class": "Tanning and dressing of leather; dressing and dyeing of fur",
                Subclasses: {
                  15110: "Tanning and dressing of leather; dressing and dyeing of fur",
                },
              },
              1512: {
                "Industry Class":
                  "Manufacture of luggage, handbags and the like, saddlery and harness",
                Subclasses: {
                  15120: "Manufacture of luggage, handbags and the like, saddlery and harness",
                },
              },
            },
          },
          152: {
            "Industry Group": "Manufacture of footwear",
            Classes: {
              1520: {
                "Industry Class": "Manufacture of footwear",
                Subclasses: {
                  15200: "Manufacture of footwear",
                },
              },
            },
          },
        },
      },
      16: {
        "Industry Division":
          "Manufacturing of wood and of product of wood and cork, except furniture; manufacture of articles of straw and plaiting materials",
        Groups: {
          161: {
            "Industry Group": "Sawmilling and planing of wood",
            Classes: {
              1610: {
                "Industry Class": "Sawmilling and planing of wood",
                Subclasses: {
                  16100: "Sawmilling and planing of wood",
                },
              },
            },
          },
          162: {
            "Industry Group": "Manufacture of products of wood, cork, straw and plaiting materials",
            Classes: {
              1621: {
                "Industry Class": "Manufacture of veneer sheets and wood-based panels",
                Subclasses: {
                  16210: "Manufacture of veneer sheets and wood-based panels",
                },
              },
              1622: {
                "Industry Class": "Manufacture of builders' carpentry and joinery",
                Subclasses: {
                  16220: "Manufacture of builders' carpentry and joinery",
                },
              },
              1623: {
                "Industry Class": "Manufacture of wooden containers",
                Subclasses: {
                  16230: "Manufacture of wooden containers",
                },
              },
              1629: {
                "Industry Class":
                  "Manufacture of other products of wood; manufacture of articles of cork, straw and\nplaiting materials",
                Subclasses: {
                  16291: "Manufacture of other products of wood",
                  16292:
                    "Manufacturing of other articles of cork, straw and plaiting materials, including\nwoodcarving and woodturning n.e.c.",
                },
              },
            },
          },
        },
      },
      17: {
        "Industry Division": "Manufacturing of paper and paper products",
        Groups: {
          170: {
            "Industry Group": "Manufacture of paper and paper products",
            Classes: {
              1701: {
                "Industry Class": "Manufacture of pulp, paper and paperboard",
                Subclasses: {
                  17010: "Manufacture of pulp, paper and paperboard",
                },
              },
              1702: {
                "Industry Class":
                  "Manufacture of corrugated paper and paperboard and of containers of paper and paperboard",
                Subclasses: {
                  17021: "Manufacture of corrugated paper and paperboard",
                  17022: "Manufacture of containers of paper and paperboard",
                },
              },
              1709: {
                "Industry Class": "Manufacture of other articles of paper and paperboard",
                Subclasses: {
                  17090: "Manufacture of other articles of paper and paperboard",
                },
              },
            },
          },
        },
      },
      18: {
        "Industry Division": "Printing and reproduction of recorded media",
        Groups: {
          181: {
            "Industry Group": "Printing and service activities related to printing",
            Classes: {
              1811: {
                "Industry Class": "Printing",
                Subclasses: {
                  18110: "Printing",
                },
              },
              1812: {
                "Industry Class": "Service activities related to printing",
                Subclasses: {
                  18120: "Service activities related to printing",
                },
              },
            },
          },
          182: {
            "Industry Group": "Reproduction of recorded media",
            Classes: {
              1820: {
                "Industry Class": "Reproduction of recorded media",
                Subclasses: {
                  18200: "Reproduction of recorded media",
                },
              },
            },
          },
        },
      },
      19: {
        "Industry Division": "Manufacture of coke and refined petroleum products",
        Groups: {
          191: {
            "Industry Group": "Manufacture of coke oven products",
            Classes: {
              1910: {
                "Industry Class": "Manufacture of coke oven products",
                Subclasses: {
                  19100: "Manufacture of coke oven products",
                },
              },
            },
          },
          192: {
            "Industry Group": "Manufacture of refined petroleum products",
            Classes: {
              1920: {
                "Industry Class": "Manufacture of refined petroleum products",
                Subclasses: {
                  19200: "Manufacture of refined petroleum products",
                },
              },
            },
          },
        },
      },
      20: {
        "Industry Division": "Manufacture of chemicals and chemical products",
        Groups: {
          201: {
            "Industry Group":
              "Manufacture of basic chemicals, fertilizers and nitrogen compounds, plastics and\nsynthetic rubber in primary forms",
            Classes: {
              2011: {
                "Industry Class": "Manufacture of basic chemicals",
                Subclasses: {
                  20110: "Manufacture of basic chemicals",
                },
              },
              2012: {
                "Industry Class": "Manufacture of fertilizers and nitrogen compounds",
                Subclasses: {
                  20120: "Manufacture of fertilizers and nitrogen compounds",
                },
              },
              2013: {
                "Industry Class": "Manufacture of plastics and synthetic rubber in primary forms",
                Subclasses: {
                  20130: "Manufacture of plastics and synthetic rubber in primary forms",
                },
              },
            },
          },
          202: {
            "Industry Group": "Manufacture of other chemicals products",
            Classes: {
              2021: {
                "Industry Class": "Manufacture of pesticides and other agrochemical products",
                Subclasses: {
                  20210: "Manufacture of pesticides and other agrochemical products",
                },
              },
              2022: {
                "Industry Class":
                  "Manufacture of paints, varnishes and similar coatings, printing ink and mastics",
                Subclasses: {
                  20220:
                    "Manufacture of paints, varnishes and similar coatings, printing ink and mastics",
                },
              },
              2023: {
                "Industry Class":
                  "Manufacture of soap and detergents, cleaning and polishing preparations, perfumes\nand toilet preparations",
                Subclasses: {
                  20230:
                    "Manufacture of soap and detergents, cleaning and polishing preparations, perfumes\nand toilet preparations",
                },
              },
              2029: {
                "Industry Class": "Manufacture of other chemical products n.e.c.",
                Subclasses: {
                  20291: "Manufacture of explosives and pyrotechnic products",
                  20292: "Manufacture of adhesives, glues, sizes and cements",
                  20299: "Manufacture of other chemical products n.e.c.",
                },
              },
            },
          },
          203: {
            "Industry Group": "Manufacture of man-made fibres",
            Classes: {
              2030: {
                "Industry Class": "Manufacture of man-made fibres",
                Subclasses: {
                  20300: "Manufacture of man-made fibres",
                },
              },
            },
          },
        },
      },
      21: {
        "Industry Division":
          "Manufacture of pharmaceuticals, medicinal chemical and botanical Products",
        Groups: {
          210: {
            "Industry Group":
              "Manufacture of pharmaceuticals, medicinal chemical and botanical products",
            Classes: {
              2100: {
                "Industry Class":
                  "Manufacture of pharmaceuticals, medicinal chemical and botanical products",
                Subclasses: {
                  21000:
                    "Manufacture of pharmaceuticals, medicinal chemical and botanical products",
                },
              },
            },
          },
        },
      },
      22: {
        "Industry Division": "Manufacture of rubber and plastic products",
        Groups: {
          221: {
            "Industry Group": "Manufacture of rubber products",
            Classes: {
              2211: {
                "Industry Class":
                  "Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres",
                Subclasses: {
                  22110:
                    "Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres",
                },
              },
              2219: {
                "Industry Class": "Manufacture of other rubber products",
                Subclasses: {
                  22190: "Manufacture of other rubber products",
                },
              },
            },
          },
          222: {
            "Industry Group": "Manufacture of plastic products",
            Classes: {
              2220: {
                "Industry Class": "Manufacture of plastic products",
                Subclasses: {
                  22200: "Manufacture of plastic products",
                },
              },
            },
          },
        },
      },
      23: {
        "Industry Division": "Manufacture of other non-metallic mineral products",
        Groups: {
          231: {
            "Industry Group": "Manufacture of glass and glass products",
            Classes: {
              2310: {
                "Industry Class": "Manufacture of glass and glass products",
                Subclasses: {
                  23100: "Manufacture of glass and glass products",
                },
              },
            },
          },
          239: {
            "Industry Group": "Manufacture of non-metallic mineral products n.e.c",
            Classes: {
              2391: {
                "Industry Class": "Manufacture of refractory products",
                Subclasses: {
                  23910: "Manufacture of refractory products",
                },
              },
              2392: {
                "Industry Class": "Manufacture of clay building materials",
                Subclasses: {
                  23920: "Manufacture of clay building materials",
                },
              },
              2393: {
                "Industry Class": "Manufacture of other porcelain and ceramic products",
                Subclasses: {
                  23930: "Manufacture of other porcelain and ceramic products",
                },
              },
              2394: {
                "Industry Class": "Manufacture of cement, lime and plaster",
                Subclasses: {
                  23940: "Manufacture of cement, lime and plaster",
                },
              },
              2395: {
                "Industry Class": "Manufacture of articles of concrete, cement and plaster",
                Subclasses: {
                  23950: "Manufacture of articles of concrete, cement and plaster",
                },
              },
              2396: {
                "Industry Class": "Cutting, shaping and finishing of stone",
                Subclasses: {
                  23960: "Cutting, shaping and finishing of stone",
                },
              },
              2399: {
                "Industry Class": "Manufacture of other non-metallic mineral products n.e.c.",
                Subclasses: {
                  23990: "Manufacture of other non-metallic mineral products n.e.c.",
                },
              },
            },
          },
        },
      },
      24: {
        "Industry Division": "Manufacture of basic metals",
        Groups: {
          241: {
            "Industry Group": "Manufacture of basic iron and steel",
            Classes: {
              2410: {
                "Industry Class": "Manufacture of basic iron and steel",
                Subclasses: {
                  24101: "Basic iron and steel industries; except steel pipe and tube mills",
                  24102: "Steel pipe and tube mills",
                },
              },
            },
          },
          242: {
            "Industry Group": "Manufacture of basic precious and other non-ferrous metals",
            Classes: {
              2420: {
                "Industry Class": "Manufacture of basic precious and other non-ferrous metals",
                Subclasses: {
                  24201: "Refining of precious metals, e.g. gold, silver d platinum",
                  24202:
                    "Manufacture of primary non-ferrous metal products, excluding precious metals",
                },
              },
            },
          },
          243: {
            "Industry Group": "Casting of metals",
            Classes: {
              2431: {
                "Industry Class": "Casting of iron and steel",
                Subclasses: {
                  24310: "Casting of iron and steel",
                },
              },
              2432: {
                "Industry Class": "Casting of non-ferrous metals",
                Subclasses: {
                  24320: "Casting of non-ferrous metals",
                },
              },
            },
          },
        },
      },
      25: {
        "Industry Division":
          "Manufacture of fabricated metal products, except machinery and Equipment",
        Groups: {
          251: {
            "Industry Group":
              "Manufacture of structural metal products, tanks, reservoirs and steam generators",
            Classes: {
              2511: {
                "Industry Class": "Manufacture of structural metal products",
                Subclasses: {
                  25111: "Manufacture of metal structures or parts thereof",
                  25119:
                    "Manufacture of other structural metal products, e.g. metal doors, windows and gates",
                },
              },
              2512: {
                "Industry Class": "Manufacture of tanks, reservoirs and containers of metal",
                Subclasses: {
                  25120: "Manufacture of tanks, reservoirs and containers of metal",
                },
              },
              2513: {
                "Industry Class":
                  "Manufacture of steam generators, except central heating hot water boilers",
                Subclasses: {
                  25130:
                    "Manufacture of steam generators, except central heating hot water boilers",
                },
              },
            },
          },
          252: {
            "Industry Group": "Manufacture of weapons and ammunition",
            Classes: {
              2520: {
                "Industry Class": "Manufacture of weapons and ammunition",
                Subclasses: {
                  25200: "Manufacture of weapons and ammunition",
                },
              },
            },
          },
          259: {
            "Industry Group":
              "Manufacture of other fabricated metal products; metalworking service activities",
            Classes: {
              2591: {
                "Industry Class":
                  "Forging, pressing, stamping and roll-forming of metal; powder metallurgy",
                Subclasses: {
                  25910: "Forging, pressing, stamping and roll-forming of metal; powder metallurgy",
                },
              },
              2592: {
                "Industry Class": "Treatment and coating of metals; machining",
                Subclasses: {
                  25921: "Treating and coating of metals",
                  25922: "General mechanical engineering on a fee or contract basis",
                },
              },
              2593: {
                "Industry Class": "Manufacture of cutlery, hand tools and general hardware",
                Subclasses: {
                  25930: "Manufacture of cutlery, hand tools and general hardware",
                },
              },
              2599: {
                "Industry Class": "Manufacture of other fabricated metal products n.e.c.",
                Subclasses: {
                  25991: "Manufacture of metal containers, e.g. cans and tins",
                  25992: "Manufacture of cables and wire products",
                  25993: "Manufacture of springs (all types)",
                  25994: "Manufacture of metal fasteners",
                  25999: "Other Manufacture of other fabricated metal products n.e.c.",
                },
              },
            },
          },
        },
      },
      26: {
        "Industry Division": "Manufacture of computer, electronic and optical products",
        Groups: {
          261: {
            "Industry Group": "Manufacture of electronic components and boards",
            Classes: {
              2610: {
                "Industry Class": "Manufacture of electronic components and boards",
                Subclasses: {
                  26100: "Manufacture of electronic components and boards",
                },
              },
            },
          },
          262: {
            "Industry Group": "Manufacture of computers and peripheral equipment",
            Classes: {
              2620: {
                "Industry Class": "Manufacture of computers and peripheral equipment",
                Subclasses: {
                  26200: "Manufacture of computers and peripheral equipment",
                },
              },
            },
          },
          263: {
            "Industry Group": "Manufacture of communication equipment",
            Classes: {
              2630: {
                "Industry Class": "Manufacture of communication equipment",
                Subclasses: {
                  26300: "Manufacture of communication equipment",
                },
              },
            },
          },
          264: {
            "Industry Group": "Manufacture of consumer electronics",
            Classes: {
              2640: {
                "Industry Class": "Manufacture of consumer electronics",
                Subclasses: {
                  26400: "Manufacture of consumer electronics",
                },
              },
            },
          },
          265: {
            "Industry Group":
              "Manufacture of measuring, testing, navigating and control equipment; watches and clocks",
            Classes: {
              2651: {
                "Industry Class":
                  "Manufacture of measuring, testing, navigating and control equipment",
                Subclasses: {
                  26510: "Manufacture of measuring, testing, navigating and control equipment",
                },
              },
              2652: {
                "Industry Class": "Manufacture of watches and clocks",
                Subclasses: {
                  26520: "Manufacture of watches and clocks",
                },
              },
            },
          },
          266: {
            "Industry Group":
              "Manufacture of irradiation, electromedical and electrotherapeutic equipment",
            Classes: {
              2660: {
                "Industry Class":
                  "Manufacture of irradiation, electromedical and electrotherapeutic equipment",
                Subclasses: {
                  26600:
                    "Manufacture of irradiation, electromedical and electrotherapeutic equipment",
                },
              },
            },
          },
          267: {
            "Industry Group": "Manufacture of optical instruments and photographic equipment",
            Classes: {
              2670: {
                "Industry Class": "Manufacture of optical instruments and photographic equipment",
                Subclasses: {
                  26700: "Manufacture of optical instruments and photographic equipment",
                },
              },
            },
          },
          268: {
            "Industry Group": "Manufacture of magnetic and optical media",
            Classes: {
              2680: {
                "Industry Class": "Manufacture of magnetic and optical media",
                Subclasses: {
                  26800: "Manufacture of magnetic and optical media",
                },
              },
            },
          },
        },
      },
      27: {
        "Industry Division": "Manufacture of electrical equipment",
        Groups: {
          271: {
            "Industry Group":
              "Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus",
            Classes: {
              2710: {
                "Industry Class":
                  "Manufacture of electric motors, generators, transformers and electricity distribution and\ncontrol apparatus",
                Subclasses: {
                  27100:
                    "Manufacture of electric motors, generators, transformers and electricity distribution and\ncontrol apparatus",
                },
              },
            },
          },
          272: {
            "Industry Group": "Manufacture of batteries and accumulators",
            Classes: {
              2720: {
                "Industry Class": "Manufacture of batteries and accumulators",
                Subclasses: {
                  27200: "Manufacture of batteries and accumulators",
                },
              },
            },
          },
          273: {
            "Industry Group": "Manufacture of wiring and wiring devices",
            Classes: {
              2731: {
                "Industry Class": "Manufacture of fibre optic cables",
                Subclasses: {
                  27310: "Manufacture of fibre optic cables",
                },
              },
              2732: {
                "Industry Class": "Manufacture of other electronic and electric wires and cables",
                Subclasses: {
                  27320: "Manufacture of other electronic and electric wires and cables",
                },
              },
              2733: {
                "Industry Class": "Manufacture of wiring devices",
                Subclasses: {
                  27330: "Manufacture of wiring devices",
                },
              },
            },
          },
          274: {
            "Industry Group": "Manufacture of electric lighting equipment",
            Classes: {
              2740: {
                "Industry Class": "Manufacture of electric lighting equipment",
                Subclasses: {
                  27400: "Manufacture of electric lighting equipment",
                },
              },
            },
          },
          275: {
            "Industry Group": "Manufacture of domestic appliances",
            Classes: {
              2750: {
                "Industry Class": "Manufacture of domestic appliances",
                Subclasses: {
                  27500: "Manufacture of domestic appliances",
                },
              },
            },
          },
          279: {
            "Industry Group": "Manufacture of other electrical equipment",
            Classes: {
              2790: {
                "Industry Class": "Manufacture of other electrical equipment",
                Subclasses: {
                  27900: "Manufacture of other electrical equipment",
                },
              },
            },
          },
        },
      },
      28: {
        "Industry Division": "Manufacture of machinery and equipment n.e.c.",
        Groups: {
          281: {
            "Industry Group": "Manufacture of general-purpose machinery",
            Classes: {
              2811: {
                "Industry Class":
                  "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines",
                Subclasses: {
                  28110:
                    "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines",
                },
              },
              2812: {
                "Industry Class": "Manufacture of fluid power equipment",
                Subclasses: {
                  28120: "Manufacture of fluid power equipment",
                },
              },
              2813: {
                "Industry Class": "Manufacture of other pumps, compressors, taps and valves",
                Subclasses: {
                  28130: "Manufacture of other pumps, compressors, taps and valves",
                },
              },
              2814: {
                "Industry Class": "Manufacture of bearings, gears, gearing and driving elements",
                Subclasses: {
                  28140: "Manufacture of bearings, gears, gearing and driving elements",
                },
              },
              2815: {
                "Industry Class": "Manufacture of ovens, furnaces and furnace burners",
                Subclasses: {
                  28150: "Manufacture of ovens, furnaces and furnace burners",
                },
              },
              2816: {
                "Industry Class": "Manufacture of lifting and handling equipment",
                Subclasses: {
                  28160: "Manufacture of lifting and handling equipment",
                },
              },
              2817: {
                "Industry Class":
                  "Manufacture of office machinery and equipment (except computers and peripheral\nequipment)",
                Subclasses: {
                  28170:
                    "Manufacture of office machinery and equipment (except computers and peripheral\nequipment)",
                },
              },
              2818: {
                "Industry Class": "Manufacture of power-driven hand tools",
                Subclasses: {
                  28180: "Manufacture of power-driven hand tools",
                },
              },
              2819: {
                "Industry Class": "Manufacture of other general-purpose machinery",
                Subclasses: {
                  28190: "Manufacture of other general-purpose machinery",
                },
              },
            },
          },
          282: {
            "Industry Group": "Manufacture of special-purpose machinery",
            Classes: {
              2821: {
                "Industry Class": "Manufacture of agricultural and forestry machinery",
                Subclasses: {
                  28210: "Manufacture of agricultural and forestry machinery",
                },
              },
              2822: {
                "Industry Class": "Manufacture of metal-forming machinery and machine tools",
                Subclasses: {
                  28220: "Manufacture of metal-forming machinery and machine tools",
                },
              },
              2823: {
                "Industry Class": "Manufacture of machinery for metallurgy",
                Subclasses: {
                  28230: "Manufacture of machinery for metallurgy",
                },
              },
              2824: {
                "Industry Class": "Manufacture of machinery for mining, quarrying and construction",
                Subclasses: {
                  28240: "Manufacture of machinery for mining, quarrying and construction",
                },
              },
              2825: {
                "Industry Class":
                  "Manufacture of machinery for food, beverage and tobacco processing",
                Subclasses: {
                  28250: "Manufacture of machinery for food, beverage and tobacco processing",
                },
              },
              2826: {
                "Industry Class":
                  "Manufacture of machinery for textile, apparel and leather production",
                Subclasses: {
                  28260: "Manufacture of machinery for textile, apparel and leather production",
                },
              },
              2829: {
                "Industry Class": "Manufacture of other special-purpose machinery",
                Subclasses: {
                  28290: "Manufacture of other special-purpose machinery",
                },
              },
            },
          },
        },
      },
      29: {
        "Industry Division": "Manufacture of motor vehicles, trailers and semi-trailers",
        Groups: {
          291: {
            "Industry Group": "Manufacture of motor vehicles",
            Classes: {
              2910: {
                "Industry Class": "Manufacture of motor vehicles",
                Subclasses: {
                  29100: "Manufacture of motor vehicles",
                },
              },
            },
          },
          292: {
            "Industry Group":
              "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers",
            Classes: {
              2920: {
                "Industry Class":
                  "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers",
                Subclasses: {
                  29200:
                    "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-",
                },
              },
            },
          },
          293: {
            "Industry Group": "Manufacture of parts and accessories for motor vehicles",
            Classes: {
              2930: {
                "Industry Class": "Manufacture of parts and accessories for motor vehicles",
                Subclasses: {
                  29300: "Manufacture of parts and accessories for motor vehicles",
                },
              },
            },
          },
        },
      },
      30: {
        "Industry Division": "Manufacture of other transport equipment",
        Groups: {
          301: {
            "Industry Group": "Building of ships and boats",
            Classes: {
              3011: {
                "Industry Class": "Building of ships and floating structures",
                Subclasses: {
                  30110: "Building of ships and floating structures",
                },
              },
              3012: {
                "Industry Class": "Building of pleasure and sporting boats",
                Subclasses: {
                  30120: "Building of pleasure and sporting boats",
                },
              },
            },
          },
          302: {
            "Industry Group": "Manufacture of railway locomotives and rolling stock",
            Classes: {
              3020: {
                "Industry Class": "Manufacture of railway locomotives and rolling stock",
                Subclasses: {
                  30200: "Manufacture of railway locomotives and rolling stock",
                },
              },
            },
          },
          303: {
            "Industry Group": "Manufacture of air and spacecraft and related machinery",
            Classes: {
              3030: {
                "Industry Class": "Manufacture of air and spacecraft and related machinery",
                Subclasses: {
                  30300: "Manufacture of air and spacecraft and related machinery",
                },
              },
            },
          },
          304: {
            "Industry Group": "Manufacture of military fighting vehicles",
            Classes: {
              3040: {
                "Industry Class": "Manufacture of military fighting vehicles",
                Subclasses: {
                  30400: "Manufacture of military fighting vehicles",
                },
              },
            },
          },
          309: {
            "Industry Group": "Manufacture of other transport equipment n.e.c",
            Classes: {
              3091: {
                "Industry Class": "Manufacture of motorcycles",
                Subclasses: {
                  30910: "Manufacture of motorcycles",
                },
              },
              3092: {
                "Industry Class": "Manufacture of bicycles and invalid carriages",
                Subclasses: {
                  30920: "Manufacture of bicycles and invalid carriages",
                },
              },
              3099: {
                "Industry Class": "Manufacture of other transport equipment n.e.c.",
                Subclasses: {
                  30990: "Manufacture of other transport equipment n.e.c.",
                },
              },
            },
          },
        },
      },
      31: {
        "Industry Division": "Manufacture of furniture",
        Groups: {
          310: {
            "Industry Group": "Manufacture of furniture",
            Classes: {
              3100: {
                "Industry Class": "Manufacture of furniture",
                Subclasses: {
                  31000: "Manufacture of furniture",
                },
              },
            },
          },
        },
      },
      32: {
        "Industry Division": "Other manufacturing",
        Groups: {
          321: {
            "Industry Group": "Manufacture of jewellery, bijouterie and related articles",
            Classes: {
              3211: {
                "Industry Class": "Manufacture of jewellery and related articles",
                Subclasses: {
                  32111:
                    "Manufacture of jewellery and related articles composed of precious metals, precious\nand semi-precious stone and pearls",
                  32119: "Other precious and semi-precious stone cutting and polishing",
                },
              },
              3212: {
                "Industry Class": "Manufacture of imitation jewellery and related articles",
                Subclasses: {
                  32120: "Manufacture of imitation jewellery and related articles",
                },
              },
            },
          },
          322: {
            "Industry Group": "Manufacture of musical instruments",
            Classes: {
              3220: {
                "Industry Class": "Manufacture of musical instruments",
                Subclasses: {
                  32200: "Manufacture of musical instruments",
                },
              },
            },
          },
          323: {
            "Industry Group": "Manufacture of sports goods",
            Classes: {
              3230: {
                "Industry Class": "Manufacture of sports goods",
                Subclasses: {
                  32300: "Manufacture of sports goods",
                },
              },
            },
          },
          324: {
            "Industry Group": "Manufacture of games and toys",
            Classes: {
              3240: {
                "Industry Class": "Manufacture of games and toys",
                Subclasses: {
                  32400: "Manufacture of games and toys",
                },
              },
            },
          },
          325: {
            "Industry Group": "Manufacture of medical and dental instruments and supplies",
            Classes: {
              3250: {
                "Industry Class": "Manufacture of medical and dental instruments and supplies",
                Subclasses: {
                  32500: "Manufacture of medical and dental instruments and supplies",
                },
              },
            },
          },
          329: {
            "Industry Group": "Other manufacturing n.e.c.",
            Classes: {
              3290: {
                "Industry Class": "Other manufacturing n.e.c.",
                Subclasses: {
                  32901: "Manufacture of coffins",
                  32909: "Other manufacture n.e.c. (except coffins)",
                },
              },
            },
          },
        },
      },
      33: {
        "Industry Division": "Repair and installation of machinery and equipment",
        Groups: {
          331: {
            "Industry Group": "Repair of fabricated metal products, machinery and equipment",
            Classes: {
              3311: {
                "Industry Class": "Repair of fabricated metal products",
                Subclasses: {
                  33110: "Repair of fabricated metal products",
                },
              },
              3312: {
                "Industry Class": "Repair of machinery",
                Subclasses: {
                  33120: "Repair of machinery",
                },
              },
              3313: {
                "Industry Class": "Repair of electronic and optical equipment",
                Subclasses: {
                  33130: "Repair of electronic and optical equipment",
                },
              },
              3314: {
                "Industry Class": "Repair of electrical equipment",
                Subclasses: {
                  33140: "Repair of electrical equipment",
                },
              },
              3315: {
                "Industry Class": "Repair of transport equipment, except motor vehicles",
                Subclasses: {
                  33150: "Repair of transport equipment, except motor vehicles",
                },
              },
              3319: {
                "Industry Class": "Repair of other equipment",
                Subclasses: {
                  33190: "Repair of other equipment",
                },
              },
            },
          },
          332: {
            "Industry Group": "Installation of industrial machinery and equipment",
            Classes: {
              3320: {
                "Industry Class": "Installation of industrial machinery and equipment",
                Subclasses: {
                  33200: "Installation of industrial machinery and equipment",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section D": {
    "Industry Section": "Electricity, gas, steam and air conditioning supply",
    Divisions: {
      35: {
        "Industry Division": "Electricity, gas, steam and air conditioning supply",
        Groups: {
          351: {
            "Industry Group": "Electric power generation, transmission and distribution",
            Classes: {
              3510: {
                "Industry Class": "Electric power generation, transmission and distribution",
                Subclasses: {
                  35101: "Generation of electricity",
                  35102: "Distribution of purchased electric energy only",
                  35103: "Generation and/or distribution for own use",
                },
              },
            },
          },
          352: {
            "Industry Group": "Manufacture of gas; distribution of gaseous fuels through mains",
            Classes: {
              3520: {
                "Industry Class": "Manufacture of gas; distribution of gaseous fuels through mains",
                Subclasses: {
                  35200: "Manufacture of gas; distribution of gaseous fuels through mains",
                },
              },
            },
          },
          353: {
            "Industry Group": "Steam and air conditioning supply",
            Classes: {
              3530: {
                "Industry Class": "Steam and air conditioning supply",
                Subclasses: {
                  35300: "Steam and air conditioning supply",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section E": {
    "Industry Section": "Water supply; sewerage, waste management and remediation activities",
    Divisions: {
      36: {
        "Industry Division": "Water collection, treatment and supply",
        Groups: {
          360: {
            "Industry Group": "Water collection, treatment and supply",
            Classes: {
              3600: {
                "Industry Class": "Water collection, treatment and supply",
                Subclasses: {
                  36000: "Water collection, treatment and supply",
                },
              },
            },
          },
        },
      },
      37: {
        "Industry Division": "Sewerage",
        Groups: {
          370: {
            "Industry Group": "Sewerage",
            Classes: {
              3700: {
                "Industry Class": "Sewerage",
                Subclasses: {
                  37000: "Sewerage",
                },
              },
            },
          },
        },
      },
      38: {
        "Industry Division":
          "Waste collection, treatment and disposal activities; materials recovery",
        Groups: {
          381: {
            "Industry Group": "Waste collection",
            Classes: {
              3811: {
                "Industry Class": "Collection of non-hazardous waste",
                Subclasses: {
                  38110: "Collection of non-hazardous waste",
                },
              },
              3812: {
                "Industry Class": "Collection of hazardous waste",
                Subclasses: {
                  38120: "Collection of hazardous waste",
                },
              },
            },
          },
          382: {
            "Industry Group": "Waste treatment and disposal",
            Classes: {
              3821: {
                "Industry Class": "Treatment and disposal of non-hazardous waste",
                Subclasses: {
                  38210: "Treatment and disposal of non-hazardous waste",
                },
              },
              3822: {
                "Industry Class": "Treatment and disposal of hazardous waste",
                Subclasses: {
                  38220: "Treatment and disposal of hazardous waste",
                },
              },
            },
          },
          383: {
            "Industry Group": "Materials recovery",
            Classes: {
              3830: {
                "Industry Class": "Materials recovery",
                Subclasses: {
                  38300: "Materials recovery",
                },
              },
            },
          },
        },
      },
      39: {
        "Industry Division": "Remediation activities and other waste management services",
        Groups: {
          390: {
            "Industry Group": "Remediation activities and other waste management services",
            Classes: {
              3900: {
                "Industry Class": "Remediation activities and other waste management services",
                Subclasses: {
                  39000: "Remediation activities and other waste management services",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section F": {
    "Industry Section": "Construction",
    Divisions: {
      41: {
        "Industry Division": "Construction of buildings",
        Groups: {
          410: {
            "Industry Group": "Construction of buildings",
            Classes: {
              4100: {
                "Industry Class": "Construction of buildings",
                Subclasses: {
                  41000: "Construction of buildings",
                },
              },
            },
          },
        },
      },
      42: {
        "Industry Division": "Civil engineering",
        Groups: {
          421: {
            "Industry Group": "Construction of roads and railways",
            Classes: {
              4210: {
                "Industry Class": "Construction of roads and railways",
                Subclasses: {
                  42100: "Construction of roads and railways",
                },
              },
            },
          },
          422: {
            "Industry Group": "Construction of utility projects",
            Classes: {
              4220: {
                "Industry Class": "Construction of utility projects",
                Subclasses: {
                  42200: "Construction of utility projects",
                },
              },
            },
          },
          429: {
            "Industry Group": "Construction of other civil engineering projects",
            Classes: {
              4290: {
                "Industry Class": "Construction of other civil engineering projects",
                Subclasses: {
                  42900: "Construction of other civil engineering projects",
                },
              },
            },
          },
        },
      },
      43: {
        "Industry Division": "Specialised construction activities",
        Groups: {
          431: {
            "Industry Group": "Demolition and site preparation",
            Classes: {
              4311: {
                "Industry Class": "Demolition",
                Subclasses: {
                  43110: "Demolition",
                },
              },
              4312: {
                "Industry Class": "Site preparation",
                Subclasses: {
                  43120: "Site preparation",
                },
              },
            },
          },
          432: {
            "Industry Group": "Electrical, plumbing and other construction installation activities",
            Classes: {
              4321: {
                "Industry Class": "Electrical installation",
                Subclasses: {
                  43210: "Electrical installation",
                },
              },
              4322: {
                "Industry Class": "Plumbing, heat and air-conditioning installation",
                Subclasses: {
                  43220: "Plumbing, heat and air-conditioning installation",
                },
              },
              4329: {
                "Industry Class": "Other construction installation",
                Subclasses: {
                  43290: "Other construction installation",
                },
              },
            },
          },
          433: {
            "Industry Group": "Building completion and finishing",
            Classes: {
              4330: {
                "Industry Class": "Building completion and finishing",
                Subclasses: {
                  43301: "Painting and decorating",
                  43302: "Shop fitting",
                  43309: "Other building and completion and finishing",
                },
              },
            },
          },
          439: {
            "Industry Group": "Other specialized construction activities",
            Classes: {
              4390: {
                "Industry Class": "Other specialized construction activities",
                Subclasses: {
                  43901: "Rental of construction machinery and equipment (with operator)",
                  43909: "Other specialized construction activities n.e.c.",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section G": {
    "Industry Section": "Wholesale and retail trade; repair of motor vehicles and motorcycles",
    Divisions: {
      45: {
        "Industry Division":
          "Wholesale and retail trade and repair of motor vehicles and motorcycles",
        Groups: {
          451: {
            "Industry Group": "Sale of motor vehicles",
            Classes: {
              4510: {
                "Industry Class": "Sale of motor vehicles",
                Subclasses: {
                  45101: "Wholesale of motor vehicles",
                  45102: "Retail of new motor vehicles",
                  45103: "Retail sale of used motor vehicles",
                },
              },
            },
          },
          452: {
            "Industry Group": "Maintenance and repair of motor vehicles",
            Classes: {
              4520: {
                "Industry Class": "Maintenance and repair of motor vehicles",
                Subclasses: {
                  45200: "Maintenance and repair of motor vehicles",
                },
              },
            },
          },
          453: {
            "Industry Group": "Sale of motor vehicle parts and accessories",
            Classes: {
              4530: {
                "Industry Class": "Sale of motor vehicle parts and accessories",
                Subclasses: {
                  45300: "Sale of motor vehicle parts and accessories",
                },
              },
            },
          },
          454: {
            "Industry Group":
              "Sale, maintenance and repair of motorcycles and related parts and accessories",
            Classes: {
              4540: {
                "Industry Class":
                  "Sale, maintenance and repair of motorcycles and related parts and accessories",
                Subclasses: {
                  45400:
                    "Sale, maintenance and repair of motorcycles and related parts and accessories",
                },
              },
            },
          },
          455: {
            "Industry Group": "Retail of automotive fuel in specialized stores",
            Classes: {
              4550: {
                "Industry Class": "Retail of automotive fuel in specialized stores",
                Subclasses: {
                  45500: "Retail of automotive fuel in specialized stores",
                },
              },
            },
          },
        },
      },
      46: {
        "Industry Division": "Wholesale trade, except of motor vehicles and motorcycles",
        Groups: {
          461: {
            "Industry Group": "Wholesale on a fee or contract basis",
            Classes: {
              4610: {
                "Industry Class": "Wholesale on a fee or contract basis",
                Subclasses: {
                  46100: "Wholesale on a fee or contract basis",
                },
              },
            },
          },
          462: {
            "Industry Group": "Wholesale of agricultural raw materials and live animals",
            Classes: {
              4620: {
                "Industry Class": "Wholesale of agricultural raw materials and live animals",
                Subclasses: {
                  46200: "Wholesale of agricultural raw materials and live animals",
                },
              },
            },
          },
          463: {
            "Industry Group": "Wholesale of food, beverages and tobacco",
            Classes: {
              4630: {
                "Industry Class": "Wholesale of food, beverages and tobacco",
                Subclasses: {
                  46301: "Wholesale trade in foodstuffs",
                  46302: "Wholesale trade in beverages",
                  46303: "Wholesale in tobacco products",
                },
              },
            },
          },
          464: {
            "Industry Group": "Wholesale of household goods",
            Classes: {
              4641: {
                "Industry Class": "Wholesale of textiles, clothing and footwear",
                Subclasses: {
                  46410: "Wholesale of textiles, clothing and footwear",
                },
              },
              4649: {
                "Industry Class": "Wholesale of other household goods",
                Subclasses: {
                  46491: "Wholesale trade in household furniture, requisites and appliances",
                  46492: "Wholesale trade in books and stationery",
                  46493: "Wholesale trade in pharmaceuticals, toiletries and medical equipment",
                  46499: "Other wholesale trade in other household goods n.e.c.",
                },
              },
            },
          },
          465: {
            "Industry Group": "Wholesale of machinery, equipment and supplies",
            Classes: {
              4651: {
                "Industry Class":
                  "Wholesale of computers, computer peripheral equipment and software",
                Subclasses: {
                  46510: "Wholesale of computers, computer peripheral equipment and software",
                },
              },
              4652: {
                "Industry Class":
                  "Wholesale of electronic and telecommunications equipment and parts",
                Subclasses: {
                  46520: "Wholesale of electronic and telecommunications equipment and parts",
                },
              },
              4653: {
                "Industry Class": "Wholesale of agricultural machinery, equipment and supplies",
                Subclasses: {
                  46530: "Wholesale of agricultural machinery, equipment and supplies",
                },
              },
              4659: {
                "Industry Class": "Wholesale of other machinery and equipment",
                Subclasses: {
                  46590: "Wholesale of other machinery and equipment",
                },
              },
            },
          },
          466: {
            "Industry Group": "Other specialised wholesale",
            Classes: {
              4661: {
                "Industry Class":
                  "Wholesale of solid, liquid and gaseous fuels and related products",
                Subclasses: {
                  46610: "Wholesale of solid, liquid and gaseous fuels and related products",
                },
              },
              4662: {
                "Industry Class": "Wholesale of metals and metal ores",
                Subclasses: {
                  46621: "Wholesale of gold",
                  46629: "Other wholesale of metals and metal ores",
                },
              },
              4663: {
                "Industry Class":
                  "Wholesale of construction materials, hardware, plumbing and heating equipment and\nsupplies",
                Subclasses: {
                  46630:
                    "Wholesale of construction materials, hardware, plumbing and heating equipment and\nsupplies",
                },
              },
              4669: {
                "Industry Class": "Wholesale of waste and scrap and other products n.e.c.",
                Subclasses: {
                  46691: "Sale of used parts and accessories, including scrapyards",
                  46692:
                    "Wholesale trade in diamonds, pearls and other precious and semi-precious stones",
                  46699: "Other wholesale of waste and scrap and other products n.e.c.",
                },
              },
            },
          },
          469: {
            "Industry Group": "Non-specialised wholesale trade",
            Classes: {
              4690: {
                "Industry Class": "Non-specialised wholesale trade",
                Subclasses: {
                  46900: "Non-specialised wholesale trade",
                },
              },
            },
          },
        },
      },
      47: {
        "Industry Division": "Retail trade, except of motor vehicles and motorcycles",
        Groups: {
          471: {
            "Industry Group": "Retail sale in non-specialized stores",
            Classes: {
              4711: {
                "Industry Class":
                  "Retail sale in non-specialized stores with food, beverages or tobacco predominating",
                Subclasses: {
                  47110:
                    "Retail sale in non-specialized stores with food, beverages or tobacco predominating",
                },
              },
              4719: {
                "Industry Class": "Other retail sale in non-specialized stores",
                Subclasses: {
                  47190: "Other retail sale in non-specialized stores",
                },
              },
            },
          },
          472: {
            "Industry Group": "Retail sale of food, beverages and tobacco in specialised stores",
            Classes: {
              4721: {
                "Industry Class": "Retail sale of food in specialised stores",
                Subclasses: {
                  47211: "Retail trade in fresh fruit and vegetables",
                  47212: "Retail in meat and meat products",
                  47213: "Retail trade in bakery products",
                  47219: "Other retail sale in specialised stores",
                },
              },
              4722: {
                "Industry Class": "Retail sale of beverages in specialised stores",
                Subclasses: {
                  47220: "Retail sale of beverages in specialised stores",
                },
              },
              4723: {
                "Industry Class": "Retail sale of tobacco products in specialised stores",
                Subclasses: {
                  47230: "Retail sale of tobacco products in specialised stores",
                },
              },
            },
          },
          473: {
            "Industry Group":
              "Retail sale of information and communications equipment in specialized stores",
            Classes: {
              4731: {
                "Industry Class":
                  "Retail sale of computers, peripheral units, software and telecommunications",
                Subclasses: {
                  47310:
                    "Retail sale of computers, peripheral units, software and telecommunications",
                },
              },
              4732: {
                "Industry Class": "Retail sale of audio and video equipment in specialised stores",
                Subclasses: {
                  47320: "Retail sale of audio and video equipment in specialised stores",
                },
              },
            },
          },
          474: {
            "Industry Group": "Retail sale of other household equipment in specialized stores",
            Classes: {
              4741: {
                "Industry Class": "Retail sale of textiles in specialized stores",
                Subclasses: {
                  47410: "Retail sale of textiles in specialized stores",
                },
              },
              4742: {
                "Industry Class": "Retail sale of hardware, paints and glass in specialized stores",
                Subclasses: {
                  47420: "Retail sale of hardware, paints and glass in specialized stores",
                },
              },
              4743: {
                "Industry Class":
                  "Retail sale of carpets, rugs, wall and floor coverings in specialized stores",
                Subclasses: {
                  47430:
                    "Retail sale of carpets, rugs, wall and floor coverings in specialized stores",
                },
              },
              4749: {
                "Industry Class":
                  "Retail sale of electrical household appliances, furniture, lighting equipment and other\nhousehold articles in specialized stores",
                Subclasses: {
                  47490:
                    "Retail sale of electrical household appliances, furniture, lighting equipment and other\nhousehold articles in specialized stores",
                },
              },
            },
          },
          475: {
            "Industry Group": "Retail sale of cultural and recreation goods in specialized stores",
            Classes: {
              4751: {
                "Industry Class":
                  "Retail sale of books, newspapers and stationary in specialized stores",
                Subclasses: {
                  47510: "Retail sale of books, newspapers and stationary in specialized stores",
                },
              },
              4752: {
                "Industry Class": "Retail sale of music and video recordings in specialized stores",
                Subclasses: {
                  47520: "Retail sale of music and video recordings in specialized stores",
                },
              },
              4753: {
                "Industry Class": "Retail sale of sporting equipment in specialized stores",
                Subclasses: {
                  47530: "Retail sale of sporting equipment in specialized stores",
                },
              },
              4754: {
                "Industry Class": "Retail sale of games and toys in specialized stores",
                Subclasses: {
                  47540: "Retail sale of games and toys in specialized stores",
                },
              },
            },
          },
          476: {
            "Industry Group": "Retail sale of other goods in specialized stores",
            Classes: {
              4761: {
                "Industry Class":
                  "Retail sale of clothing, footwear and leather articles in specialized stores",
                Subclasses: {
                  47610:
                    "Retail sale of clothing, footwear and leather articles in specialized stores",
                },
              },
              4762: {
                "Industry Class":
                  "Retail sale of pharmaceutical and medical goods, cosmetic and toilet articles in\nspecialized stores",
                Subclasses: {
                  47620:
                    "Retail sale of pharmaceutical and medical goods, cosmetic and toilet articles in\nspecialized stores",
                },
              },
              4763: {
                "Industry Class": "Other retail sale of new goods in specialized stores",
                Subclasses: {
                  47631: "Specialized retail sale of jewellery",
                  47632: "Specialized retail sale of watches and clocks",
                  47639: "Other retail sale of new goods in specialized stores n.e.c.",
                },
              },
              4764: {
                "Industry Class": "Retail sale of second-hand goods",
                Subclasses: {
                  47640: "Retail sale of second-hand goods",
                },
              },
            },
          },
          477: {
            "Industry Group": "Retail sale via stalls and markets",
            Classes: {
              4771: {
                "Industry Class":
                  "Retail sale via stalls and markets of food, beverages and tobacco products",
                Subclasses: {
                  47710:
                    "Retail sale via stalls and markets of food, beverages and tobacco products",
                },
              },
              4772: {
                "Industry Class":
                  "Retail sale via stalls and markets of textiles, clothing and footwear",
                Subclasses: {
                  47720: "Retail sale via stalls and markets of textiles, clothing and footwear",
                },
              },
              4779: {
                "Industry Class": "Retail sale via stalls and markets of other goods",
                Subclasses: {
                  47790: "Retail sale via stalls and markets of other goods",
                },
              },
            },
          },
          478: {
            "Industry Group": "Retail trade not in stores, stalls or markets",
            Classes: {
              4781: {
                "Industry Class": "Retail sale via mail order houses or via internet",
                Subclasses: {
                  47810: "Retail sale via mail order houses or via internet",
                },
              },
              4789: {
                "Industry Class": "Other retail sale not in stores, stalls or markets",
                Subclasses: {
                  47890: "Other retail sale not in stores, stalls or markets",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section H": {
    "Industry Section": "Transportation and storage",
    Divisions: {
      49: {
        "Industry Division": "Land transport and transport via pipelines",
        Groups: {
          491: {
            "Industry Group": "Transport via railways",
            Classes: {
              4911: {
                "Industry Class": "Passenger rail transport, interurban",
                Subclasses: {
                  49110: "Passenger rail transport, interurban",
                },
              },
              4912: {
                "Industry Class": "Freight rail transport",
                Subclasses: {
                  49120: "Freight rail transport",
                },
              },
            },
          },
          492: {
            "Industry Group": "Other land transport",
            Classes: {
              4921: {
                "Industry Class": "Urban and suburban passenger transport",
                Subclasses: {
                  49210: "Urban and suburban passenger transport",
                },
              },
              4922: {
                "Industry Class": "Other passenger land transport",
                Subclasses: {
                  49221: "Minibus Taxi transport",
                  49222: "Other Taxi transport (except minibus taxis)",
                  49223: "Bus transport",
                  49229: "Other passenger land transport n.e.c.",
                },
              },
              4923: {
                "Industry Class": "Freight transport by road",
                Subclasses: {
                  49230: "Freight transport by road",
                },
              },
            },
          },
          493: {
            "Industry Group": "Transport via pipeline",
            Classes: {
              4930: {
                "Industry Class": "Transport via pipeline",
                Subclasses: {
                  49300: "Transport via pipeline",
                },
              },
            },
          },
        },
      },
      50: {
        "Industry Division": "Water transport",
        Groups: {
          501: {
            "Industry Group": "Sea and coastal water transport",
            Classes: {
              5011: {
                "Industry Class": "Sea and coastal passenger water transport",
                Subclasses: {
                  50110: "Sea and coastal passenger water transport",
                },
              },
              5012: {
                "Industry Class": "Sea and coastal freight water transport",
                Subclasses: {
                  50120: "Sea and coastal freight water transport",
                },
              },
            },
          },
          502: {
            "Industry Group": "Inland water transport",
            Classes: {
              5021: {
                "Industry Class": "Inland passenger water transport",
                Subclasses: {
                  50210: "Inland passenger water transport",
                },
              },
              5022: {
                "Industry Class": "Inland freight water transport",
                Subclasses: {
                  50220: "Inland freight water transport",
                },
              },
            },
          },
        },
      },
      51: {
        "Industry Division": "Air transport",
        Groups: {
          511: {
            "Industry Group": "Passenger air transport",
            Classes: {
              5110: {
                "Industry Class": "Passenger air transport",
                Subclasses: {
                  51100: "Passenger air transport",
                },
              },
            },
          },
          512: {
            "Industry Group": "Freight air transport",
            Classes: {
              5120: {
                "Industry Class": "Freight air transport",
                Subclasses: {
                  51200: "Freight air transport",
                },
              },
            },
          },
        },
      },
      52: {
        "Industry Division": "Warehousing and support activities for transportation",
        Groups: {
          521: {
            "Industry Group": "Warehousing and storage",
            Classes: {
              5210: {
                "Industry Class": "Warehousing and storage",
                Subclasses: {
                  52100: "Warehousing and storage",
                },
              },
            },
          },
          522: {
            "Industry Group": "Support activities for transportation",
            Classes: {
              5221: {
                "Industry Class": "Service activities incidental to land transportation",
                Subclasses: {
                  52211: "Operation of parking garages and parking lots",
                  52212: "Operation of roads and toll roads",
                  52219: "Other service activities incidental to land transportation",
                },
              },
              5222: {
                "Industry Class": "Service activities incidental to water transportation",
                Subclasses: {
                  52220: "Service activities incidental to water transportation",
                },
              },
              5223: {
                "Industry Class": "Service activities incidental to air transportation",
                Subclasses: {
                  52230: "Service activities incidental to air transportation",
                },
              },
              5224: {
                "Industry Class": "Cargo handling",
                Subclasses: {
                  52240: "Cargo handling",
                },
              },
              5229: {
                "Industry Class": "Other transportation support activities",
                Subclasses: {
                  52290: "Other transportation support activities",
                },
              },
            },
          },
        },
      },
      53: {
        "Industry Division": "Postal and courier activities",
        Groups: {
          531: {
            "Industry Group": "Postal activities",
            Classes: {
              5310: {
                "Industry Class": "Postal activities",
                Subclasses: {
                  53100: "Postal activities",
                },
              },
            },
          },
          532: {
            "Industry Group": "Courier activities",
            Classes: {
              5320: {
                "Industry Class": "Courier activities",
                Subclasses: {
                  53200: "Courier activities",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section I": {
    "Industry Section": "Accommodation and food service activities",
    Divisions: {
      55: {
        "Industry Division": "Accommodation",
        Groups: {
          551: {
            "Industry Group": "Short term accommodation activities",
            Classes: {
              5510: {
                "Industry Class": "Short term accommodation activities",
                Subclasses: {
                  55101: "Short term accommodation activities of hotels and motels",
                  55102: "Short term accommodation activities of guesthouses, bed and breakfast",
                  55103:
                    "Short term accommodation activities of pensions, youth hostels and mountain refuges",
                  55109:
                    "Short term accommodation activities of visitor flats and bungalows, time-share units\nand holiday homes and other accommodation",
                },
              },
            },
          },
          552: {
            "Industry Group": "Camping grounds, recreational vehicle parks and trailer parks",
            Classes: {
              5520: {
                "Industry Class": "Camping grounds, recreational vehicle parks and trailer parks",
                Subclasses: {
                  55200: "Camping grounds, recreational vehicle parks and trailer parks",
                },
              },
            },
          },
          559: {
            "Industry Group": "Other accommodation",
            Classes: {
              5590: {
                "Industry Class": "Other accommodation",
                Subclasses: {
                  55900: "Other accommodation",
                },
              },
            },
          },
        },
      },
      56: {
        "Industry Division": "Food and beverage service activities",
        Groups: {
          561: {
            "Industry Group": "Restaurants and mobile food service activities",
            Classes: {
              5610: {
                "Industry Class": "Restaurants and mobile food service activities",
                Subclasses: {
                  56101: "Food service activities of take away counters",
                  56109: "Other restaurant and mobile food service activities",
                },
              },
            },
          },
          562: {
            "Industry Group": "Event catering and other food service activities",
            Classes: {
              5621: {
                "Industry Class": "Event catering",
                Subclasses: {
                  56210: "Event catering",
                },
              },
              5629: {
                "Industry Class": "Other food service activities",
                Subclasses: {
                  56290: "Other food service activities",
                },
              },
            },
          },
          563: {
            "Industry Group": "Beverage serving activities",
            Classes: {
              5630: {
                "Industry Class": "Beverage serving activities",
                Subclasses: {
                  56300: "Beverage serving activities",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section J": {
    "Industry Section": "Information and communication",
    Divisions: {
      58: {
        "Industry Division": "Publishing activities",
        Groups: {
          581: {
            "Industry Group": "Publishing of books, periodicals and other publishing activities",
            Classes: {
              5811: {
                "Industry Class": "Book publishing",
                Subclasses: {
                  58110: "Book publishing",
                },
              },
              5812: {
                "Industry Class": "Publishing of directories and mailing lists",
                Subclasses: {
                  58120: "Publishing of directories and mailing lists",
                },
              },
              5813: {
                "Industry Class": "Publishing of newspapers, journals and periodicals",
                Subclasses: {
                  58130: "Publishing of newspapers, journals and periodicals",
                },
              },
              5819: {
                "Industry Class": "Other publishing activities",
                Subclasses: {
                  58190: "Other publishing activities",
                },
              },
            },
          },
          582: {
            "Industry Group": "Software publishing",
            Classes: {
              5820: {
                "Industry Class": "Software publishing",
                Subclasses: {
                  58200: "Software publishing",
                },
              },
            },
          },
        },
      },
      59: {
        "Industry Division":
          "Motion picture, video and television programme production, sound recording and music publishing activities",
        Groups: {
          591: {
            "Industry Group": "Motion picture, video and television programme activities",
            Classes: {
              5911: {
                "Industry Class":
                  "Motion picture, video and television programme production activities",
                Subclasses: {
                  59110: "Motion picture, video and television programme production activities",
                },
              },
              5912: {
                "Industry Class":
                  "Motion picture, video and television programme post-production activities",
                Subclasses: {
                  59120:
                    "Motion picture, video and television programme post-production activities",
                },
              },
              5913: {
                "Industry Class":
                  "Motion picture, video and television programme distribution activities",
                Subclasses: {
                  59130: "Motion picture, video and television programme distribution activities",
                },
              },
              5914: {
                "Industry Class": "Motion picture projection activities",
                Subclasses: {
                  59140: "Motion picture projection activities",
                },
              },
            },
          },
          592: {
            "Industry Group": "Sound recording and music publishing activities",
            Classes: {
              5920: {
                "Industry Class": "Sound recording and music publishing activities",
                Subclasses: {
                  59200: "Sound recording and music publishing activities",
                },
              },
            },
          },
        },
      },
      60: {
        "Industry Division": "Programming and broadcasting activities",
        Groups: {
          601: {
            "Industry Group": "Radio broadcasting",
            Classes: {
              6010: {
                "Industry Class": "Radio broadcasting",
                Subclasses: {
                  60100: "Radio broadcasting",
                },
              },
            },
          },
          602: {
            "Industry Group": "Television programming and broadcasting activities",
            Classes: {
              6020: {
                "Industry Class": "Television programming and broadcasting activities",
                Subclasses: {
                  60200: "Television programming and broadcasting activities",
                },
              },
            },
          },
        },
      },
      61: {
        "Industry Division": "Telecommunications",
        Groups: {
          611: {
            "Industry Group": "Wired telecommunications activities",
            Classes: {
              6110: {
                "Industry Class": "Wired telecommunications activities",
                Subclasses: {
                  61100: "Wired telecommunications activities",
                },
              },
            },
          },
          612: {
            "Industry Group": "Wireless telecommunications activities",
            Classes: {
              6120: {
                "Industry Class": "Wireless telecommunications activities",
                Subclasses: {
                  61200: "Wireless telecommunications activities",
                },
              },
            },
          },
          613: {
            "Industry Group": "Satellite telecommunications activities",
            Classes: {
              6130: {
                "Industry Class": "Satellite telecommunications activities",
                Subclasses: {
                  61300: "Satellite telecommunications activities",
                },
              },
            },
          },
          619: {
            "Industry Group": "Other telecommunications activities",
            Classes: {
              6190: {
                "Industry Class": "Other telecommunications activities",
                Subclasses: {
                  61900: "Other telecommunications activities",
                },
              },
            },
          },
        },
      },
      62: {
        "Industry Division": "Computer programming, consultancy and related activities",
        Groups: {
          620: {
            "Industry Group": "Computer programming, consultancy and related activities",
            Classes: {
              6201: {
                "Industry Class": "Computer programming activities",
                Subclasses: {
                  62010: "Computer programming activities",
                },
              },
              6202: {
                "Industry Class":
                  "Computer consultancy and computer facilities management activities",
                Subclasses: {
                  62020: "Computer consultancy and computer facilities management activities",
                },
              },
              6209: {
                "Industry Class": "Other information technology and computer service activities",
                Subclasses: {
                  62090: "Other information technology and computer service activities",
                },
              },
            },
          },
        },
      },
      63: {
        "Industry Division": "Information service activities",
        Groups: {
          631: {
            "Industry Group": "Data processing, hosting and related activities; web portals",
            Classes: {
              6311: {
                "Industry Class": "Data processing, hosting and related activities",
                Subclasses: {
                  63110: "Data processing, hosting and related activities",
                },
              },
              6312: {
                "Industry Class": "Web portals",
                Subclasses: {
                  63120: "Web portals",
                },
              },
            },
          },
          639: {
            "Industry Group": "Other information service activities",
            Classes: {
              6391: {
                "Industry Class": "News agency activities",
                Subclasses: {
                  63910: "News agency activities",
                },
              },
              6399: {
                "Industry Class": "Other information service activities n.e.c.",
                Subclasses: {
                  63990: "Other information service activities n.e.c.",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section K": {
    "Industry Section": "Financial and insurance activities",
    Divisions: {
      64: {
        "Industry Division": "Financial service activities, except insurance and pension funding",
        Groups: {
          641: {
            "Industry Group": "Monetary intermediation",
            Classes: {
              6411: {
                "Industry Class": "Central banking",
                Subclasses: {
                  64110: "Central banking",
                },
              },
              6419: {
                "Industry Class": "Other monetary intermediation",
                Subclasses: {
                  64190: "Other monetary intermediation",
                },
              },
            },
          },
          642: {
            "Industry Group": "Activities of holding companies",
            Classes: {
              6420: {
                "Industry Class": "Activities of holding companies",
                Subclasses: {
                  64200: "Activities of holding companies",
                },
              },
            },
          },
          643: {
            "Industry Group": "Trusts, funds and similar financial entities",
            Classes: {
              6430: {
                "Industry Class": "Trusts, funds and similar financial entities",
                Subclasses: {
                  64300: "Trusts, funds and similar financial entities",
                },
              },
            },
          },
          649: {
            "Industry Group":
              "Other financial service activities, except insurance and pension funding\nactivities",
            Classes: {
              6491: {
                "Industry Class": "Financial leasing",
                Subclasses: {
                  64910: "Financial leasing",
                },
              },
              6492: {
                "Industry Class": "Other credit granting",
                Subclasses: {
                  64920: "Other credit granting",
                },
              },
              6499: {
                "Industry Class":
                  "Other financial service activities, except insurance and pension funding activities\nn.e.c.",
                Subclasses: {
                  64990:
                    "Other financial service activities, except insurance and pension funding activities\nn.e.c.",
                },
              },
            },
          },
        },
      },
      65: {
        "Industry Division":
          "Insurance, reinsurance and pension funding, except compulsory social security",
        Groups: {
          651: {
            "Industry Group": "Insurance",
            Classes: {
              6511: {
                "Industry Class": "Life insurance",
                Subclasses: {
                  65110: "Life insurance",
                },
              },
              6512: {
                "Industry Class": "Non-life insurance",
                Subclasses: {
                  65121: "Health insurance",
                  65122: "Travel insurance",
                  65123: "Transport insurance",
                  65129: "Other non-life insurance",
                },
              },
            },
          },
          652: {
            "Industry Group": "Reinsurance",
            Classes: {
              6520: {
                "Industry Class": "Reinsurance",
                Subclasses: {
                  65200: "Reinsurance",
                },
              },
            },
          },
          653: {
            "Industry Group": "Pension funding",
            Classes: {
              6530: {
                "Industry Class": "Pension funding",
                Subclasses: {
                  65300: "Pension funding",
                },
              },
            },
          },
        },
      },
      66: {
        "Industry Division": "Activities auxiliary to financial service and insurance activities",
        Groups: {
          661: {
            "Industry Group":
              "Activities auxiliary to financial service activities, except insurance and pension\nfunding",
            Classes: {
              6611: {
                "Industry Class": "Administration of financial markets",
                Subclasses: {
                  66110: "Administration of financial markets",
                },
              },
              6612: {
                "Industry Class": "Security and commodity contracts brokerage",
                Subclasses: {
                  66120: "Security and commodity contracts brokerage",
                },
              },
              6619: {
                "Industry Class": "Other activities auxiliary to financial service activities",
                Subclasses: {
                  66190: "Other activities auxiliary to financial service activities",
                },
              },
            },
          },
          662: {
            "Industry Group": "Activities auxiliary to insurance and pension funding",
            Classes: {
              6621: {
                "Industry Class": "Risk and damage evaluation",
                Subclasses: {
                  66210: "Risk and damage evaluation",
                },
              },
              6622: {
                "Industry Class": "Activities of insurance agents and brokers",
                Subclasses: {
                  66220: "Activities of insurance agents and brokers",
                },
              },
              6629: {
                "Industry Class": "Other activities auxiliary to insurance and pension funding",
                Subclasses: {
                  66290: "Other activities auxiliary to insurance and pension funding",
                },
              },
            },
          },
          663: {
            "Industry Group": "Fund management activities",
            Classes: {
              6630: {
                "Industry Class": "Fund management activities",
                Subclasses: {
                  66300: "Fund management activities",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section L": {
    "Industry Section": "Real estate activities",
    Divisions: {
      68: {
        "Industry Division": "Real estate activities",
        Groups: {
          681: {
            "Industry Group": "Real estate activities with own or leased property",
            Classes: {
              6810: {
                "Industry Class": "Real estate activities with own or leased property",
                Subclasses: {
                  68100: "Real estate activities with own or leased property",
                },
              },
            },
          },
          682: {
            "Industry Group": "Real estate activities on a fee or contract basis",
            Classes: {
              6820: {
                "Industry Class": "Real estate activities on a fee or contract basis",
                Subclasses: {
                  68200: "Real estate activities on a fee or contract basis",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section M": {
    "Industry Section": "Professional, scientific and technical activities",
    Divisions: {
      69: {
        "Industry Division": "Legal and accounting activities",
        Groups: {
          691: {
            "Industry Group": "Legal activities",
            Classes: {
              6910: {
                "Industry Class": "Legal activities",
                Subclasses: {
                  69100: "Legal activities",
                },
              },
            },
          },
          692: {
            "Industry Group": "Accounting, bookkeeping and auditing activities, tax consultancy",
            Classes: {
              6920: {
                "Industry Class":
                  "Accounting, bookkeeping and auditing activities, tax consultancy",
                Subclasses: {
                  69201: "Accounting and bookkeeping activities",
                  69202: "Auditing activities",
                  69209: "Other accounting, bookkeeping and auditing activities, tax consultancy",
                },
              },
            },
          },
        },
      },
      70: {
        "Industry Division": "Activities of head offices; management consultancy activities",
        Groups: {
          701: {
            "Industry Group": "Activities of head offices",
            Classes: {
              7010: {
                "Industry Class": "Activities of head offices",
                Subclasses: {
                  70100: "Activities of head offices",
                },
              },
            },
          },
          702: {
            "Industry Group": "Management consultancy activities",
            Classes: {
              7020: {
                "Industry Class": "Management consultancy activities",
                Subclasses: {
                  70200: "Management consultancy activities",
                },
              },
            },
          },
        },
      },
      71: {
        "Industry Division":
          "Architectural and engineering activities; technical testing and analysis",
        Groups: {
          711: {
            "Industry Group":
              "Architectural and engineering activities and related technical consultancy",
            Classes: {
              7110: {
                "Industry Class":
                  "Architectural and engineering activities and related technical consultancy",
                Subclasses: {
                  71101: "Consulting engineering activities",
                  71102: "Architectural activities",
                  71103: "Activities of quantity surveyors",
                  71104: "Activities of land surveyors",
                  71105: "Geological and prospecting activities on a fee or contract basis",
                  71106: "Activities of non-registered architects, e.g. tracers and draughtsmen",
                  71109:
                    "Other architectural and engineering activities and related technical consultancy",
                },
              },
            },
          },
          712: {
            "Industry Group": "Technical testing and analysis",
            Classes: {
              7120: {
                "Industry Class": "Technical testing and analysis",
                Subclasses: {
                  71200: "Technical testing and analysis",
                },
              },
            },
          },
        },
      },
      72: {
        "Industry Division": "Scientific research and development",
        Groups: {
          721: {
            "Industry Group":
              "Research and experimental development on natural sciences and engineering",
            Classes: {
              7210: {
                "Industry Class":
                  "Research and experimental development on natural sciences and engineering",
                Subclasses: {
                  72100:
                    "Research and experimental development on natural sciences and engineering",
                },
              },
            },
          },
          722: {
            "Industry Group":
              "Research and experimental development on social sciences and humanities",
            Classes: {
              7220: {
                "Industry Class":
                  "Research and experimental development on social sciences and humanities",
                Subclasses: {
                  72200: "Research and experimental development on social sciences and humanities",
                },
              },
            },
          },
        },
      },
      73: {
        "Industry Division": "Advertising and market research",
        Groups: {
          731: {
            "Industry Group": "Advertising",
            Classes: {
              7310: {
                "Industry Class": "Advertising",
                Subclasses: {
                  73100: "Advertising",
                },
              },
            },
          },
          732: {
            "Industry Group": "Market research and public opinion polling",
            Classes: {
              7320: {
                "Industry Class": "Market research and public opinion polling",
                Subclasses: {
                  73200: "Market research and public opinion polling",
                },
              },
            },
          },
        },
      },
      74: {
        "Industry Division": "Other professional, scientific and technical activities",
        Groups: {
          741: {
            "Industry Group": "Specialized design activities",
            Classes: {
              7410: {
                "Industry Class": "Specialized design activities",
                Subclasses: {
                  74100: "Specialized design activities",
                },
              },
            },
          },
          742: {
            "Industry Group": "Photographic activities",
            Classes: {
              7420: {
                "Industry Class": "Photographic activities",
                Subclasses: {
                  74200: "Photographic activities",
                },
              },
            },
          },
          749: {
            "Industry Group": "Other professional, scientific and technical activities n.e.c.",
            Classes: {
              7490: {
                "Industry Class": "Other professional, scientific and technical activities n.e.c.",
                Subclasses: {
                  74900: "Other professional, scientific and technical activities n.e.c.",
                },
              },
            },
          },
        },
      },
      75: {
        "Industry Division": "Veterinary activities",
        Groups: {
          750: {
            "Industry Group": "Veterinary activities",
            Classes: {
              7500: {
                "Industry Class": "Veterinary activities",
                Subclasses: {
                  75000: "Veterinary activities",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section N": {
    "Industry Section": "Administrative and support activities",
    Divisions: {
      77: {
        "Industry Division": "Rental and leasing activities",
        Groups: {
          771: {
            "Industry Group": "Renting and leasing of motor vehicles (without driver)",
            Classes: {
              7710: {
                "Industry Class": "Renting and leasing of motor vehicles (without driver)",
                Subclasses: {
                  77100: "Renting and leasing of motor vehicles (without driver)",
                },
              },
            },
          },
          772: {
            "Industry Group": "Renting and leasing of personal and household goods",
            Classes: {
              7721: {
                "Industry Class": "Renting and leasing of recreational and sports goods",
                Subclasses: {
                  77210: "Renting and leasing of recreational and sports goods",
                },
              },
              7722: {
                "Industry Class": "Renting of video tapes and disks",
                Subclasses: {
                  77220: "Renting of video tapes and disks",
                },
              },
              7729: {
                "Industry Class": "Renting and leasing of other personal and household goods",
                Subclasses: {
                  77290: "Renting and leasing of other personal and household goods",
                },
              },
            },
          },
          773: {
            "Industry Group":
              "Renting and leasing of other machinery, equipment and tangible goods",
            Classes: {
              7730: {
                "Industry Class":
                  "Renting and leasing of other machinery, equipment and tangible goods",
                Subclasses: {
                  77301: "Renting of land transport equipment",
                  77302: "Renting of water transport equipment",
                  77303: "Renting of air transport equipment",
                  77304: "Renting of agricultural machinery and equipment",
                  77305: "Renting of construction and civil engineering machinery and equipment",
                  77306: "Renting of office machinery and equipment",
                  77309: "Renting and leasing of other machinery, equipment and tangible goods",
                },
              },
            },
          },
          774: {
            "Industry Group":
              "Leasing of intellectual property and similar products, except copyrighted works",
            Classes: {
              7740: {
                "Industry Class":
                  "Leasing of intellectual property and similar products, except copyrighted works",
                Subclasses: {
                  77400:
                    "Leasing of intellectual property and similar products, except copyrighted works",
                },
              },
            },
          },
        },
      },
      78: {
        "Industry Division": "Employment activities",
        Groups: {
          781: {
            "Industry Group": "Activities of employment placement agencies",
            Classes: {
              7810: {
                "Industry Class": "Activities of employment placement agencies",
                Subclasses: {
                  78100: "Activities of employment placement agencies",
                },
              },
            },
          },
          782: {
            "Industry Group": "Temporary employment agency activities",
            Classes: {
              7820: {
                "Industry Class": "Temporary employment agency activities",
                Subclasses: {
                  78200: "Temporary employment agency activities",
                },
              },
            },
          },
          783: {
            "Industry Group": "Other human resources provision",
            Classes: {
              7830: {
                "Industry Class": "Other human resources provision",
                Subclasses: {
                  78300: "Other human resources provision",
                },
              },
            },
          },
        },
      },
      79: {
        "Industry Division":
          "Travel agency, tour operator, reservation service and related activities",
        Groups: {
          791: {
            "Industry Group": "Travel agency and tour operator activities",
            Classes: {
              7911: {
                "Industry Class": "Travel agency activities",
                Subclasses: {
                  79110: "Travel agency activities",
                },
              },
              7912: {
                "Industry Class": "Tour operator activities",
                Subclasses: {
                  79120: "Tour operator activities",
                },
              },
            },
          },
          799: {
            "Industry Group": "Other reservation service and related activities",
            Classes: {
              7990: {
                "Industry Class": "Other reservation service and related activities",
                Subclasses: {
                  79900: "Other reservation service and related activities",
                },
              },
            },
          },
        },
      },
      80: {
        "Industry Division": "Security and investigation activities",
        Groups: {
          801: {
            "Industry Group": "Private security activities",
            Classes: {
              8010: {
                "Industry Class": "Private security activities",
                Subclasses: {
                  80100: "Private security activities",
                },
              },
            },
          },
          802: {
            "Industry Group": "Security systems service activities",
            Classes: {
              8020: {
                "Industry Class": "Security systems service activities",
                Subclasses: {
                  80200: "Security systems service activities",
                },
              },
            },
          },
          803: {
            "Industry Group": "Investigation activities",
            Classes: {
              8030: {
                "Industry Class": "Investigation activities",
                Subclasses: {
                  80300: "Investigation activities",
                },
              },
            },
          },
        },
      },
      81: {
        "Industry Division": "Services to buildings and landscape activities",
        Groups: {
          811: {
            "Industry Group": "Combined facilities support activities",
            Classes: {
              8110: {
                "Industry Class": "Combined facilities support activities",
                Subclasses: {
                  81100: "Combined facilities support activities",
                },
              },
            },
          },
          812: {
            "Industry Group": "Cleaning activities",
            Classes: {
              8121: {
                "Industry Class": "General cleaning of buildings",
                Subclasses: {
                  81210: "General cleaning of buildings",
                },
              },
              8129: {
                "Industry Class": "Other building and industrial cleaning activities",
                Subclasses: {
                  81290: "Other building and industrial cleaning activities",
                },
              },
            },
          },
          813: {
            "Industry Group": "Landscape care and maintenance service activities",
            Classes: {
              8130: {
                "Industry Class": "Landscape care and maintenance service activities",
                Subclasses: {
                  81300: "Landscape care and maintenance service activities",
                },
              },
            },
          },
        },
      },
      82: {
        "Industry Division":
          "Office administrative, office support and other business support activities",
        Groups: {
          821: {
            "Industry Group": "Office administrative and support activities",
            Classes: {
              8211: {
                "Industry Class": "Combined office administrative service activities",
                Subclasses: {
                  82110: "Combined office administrative service activities",
                },
              },
              8219: {
                "Industry Class":
                  "Photocopying, document preparation and other specialized office support activities",
                Subclasses: {
                  82190:
                    "Photocopying, document preparation and other specialized office support activities",
                },
              },
            },
          },
          822: {
            "Industry Group": "Activities of call centres",
            Classes: {
              8220: {
                "Industry Class": "Activities of call centres",
                Subclasses: {
                  82200: "Activities of call centres",
                },
              },
            },
          },
          823: {
            "Industry Group": "Organization of conventions and trade shows",
            Classes: {
              8230: {
                "Industry Class": "Organization of conventions and trade shows",
                Subclasses: {
                  82300: "Organization of conventions and trade shows",
                },
              },
            },
          },
          829: {
            "Industry Group": "Business support service activities n.e.c.",
            Classes: {
              8291: {
                "Industry Class": "Activities of collection agencies and credit bureaus",
                Subclasses: {
                  82910: "Activities of collection agencies and credit bureaus",
                },
              },
              8292: {
                "Industry Class": "Packaging activities",
                Subclasses: {
                  82920: "Packaging activities",
                },
              },
              8299: {
                "Industry Class": "Other business support service activities n.e.c.",
                Subclasses: {
                  82990: "Other business support service activities n.e.c.",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section O": {
    "Industry Section": "Public administration and defence; compulsory social security",
    Divisions: {
      84: {
        "Industry Division": "Public administration and defence; compulsory social security",
        Groups: {
          841: {
            "Industry Group":
              "Administration of the state and the economic and social policy of the community",
            Classes: {
              8411: {
                "Industry Class": "General public administration",
                Subclasses: {
                  84111: "General public administration at National Government level",
                  84112: "General public administration at Provincial Government level",
                  84113: "General public administration at Local Government level",
                },
              },
              8412: {
                "Industry Class":
                  "Regulation of the activities of providing health care, education, cultural services and\nother social services, excluding social security",
                Subclasses: {
                  84121:
                    "Regulation of the activities of providing health care, education, cultural services and\nother social services, excluding social security at National Government level",
                  84122:
                    "Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security at Provincial Government level",
                  84123:
                    "Regulation of the activities of providing health care, education, cultural services and\nother social services, excluding social security at Local Government level",
                },
              },
              8413: {
                "Industry Class":
                  "Regulation of and contribution to more efficient operation of businesses",
                Subclasses: {
                  84131:
                    "Regulation of and contribution to more efficient operation of businesses at National\nGovernment level",
                  84132:
                    "Regulation of and contribution to more efficient operation of businesses at Provincial\nGovernment level",
                  84133:
                    "Regulation of and contribution to more efficient operation of businesses\nGovernment level",
                },
              },
              8414: {
                "Industry Class": "Extra budgetary account n.e.c.",
                Subclasses: {
                  84140: "Extra budgetary account n.e.c.",
                },
              },
            },
          },
          842: {
            "Industry Group": "Provision of services to the community as a whole",
            Classes: {
              8421: {
                "Industry Class": "Foreign affairs",
                Subclasses: {
                  84210: "Foreign affairs",
                },
              },
              8422: {
                "Industry Class": "Defence activities",
                Subclasses: {
                  84220: "Defence activities",
                },
              },
              8423: {
                "Industry Class": "Public order and safety activities",
                Subclasses: {
                  84231: "Public order and safety activities at National Government level",
                  84232: "Public order and safety activities at Provincial Government level",
                  84233: "Public order and safety activities at Local Government level",
                },
              },
            },
          },
          843: {
            "Industry Group": "Compulsory social security activities",
            Classes: {
              8430: {
                "Industry Class": "Compulsory social security activities",
                Subclasses: {
                  84300: "Compulsory social security activities",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section P": {
    "Industry Section": "Education",
    Divisions: {
      85: {
        "Industry Division": "Education",
        Groups: {
          851: {
            "Industry Group": "Pre-primary and primary education",
            Classes: {
              8510: {
                "Industry Class": "Pre-primary and primary education",
                Subclasses: {
                  85101: "Pre-primary education and activities of after-school centres",
                  85102: "Primary education",
                },
              },
            },
          },
          852: {
            "Industry Group": "Secondary education",
            Classes: {
              8521: {
                "Industry Class": "General secondary education",
                Subclasses: {
                  85210: "General secondary education",
                },
              },
              8522: {
                "Industry Class": "Technical and vocational secondary education",
                Subclasses: {
                  85220: "Technical and vocational secondary education",
                },
              },
            },
          },
          853: {
            "Industry Group": "Higher education",
            Classes: {
              8530: {
                "Industry Class": "Higher education",
                Subclasses: {
                  85300: "Higher education",
                },
              },
            },
          },
          854: {
            "Industry Group": "Other education",
            Classes: {
              8541: {
                "Industry Class": "Sports and recreation education",
                Subclasses: {
                  85410: "Sports and recreation education",
                },
              },
              8542: {
                "Industry Class": "Cultural education",
                Subclasses: {
                  85420: "Cultural education",
                },
              },
              8549: {
                "Industry Class": "Other education n.e.c.",
                Subclasses: {
                  85490: "Other education n.e.c.",
                },
              },
            },
          },
          855: {
            "Industry Group": "Educational support activities",
            Classes: {
              8550: {
                "Industry Class": "Educational support activities",
                Subclasses: {
                  85500: "Educational support activities",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section Q": {
    "Industry Section": "Human health and social work activities",
    Divisions: {
      86: {
        "Industry Division": "Human health activities",
        Groups: {
          861: {
            "Industry Group": "Hospital activities",
            Classes: {
              8610: {
                "Industry Class": "Hospital activities",
                Subclasses: {
                  86100: "Hospital activities",
                },
              },
            },
          },
          862: {
            "Industry Group": "Medical and dental practice activities",
            Classes: {
              8620: {
                "Industry Class": "Medical and dental practice activities",
                Subclasses: {
                  86201: "Medical practitioner- and specialist activities",
                  86202: "Dentist and specialist dentist activities",
                  86209: "Other medical and dental practice activities",
                },
              },
            },
          },
          869: {
            "Industry Group": "Other human health activities",
            Classes: {
              8690: {
                "Industry Class": "Other human health activities",
                Subclasses: {
                  86900: "Other human health activities",
                },
              },
            },
          },
        },
      },
      87: {
        "Industry Division": "Residential care activities",
        Groups: {
          871: {
            "Industry Group": "Residential nursing care facilities",
            Classes: {
              8710: {
                "Industry Class": "Residential nursing care facilities",
                Subclasses: {
                  87100: "Residential nursing care facilities",
                },
              },
            },
          },
          872: {
            "Industry Group":
              "Residential care activities for mental retardation, mental health and substance\nabuse",
            Classes: {
              8720: {
                "Industry Class":
                  "Residential care activities for mental retardation, mental health and substance abuse",
                Subclasses: {
                  87200:
                    "Residential care activities for mental retardation, mental health and substance abuse",
                },
              },
            },
          },
          873: {
            "Industry Group": "Residential care activities for the elderly and disabled",
            Classes: {
              8730: {
                "Industry Class": "Residential care activities for the elderly and disabled",
                Subclasses: {
                  87300: "Residential care activities for the elderly and disabled",
                },
              },
            },
          },
          879: {
            "Industry Group": "Other residential care activities",
            Classes: {
              8790: {
                "Industry Class": "Other residential care activities",
                Subclasses: {
                  87900: "Other residential care activities",
                },
              },
            },
          },
        },
      },
      88: {
        "Industry Division": "Social work activities without accommodation",
        Groups: {
          881: {
            "Industry Group":
              "Social work activities without accommodation for the elderly and disabled",
            Classes: {
              8810: {
                "Industry Class":
                  "Social work activities without accommodation for the elderly and disabled",
                Subclasses: {
                  88100:
                    "Social work activities without accommodation for the elderly and disabled",
                },
              },
            },
          },
          889: {
            "Industry Group": "Other social work activities without accommodation",
            Classes: {
              8890: {
                "Industry Class": "Other social work activities without accommodation",
                Subclasses: {
                  88900: "Other social work activities without accommodation",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section R": {
    "Industry Section": "Arts, entertainment and recreation",
    Divisions: {
      90: {
        "Industry Division": "Creative, arts and entertainment activities",
        Groups: {
          900: {
            "Industry Group": "Creative, arts and entertainment activities",
            Classes: {
              9000: {
                "Industry Class": "Creative, arts and entertainment activities",
                Subclasses: {
                  90000: "Creative, arts and entertainment activities",
                },
              },
            },
          },
        },
      },
      91: {
        "Industry Division": "Libraries, archives, museums and other cultural activities",
        Groups: {
          910: {
            "Industry Group": "Libraries, archives, museums and other cultural activities",
            Classes: {
              9101: {
                "Industry Class": "Library and archives activities",
                Subclasses: {
                  91010: "Library and archives activities",
                },
              },
              9102: {
                "Industry Class":
                  "Museums activities and operation of historical sites and buildings",
                Subclasses: {
                  91020: "Museums activities and operation of historical sites and buildings",
                },
              },
              9103: {
                "Industry Class": "Botanical and zoological gardens and nature reserves activities",
                Subclasses: {
                  91030: "Botanical and zoological gardens and nature reserves activities",
                },
              },
            },
          },
        },
      },
      92: {
        "Industry Division": "Gambling and betting activities",
        Groups: {
          920: {
            "Industry Group": "Gambling and betting activities",
            Classes: {
              9200: {
                "Industry Class": "Gambling and betting activities",
                Subclasses: {
                  92000: "Gambling and betting activities",
                },
              },
            },
          },
        },
      },
      93: {
        "Industry Division": "Sports activities and amusement and recreation activities",
        Groups: {
          931: {
            "Industry Group": "Sports activities",
            Classes: {
              9311: {
                "Industry Class": "Operation of sports facilities",
                Subclasses: {
                  93110: "Operation of sports facilities",
                },
              },
              9312: {
                "Industry Class": "Activities of sports clubs",
                Subclasses: {
                  93120: "Activities of sports clubs",
                },
              },
              9319: {
                "Industry Class": "Other sports activities",
                Subclasses: {
                  93190: "Other sports activities",
                },
              },
            },
          },
          932: {
            "Industry Group": "Other amusement and recreation activities",
            Classes: {
              9321: {
                "Industry Class": "Activities of amusement parks and theme parks",
                Subclasses: {
                  93210: "Activities of amusement parks and theme parks",
                },
              },
              9329: {
                "Industry Class": "Other amusement and recreation activities n.e.c.",
                Subclasses: {
                  93290: "Other amusement and recreation activities n.e.c.",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section S": {
    "Industry Section": "Other service activities",
    Divisions: {
      94: {
        "Industry Division": "Activities of membership organizations",
        Groups: {
          941: {
            "Industry Group":
              "Activities of business, employers and professional membership organizations",
            Classes: {
              9411: {
                "Industry Class": "Activities of business and employers membership organizations",
                Subclasses: {
                  94110: "Activities of business and employers membership organizations",
                },
              },
              9412: {
                "Industry Class": "Activities of professional membership organizations",
                Subclasses: {
                  94120: "Activities of professional membership organizations",
                },
              },
            },
          },
          942: {
            "Industry Group": "Activities of trade unions",
            Classes: {
              9420: {
                "Industry Class": "Activities of trade unions",
                Subclasses: {
                  94200: "Activities of trade unions",
                },
              },
            },
          },
          949: {
            "Industry Group": "Activities of other membership organizations",
            Classes: {
              9491: {
                "Industry Class": "Activities of religious organizations",
                Subclasses: {
                  94910: "Activities of religious organizations",
                },
              },
              9492: {
                "Industry Class": "Activities of political organizations",
                Subclasses: {
                  94920: "Activities of political organizations",
                },
              },
              9499: {
                "Industry Class": "Activities of other membership organizations n.e.c.",
                Subclasses: {
                  94990: "Activities of other membership organizations n.e.c.",
                },
              },
            },
          },
        },
      },
      95: {
        "Industry Division": "Repair of computers and personal and household goods",
        Groups: {
          951: {
            "Industry Group": "Repair of computers and communication equipment",
            Classes: {
              9511: {
                "Industry Class": "Repair of computers and peripheral equipment",
                Subclasses: {
                  95110: "Repair of computers and peripheral equipment",
                },
              },
              9512: {
                "Industry Class": "Repair of communication equipment",
                Subclasses: {
                  95120: "Repair of communication equipment",
                },
              },
            },
          },
          952: {
            "Industry Group": "Repair of personal household goods",
            Classes: {
              9521: {
                "Industry Class": "Repair of consumer electronics",
                Subclasses: {
                  95210: "Repair of consumer electronics",
                },
              },
              9522: {
                "Industry Class": "Repair of household appliances and home and garden equipment",
                Subclasses: {
                  95220: "Repair of household appliances and home and garden equipment",
                },
              },
              9523: {
                "Industry Class": "Repair of footwear and leather goods",
                Subclasses: {
                  95230: "Repair of footwear and leather goods",
                },
              },
              9524: {
                "Industry Class": "Repair of furniture and home furnishings",
                Subclasses: {
                  95240: "Repair of furniture and home furnishings",
                },
              },
              9529: {
                "Industry Class": "Repair of other personal and household goods",
                Subclasses: {
                  95290: "Repair of other personal and household goods",
                },
              },
            },
          },
        },
      },
      96: {
        "Industry Division": "Other personal service activities",
        Groups: {
          960: {
            "Industry Group": "Other personal activities",
            Classes: {
              9601: {
                "Industry Class": "Washing and (dry-) cleaning of textile- and fur products",
                Subclasses: {
                  96010: "Washing and (dry-) cleaning of textile- and fur products",
                },
              },
              9602: {
                "Industry Class": "Hairdressing and other beauty treatment",
                Subclasses: {
                  96021: "Hairdressing",
                  96022: "Beauty treatment",
                },
              },
              9603: {
                "Industry Class": "Funeral and related activities",
                Subclasses: {
                  96030: "Funeral and related activities",
                },
              },
              9609: {
                "Industry Class": "Other personal service activities n.e.c.",
                Subclasses: {
                  96090: "Other personal service activities n.e.c.",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section T": {
    "Industry Section":
      "Activities of households as employers; undifferentiated goods- and services producing activities of households for own use",
    Divisions: {
      97: {
        "Industry Division": "Activities of households as employers of domestic personnel",
        Groups: {
          970: {
            "Industry Group": "Activities of households as employers of domestic personnel",
            Classes: {
              9700: {
                "Industry Class": "Activities of households as employers of domestic personnel",
                Subclasses: {
                  97000: "Activities of households as employers of domestic personnel",
                },
              },
            },
          },
        },
      },
      98: {
        "Industry Division":
          "Undifferentiated goods- and services-producing activities of private households for own use",
        Groups: {
          981: {
            "Industry Group":
              "Undifferentiated goods-producing activities of private households for own use",
            Classes: {
              9810: {
                "Industry Class":
                  "Undifferentiated goods-producing activities of private households for own use",
                Subclasses: {
                  98100:
                    "Undifferentiated goods-producing activities of private households for own use",
                },
              },
            },
          },
          982: {
            "Industry Group":
              "Undifferentiated service-producing activities of private households for own use",
            Classes: {
              9820: {
                "Industry Class":
                  "Undifferentiated service-producing activities of private households for own use",
                Subclasses: {
                  98200:
                    "Undifferentiated service-producing activities of private households for own use",
                },
              },
            },
          },
        },
      },
    },
  },
  "Section U": {
    "Industry Section":
      "Activities of extraterritorial organizations and bodies, not economically active people, unemployed people etc.",
    Divisions: {
      99: {
        "Industry Division":
          "Activities of extraterritorial organizations and bodies, not economically active, people, unemployed people etc",
        Groups: {
          990: {
            "Industry Group":
              "Activities of extraterritorial organizations and bodies, not economically active,\npeople, unemployed people etc",
            Classes: {
              9901: {
                "Industry Class":
                  "Activities of extraterritorial organizations and bodies, not economically active, unemployed people etc",
                Subclasses: {
                  99011: "Activities of extraterritorial organizations and bodies",
                  99012: "Representatives of foreign countries",
                  99013:
                    "Not economically active people, beggars, people living from handouts and charity, etc.",
                  99014: "Unemployed people, people seeking work, etc.",
                },
              },
            },
          },
        },
      },
    },
  },
};
