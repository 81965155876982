import Grid from "@mui/material/Grid";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { FormikProps } from "formik";

interface Option {
  field: string;
  label: string;
  values: string[];
}

interface SignupProfileProps {
  formData: FormikProps<any>;
}

const options: Option[] = [
  { field: "joiningAs", label: "I am joining as a:", values: ["Startup", "Investor"] },
  {
    field: "role",
    label: "My role in the company:",
    values: ["Founder", "Co-founder", "CEO", "Principal Investigator", "Other"],
  },
  {
    field: "seekingToConnect",
    label: "I am seeking to connect with:",
    values: [
      "Startups",
      "Research Partners",
      "Funders / Investors",
      "Industry Partners",
      "Distribution Partners",
    ],
  },
];

const renderOptions = (
  options: Option[],
  values: any,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
) =>
  options.map((option, index) => (
    <Grid
      key={index}
      item
      display="flex"
      justifyContent="center"
      alignItems="center"
      xs={12}
      lg={4}
      sx={{
        borderLeft: { lg: index === 1 ? 1 : 0 },
        borderRight: { lg: index === 1 ? 1 : 0 },
        borderColor: "#C7CCD0",
      }}
    >
      <MDBox sx={{ width: "fit-content" }} pb={{ xs: 2, sm: 2, lg: 5, xl: 5 }}>
        <MDBox pt={1}>
          <MDTypography variant="button" color="text">
            {option.label}
          </MDTypography>
        </MDBox>
        <MDBox pl={4}>
          <RadioGroup
            name={option.field}
            value={
              option.field === "joiningAs" && values[option.field] === "Business"
                ? "Startup"
                : values[option.field]
            }
            onChange={(e) => {
              const newValue = e.target.value;
              if (option.field === "joiningAs" && newValue === "Startup") {
                setFieldValue(option.field, "Business");
              } else {
                setFieldValue(option.field, newValue);
              }
            }}
          >
            {option.values.map((value, idx) => (
              <FormControlLabel
                key={idx}
                label={value}
                value={value}
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": { borderRadius: "4px" },
                      "&:after": { borderRadius: "2px" },
                    }}
                  />
                }
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: { xs: "12px", sm: "12px", lg: "15px" },
                    color: "#7B809A",
                    fontWeight: "400",
                  },
                }}
              />
            ))}
          </RadioGroup>
        </MDBox>
      </MDBox>
    </Grid>
  ));

const SignupProfile: React.FC<SignupProfileProps> = ({ formData }) => {
  const { values, setFieldValue } = formData;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Profile</MDTypography>
        <MDTypography variant="button" color="text">
          Tell us a bit about what you do.
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container>{renderOptions(options, values, setFieldValue)}</Grid>
      </MDBox>
    </MDBox>
  );
};

export default SignupProfile;
