import { CognitoUserPool } from "amazon-cognito-identity-js";
import getConfig from "./config";

// Define the shape of your configuration object
interface CognitoConfig {
  userPoolIdCognito: string;
  clientIdCognito: string;
}

// Get the configuration values
const config: CognitoConfig = getConfig;
console.log("Cognito Config Values:", config);

const poolData = {
  UserPoolId: config.userPoolIdCognito,
  ClientId: config.clientIdCognito,
};

console.log("Pool Data:", poolData);

if (!poolData.UserPoolId || !poolData.ClientId) {
  throw new Error("Both UserPoolId and ClientId are required.");
}

export default new CognitoUserPool(poolData);
