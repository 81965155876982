import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import BalanceSheet from "./BalanceSheet";
import ProfitAndLoss from "./ProfitAndLoss";
import { FinancialSection } from "../types";
/*
interface FinancialDataItem {
  subcategory: string | null;
  amountCurrent: number | null;
  amountPrevious: number | null;
  isHeader?: boolean;
}
*/
/*
interface FinancialSection {
  category: string;
  items: FinancialDataItem[];
}
*/
interface BalanceSheetAndProfitLossProps {
  balanceSheetData: FinancialSection[];
  loadingBalanceSheet: boolean;
  profitData: FinancialSection[];
  loadingProfitAndLoss: boolean;
  asAtDate: string;
  currentDate: string;
  previousDate: string;
}

const BalanceSheetAndProfitLoss: React.FC<BalanceSheetAndProfitLossProps> = ({
  balanceSheetData,
  loadingBalanceSheet,
  profitData,
  loadingProfitAndLoss,
  asAtDate,
  currentDate,
  previousDate,
}) => {
  return (
    <Grid container spacing={2} mb={3}>
      <Grid item xs={12} md={6}>
        <BalanceSheet
          balanceData={balanceSheetData}
          loading={loadingBalanceSheet}
          asAtDate={asAtDate}
          currentDate={currentDate}
          previousDate={previousDate}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ProfitAndLoss
          profitData={profitData}
          loading={loadingProfitAndLoss}
          asAtDate={asAtDate}
          currentDate={currentDate}
          previousDate={previousDate}
        />
      </Grid>
    </Grid>
  );
};

export default BalanceSheetAndProfitLoss;
