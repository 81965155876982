import React from "react";
import Grid from "@mui/material/Grid";
import MDTypography from "components/generic/MDTypography";
import FinancialKpiCard from "./FinancialKpiCard";
import { KpiData } from "../types"; // Import KpiData interface
import { getCurrencySymbol } from "utility/getCurrencySymbol";

interface KpiCardsSectionProps {
  kpiData: KpiData[];
  baseCurrency: string;
}

const KpiCardsSection: React.FC<KpiCardsSectionProps> = ({ kpiData, baseCurrency }) => {
  if (!kpiData || kpiData.length === 0) {
    return <MDTypography variant="body2">No KPI data available</MDTypography>;
  }
  const currencySymbol = getCurrencySymbol(baseCurrency);

  return (
    <Grid container spacing={2} mb={3}>
      {kpiData.map((kpi, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <FinancialKpiCard
            abbreviation={kpi.abbreviation}
            title={kpi.title}
            value={kpi.value}
            change={kpi.change}
            changeColor={kpi.color}
            currencySymbol={currencySymbol}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default KpiCardsSection;
