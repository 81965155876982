import React, {
  createContext,
  useReducer,
  useEffect,
  useContext,
  ReactNode,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
//import { isJwtExpired } from "jwt-check-expiration"; form package if possible/cognito, otherwise create util function and import here.. 2b used to determine login state of user
import {
  dummyUsers,
  publicPermissions,
  administratorPermissions,
  businessPermissions,
  investorPermissions,
} from "../dummyUsers";
import { storeTokens, loginUser } from "../services/auth";
import { LoginResponse, Entity } from "../types/authTypes";

interface User {
  username: string;
  role: string;
  permissions: Set<string>;
}

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  entities: Entity[];
  loginResponse?: LoginResponse;
}

type AuthAction =
  | { type: "LOGIN"; payload: { user: User; entities: Entity[]; loginResponse: LoginResponse } }
  | { type: "LOGOUT" };

//| { type: "SET_ENTITIES"; payload: Entity[] }
//| { type: "SET_LOGIN_RESPONSE"; payload: LoginResponse };

interface AuthContextType extends AuthState {
  login: (
    username: string,
    password: string,
    initialSignUp: boolean,
    rememberMe: boolean,
    entity_id?: number,
    role_id?: number
  ) => Promise<void>;
  logout: () => void;
  dispatch: React.Dispatch<AuthAction>;
}

const defaultPublicUser: User = {
  username: "public",
  role: "public",
  permissions: new Set(publicPermissions), // Set public permissions by default
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: true,
        entities: action.payload.entities,
        loginResponse: action.payload.loginResponse,
      };
    case "LOGOUT":
      return {
        ...state,
        user: defaultPublicUser,
        isAuthenticated: false,
        entities: [],
        loginResponse: null,
      };
    default:
      return state;
  }
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    isAuthenticated: false,
    entities: [],
  });
  //review - aim to include entities/response data from local or revise later
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const storedLoginResponse = localStorage.getItem("loginResponse");
    //const intendedRoute = localStorage.getItem("intendedRoute");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      const parsedLoginResponse = storedLoginResponse ? JSON.parse(storedLoginResponse) : null;
      parsedUser.permissions = new Set(parsedUser.permissions);
      console.log("parsedUser");
      console.log(parsedUser);
      dispatch({
        type: "LOGIN",
        payload: {
          user: parsedUser,
          entities: parsedUser.entities || [],
          loginResponse: parsedLoginResponse || {},
        },
      });
    } else {
      console.log("No user found, setting to public, dispatcing LOGOUT");
      dispatch({
        type: "LOGOUT",
      });
    }
    setLoading(false);
  }, []);

  const login = async (
    username: string,
    password: string,
    initialSignUp: boolean,
    rememberMe: boolean,
    entity_id?: number,
    role_id?: number
  ): Promise<void> => {
    //vs LoginResponse | null if it returns something at end of day..
    try {
      console.log("pre login");
      const loginData = await loginUser(
        username,
        password,
        initialSignUp,
        rememberMe,
        entity_id,
        role_id
      );

      console.log("Login Data:", loginData);
      let permissions = new Set(publicPermissions); // Default to publicPermissions else wise
      /*
      if (loginData.entity && loginData.entity.role_name === "Administrator") {
        permissions = new Set(administratorPermissions);
      }
      */
      const additionalInfo = loginData.entity?.additional_info;
      const entityType = additionalInfo?.profile?.entityType || "unknown";

      if (entityType === "Business") {
        permissions = new Set(businessPermissions);
      } else if (entityType === "Investor") {
        permissions = new Set(investorPermissions);
      }
      console.log("permissions:", permissions);

      const user: User = {
        username: loginData.user_profile.email,
        role: loginData.entity ? loginData.entity.role_name : "public",
        permissions: permissions, // Placeholder for permissions - include in loginData response later + actions p
      };

      localStorage.setItem(
        "user",
        JSON.stringify({
          ...user,
          permissions: Array.from(user.permissions),
          entities: loginData.entities || [],
        })
      );

      localStorage.setItem("loginResponse", JSON.stringify(loginData));

      dispatch({
        type: "LOGIN",
        payload: { user, entities: loginData.entities, loginResponse: loginData },
      });

      const gq_state = loginData.state || "no state";
      console.log("gq_state in use effect after login:", gq_state);
    } catch (error) {
      console.error("Login failed:", error);
      //return null;
    }
  };

  const logout = () => {
    localStorage.clear();

    // Dispatch public user state
    dispatch({ type: "LOGOUT" });

    // Redirect to sign-in page
    navigate("/authentication/sign-in");
    //navigate("/authentication/sign-up");
  };
  /*
    const storedUser = JSON.parse(localStorage.getItem("user") || "{}");

    if (storedUser.role !== "public") {
      // If the user is not public, proceed with the logout process
      dispatch({ type: "LOGOUT" });

      // Remove user-specific data from localStorage
      localStorage.removeItem("user");
      localStorage.removeItem("loginResponse");
    }

    // Regardless, navigate to sign-in after logging out
    navigate("/authentication/sign-in");
    
  };
  */

  return (
    <AuthContext.Provider value={{ ...state, login, logout, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
