import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDInput from "components/generic/MDInput";
import MDBadge from "components/generic/MDBadge";
import MDSnackbar from "components/generic/MDSnackbar";
import MDTypography from "components/generic/MDTypography";
import MDTooltip from "components/generic/MDTooltip";

// Material Dashboard 2 PRO React TS components/refined components
import Breadcrumbs from "components/refined/Breadcrumbs";
//import NotificationItem from "components/refined/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "components/refined/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

import icon1 from "assets/icons-funding/build-route-icon.png";
import icon2 from "assets/icons-funding/rocket-route-icon.png";
import icon3 from "assets/icons-funding/investor-route-icon.png";

import uiConfig from "assets/ui-config/ui-config";

// Declaring prop types for DashboardNavbar
interface Props {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
}

function DashboardNavbar({ absolute = true, light = true, isMini = false }: Props): JSX.Element {
  const [navbarType, setNavbarType] = useState<
    "fixed" | "absolute" | "relative" | "static" | "sticky"
  >();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState<null | HTMLElement>(null);
  const route = useLocation().pathname.split("/").slice(1);
  const route1 = useLocation().pathname;
  const breadcrumbStyles =
    route1 === "/profile/welcome-page"
      ? { backgroundColor: "#386A11", padding: "10px", borderRadius: "8px", zIndex: 1 } // Example styles for Welcome Page
      : {};
  console.log("route1", route1);
  /*
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  */
  /*
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
*/
  const alertContent = (name: string) => (
    <MDTypography variant="body2" color="white">
      A simple {name} alert with{" "}
      <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
        an example link
      </MDTypography>
      . Give it a click if you like.
    </MDTypography>
  );

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  {
    /* remove notifications for now
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  */
  }

  const renderMenu = () => (
    // You can uncomment and use the below menu when needed
    // <Menu
    //   anchorEl={openMenu}
    //   anchorReference={null}
    //   anchorOrigin={{
    //     vertical: "bottom",
    //     horizontal: "left",
    //   }}
    //   open={Boolean(openMenu)}
    //   onClose={handleCloseMenu}
    //   sx={{ mt: 0 }}
    // >
    //   <MDAlert color="primary" dismissible>
    //     {alertContent("primary")}
    //   </MDAlert>
    //   <MDAlert color="success" dismissible>
    //     {alertContent("success")}
    //   </MDAlert>
    //   <MDAlert color="error" dismissible>
    //     {alertContent("error")}
    //   </MDAlert>
    //   <MDAlert color="warning" dismissible>
    //     {alertContent("warning")}
    //   </MDAlert>
    // </Menu>
    <></>
  );

  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }: {
    palette: any;
    functions: any;
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const renderIcon = () => {
    //console.log("Current Route: ", route);
    if (route.includes("profile")) {
      return icon1;
    } else if (route.includes("startup")) {
      return icon2;
    } else if (route.includes("investor")) {
      return icon3;
    } else {
      return icon1;
    }
  };

  const renderTitle = () => {
    if (
      route.includes("Venture-Snapshot") ||
      route.includes("Critical-Solution") ||
      route.includes("AI-Pen-Testing") ||
      route.includes("Template")
    ) {
      const part1 = route[route.length - 2].split("-").join(" ");
      const part2 = route[route.length - 1].split("-").join(" ");
      return part1 + " / " + part2;
    } else {
      return route[route.length - 1];
    }
  };

  return (
    <AppBar
      //position={absolute ? "absolute" : navbarType}
      position={absolute ? "absolute" : "fixed"}
      color="inherit"
      sx={(theme) => ({
        ...navbar(theme, { transparentNavbar, absolute, light, darkMode }),
        backgroundColor:
          route1 === "/profile/welcome-page" || route1 === "/profile/profile-building-agreement"
            ? "#386A11" // Green background for both pages
            : "#ffffff00", // Transparent for other pages
        boxShadow: "none",
        margin:
          route1 === "/profile/welcome-page" || route1 === "/profile/profile-building-agreement"
            ? "0px 10px"
            : "auto", // Margin for green background pages
        padding:
          route1 === "/profile/welcome-page" || route1 === "/profile/profile-building-agreement"
            ? "10px"
            : "5px", // Padding for green background pages
        marginTop:
          route1 === "/profile/welcome-page" || route1 === "/profile/profile-building-agreement"
            ? "10px"
            : "15px", // Margin-top for green background pages
        width:
          route1 === "/profile/welcome-page" || route1 === "/profile/profile-building-agreement"
            ? "calc(100% - 20px)"
            : "100%", // Adjust width for green background pages
        borderRadius:
          route1 === "/profile/welcome-page" || route1 === "/profile/profile-building-agreement"
            ? "6px"
            : "0px", // Border-radius for green background pages
      })}
    >
      <Toolbar
        sx={(theme) => ({
          ...navbar(theme, { transparentNavbar, absolute, light, darkMode }),
          backgroundColor: "transparent", // Set your desired color here
          width: "100% !important",
          margin: "auto",
        })}
      >
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon={renderIcon()} title={renderTitle()} route={route} light={light} />
          <IconButton
            sx={{ ...navbarDesktopMenu, display: { xs: "block", xl: "none" } }}
            onClick={handleMiniSidenav}
            size="small"
            disableRipple
          >
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </MDBox>
        {/*
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "white"}>
              <MDTooltip
                title={
                  <div id="tooltip12">
                    <p>Notifications</p>
                  </div>
                }
              >
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  //variant="contained"
                  onClick={handleOpenMenu}
                >
                  <MDBadge badgeContent={0} color="error" size="xs" circular>
                    <Icon sx={iconsStyle}>notifications</Icon>
                  </MDBadge>
                </IconButton>
              </MDTooltip>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
        */}
      </Toolbar>
      {/*
      {renderSuccessSB}
      {renderInfoSB}
      {renderWarningSB}
      {renderErrorSB}
      */}
    </AppBar>
  );
}

export default DashboardNavbar;
