import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Card,
  Modal,
  Box,
} from "@mui/material";
import MDBox from "components/generic/MDBox";
import MDInput from "components/generic/MDInput";
import MDButton from "components/generic/MDButton";
import AddIcon from "@mui/icons-material/Add";
import CombinedMapComponent from "components/custom/Google/combinedMapComponent"; // Adjust the path as necessary
import UploadComponent from "components/shared/questionnaire/UploadComponent";
import { EntityDetails, FormData } from "../../types";
/*
interface EntityDetails {
  businessName: string;
  investorType: string;
  primaryInvestmentSector: string;
  inputValue: string;
  establishmentDate: string;
  businessRegistrationNumber: string;
  taxReferenceNumber: string;
  streetNo: string;
  streetName: string;
  suburb: string;
  city: string;
  zipCode: string;
  country: string;
  latitude: string;
  longitude: string;
  businessWebsite?: string; // optional if you plan to include this
}

interface FormData {
  entityDetails: EntityDetails;
}
*/
interface CompanySummaryProps {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const center = {
  lat: -3.745,
  lng: -38.523,
};

const investorTypes = [
  "Venture Capitalist",
  "Angel Investor",
  "Private Equity Investor",
  "Corporate Investor",
  "Institutional Investor",
  "Retail Investor",
  "High Networth Individual Investor (HNWI)",
  "Impact Investor",
  "Bank or Financial Institution",
  "Non-Traditional Investor (e.g. crypto, NPO, P2P)",
  "International Organization",
  "Government Entity",
];

const sectorTypes = ["Private Sector", "Public Sector", "Both", "Other"];

const CompanySummary: React.FC<CompanySummaryProps> = ({ formData, setFormData }) => {
  const {
    businessName,
    investorType,
    primaryInvestmentSector,
    inputValue,
    establishmentDate,
    businessRegistrationNumber,
    taxReferenceNumber,
    streetNo,
    streetName,
    suburb,
    city,
    zipCode,
    country,
    latitude,
    longitude,
  } = formData.entityDetails;

  const [position, setPosition] = useState<{ lat: number; lng: number }>({
    lat: parseFloat(latitude) || center.lat,
    lng: parseFloat(longitude) || center.lng,
  });

  const [openLogoModal, setOpenLogoModal] = useState<boolean>(false);
  const [openWebsiteModal, setOpenWebsiteModal] = useState<boolean>(false);

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof EntityDetails
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      entityDetails: {
        ...prevState.entityDetails,
        [field]: event.target.value,
      },
    }));
  };

  const handlePlaceSelected = (place: any, lat: number, lng: number) => {
    const addressComponents = place.address_components;

    const getAddressComponent = (type: string) => {
      const component = addressComponents.find((component: any) => component.types.includes(type));
      return component ? component.long_name : "";
    };

    setFormData((prevState) => ({
      ...prevState,
      entityDetails: {
        ...prevState.entityDetails,
        streetNo: getAddressComponent("street_number"),
        streetName: getAddressComponent("route"),
        suburb: getAddressComponent("sublocality_level_1"),
        city: getAddressComponent("locality"),
        zipCode: getAddressComponent("postal_code"),
        country: getAddressComponent("country"),
        latitude: lat.toString(),
        longitude: lng.toString(),
      },
    }));
    setPosition({ lat, lng });
  };

  const handleLogoModalOpen = () => setOpenLogoModal(true);
  const handleLogoModalClose = () => setOpenLogoModal(false);

  const handleWebsiteModalOpen = () => setOpenWebsiteModal(true);
  const handleWebsiteModalClose = () => setOpenWebsiteModal(false);

  const handleSectorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedSector = event.target.value;

    setFormData((prevState) => ({
      ...prevState,
      entityDetails: {
        ...prevState.entityDetails,
        primaryInvestmentSector: selectedSector,
        inputValue: selectedSector === "Other" ? prevState.entityDetails.inputValue : "", // Clear inputValue if not "Other"
      },
    }));
  };

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      entityDetails: {
        businessName,
        investorType,
        primaryInvestmentSector,
        inputValue,
        establishmentDate,
        businessRegistrationNumber,
        taxReferenceNumber,
        streetNo,
        streetName,
        suburb,
        city,
        zipCode,
        country,
        latitude,
        longitude,
      },
    }));
  }, [
    businessName,
    investorType,
    primaryInvestmentSector,
    inputValue,
    establishmentDate,
    businessRegistrationNumber,
    taxReferenceNumber,
    streetNo,
    streetName,
    suburb,
    city,
    zipCode,
    country,
    latitude,
    longitude,
    setFormData,
  ]);

  return (
    <Grid container px={{ xs: 1, lg: 2, xl: 2 }} spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Company Summary</Typography>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Please provide the following information regarding your investment:
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          What is the name of the fund?
        </Typography>
        <TextField
          label="Enter name of fund"
          value={businessName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleFieldChange(e, "businessName")
          }
          fullWidth
          variant="outlined"
          margin="normal"
        />
        <Typography sx={{ fontSize: "14px", mt: 3 }} variant="body1">
          When was your business established and operational?{" "}
          <span className="text-red-500">*</span>
        </Typography>
        <TextField
          label="Date"
          sx={{ width: "75%" }}
          type="date"
          value={establishmentDate}
          onChange={(e) => handleFieldChange(e, "establishmentDate")}
          InputLabelProps={{ shrink: true }}
          fullWidth
          variant="outlined"
          margin="normal"
        />
        <Typography sx={{ fontSize: "14px", mt: 3 }} variant="body1">
          What is the physical address of your business? <span className="text-red-500">*</span>
        </Typography>
        <br />
        <CombinedMapComponent onPlaceSelected={handlePlaceSelected} position={position} />
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <MDInput
              label="Street No"
              sx={{ display: "none" }}
              value={streetNo}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFieldChange(e, "streetNo")
              }
              required
            />
          </Grid>
          <Grid item xs={6} md={9}>
            <MDInput
              label="Street Name"
              sx={{ display: "none" }}
              value={streetName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFieldChange(e, "streetName")
              }
              required
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <MDInput
              label="Suburb"
              sx={{ display: "none" }}
              value={suburb}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFieldChange(e, "suburb")}
            />
          </Grid>
          <Grid item xs={6} md={9}>
            <MDInput
              label="City"
              sx={{ display: "none" }}
              value={city}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFieldChange(e, "city")}
              required
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <MDInput
              label="ZIP/Postal Code"
              sx={{ display: "none" }}
              value={zipCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFieldChange(e, "zipCode")}
              required
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <MDInput
              label="Country"
              sx={{ display: "none" }}
              value={country}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFieldChange(e, "country")}
              required
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <MDInput
              label="Latitude"
              sx={{ display: "none" }}
              value={latitude}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFieldChange(e, "latitude")
              }
              required
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <MDInput
              label="Longitude"
              sx={{ display: "none" }}
              value={longitude}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFieldChange(e, "longitude")
              }
              required
            />
          </Grid>
        </Grid>
        <Typography sx={{ fontSize: "14px", mt: 3 }} variant="body1">
          What is your business registration number?
        </Typography>
        <MDInput
          sx={{ width: "75%" }}
          label="Please Provide"
          value={businessRegistrationNumber}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleFieldChange(e, "businessRegistrationNumber")
          }
        />
        <Typography sx={{ fontSize: "14px", mt: 3 }} variant="body1">
          What is your business tax reference number? <span className="text-red-500">*</span>
        </Typography>
        <MDInput
          label="Please Provide"
          sx={{ width: "75%" }}
          value={taxReferenceNumber}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleFieldChange(e, "taxReferenceNumber")
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Choose the option that best describes your organization:
        </Typography>
        <RadioGroup row value={investorType} onChange={(e) => handleFieldChange(e, "investorType")}>
          <MDBox>
            {investorTypes.map((type) => (
              <FormControlLabel
                key={type}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      lg: "15px",
                    },
                    color: "#7B809A",
                    fontWeight: "400",
                  },
                }}
                value={type}
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        borderRadius: "4px",
                      },
                      "&:after": {
                        borderRadius: "2px",
                      },
                    }}
                  />
                }
                label={type}
              />
            ))}
          </MDBox>
        </RadioGroup>
        <Typography sx={{ fontSize: "14px", mt: 3 }} variant="body1">
          What is the primary sector that you typically invest in?
        </Typography>
        <RadioGroup
          row
          value={primaryInvestmentSector}
          //onChange={(e) => handleFieldChange(e, "primaryInvestmentSector")}
          onChange={handleSectorChange}
        >
          <MDBox>
            {sectorTypes.map((type) => (
              <FormControlLabel
                key={type}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      lg: "15px",
                    },
                    color: "#7B809A",
                    fontWeight: "400",
                  },
                }}
                value={type}
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        borderRadius: "4px",
                      },
                      "&:after": {
                        borderRadius: "2px",
                      },
                    }}
                  />
                }
                label={
                  type === "Other" ? (
                    <MDInput
                      value={inputValue}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleFieldChange(e, "inputValue")
                      }
                      label="Other"
                    />
                  ) : (
                    type
                  )
                }
              />
            ))}
          </MDBox>
        </RadioGroup>
        <Grid container alignItems="center" spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={4}>
            <Typography sx={{ fontSize: "14px", fontWeight: "bold" }} variant="body1">
              Business Logo
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <MDButton
              variant="contained"
              sx={{ width: "200px" }}
              color="primary"
              onClick={handleLogoModalOpen}
            >
              Upload Logo <AddIcon />
            </MDButton>
          </Grid>
        </Grid>

        <Grid container alignItems="center" spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={4}>
            <Typography sx={{ fontSize: "14px", fontWeight: "bold" }} variant="body1">
              Business Website Link
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <MDInput
              label="Website link"
              sx={{ width: "200px" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFieldChange(e, "businessWebsite")
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={openLogoModal}
        onClose={handleLogoModalClose}
        aria-labelledby="upload-logo-modal"
        aria-describedby="upload-logo-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: { xs: 320, lg: 500 } }}>
          <UploadComponent
            onClose={handleLogoModalClose}
            btndata={setOpenLogoModal}
            purpose="business_logo"
            //sx={{ width: { xs: 320, lg: 500 } }}, doesnt have sx prop therefore style in wrapper Box
          />
        </Box>
      </Modal>

      <Modal
        open={openWebsiteModal}
        onClose={handleWebsiteModalClose}
        aria-labelledby="upload-website-modal"
        aria-describedby="upload-website-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: { xs: 320, lg: 500 } }}>
          <UploadComponent
            onClose={handleWebsiteModalClose}
            btndata={setOpenWebsiteModal}
            purpose={"business_logo"}
          />
        </Box>
      </Modal>
    </Grid>
  );
};

export default CompanySummary;
