import React from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Custom components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";

// Declaring prop types for ProjectCard
interface ProjectCardProps {
  bgColor?: "white" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
  title: string;
  value: string;
  icon: {
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
    component: React.ReactNode;
  };
  [key: string]: any;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ bgColor = "white", title, value, icon }) => {
  return (
    <Card sx={{ overflow: "hidden" }}>
      <MDBox
        bgColor={bgColor}
        variant="gradient"
        sx={({ palette: { background } }: { palette: any }) => ({
          background: bgColor === "white" ? background.card : undefined,
        })}
      >
        <MDBox p={2}>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <MDBox
                variant="gradient"
                bgColor={bgColor === "white" ? icon.color : "white"}
                color={bgColor === "white" ? "white" : "dark"}
                width="4rem"
                height="4rem"
                borderRadius="md"
                display="flex"
                justifyContent="center"
                alignItems="center"
                shadow="md"
              >
                <Icon fontSize="medium" color="inherit">
                  {icon.component}
                </Icon>
              </MDBox>
            </Grid>
            <Grid item xs={8}>
              <MDBox ml={2} lineHeight={1}>
                <MDTypography
                  variant="button"
                  color={bgColor === "white" ? "text" : "white"}
                  opacity={bgColor === "white" ? 1 : 0.7}
                  textTransform="capitalize"
                  fontWeight="medium"
                >
                  {title}
                </MDTypography>
                <MDTypography
                  variant="h5"
                  fontWeight="bold"
                  color={bgColor === "white" ? "dark" : "white"}
                >
                  {value}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default ProjectCard;
