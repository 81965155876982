import { ReactNode } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import backgroundImage from "assets/images/reisiger/bg-profile.png";
import profileStartImg from "assets/images/reisiger/profile_start.png";
import Footer from "layouts/authentication/components/Footer";
import DashboardNavbar from "components/refined/Navbars/DashboardNavbar";
import uiConfig from "assets/ui-config/ui-config";
import FooterLogo from "../../../../../../authentication/components/FooterLogo";

interface HeaderProps {
  children?: ReactNode;
}

function Header({ children }: HeaderProps): JSX.Element {
  return (
    <MDBox position="relative" mb={5} mt={2}>
      <DashboardNavbar />
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        minHeight={{
          xs: "8em",
          sm: "8rem",
          md: "10rem",
          lg: "10rem",
          xl: "10rem",
        }}
        borderRadius="xl"
        sx={{
          //backgroundColor: uiConfig.theme.palette.secondary.main,
          backgroundColor: "#ffffff",
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      ></MDBox>
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: {
            xs: 1,
            lg: 3,
          },
          py: 2,
          px: {
            xs: 2,
            lg: 2,
          },
          backgroundImage: `url(${profileStartImg})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
          minHeight: {
            xs: "950px",
            sm: "950px",
            lg: "990px",
            xl: "1050px",
          },
        }}
      >
        {children}
        <FooterLogo />
      </Card>
    </MDBox>
  );
}

Header.defaultProps = {
  children: null,
};

export default Header;
