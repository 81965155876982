interface BudgetSummaryItem {
  month: string;
  totalIncome: number;
  grossProfit: number;
  operatingExpenses: number;
  netProfit: number;
}

export function transformBudgetChartApiResponse(apiData: any): BudgetSummaryItem[] {
  const { Months, TotalIncome, TotalExpenses, GrossProfit, NetProfit } = apiData.data;

  const formattedData: BudgetSummaryItem[] = Months.map((month: string, index: number) => {
    if (index === 0) return null;

    return {
      month: formatMonth(Months[index]),
      totalIncome: parseFloat(TotalIncome[index]) || 0,
      grossProfit: parseFloat(GrossProfit[index]) || 0,
      operatingExpenses: parseFloat(TotalExpenses[index]) || 0,
      netProfit: parseFloat(NetProfit[index]) || 0,
    };
  }).filter(Boolean);
  formattedData.sort((a, b) => compareMonths(a.month, b.month));
  return formattedData;
}

function formatMonth(month: string): string {
  if (/^\w{3}-\d{2}$/.test(month)) {
    return month;
  }

  const parts = month.split(" ");
  if (parts.length === 3) {
    const day = parts[0];
    const monthShort = parts[1].substring(0, 3);
    const year = parts[2].slice(-2);

    return `${monthShort}-${year}`;
  }

  return month;
}

function compareMonths(monthA: string, monthB: string): number {
  const [monthAName, yearA] = monthA.split("-");
  const [monthBName, yearB] = monthB.split("-");

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const monthAIndex = monthNames.indexOf(monthAName);
  const monthBIndex = monthNames.indexOf(monthBName);

  const yearComparison = parseInt(yearA) - parseInt(yearB);

  if (yearComparison !== 0) {
    return yearComparison; // Compare by year first
  } else {
    return monthAIndex - monthBIndex; // If same year, compare by month
  }
}
