import React from "react";
import MDBox from "components/generic/MDBox";
import headerImage from "assets/images/reisiger/bg-profile.png"; // get appropriate image as bg-investor-portifolio and add to src overrrides

const Header: React.FC = () => {
  return (
    <MDBox position="relative" mb={5}>
      {/* Header Background Image */}
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="10rem"
        borderRadius="xl"
        sx={{
          backgroundImage: `url(${headerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
          mx: 0, // Ensure margins are consistent with the design
          mb: 2,
          mt: 2,
        }}
      />
    </MDBox>
  );
};

export default Header;
