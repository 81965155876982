import React from "react";
import MDBox from "../../../components/generic/MDBox/index";
import MDTypography from "../../../components/generic/MDTypography";
import MDButton from "../../../components/generic/MDButton";
import Icon from "@mui/material/Icon";
import PageLayout from "../../../components/refined/LayoutContainers/PageLayout";
import DashboardLayout from "../../../components/refined/LayoutContainers/DashboardLayout";
import { useNavigate } from "react-router-dom";

function UnauthorizedAccess(): JSX.Element {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <DashboardLayout>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="calc(100vh - 3.5rem)"
        px={1}
        mx="auto"
        width="100%"
        textAlign="center"
      >
        <MDBox width="100%" maxWidth="500px" textAlign="center">
          <Icon fontSize="large" color="error" sx={{ fontSize: "5rem" }}>
            lock
          </Icon>
          <MDTypography variant="h2" color="error" mt={2} mb={1}>
            Unauthorized Access
          </MDTypography>
          <MDTypography variant="body1" color="textSecondary" mb={3}>
            You do not have the necessary permissions to access this page.
          </MDTypography>
          <MDButton variant="contained" color="error" onClick={handleGoBack} size="large">
            Go Back
          </MDButton>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default UnauthorizedAccess;
