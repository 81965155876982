import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField } from "@mui/material";
import { OnelinerDetailsProps, OnelinerProposal } from "../../types";

const Onelinerdetails: React.FC<OnelinerDetailsProps> = ({ formData, setFormData }) => {
  const startupBusinessName = formData.entityDetails?.businessName || "";
  const [proposal, setProposal] = useState<OnelinerProposal>({
    businessName: formData.oneLinerDetails?.businessName || startupBusinessName,
    problemOutline: formData.oneLinerDetails?.problemOutline || "",
    keyOffering: formData.oneLinerDetails?.keyOffering || "",
    targetMarket: formData.oneLinerDetails?.targetMarket || "",
    valueProposition: formData.oneLinerDetails?.valueProposition || "",
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      oneLinerDetails: proposal,
    }));
  }, [proposal, setFormData]);

  const handleChange =
    (field: keyof OnelinerProposal) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setProposal((prevState) => ({
        ...prevState,
        [field]: event.target.value,
      }));
    };

  return (
    <Grid container px={{ xs: 2, lg: 5 }} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Business One-Liner</Typography>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Please complete the following statement:
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <TextField
          placeholder="{Business Name}"
          variant="outlined"
          value={proposal.businessName}
          onChange={handleChange("businessName")}
          sx={{
            width: {
              xs: "99%",
              sm: "90%",
              md: "407px",
            },
            "& input": {
              textAlign: "center", // Center align text input
            },
            "& ::placeholder": {
              fontStyle: "italic",
              textAlign: "center", // Center align placeholder
              fontSize: { xs: "10px", lg: "13px" },
              width: "100%",
            },
          }}
          multiline
          rows={2}
          disabled={Boolean(startupBusinessName)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography textAlign="center" sx={{ fontSize: "14px" }} variant="body1">
          is tackling
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <TextField
          placeholder="Outline the problem you solve"
          variant="outlined"
          value={proposal.problemOutline}
          onChange={handleChange("problemOutline")}
          sx={{
            width: {
              xs: "99%",
              sm: "90%",
              md: "407px",
            },
            "& input": {
              textAlign: "center", // Center align text input
            },
            "& ::placeholder": {
              fontStyle: "italic",
              textAlign: "center", // Center align placeholder
              fontSize: { xs: "10px", lg: "13px" },
              width: "100%",
            },
          }}
          multiline
          rows={2}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography textAlign="center" sx={{ fontSize: "14px" }} variant="body1">
          by offering
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <TextField
          placeholder="Specify your key offering"
          variant="outlined"
          value={proposal.keyOffering}
          onChange={handleChange("keyOffering")}
          sx={{
            width: {
              xs: "99%",
              sm: "90%",
              md: "407px",
            },
            "& input": {
              textAlign: "center", // Center align text input
            },
            "& ::placeholder": {
              fontStyle: "italic",
              textAlign: "center", // Center align placeholder
              fontSize: { xs: "10px", lg: "13px" },
              width: "100%",
            },
          }}
          multiline
          rows={2}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography textAlign="center" sx={{ fontSize: "14px" }} variant="body1">
          which enable(s)
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <TextField
          placeholder="Define your target market"
          variant="outlined"
          value={proposal.targetMarket}
          onChange={handleChange("targetMarket")}
          sx={{
            width: {
              xs: "99%",
              sm: "90%",
              md: "407px",
            },
            "& input": {
              textAlign: "center", // Center align text input
            },
            "& ::placeholder": {
              fontStyle: "italic",
              textAlign: "center", // Center align placeholder
              fontSize: { xs: "10px", lg: "13px" },
              width: "100%",
            },
          }}
          multiline
          rows={2}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography textAlign="center" sx={{ fontSize: "14px" }} variant="body1">
          to
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <TextField
          placeholder="Describe your secret sauce"
          variant="outlined"
          value={proposal.valueProposition}
          onChange={handleChange("valueProposition")}
          sx={{
            width: {
              xs: "99%",
              sm: "90%",
              md: "407px",
            },
            "& input": {
              textAlign: "center", // Center align text input
            },
            "& ::placeholder": {
              fontStyle: "italic",
              textAlign: "center", // Center align placeholder
              fontSize: { xs: "10px", lg: "13px" },
              width: "100%",
            },
          }}
          multiline
          rows={2}
        />
      </Grid>
    </Grid>
  );
};

export default Onelinerdetails;
