import React from "react";
import { axiosInstance } from "api/axiosInstance";
import endpoints from "api/endpoints";
import { useFormik } from "formik";
import * as Yup from "yup";
// @mui material components
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
// Material Dashboard 2 PRO React components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
// Images
import bgImage from "assets/images/reisiger/profileBgImage.png";
import { EmailOutlined, LockReset } from "@mui/icons-material";
// react-router-dom components
import { Link } from "react-router-dom";
import MDTooltip from "components/generic/MDTooltip";
import Footer from "components/refined/Footer";
//import Footer from "../components/Footer" //use this if generic footer looks odd

const NewPassword: React.FC = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(
        8,
        "Password must be at least 8 characters long and include uppercase letters, lowercase letters, numbers, and symbols."
      ),
    repeatPassword: Yup.string()
      .required("Repeat password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      repeatPassword: "",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      console.log(values);
      try {
        /*
        const response = await axiosInstance.post(endpoints.auth.confirmResetPassword, {
          password: values.password,
          repeatPassword: values.repeatPassword,
        });
        console.log("response in NewPassword");
        console.log(response.data);
        */
        console.log("in request handler - request not lnked");
        // Handle success + redirect
      } catch (error: any) {
        // Handle error, maybe show a notification or error message
        console.error("Error resetting password:", error);
      }
    },
  });

  return (
    <BasicLayout image={bgImage}>
      <Card
        sx={{
          minWidth: {
            lg: 485,
            xl: 485,
          },
          mb: 2,
        }}
      >
        <MDBox
          variant="contained"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={{
            xs: 2,
            sm: 2,
            lg: 4.5,
            xl: 4.5,
          }}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
        </MDBox>
        <MDBox
          pt={{
            xs: 2,
            sm: 2,
            lg: 6,
            xl: 6,
          }}
          pb={{
            xs: 2,
            sm: 2,
            lg: 5,
            xl: 5,
          }}
          px={6}
        >
          <form onSubmit={formik.handleSubmit}>
            <MDBox
              component="form"
              role="form"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              gap={3}
              px={5}
            >
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ fontSize: 80, height: 80, overflowY: "hidden" }}
              >
                <EmailOutlined color="info" /> {/*was black - change back later when provisioned*/}
              </MDBox>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                gap={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="black">
                  Success
                </MDTypography>
                <MDTypography display="block" variant="button" color="black">
                  Your password has successfully been reset. Set your new password to sign in.
                </MDTypography>
              </MDBox>
              <MDBox mt={1}>
                <FormControl
                  sx={{ mb: 3 }}
                  fullWidth
                  variant="standard"
                  error={formik.touched.password && Boolean(formik.errors.password)}
                >
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    endAdornment={
                      <MDTooltip
                        title={
                          <div id="tooltip11">
                            <p>Tooltip: View Password</p>
                          </div>
                        }
                      >
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            sx={{ color: showPassword ? "#00DC82" : "grey-900" }}
                          >
                            <LockReset />
                          </IconButton>
                        </InputAdornment>
                      </MDTooltip>
                    }
                  />
                  {formik.touched.password && formik.errors.password && (
                    <MDTypography variant="caption" color="error">
                      {formik.errors.password}
                    </MDTypography>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
                >
                  <InputLabel htmlFor="repeatPassword">Repeat Password</InputLabel>
                  <Input
                    id="repeatPassword"
                    name="repeatPassword"
                    type={showPassword2 ? "text" : "password"}
                    value={formik.values.repeatPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    endAdornment={
                      <MDTooltip
                        title={
                          <div id="tooltip11">
                            <p>View Password</p>
                          </div>
                        }
                      >
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword}
                            sx={{ color: showPassword2 ? "#00DC82" : "grey-900" }}
                          >
                            <LockReset />
                          </IconButton>
                        </InputAdornment>
                      </MDTooltip>
                    }
                  />
                  {formik.touched.repeatPassword && formik.errors.repeatPassword && (
                    <MDTypography variant="caption" color="error">
                      {formik.errors.repeatPassword}
                    </MDTypography>
                  )}
                </FormControl>
              </MDBox>
              <MDBox mt={2} textAlign="center">
                <a
                  href="https://share-eu1.hsforms.com/1tSYB4fdJTyKprNAC0as67Q2dypu8"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <MDTypography variant="button" color="primary" fontWeight="medium">
                    Need help?
                  </MDTypography>
                </a>
              </MDBox>
              <MDBox sx={{ width: "100%" }} mt={{ xs: 2, sm: 2, lg: 5 }}>
                <MDButton
                  component={Link}
                  to="/authentication/sign-in"
                  sx={{ textTransform: "uppercase" }}
                  variant="gradient"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  Sign In
                </MDButton>
              </MDBox>
            </MDBox>
          </form>
        </MDBox>
      </Card>
      <Footer />
    </BasicLayout>
  );
};

export default NewPassword;
