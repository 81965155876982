import React from "react";
import Card from "@mui/material/Card";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import MDButton from "components/generic/MDButton";
import MDBox from "components/generic/MDBox";
import CloseIcon from "@mui/icons-material/Close";
import uiConfig from "assets/ui-config/ui-config";
import { confirmTC } from "services/ventureApi";

interface TermsAndConditionsProps {
  closeAll: (closed: boolean) => void;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ closeAll }) => {
  const navigate = useNavigate();

  const handleAccept = async () => {
    try {
      // Confirm T&C and redirect to welcome page
      await confirmTC();
      navigate("/profile/welcome-page");
    } catch (error) {
      console.error("Error during T&C confirmation", error);
    }
  };

  const handleDecline = () => {
    window.open(uiConfig.tcDeclineLink.redirect.href, "_blank"); // Redirect logic after declining
  };
  return (
    <>
      <Card sx={{ overflow: "visible !important", width: { xs: 300, lg: 400 } }}>
        <MDBox
          variant="contained"
          bgColor="black"
          borderRadius="lg"
          coloredShadow="black"
          mx={2}
          mt={-3}
          px={2}
          py={2}
          mb={1}
          textAlign="center"
          color="white"
          sx={{ position: "relative" }}
        >
          <Typography textAlign="center" sx={{ fontSize: "24px", fontWeight: 700 }}>
            Attention
          </Typography>
          <CloseIcon
            onClick={() => closeAll(true)}
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: "7%",
              right: "2%",
              fontSize: "15px",
              fontWeight: 300,
            }}
          />
        </MDBox>
        <Container
          sx={{
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "15px",
            overflowY: "scroll",
            maxHeight: "75vh",
          }}
        >
          <Typography textAlign="center" sx={{ fontSize: "14px" }} variant="body1">
            Are you sure you want to decline the Terms and Conditions?
          </Typography>
          <Typography textAlign="center" sx={{ fontSize: "14px" }} variant="body1">
            We want to reassure you that your privacy and the security of your information are our
            top priorities. Even if you decide not to accept the Terms and Conditions, your data
            remains secure with us.
          </Typography>
          {/*
          <MDButton onClick={() => closeAll(true)} variant="contained" color="info">
            ACCEPT Ts & Cs
          </MDButton>
          */}
          <MDButton
            onClick={handleAccept}
            variant="contained"
            color="info"
            sx={{
              backgroundColor: uiConfig.theme.palette.primary.main,
              "&:hover": {
                backgroundColor: uiConfig.theme.palette.secondary.main, // Darker shade on hover
              },
            }}
          >
            ACCEPT Ts & Cs
          </MDButton>

          <Typography textAlign="center" sx={{ fontSize: "14px" }} variant="body1">
            We respect your decision to decline the Terms and Conditions. If you have a moment,
            could you let us know why? This helps us improve our services.
          </Typography>

          <TextField multiline rows={4} sx={{ width: "90%" }} label="Your Message" />

          <MDButton onClick={handleDecline} variant="outlined" color="primary">
            Decline
          </MDButton>
        </Container>
      </Card>
    </>
  );
};

export default TermsAndConditions;
