import React, { useState, useEffect } from "react";
import { Grid, Typography, Radio, FormControlLabel } from "@mui/material";
import MDInput from "components/generic/MDInput";
import { MarketDetailsProps } from "../../types";

const options = {
  scaleOfOperations: [
    "Local Only",
    "Local and Intercontinental",
    "Intercontinental Only",
    "Local and International",
    "International Only",
    "Not Sure",
  ],
  businessMarketType: [
    "B2B: Business to Business",
    "B2C: Business to Consumer",
    "B2G: Business to Government",
    "B2E: Business to Employee",
    "C2B: Consumer to Business",
    "G2B: Government to Business",
    "B2B2C: Business to Business to Consumer",
    "G2G: Government to Government",
    "N2B: Non-profit to Business",
    "B2N: Business to Non-Profit",
    "P2P: Peer to Peer",
    "Other",
  ],
};

const MarketDetails: React.FC<MarketDetailsProps> = ({ formData, setFormData }) => {
  const [scaleOfOperations, setScaleOfOperations] = useState<string>(
    formData.marketDetails?.scaleOfOperations || ""
  );
  const [businessMarketType, setBusinessMarketType] = useState<string>(
    formData.marketDetails?.businessMarketType || ""
  );
  const [otherValue, setOtherValue] = useState<string>(formData.marketDetails?.otherValue || "");

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      marketDetails: {
        scaleOfOperations,
        businessMarketType,
        otherValue,
      },
    }));
  }, [scaleOfOperations, businessMarketType, otherValue, setFormData]);

  const handleScaleOfOperationsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScaleOfOperations(event.target.value);
  };

  const handleBusinessMarketTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setBusinessMarketType(value);
    if (value !== "Other") {
      setOtherValue("");
    }
  };

  const handleOtherValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherValue(event.target.value);
  };

  return (
    <Grid container px={{ xs: 2, lg: 5 }} spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Market Information</Typography>
      </Grid>
      <Grid item md={6}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          What is the scale of the business operations?
        </Typography>
        <Grid container direction="column">
          {options.scaleOfOperations.map((option) => (
            <FormControlLabel
              key={option}
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                  color: "#7B809A",
                  fontWeight: "400",
                },
              }}
              value={option}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      borderRadius: "4px",
                    },
                    "&:after": {
                      borderRadius: "2px",
                    },
                  }}
                />
              }
              label={option}
              onChange={(event, checked) => {
                if (checked)
                  handleScaleOfOperationsChange(event as React.ChangeEvent<HTMLInputElement>);
              }}
              checked={scaleOfOperations === option}
            />
          ))}
        </Grid>
      </Grid>
      <Grid item md={6}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          What type of business Market does your business primarily address?
        </Typography>
        <Grid container direction="column">
          {options.businessMarketType.map((option) => (
            <FormControlLabel
              key={option}
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                  color: "#7B809A",
                  fontWeight: "400",
                },
              }}
              value={option}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      borderRadius: "4px",
                    },
                    "&:after": {
                      borderRadius: "2px",
                    },
                  }}
                />
              }
              label={
                option === "Other" && businessMarketType === "Other" ? (
                  <MDInput value={otherValue} onChange={handleOtherValueChange} label="Other" />
                ) : (
                  option
                )
              }
              onChange={(event, checked) => {
                if (checked)
                  handleBusinessMarketTypeChange(event as React.ChangeEvent<HTMLInputElement>);
              }}
              checked={businessMarketType === option}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MarketDetails;
