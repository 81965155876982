import React from "react";
import MDBox from "components/generic/MDBox";
import Logo from "assets/images/reisiger/logo2.png";

const FooterLogo: React.FC = () => {
  return (
    <MDBox zIndex={0} position="absolute" width="fit-content" bottom={0} right={0} py={4}>
      <MDBox
        width="100%"
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent="flex-end"
        alignItems="center"
        px={6}
      >
        <MDBox component="img" src={Logo} width="120%" height="auto" />
      </MDBox>
    </MDBox>
  );
};

export default FooterLogo;
