import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
import Icon from "@mui/material/Icon";
//component imports
import ProjectList from "./components/ProjectList";
import ProjectDetailsHeader from "./components/ProjectDetailsHeader";
import ProjectCardsSection from "./components/ProjectCardsSection";
import TaskDistributionChart from "./components/TaskDistributionChart";
import TasksProgressSection from "./components/TasksProgressSection";
import GanttChartContainer from "./components/GanttChartContainer";
import SummaryTable from "./components/SummaryTable";
import {
  getClickUpOverview,
  getClickUpClientId,
  login,
  getClickUpTasks,
  getClickUpSubtasks,
  initialSetup,
} from "services/ventureApi";
import { useAuth } from "context/AuthContext";
import { generateGUID } from "utility/guidGenerator";
import { prepareTaskSummationData } from "utility/prepareTaskSummationData";

interface Subtask {
  id: string;
  name: string;
  status: {
    status: string;
    color: string;
  };
  due_date: string | null;
  start_date: string | null;
}

interface Task {
  id: string;
  name: string;
  status: {
    status: string;
    color: string;
  };
  priority: string;
  due_date: string | null;
  start_date: string | null;
  //subtasks: Subtask[]; //no such property
  parent: string;
  project: {
    name: string;
  };
  assignees: {
    name: string;
  };
}

interface GanttTask {
  name: string;
  startDate: string;
  endDate: string;
  color: string;
}

interface TaskCount {
  total: number;
  byStatus: {
    [status: string]: {
      count: number;
      color: string;
    };
  };
}

const prepareGanttData = (tasks: Task[]) => {
  if (tasks.length === 0) return [];

  console.log("tasks in prep", tasks);

  return tasks.map((task: any) => {
    // Handle missing or incorrect data gracefully
    const startDate = task.start_date ? new Date(parseInt(task.start_date, 10)) : new Date();
    const endDate = task.due_date
      ? new Date(parseInt(task.due_date, 10))
      : new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000); // Default to 1 week if no due date

    return {
      name: task.name || "Unnamed Task", // Default to "Unnamed Task" if no name is provided
      startDate: startDate.toISOString().split("T")[0], // Convert to YYYY-MM-DD
      endDate: endDate.toISOString().split("T")[0], // Convert to YYYY-MM-DD
      color: task.status?.color || "#000000", // Default to black if no status color is provided
    };
  });
};

const getEarliestStartDate = (tasks: any[]): string => {
  if (!tasks.length) return new Date().toISOString().split("T")[0]; // Default to today's date if tasks is empty

  return tasks.reduce((earliest: string, task: any) => {
    const taskStartDate = new Date(task.startDate);
    return taskStartDate < new Date(earliest) ? task.startDate : earliest;
  }, tasks[0].startDate);
};

const Projects: React.FC = () => {
  const [refresh, setRefresh] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null); // Default to first project
  const [projects, setProjects] = useState<any[]>([]);
  const [ganttTasks, setGanttTasks] = useState<Task[]>([]);
  //const [subtasks, setSubtasks] = useState<Subtask[]>([]);
  const [isClickUpIntegrated, setIsClickUpIntegrated] = useState(false); // set to false
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { loginResponse } = useAuth();
  //const [clientId, setClientId] = useState<string | null>(null);
  const [data, setData] = useState<any>(null);
  //const [taskCount, setTaskCount] = useState<number>(null);
  //const [taskChartData, setTaskChartData] = useState(mockTaskChartData);
  //cards
  const [projectStatus, setProjectStatus] = useState("");
  const [projectLead, setProjectLead] = useState("");
  const [projectDueDate, setProjectDueDate] = useState("");
  //task distribution
  const [taskCount, setTaskCount] = useState<TaskCount | null>(null);
  const [taskDistributionChartData, setTaskDistributionChartData] = useState({
    labels: [],
    datasets: [{ label: "Tasks", data: [], backgroundColor: [] }],
  });
  const [taskSummationChartData, setTaskSummationChartData] = useState({
    labels: [],
    datasets: [],
  });
  //table
  const [tableTasks, setTableTasks] = useState<Task[]>([]);

  const entityId = loginResponse.entity?.entity_id;
  //used to do inital setup call when overview fails
  useEffect(() => {
    const entityId = loginResponse.entity.entity_id;

    const fetchOverviewData = async () => {
      try {
        setLoading(true);
        const data = await getClickUpOverview(entityId);
        const lists = data.project_overview.lists;
        setProjects(lists);
        setSelectedProject(lists[0]);
        fetchTasksForProject(lists[0].id);
        setData(data);
        setIsClickUpIntegrated(true);
      } catch (error: any) {
        console.error("Error fetching ClickUp overview:", error);

        // Call initialSetup if overview fetch fails
        try {
          await initialSetup(entityId);
          // Retry fetching the overview data
          const data = await getClickUpOverview(entityId);
          const lists = data.project_overview.lists;
          setProjects(lists);
          setSelectedProject(lists[0]);
          fetchTasksForProject(lists[0].id);
          setData(data);
          setIsClickUpIntegrated(true);
        } catch (setupError) {
          console.error("Error during initial setup:", setupError);
          setError("An error occurred while setting up ClickUp integration.");
          setIsClickUpIntegrated(false);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchOverviewData();
  }, [refresh]); // Include 'refresh' in dependency array

  useEffect(() => {
    if (ganttTasks.length > 0) {
      setTableTasks(ganttTasks);
    }
  }, [ganttTasks]);

  useEffect(() => {
    const totalCount = countTasks(ganttTasks);
    console.log("ganttTasks", ganttTasks);
    console.log("totalCount", totalCount);
    setTaskCount(totalCount);
    setTaskDistributionChartData(prepareChartData(totalCount));
  }, [ganttTasks]);

  useEffect(() => {
    if (selectedProject) {
      // Assuming `selectedProject` contains the relevant data
      console.log("selected Project", selectedProject);
      setProjectStatus(selectedProject.status?.status || "Unknown");
      setProjectLead(selectedProject.assignee?.username || "Unknown Lead");
      setProjectDueDate(
        selectedProject.due_date
          ? new Date(parseInt(selectedProject.due_date, 10)).toLocaleDateString()
          : "No due date"
      );
    }
  }, [selectedProject, refresh]);

  const countTasks = (tasks: Task[]): TaskCount => {
    console.log("tasks in count", tasks);
    const topLevelTasks = tasks.filter((task) => task.parent === null);
    const subtasks = tasks.filter((task) => task.parent !== null);

    const topLevelTasksWithoutSubtasks = topLevelTasks.filter(
      (topLevelTask) => !subtasks.some((subtask) => subtask.parent === topLevelTask.id)
    );

    const allTasks = [...topLevelTasksWithoutSubtasks, ...subtasks];

    const taskCountByStatus: TaskCount["byStatus"] = {};

    allTasks.forEach((task) => {
      const status = task.status.status;
      const color = task.status.color;

      if (!taskCountByStatus[status]) {
        taskCountByStatus[status] = { count: 0, color: color };
      }

      taskCountByStatus[status].count += 1;
    });

    // Get the total task count
    const totalTaskCount = allTasks.length;

    console.log("Task count by status:", taskCountByStatus);

    return {
      total: totalTaskCount,
      byStatus: taskCountByStatus,
    };
  };

  const prepareChartData = (taskCount: TaskCount) => {
    const labels = Object.keys(taskCount.byStatus);
    const dataset = {
      label: "Tasks",
      data: labels.map((status) => taskCount.byStatus[status].count),
      backgroundColor: labels.map((status) => taskCount.byStatus[status].color),
    };

    return {
      labels,
      datasets: [dataset],
    };
  };

  useEffect(() => {
    if (ganttTasks.length > 0) {
      const preparedData = prepareTaskSummationData(ganttTasks); //util f()
      console.log("chart data for summation chart", preparedData);
      setTaskSummationChartData(preparedData);
    }
  }, [ganttTasks]);

  const fetchTasksForProject = async (listId: string) => {
    try {
      setLoading(true);
      const tasksData = await getClickUpTasks(entityId, listId);

      const allTasks = tasksData?.tasks.tasks || [];
      console.log("All tasks:", allTasks);

      // Count tasks based on the rules
      //const taskCount = countTasks(allTasks);
      //console.log("taskCount", taskCount);

      setGanttTasks(allTasks); // Set the tasks for display in Gantt chart
      //setTaskCount(totalTaskCount); // You can store the total task count in state
    } catch (error) {
      console.error("Error fetching tasks:", error);
      setError("Could not fetch tasks for the selected project.");
    } finally {
      setLoading(false);
    }
  };

  const handleProjectSelect = (project: any) => {
    setSelectedProject(project);
    fetchTasksForProject(project.id);
  };

  const ganttData = prepareGanttData(ganttTasks);
  const projectStartDate = ganttData.length > 0 ? getEarliestStartDate(ganttData) : "";

  const handleUpdateTask = (updatedTask: Task) => {
    setTableTasks((prevTasks) =>
      prevTasks.map((task) => (task.id === updatedTask.id ? updatedTask : task))
    );
  };

  if (loading) {
    return (
      <MDBox px={2}>
        <MDTypography variant="h6" align="center">
          Loading...
        </MDTypography>
      </MDBox>
    );
  }
  {
    /* add back
  if (error) {
    return (
      <MDBox px={2}>
        <MDTypography variant="h6" color="error" align="center">
          {error}
        </MDTypography>
      </MDBox>
    );
  }
*/
  }
  return (
    <MDBox px={2}>
      <MDBox>
        <Grid container spacing={2}>
          {/* Left Column: Project Overview */}
          <Grid item xs={12} md={3}>
            <ProjectList
              projects={projects}
              selectedProject={selectedProject?.name || ""}
              onProjectSelect={handleProjectSelect}
            />
          </Grid>

          {/* Right Column: Selected Project Details */}
          <Grid item xs={12} md={9}>
            <ProjectDetailsHeader
              projectName={selectedProject?.name || ""}
              projectContent={selectedProject?.content || ""}
              onRefresh={() => setRefresh(!refresh)}
            />
            <ProjectCardsSection
              status={projectStatus}
              lead={projectLead}
              dueDate={projectDueDate}
            />
            <TaskDistributionChart chart={taskSummationChartData} />
            <TasksProgressSection taskChartData={taskDistributionChartData} taskCount={taskCount} />
            <GanttChartContainer tasks={ganttData} projectStartDate={projectStartDate} />
            <SummaryTable tasks={tableTasks} onUpdateTask={handleUpdateTask} />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default Projects;
