import React, { ReactNode } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/generic/MDBox";
import PageLayout from "components/refined/LayoutContainers/PageLayout";
import Footer from "layouts/authentication/components/Footer";
import FooterLogo from "../FooterLogo";

interface SignupLayoutProps {
  image: string;
  children: ReactNode;
}

const SignupLayout: React.FC<SignupLayoutProps> = ({ image, children }) => {
  return (
    <PageLayout>
      {/* <DefaultNavbar
          routes={pageRoutes}
          action={{
            type: "external",
            route: "https://creative-tim.com/product/material-dashboard-pro-react",
            label: "buy now",
          }}
          transparent
          light
        /> */}
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid
          container
          spacing={1}
          py={8}
          display={"flex"}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item sx={{ zIndex: "1000" }} xs={11} sm={9} md={5} lg={4} xl={8}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
      <FooterLogo />
    </PageLayout>
  );
};

export default SignupLayout;
