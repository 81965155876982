import React, { useState, useEffect, ChangeEvent } from "react";
import { Grid, Typography, TextField } from "@mui/material";
import { Mandate4_3Props, AdditionalInfo, MandateDetails } from "../../types";
/*
interface AdditionalInfo {
  additionalPreferences: string;
}

interface MandateDetails {
  additionalInfo?: AdditionalInfo;
}

interface FormData {
  mandateDetails: MandateDetails;
}

interface Mandate4_3Props {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}
*/
const Mandate4_3: React.FC<Mandate4_3Props> = ({ formData, setFormData }) => {
  const mandateDetails = (formData.mandateDetails || {}) as MandateDetails;
  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo>(
    mandateDetails.additionalInfo || {
      additionalPreferences: "",
    }
  );

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      mandateDetails: {
        ...prevData.mandateDetails,
        additionalInfo,
      },
    }));
  }, [additionalInfo, setFormData]);

  const handleChange = (field: keyof AdditionalInfo) => (event: ChangeEvent<HTMLInputElement>) => {
    setAdditionalInfo({
      ...additionalInfo,
      [field]: event.target.value,
    });
  };

  return (
    <Grid container px={{ xs: 2, lg: 5 }} spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Investor Mandate Additional Information</Typography>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Please complete the following information regarding your mandate in general:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography mb={1} sx={{ fontSize: "14px" }} variant="body1">
          Are there any other preferences or sentiments that are not specifically required for
          funding but are deemed favorable?
        </Typography>
        <TextField
          multiline
          label="Please Specify"
          fullWidth
          value={additionalInfo.additionalPreferences}
          onChange={handleChange("additionalPreferences")}
          rows={8}
          sx={{
            "& .MuiInputBase-root": {
              height: "30%",
              alignItems: "flex-start",
            },
            "& .MuiFormLabel-root": {
              fontSize: { xs: "10px", lg: "14px" },
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Mandate4_3;
