import React, {
  useState,
  useEffect,
  useMemo,
  JSXElementConstructor,
  Key,
  ReactElement,
} from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";

// Material Dashboard 2 PRO React TS components/refineds
import Sidenav from "components/refined/Sidenav";
import Configurator from "components/refined/Configurator";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React TS routes
import routes, { publicRoutes } from "routes";

//import { routesFromConfig } from "./routes/routesConfig";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
//pages
import LoadingPage from "./layouts/utility/loading-page";
import UnauthorizedAccess from "layouts/utility/unauthorized-access";
import SignIn from "layouts/authentication/sign-in/SignIn";
//import OAuthCallback from "layouts/utility/callback/ClickcupOAuthCallback";
import OAuthCallback from "layouts/utility/callback/XeroOAuthCallback";
import OopsPage from "layouts/utility/error/OopsPage";

//Route protection
//import ProtectedRoute from "./components/ProtectedRoute";
import FeedbackButton from "components/shared/FeedbackButton";

import { useAuth } from "./context/AuthContext";
import { buildRoutes, filterRoutesByRoleAndPermissions } from "./routes/routesBuilder";
import { allPermissions, publicPermissions } from "dummyUsers";
import { LoginResponse } from "types/authTypes";
const permissions: Set<string> = new Set(allPermissions);
const publicPermissionsSet: Set<string> = new Set(publicPermissions);

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const { user, isAuthenticated, loginResponse } = useAuth();
  const [filteredRoutes, setFilteredRoutes] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const entityName = loginResponse?.entity?.entity_name || "Entity Profile";

  useEffect(() => {
    const loadAndFilterRoutes = async () => {
      if (user) {
        console.log("user exists, begin routes config");
        console.log("login response: ", loginResponse);
        const config = await import("./routes/routesConfig"); //or testRoutesConfig | routesConfig

        const builtRoutes = buildRoutes(config.routesConfig, entityName);

        const entityType =
          (loginResponse as LoginResponse)?.entity?.additional_info?.profile?.entityType ||
          "Business";
        const gq_state = loginResponse?.state || "no state";
        const filtered =
          user?.role && user?.permissions
            ? filterRoutesByRoleAndPermissions(
                builtRoutes,
                user.role,
                user.permissions,
                entityType,
                gq_state
              )
            : filterRoutesByRoleAndPermissions(
                builtRoutes,
                "public",
                publicPermissionsSet,
                "public",
                "no state"
              ); //this loads all if no user, configure to load only public routes with public permissions while no user
        console.log("user: ", user);
        console.log("filtered");
        console.log(filtered);

        setFilteredRoutes(filtered);
        setLoading(false);
      } else {
        console.log("user does not exists, begin public routes config");
        setFilteredRoutes(publicRoutes);
        setLoading(false);
      }
    };

    loadAndFilterRoutes();
  }, [user, loginResponse]);
  // Cache for the rtl
  useMemo(() => {
    const pluginRtl: any = rtlPlugin;
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [pluginRtl],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes: any[]): any =>
    allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
      }) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return <Route path={route.route} element={route.component} key={route.key} />;
        }

        return null;
      }
    );

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );
  if (loading) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <LoadingPage />
      </ThemeProvider>
    );
  }
  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Material Dashboard PRO"
              routes={filteredRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/*{configsButton}*/}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {/* Redirect root path "/" to SignIn */}
          <Route path="/" element={<Navigate to="/authentication/sign-in" />} />
          {/* SignIn Route */}
          <Route path="/authentication/sign-in" element={<SignIn />} />
          <Route path="/callback" element={<OAuthCallback />} />
          <Route path="/oops" element={<OopsPage />} />
          {getRoutes(filteredRoutes)}
          <Route path="*" element={<UnauthorizedAccess />} />
          {/*<Route path="*" element={<Navigate to="/dashboards/analytics" />} />*/}
        </Routes>
        <FeedbackButton />
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Material Dashboard PRO"
            routes={filteredRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/*{configsButton}*/}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {/* Redirect root path "/" to SignIn */}
        <Route path="/" element={<Navigate to="/authentication/sign-in" />} />

        {/* SignIn Route */}
        <Route path="/authentication/sign-in" element={<SignIn />} />
        <Route path="/callback" element={<OAuthCallback />} />
        <Route path="/oops" element={<OopsPage />} />
        {getRoutes(filteredRoutes)}
        <Route path="*" element={<UnauthorizedAccess />} />
        {/*<Route path="*" element={<Navigate to="/dashboards/analytics" />} />*/}
      </Routes>
      <FeedbackButton />
    </ThemeProvider>
  );
}
