import React, { useState, useEffect, ChangeEvent } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Modal,
  Box,
} from "@mui/material";
import MDBox from "components/generic/MDBox";
import MDInput from "components/generic/MDInput";
import MDButton from "components/generic/MDButton";
import AddIcon from "@mui/icons-material/Add";
import UploadComponent from "components/shared/questionnaire/UploadComponent"; // Adjust the path as necessary
import CombinedMapComponent from "components/custom/Google/combinedMapComponent";
import { BusinessDetailsProps } from "../../types";
import { validateBusinessRegistrationNumber } from "services/ventureApi";

const center = {
  lat: -3.745,
  lng: -38.523,
};

const businessTypes = [
  "Sole Proprietorship",
  "Private Company Pty (Ltd)",
  "Partnership / Joint Venture",
  "Public Company (Ltd)",
  "Non-Profit Company",
  "Commercializing Research Project",
  "Not Sure",
  "Other",
];

const registrationStatuses = [
  "Registered at CIPC",
  "Not registered, but planning to register in future",
  "Not registered and not planning on registering",
];

const BusinessDetails: React.FC<BusinessDetailsProps> = ({
  formData,
  setFormData,
  //setNextDisabled,
}) => {
  const [registrationNumber, setRegistrationNumber] = useState<string>(
    formData.entityDetails?.businessRegistrationNumber || ""
  );
  const [validationError, setValidationError] = useState<string | null>(null);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);

  const [selectedOption, setSelectedOption] = useState<string>(
    formData.entityDetails?.businessName ? "Yes" : "No"
  );
  const [selectedOption2, setSelectedOption2] = useState<string>(
    formData.entityDetails?.businessType || "No"
  );
  const [registrationStatus, setRegistrationStatus] = useState<string>(
    formData.entityDetails?.registrationStatus || ""
  );
  const [inputValue, setInputValue] = useState<string>(formData.entityDetails?.businessName || "");
  const [establishmentDate, setEstablishmentDate] = useState<string>(
    formData.entityDetails?.establishmentDate || ""
  );
  const [openLogoModal, setOpenLogoModal] = useState<boolean>(false);
  const [openWebsiteModal, setOpenWebsiteModal] = useState<boolean>(false);
  const [position, setPosition] = useState(center);

  useEffect(() => {
    console.log("Updated entityDetails Form Data:", formData);
  }, [formData]);

  useEffect(() => {
    if (validationError || registrationNumber === "") {
      //setNextDisabled(true);
    } else {
      //setNextDisabled(false);
    }
  }, [validationError, registrationNumber]); //setNextDisabled

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedOption(value);
    if (value === "No") {
      setInputValue("");
      setFormData((prevData) => ({
        ...prevData,
        entityDetails: { ...prevData.entityDetails, businessName: "" },
      }));
    }
  };

  const handleOptionChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedOption2(value);
    setFormData((prevData) => ({
      ...prevData,
      entityDetails: { ...prevData.entityDetails, businessType: value },
    }));
  };

  const handleRegistrationStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setRegistrationStatus(value);
    setFormData((prevData) => ({
      ...prevData,
      entityDetails: { ...prevData.entityDetails, registrationStatus: value },
    }));
  };
  const handleValidateRegistrationNumber = async () => {
    const { valid, message } = await validateBusinessRegistrationNumber(registrationNumber);

    if (!valid) {
      setValidationError(message);
      //setIsNextDisabled(true);
    } else {
      setValidationError(null);
      //setIsNextDisabled(false);
    }
  };

  const handleRegistrationNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setRegistrationNumber(value);
    setFormData((prevData) => ({
      ...prevData,
      entityDetails: { ...prevData.entityDetails, businessRegistrationNumber: value },
    }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
    setFormData((prevData) => ({
      ...prevData,
      entityDetails: { ...prevData.entityDetails, businessName: value },
    }));
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEstablishmentDate(value);
    setFormData((prevData) => ({
      ...prevData,
      entityDetails: { ...prevData.entityDetails, establishmentDate: value },
    }));
  };

  const handleLogoModalOpen = () => setOpenLogoModal(true);
  const handleLogoModalClose = () => setOpenLogoModal(false);

  const handleWebsiteModalOpen = () => setOpenWebsiteModal(true);
  const handleWebsiteModalClose = () => setOpenWebsiteModal(false);

  const handlePlaceSelected = (place: any, lat: number, lng: number) => {
    const addressComponents = place.address_components;

    const getAddressComponent = (type: string) => {
      const component = addressComponents.find((component: any) => component.types.includes(type));
      return component ? component.long_name : "";
    };

    setFormData((prevData) => ({
      ...prevData,
      entityDetails: {
        ...prevData.entityDetails,
        streetNo: getAddressComponent("street_number"),
        streetName: getAddressComponent("route"),
        suburb: getAddressComponent("sublocality_level_1"),
        city: getAddressComponent("locality"),
        zipCode: getAddressComponent("postal_code"),
        country: getAddressComponent("country"),
        latitude: lat,
        longitude: lng,
      },
    }));

    setPosition({ lat, lng });
  };

  return (
    <Grid container spacing={3} px={{ xs: 2, lg: 5 }}>
      <Grid item xs={12}>
        <Typography variant="h5">Business Summary</Typography>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Please provide the following information regarding your business:
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Do you have a business name? <span className="text-red-500">*</span>
        </Typography>
        <RadioGroup row value={selectedOption} onChange={handleOptionChange}>
          <MDBox>
            <FormControlLabel
              sx={{
                display: "flex",
                alignItems: "center",
                "& .MuiFormControlLabel-label": {
                  fontSize: { xs: "12px", sm: "12px", lg: "15px" },
                  color: "#7B809A",
                  fontWeight: "400",
                },
              }}
              value="Yes"
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": { borderRadius: "4px" },
                    "&:after": { borderRadius: "2px" },
                  }}
                />
              }
              label={
                selectedOption === "Yes" ? (
                  <MDInput value={inputValue} onChange={handleInputChange} label="Name" />
                ) : (
                  "Yes"
                )
              }
            />
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: { xs: "12px", sm: "12px", lg: "15px" },
                  color: "#7B809A",
                  fontWeight: "400",
                },
              }}
              value="No"
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": { borderRadius: "4px" },
                    "&:after": { borderRadius: "2px" },
                  }}
                />
              }
              label="No"
            />
          </MDBox>
        </RadioGroup>

        <Typography sx={{ fontSize: "14px", mt: 3 }} variant="body1">
          When was your business established and operational?{" "}
          <span className="text-red-500">*</span>
        </Typography>
        <TextField
          label="Date"
          sx={{ width: "75%" }}
          type="date"
          value={establishmentDate}
          onChange={handleDateChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
          variant="outlined"
          margin="normal"
        />

        <Typography sx={{ fontSize: "14px", mt: 3 }} variant="body1">
          What is the physical address of your business? <span className="text-red-500">*</span>
        </Typography>
        <br />

        <CombinedMapComponent onPlaceSelected={handlePlaceSelected} position={position} />

        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <MDInput
              sx={{ mt: 3 }}
              label="Street No"
              value={formData.entityDetails?.streetNo || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormData((prevData) => ({
                  ...prevData,
                  entityDetails: { ...prevData.entityDetails, streetNo: e.target.value },
                }))
              }
              required
            />
          </Grid>
          <Grid item xs={6} md={9}>
            <MDInput
              sx={{ mt: 3 }}
              label="Street Name"
              value={formData.entityDetails?.streetName || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormData((prevData) => ({
                  ...prevData,
                  entityDetails: { ...prevData.entityDetails, streetName: e.target.value },
                }))
              }
              required
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <MDInput
              label="Suburb"
              value={formData.entityDetails?.suburb || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormData((prevData) => ({
                  ...prevData,
                  entityDetails: { ...prevData.entityDetails, suburb: e.target.value },
                }))
              }
            />
          </Grid>
          <Grid item xs={6} md={9}>
            <MDInput
              label="City"
              value={formData.entityDetails?.city || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormData((prevData) => ({
                  ...prevData,
                  entityDetails: { ...prevData.entityDetails, city: e.target.value },
                }))
              }
              required
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <MDInput
              label="ZIP/Postal Code"
              value={formData.entityDetails?.zipCode || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormData((prevData) => ({
                  ...prevData,
                  entityDetails: { ...prevData.entityDetails, zipCode: e.target.value },
                }))
              }
              required
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <MDInput
              label="Country"
              value={formData.entityDetails?.country || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormData((prevData) => ({
                  ...prevData,
                  entityDetails: { ...prevData.entityDetails, country: e.target.value },
                }))
              }
              required
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={4}>
            <Typography sx={{ fontSize: "14px", fontWeight: "bold" }} variant="body1">
              Business Logo
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <MDButton
              variant="contained"
              sx={{ width: "200px" }}
              color="primary"
              onClick={handleLogoModalOpen}
            >
              Upload Logo <AddIcon />
            </MDButton>
          </Grid>
        </Grid>

        <Grid container alignItems="center" spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={4}>
            <Typography sx={{ fontSize: "14px", fontWeight: "bold" }} variant="body1">
              Business Website Link
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <MDInput
              label="Website link"
              sx={{ width: "200px" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormData((prevData) => ({
                  ...prevData,
                  entityDetails: { ...prevData.entityDetails, businessWebsite: e.target.value },
                }))
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          What type of business do you have? <span className="text-red-500">*</span>
        </Typography>
        <RadioGroup value={selectedOption2} onChange={handleOptionChange2} row={false}>
          {businessTypes.map((type) => (
            <FormControlLabel
              key={type}
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: { xs: "12px", sm: "12px", lg: "15px" },
                  color: "#7B809A",
                  fontWeight: "400",
                },
              }}
              value={type}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": { borderRadius: "4px" },
                    "&:after": { borderRadius: "2px" },
                  }}
                />
              }
              label={type}
            />
          ))}
        </RadioGroup>

        <Typography sx={{ fontSize: "14px", mt: 3 }} variant="body1">
          What is your business registration status? <span className="text-red-500">*</span>
        </Typography>
        <RadioGroup row value={registrationStatus} onChange={handleRegistrationStatusChange}>
          {registrationStatuses.map((status) => (
            <FormControlLabel
              key={status}
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: { xs: "12px", sm: "12px", lg: "15px" },
                  color: "#7B809A",
                  fontWeight: "400",
                },
              }}
              value={status}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": { borderRadius: "4px" },
                    "&:after": { borderRadius: "2px" },
                  }}
                />
              }
              label={status}
            />
          ))}
        </RadioGroup>

        <Typography sx={{ fontSize: "14px", mt: 3 }} variant="body1">
          What is your business registration number?
        </Typography>
        <MDInput
          sx={{ width: "75%" }}
          label="Please Provide"
          value={registrationNumber}
          onChange={handleRegistrationNumberChange}
          onBlur={handleValidateRegistrationNumber}
          /*
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFormData((prevData) => ({
              ...prevData,
              entityDetails: {
                ...prevData.entityDetails,
                businessRegistrationNumber: e.target.value,
              },
            }))
          }
          */
        />
        {validationError && (
          <Typography color="error" variant="body2">
            {validationError}
          </Typography>
        )}

        <Typography sx={{ fontSize: "14px", mt: 3 }} variant="body1">
          What is your business tax reference number? <span className="text-red-500">*</span>
        </Typography>
        <MDInput
          label="Please Provide"
          sx={{ width: "75%" }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFormData((prevData) => ({
              ...prevData,
              entityDetails: { ...prevData.entityDetails, taxReferenceNumber: e.target.value },
            }))
          }
        />
      </Grid>

      <Modal
        open={openLogoModal}
        onClose={handleLogoModalClose}
        aria-labelledby="upload-logo-modal"
        aria-describedby="upload-logo-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: { xs: 320, lg: 500 } }}>
          <UploadComponent
            onClose={handleLogoModalClose}
            btndata={setOpenLogoModal}
            purpose={"business_logo"}
            //sx={{ width: { xs: 320, lg: 500 } }}
          />
        </Box>
      </Modal>

      <Modal
        open={openWebsiteModal}
        onClose={handleWebsiteModalClose}
        aria-labelledby="upload-website-modal"
        aria-describedby="upload-website-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: { xs: 320, lg: 500 } }}>
          <UploadComponent
            onClose={handleWebsiteModalClose}
            btndata={setOpenWebsiteModal}
            purpose={"business_logo"}
            //sx={{ width: { xs: 320, lg: 500 } }}
          />
        </Box>
      </Modal>
    </Grid>
  );
};

export default BusinessDetails;
