import React from "react";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import logo_placeholder from "assets/logo_placeholder.png";

// Declaring props types for BusinessCard
interface Props {
  image: string;
  entityName: string;
  oneLiner: string;
  onInviteClick: () => void; // Passed from the parent
  onAboutClick: () => void; // Passed from the parent
}

const BusinessCard: React.FC<Props> = ({
  image,
  entityName,
  oneLiner,
  onInviteClick,
  onAboutClick,
}) => {
  return (
    <Card
      sx={{
        "&:hover .card-header": {
          transform: "translate3d(0, -50px, 0)", // Only the image moves up on hover
        },
      }}
    >
      {/* Card Header with Image */}
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={-3}
        mx={2}
        className="card-header"
        sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
      >
        <MDBox
          component="img"
          src={image}
          alt={entityName}
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="relative"
          zIndex={1}
        />
        <MDBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top="0"
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MDBox>

      {/* Action Buttons (invite and about) - Placed under the image */}
      <MDBox display="flex" justifyContent="center" alignItems="center" mt={-4}>
        <Icon sx={{ cursor: "pointer", mx: 1, color: "success.main" }} onClick={onInviteClick}>
          person_add
        </Icon>
        <Icon sx={{ cursor: "pointer", mx: 1, color: "info.main" }} onClick={onAboutClick}>
          info
        </Icon>
      </MDBox>

      {/* Entity Name and One-liner */}
      <MDBox textAlign="center" pt={1} px={3}>
        <MDTypography variant="h5" fontWeight="regular" sx={{ mt: 1.5 }}>
          {entityName}
        </MDTypography>
        <MDTypography variant="body2" color="text" sx={{ mt: 1.5, mb: 1 }}>
          {oneLiner}
        </MDTypography>
      </MDBox>
    </Card>
  );
};

export default BusinessCard;
