import {
  BankSummaryApiResponse,
  BankData,
} from "../layouts/pages/business/business-profile/financial-overview/types";
interface BankSummaryDataItem {
  account: string;
  openingBalance: number;
  cashReceived: number;
  cashSpent: number;
  closingBalance: number;
  isHeader?: boolean;
}

type BankSummaryData = BankSummaryDataItem[];

export const transformBankSummaryApiResponse = (apiData: any): BankSummaryData => {
  const report = apiData?.Reports?.[0];
  const rows = report?.Rows?.[1]?.Rows || [];

  const transformedData: BankSummaryData = rows.map((row: any) => {
    const [account, openingBalance, cashReceived, cashSpent, closingBalance] = row.Cells;

    return {
      account: account?.Value || "",
      openingBalance: parseFloat(openingBalance?.Value || "0"),
      cashReceived: parseFloat(cashReceived?.Value || "0"),
      cashSpent: parseFloat(cashSpent?.Value || "0"),
      closingBalance: parseFloat(closingBalance?.Value || "0"),
      isHeader: row.RowType === "SummaryRow",
    };
  });

  return transformedData;
};

/*
export function transformBankSummaryApiResponse(apiResponse: BankSummaryApiResponse): BankData[] {
  const bankData: BankData[] = [];

  const report = apiResponse.Reports.find((r) => r.ReportID === "BankSummary");
  if (!report) {
    return bankData;
  }

  for (const section of report.Rows) {
    if (section.RowType === "Section") {
      for (const row of section.Rows) {
        if (row.RowType === "Row") {
          // Extract account and the available balance (which is like 'cashReceived')
          const [account, , cashReceived] = row.Cells.map((cell) => cell.Value);

          bankData.push({
            account: account || section.Title,
            openingBalance: 0, // No data in the mock response for this
            cashReceived: cashReceived ? parseFloat(cashReceived) : 0,
            cashSpent: 0, // No data in the mock response for this
            closingBalance: cashReceived ? parseFloat(cashReceived) : 0, // Assume closing balance is same as cashReceived
            isHeader: false,
          });
        } else if (row.RowType === "SummaryRow") {
          const [account, cashReceived] = row.Cells.map((cell) => cell.Value);

          bankData.push({
            account: account || section.Title,
            openingBalance: 0, // No opening balance for summary
            cashReceived: cashReceived ? parseFloat(cashReceived) : 0,
            cashSpent: 0, // No cash spent for summary
            closingBalance: cashReceived ? parseFloat(cashReceived) : 0,
            isHeader: true,
          });
        }
      }
    }
  }

  return bankData;
}
*/
