import React from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Custom components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";

// Declaring prop types for FinancialKpiCard
interface FinancialKpiCardProps {
  abbreviation: string; // e.g., "ROI"
  title: string; // e.g., "Return on Investment"
  value: string | number; // e.g., "5%"
  change: string; // e.g., "+1.2%"
  changeColor?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
  bgColor?: "white" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
  currencySymbol?: string;
}

const FinancialKpiCard: React.FC<FinancialKpiCardProps> = ({
  abbreviation,
  title,
  value,
  change,
  changeColor = "success",
  bgColor = "white",
  currencySymbol,
}) => {
  return (
    <Card sx={{ overflow: "hidden" }}>
      <MDBox bgColor={bgColor} variant="gradient">
        <MDBox p={2}>
          <Grid container alignItems="center">
            {/* Icon Container with Abbreviation */}
            <Grid item xs={4}>
              <MDBox
                variant="gradient"
                bgColor={bgColor === "white" ? "primary" : "white"}
                color={bgColor === "white" ? "dark" : "dark"}
                width="4rem"
                height="4rem"
                borderRadius="md"
                display="flex"
                justifyContent="center"
                alignItems="center"
                shadow="md"
              >
                <MDTypography variant="h5" color="white" fontWeight="bold">
                  {abbreviation}
                </MDTypography>
              </MDBox>
            </Grid>

            {/* Title, Value, and Change */}
            <Grid item xs={8}>
              <MDBox ml={2} lineHeight={1}>
                <MDTypography
                  variant="button"
                  color={bgColor === "white" ? "text" : "white"}
                  opacity={bgColor === "white" ? 1 : 0.7}
                  textTransform="capitalize"
                  fontWeight="medium"
                >
                  {title}
                </MDTypography>
                <MDTypography
                  variant="h5"
                  fontWeight="bold"
                  color={bgColor === "white" ? "dark" : "white"}
                >
                  {abbreviation === "STC" ? `${currencySymbol} ${value}` : value}
                </MDTypography>
                <MDTypography variant="caption" color={changeColor}>
                  {change}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default FinancialKpiCard;
