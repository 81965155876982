import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserPool,
  CognitoRefreshToken,
  CognitoUserSession,
  CognitoUserAttribute,
  ISignUpResult,
} from "amazon-cognito-identity-js";
import axios from "axios"; //refactor to use api config
import UserPool from "./cognito";
import { login, signup, confirmNewTeamMember } from "./ventureApi";
import getConfig from "./config";
import { LoginResponse, RegistrationData } from "../types/authTypes";

export const transformRegistrationData = (registrationData: RegistrationData): any => {
  const { userInfo, profile, services } = registrationData;

  const userCreateData = {
    first_name: userInfo.firstName,
    last_name: userInfo.lastName,
    email: userInfo.email,
    country_code: userInfo.countryCallCode,
    primary_phone: userInfo.primaryPhoneNumber,
    location: userInfo.userLocation,
    additional_info: {
      profile,
      services,
    },
  };

  return userCreateData;
};

export const storeTokens = (session: CognitoUserSession) => {
  if (session) {
    const accessToken = session.getAccessToken().getJwtToken();
    const idToken = session.getIdToken().getJwtToken();
    const expirationTime = session.getIdToken().getExpiration() * 1000; // Convert to milliseconds

    console.log("Storing tokens:", { accessToken, idToken });

    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("idToken", idToken);
    localStorage.setItem("tokenExpiration", expirationTime.toString());
  } else {
    console.error("No session provided to storeTokens");
  }
};

// Function to generate a unique device identifier
function generateDeviceIdentifier() {
  return `device-${Math.random().toString(36).substr(2, 9)}`;
}

export const loginUser = (
  email: string,
  password: string,
  initialSignUp: boolean,
  rememberMe: boolean,
  entity_id: number | null = null,
  role_id: number | null = null
): Promise<LoginResponse> => {
  const user = new CognitoUser({ Username: email, Pool: UserPool });
  const authDetails = new AuthenticationDetails({ Username: email, Password: password });

  return new Promise((resolve, reject) => {
    user.authenticateUser(authDetails, {
      onSuccess: async (session) => {
        try {
          storeTokens(session);

          //const loginData = await login({ entity_id, role_id });

          // Handle initial sign-up if needed
          if (initialSignUp) {
            const registrationData = JSON.parse(localStorage.getItem("registrationData") || "{}");
            if (registrationData) {
              await signup(transformRegistrationData(registrationData));
              localStorage.removeItem("registrationData");
            }
          }
          let loginData;
          await login({ entity_id, role_id }).then((loginData) => {
            console.log("Authentication successful:", loginData);
            localStorage.setItem("loginData", JSON.stringify(loginData));
            loginData = loginData;
            resolve(loginData);
          });
          /* test + implement still
          // Set the refresh token as an HttpOnly cookie
        document.cookie = `refreshToken=${session
          .getRefreshToken()
          .getToken()}; HttpOnly; Secure; SameSite=Strict`;
          */

          // Optionally store additional data or handle rememberMe here
          if (rememberMe) {
            const deviceIdentifier = generateDeviceIdentifier();
            localStorage.setItem("trustedDevice", deviceIdentifier);
            localStorage.setItem("trustedDeviceToken", session.getRefreshToken().getToken());
          }

          resolve(loginData);
        } catch (err) {
          reject(err);
        }
      },
      onFailure: (err) => {
        reject(err);
      },
      newPasswordRequired: (data) => {
        resolve(data as unknown as LoginResponse);
      },
    });
  });
};
/* original
export const loginUser = (
  email: string,
  password: string,
  initialSignUp: boolean,
  rememberMe: boolean,
  entity_id: number | null = null,
  role_id: number | null = null
): Promise<LoginResponse> => {
  const user = new CognitoUser({ Username: email, Pool: UserPool });
  const authDetails = new AuthenticationDetails({ Username: email, Password: password });

  return new Promise((resolve, reject) => {
    user.authenticateUser(authDetails, {
      onSuccess: async (session) => {
        console.log("Authentication successful:", session);
        storeTokens(session);

        const deviceIdentifier = generateDeviceIdentifier();

        // Set the refresh token as an HttpOnly cookie
        document.cookie = `refreshToken=${session
          .getRefreshToken()
          .getToken()}; HttpOnly; Secure; SameSite=Strict`;

        // Store device identifier and token in local storage if it is a trusted device
        if (rememberMe) {
          localStorage.setItem("trustedDevice", deviceIdentifier);
          localStorage.setItem("trustedDeviceToken", session.getRefreshToken().getToken());

          // Update the autoLogin value in the backend
          try {
            await login({ entity_id, role_id });
          } catch (error) {
            console.error("Error updating autoLogin:", error);
          }
        }

        try {
          if (initialSignUp) {
            const registrationData = JSON.parse(localStorage.getItem("registrationData") || "{}");
            if (registrationData) {
              await signup(transformRegistrationData(registrationData));
              localStorage.removeItem("registrationData");
            }
          }

          const loginData = await login({ entity_id, role_id });
          console.log("Authentication successful:", loginData);
          localStorage.setItem("loginData", JSON.stringify(loginData));
          resolve(loginData);
        } catch (err) {
          reject(err);
        }
      },
      onFailure: (err) => {
        console.error("Authentication failed:", err);
        reject(err);
      },
      newPasswordRequired: (data) => {
        resolve(data);
      },
    });
  });
};
*/

// Function to handle auto-login
export const autoLogin = async () => {
  const trustedDevice = localStorage.getItem("trustedDevice");
  const trustedDeviceToken = localStorage.getItem("trustedDeviceToken");

  if (trustedDevice && trustedDeviceToken) {
    const cognitoUser = new CognitoUser({ Username: trustedDevice, Pool: UserPool });
    const refreshToken = new CognitoRefreshToken({ RefreshToken: trustedDeviceToken });

    cognitoUser.refreshSession(refreshToken, async (err, session) => {
      if (err) {
        console.error("Error refreshing session:", err);
        return;
      }

      // Store new tokens in localStorage
      const newIdToken = session.getIdToken().getJwtToken();
      const newAccessToken = session.getAccessToken().getJwtToken();
      const newRefreshToken = session.getRefreshToken().getToken();

      localStorage.setItem("accessToken", newAccessToken);
      localStorage.setItem("idToken", newIdToken);
      localStorage.setItem(
        "tokenExpiration",
        (session.getIdToken().getExpiration() * 1000).toString()
      );

      // Store new refresh token if it is issued
      if (newRefreshToken) {
        localStorage.setItem("trustedDeviceToken", newRefreshToken);
      }

      try {
        const response = await axios.get("/auto-navigate", {
          headers: { Authorization: `Bearer ${newIdToken}` },
        });
        const { user_profile, entity, landing_route, state } = response.data;

        // Redirect to the landing route obtained from the API
        if (entity) {
          window.location.href = landing_route;
        } else {
          window.location.href = landing_route;
        }
      } catch (error) {
        console.error("Error during auto-navigate:", error);
      }
    });
  }
};

export const registerUser = async (
  formData: RegistrationData,
  referenceKey?: string
): Promise<ISignUpResult> => {
  console.log("FormData, signup", formData);

  const {
    email,
    password,
    primaryPhoneNumber,
    firstName,
    lastName,
    countryCallCode,
    userLocation,
  } = formData.userInfo;

  // Ensure countryCallCode includes a "+"
  let formattedCountryCode = countryCallCode.startsWith("+")
    ? countryCallCode
    : `+${countryCallCode}`;

  // Remove any non-numeric characters from the phone number
  let formattedPhoneNumber = primaryPhoneNumber ? primaryPhoneNumber.replace(/\D/g, "") : "";

  // Concatenate the country code and phone number
  if (formattedPhoneNumber.startsWith("0")) {
    formattedPhoneNumber = `${formattedCountryCode}${formattedPhoneNumber.slice(1)}`;
  } else {
    formattedPhoneNumber = `${formattedCountryCode}${formattedPhoneNumber}`;
  }

  const attributeList: CognitoUserAttribute[] = [
    new CognitoUserAttribute({ Name: "email", Value: email }),
    new CognitoUserAttribute({ Name: "phone_number", Value: formattedPhoneNumber }),
    new CognitoUserAttribute({ Name: "custom:domain", Value: getConfig.domainName }),
  ];
  console.log("cognito attirbute list, attributeList: ", attributeList);
  return new Promise<ISignUpResult>((resolve, reject) => {
    console.log("in SignUpResult promise");
    UserPool.signUp(email, password, attributeList, null, async (err, result) => {
      if (err) {
        reject(err);
        return;
      }

      const user = result?.user; // CognitoUser type
      formData.userInfo.referenceKey = referenceKey || "";
      localStorage.setItem("registrationData", JSON.stringify(formData));

      //await confirmNewTeamMember(referenceKey);
      resolve(result!); // Non-null assertion because result is checked before usage
    });
  });
};

export const sendEmailVerification = (email: string): Promise<string> => {
  const userData = {
    Username: email,
    Pool: UserPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
};

// Function to verify email during registration
export const verifyEmail = (email: string, code: string): Promise<string> => {
  console.log("verifyEmail called with email:", email, "and code:", code);

  const userData = {
    Username: email,
    Pool: UserPool,
  };
  console.log("user data:", userData);
  const cognitoUser = new CognitoUser(userData);
  console.log("cognitoUser", cognitoUser);

  return new Promise(async (resolve, reject) => {
    cognitoUser.confirmRegistration(code, true, async (err, result) => {
      if (err) {
        console.error("Error during email verification:", err);
        reject(err);
        return;
      }
      console.log("Email verification successful. Result:", result);

      try {
        const registrationData: RegistrationData | null = JSON.parse(
          localStorage.getItem("registrationData") || "null"
        );
        console.log("Registration data retrieved from localStorage:", registrationData);

        if (!registrationData || !registrationData.userInfo) {
          console.log("cognitoUser", cognitoUser);
          throw new Error("Registration data or user info is missing in localStorage");
        }
        const { password, ...userInfoWithoutPassword } = registrationData.userInfo;
        await loginUser(
          registrationData.userInfo.email,
          registrationData.userInfo.password,
          true,
          false
        );
        const passwordRemovedFormData: RegistrationData = {
          ...registrationData,
          userInfo: userInfoWithoutPassword,
        };
        /*
        const passwordRemovedFormData = {
          ...registrationData,
          userInfo: { ...registrationData.userInfo, password: undefined },
        };
        */
        localStorage.setItem("registrationData", JSON.stringify(passwordRemovedFormData));
        resolve(result);
      } catch (signupError) {
        console.error("Error during user login:", signupError);
        reject(signupError);
      }
    });
  });
};

export const verifyEmailNewTeamMember = (
  email: string,
  code: string,
  referenceKey: string
): Promise<string> => {
  console.log(
    "verifyEmailNewTeamMember called with email:",
    email,
    "code:",
    code,
    "and referenceKey:",
    referenceKey
  );

  const userData = {
    Username: email,
    Pool: UserPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise(async (resolve, reject) => {
    cognitoUser.confirmRegistration(code, true, async (err, result) => {
      if (err) {
        console.error("Error during email verification:", err);
        reject(err);
        return;
      }
      console.log("Email verification successful. Result:", result);

      try {
        const registrationData: RegistrationData | null = JSON.parse(
          localStorage.getItem("registrationData") || "null"
        );
        console.log("Registration data retrieved from localStorage:", registrationData);

        if (!registrationData || !registrationData.userInfo) {
          throw new Error("Registration data or user info is missing in localStorage");
        }

        const { password, ...userInfoWithoutPassword } = registrationData.userInfo;

        await loginUser(
          registrationData.userInfo.email,
          registrationData.userInfo.password,
          true,
          false
        );
        const passwordRemovedFormData: RegistrationData = {
          ...registrationData,
          userInfo: userInfoWithoutPassword,
        };
        localStorage.setItem("registrationData", JSON.stringify(passwordRemovedFormData));
        await confirmNewTeamMember(referenceKey);
        resolve(result);
      } catch (signupError) {
        console.error("Error during user login:", signupError);
        reject(signupError);
      }
    });
  });
};

export const initiateForgotPassword = (email: string): Promise<void> => {
  const userData = {
    Username: email,
    Pool: UserPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        resolve(data);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const confirmForgotPassword = (
  email: string,
  code: string,
  newPassword: string
): Promise<void> => {
  const userData = {
    Username: email,
    Pool: UserPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess: () => {
        resolve();
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const changePassword = (
  email: string,
  oldPassword: string,
  newPassword: string
): Promise<void> => {
  const userData = {
    Username: email,
    Pool: UserPool,
  };

  const cognitoUser = new CognitoUser(userData);
  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: oldPassword,
  });

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: () => {
        cognitoUser.changePassword(oldPassword, newPassword, (err) => {
          if (err) {
            reject(err);
            return;
          }
          resolve();
        });
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};
