import React, { createContext, useContext, useState, ReactNode } from "react";

interface SkipStepContextProps {
  skipStep1: boolean;
  handleSkipStep: (skip: boolean) => void;
}

const SkipStepContext = createContext<SkipStepContextProps | undefined>(undefined);

export const SkipStepProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [skipStep1, setSkipStep1] = useState<boolean>(false);

  const handleSkipStep = (skip: boolean) => {
    setSkipStep1(skip);
  };

  return (
    <SkipStepContext.Provider value={{ skipStep1, handleSkipStep }}>
      {children}
    </SkipStepContext.Provider>
  );
};

export const useSkipStep = (): SkipStepContextProps => {
  const context = useContext(SkipStepContext);
  if (!context) {
    throw new Error("useSkipStep must be used within a SkipStepProvider");
  }
  return context;
};
