import React, { useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox"; // Adjusted MDBox path to generic for consistency
import MDTypography from "components/generic/MDTypography";
//import loader from "assets/images/startup-profile/VBS Wireframing-local.webp";
import loader from "assets/images/startup-profile/VBS Wireframing-local.webp";
import ComingSoonIcon from "@mui/icons-material/AccessTime";
const ComingSoonCard: React.FC = () => {
  const [visibility, setVisibility] = useState(true);

  const handleVisibilityToggle = () => {
    setVisibility(!visibility);
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      {" "}
      {/* Removed elevation */}
      <MDBox py={5} display="flex" flexDirection="column" alignItems="center" gap={2}>
        <ComingSoonIcon sx={{ fontSize: "50px", width: "50px", height: "50px", color: "gray" }} />
        <MDTypography variant="h4" color="dark">
          Coming Soon
        </MDTypography>
      </MDBox>
    </Card>
  );
};

export default ComingSoonCard;
