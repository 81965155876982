export interface NeedsOptions {
  [key: string]: string[];
}

export const needsOptions: NeedsOptions = {
  "Strategic Planning": [
    "Proof of Concept",
    "Market / Competitor Landscape Analysis",
    "Product-Market Fit",
    "Business Model Optimization",
    "Strategic Roadmapping",
  ],
  "Marketing & Strategy": [
    "Brand Engineering and Design",
    "Marketing Strategies and Funnels",
    "Marketing and Sales Collateral",
    "Social Media Marketing",
    "Analytics and Engagement",
  ],
  "Customer Support & Success": [
    "Customer Support",
    "Feedback and Engagement",
    "Customer Success",
    "Customer Acquisition Strategies",
    "Customer Retention",
  ],
  "Product Development": [
    "MVP or Prototyping",
    "Product Design and Development",
    "Product Validation",
    "Use Cases / User Journeys",
    "User Feedback Integration",
  ],
  "Sales & Acquisition": [
    "Sales Strategies",
    "Distribution Solutions",
    "Lead Generation",
    "Customer Relationship Management",
    "Reseller Programs",
  ],
  "Networking / Partnerships": [
    "Networking, Events and Expos",
    "Collaborations and Cocreation",
    "Partnership Development",
    "Outsourcing",
    "Supplier Connections",
  ],
  "Technical Development": [
    "Technology Infrastructure Selection",
    "Software Development",
    "Database and Solution Architecture",
    "UI/UX and Front End Development",
    "App Development",
  ],
  "HR & Talent Managing": [
    "Talent Recruitment and Management",
    "Policy Development",
    "Team Building and Company Culture",
    "Capacity Development and Training",
    "Employee Engagement and Satisfaction",
  ],
  Legal: [
    "Legal Advice",
    "Contracting",
    "Compliance Related",
    "Intellectual Property",
    "Incorporation and Business Formation",
  ],
  "Operational Support": [
    "Project Management",
    "Supply Chain Management",
    "Quality Assurance or Operational Excellence",
    "Work Culture and Employee Management",
    "Operational Support",
  ],
  "Scaling & Growing": [
    "Scalability Optimization",
    "Expansion Strategies and Market Identification",
    "Internationalization",
    "Growth Strategies and Acceleration",
    "Automations",
  ],
  "Data Analysis": [
    "Data Collection and Analytics",
    "Tracking Dashboards",
    "Data Management",
    "Business Intelligence",
    "Data Quality and Governance",
  ],
  Financial: [
    "Budgeting and Forecasting",
    "Financial Modeling",
    "Price and Cost Management",
    "Accounting Services",
    "Fundraising",
  ],
  "Data & Security": [
    "Data Security and Access",
    "Penetration Testing and Vulnerability Scanning",
    "Privacy and Data Security Policies and Standards",
    "Cybersecurity",
    "Data Recovery, Backups, and Failovers",
  ],
  Other: [],
};
