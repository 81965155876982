import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Formik, Form, FormikHelpers, FormikProps } from "formik"; //added
import { Modal, Slide } from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDBox from "components/generic/MDBox";
import MDButton from "components/generic/MDButton";
import MDTypography from "components/generic/MDTypography";
import SignupLayout from "layouts/authentication/components/SignupLayout";
import bgImage from "assets/images/reisiger/profileBgImage.png";
import Footer from "components/refined/Footer";
import { registerUser } from "services/auth"; //need to test still
import signupValidations from "./schemas/validations";
import signupForm from "./schemas/signupForm";
import SignupInitialValues from "./schemas/signupInitialValues";
import SignupUserInfo from "./components/SignupUserInfo";
import SignupProfile from "./components/SignupProfile";
import SignupService from "./components/SignupService";
import Feedback from "./components/Feedback"; //move to everywhere

type SignupFormValues = typeof SignupInitialValues;

function getSteps(referenceKey: string | undefined): string[] {
  if (referenceKey) {
    console.log("referenceKey", referenceKey);
    return ["User Info"];
  }
  return ["User Info", "Profile", "Service"];
}

function getStepContent(
  stepIndex: number,
  formData: any,
  referenceKey: string | undefined
): JSX.Element | null {
  if (referenceKey) {
    return <SignupUserInfo formData={formData} />;
  }
  switch (stepIndex) {
    case 0:
      return <SignupUserInfo formData={formData} />;
    case 1:
      return <SignupProfile formData={formData} />;
    case 2:
      return <SignupService formData={formData} />;
    default:
      return null;
  }
}

const SignUp: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [initialValues, setInitialValues] = useState<SignupFormValues>(SignupInitialValues);
  //const [openFeedback, setOpenFeedback] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const updatedInitialValues = { ...SignupInitialValues };
    console.log("URL Search Params:", searchParams);
    console.log("updatedInitialValues", updatedInitialValues);
    searchParams.forEach((value, key) => {
      updatedInitialValues[key as keyof SignupFormValues] = decodeURIComponent(value);
    });
    console.log("updatedInitialValues2", updatedInitialValues);
    setInitialValues(updatedInitialValues);
    /*if country code does not persist
    setInitialValues((prevValues) => ({
      ...prevValues,
      countryCode: prevValues.countryCode || updatedInitialValues.countryCode,
    }));
    setInitialValues(updatedInitialValues);
    */
  }, [searchParams]);

  //const referenceKey = initialValues.referenceKey;
  const referenceKey = Array.isArray(initialValues.referenceKey)
    ? initialValues.referenceKey[0]
    : initialValues.referenceKey;

  const steps = getSteps(referenceKey);
  console.log("steps", steps);
  const { formId, formField } = signupForm;
  const currentValidation = signupValidations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const handleBack = () => setActiveStep(activeStep - 1);

  const handleNext = (values: SignupFormValues, actions: FormikHelpers<SignupFormValues>) => {
    if (isLastStep || referenceKey) {
      handleSubmit(values, actions, referenceKey);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };
  //note useParams returns an string[] if key repeats - ensure no arrays are returned in testing... should be single string values
  const handleSubmit = async (
    values: SignupFormValues,
    actions: FormikHelpers<SignupFormValues>,
    referenceKey: string | null = null
  ) => {
    const userData = {
      userInfo: {
        email: Array.isArray(values.email) ? values.email[0] : values.email,
        password: Array.isArray(values.password) ? values.password[0] : values.password,
        primaryPhoneNumber: Array.isArray(values.phone) ? values.phone[0] : values.phone,
        firstName: Array.isArray(values.firstName) ? values.firstName[0] : values.firstName,
        lastName: Array.isArray(values.lastName) ? values.lastName[0] : values.lastName,
        userLocation: Array.isArray(values.location) ? values.location[0] : values.location,
        countryCallCode: Array.isArray(values.countryCode)
          ? values.countryCode[0]
          : values.countryCode,
      },
      profile: {
        teamRole: Array.isArray(values.role) ? values.role[0] : values.role,
        entityType: Array.isArray(values.joiningAs) ? values.joiningAs[0] : values.joiningAs,
        interestedConnections: Array.isArray(values.seekingToConnect)
          ? values.seekingToConnect[0]
          : values.seekingToConnect,
      },
      services: {
        supportNeedsServicesToSupply: Array.isArray(values.servicesToSupply)
          ? values.servicesToSupply
          : [values.servicesToSupply],
        supportNeedsServicesInterested: Array.isArray(values.servicesInterestedIn)
          ? values.servicesInterestedIn
          : [values.servicesInterestedIn],
      },
    };

    try {
      console.log("pre registerUser");
      await registerUser(userData, referenceKey);
      console.log("post registerUser");
      actions.setSubmitting(false);
      actions.resetForm();
      setActiveStep(0);

      navigate(
        `/authentication/sign-up/user-email-confirmation${
          referenceKey ? `?referenceKey=${referenceKey}` : ""
        }`
      );
      //navigate("/authentication/sign-up/user-email-confirmation");
    } catch (error: any) {
      alert("Sign-up failed: " + error.message);
      //redirect to:
      navigate("/authentication/sign-in");
      actions.setSubmitting(false);
    }
  };

  return (
    <SignupLayout image={bgImage}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
          my: {
            xs: 2,
            sm: 2,
            lg: 8,
            xl: 8,
          },
        }}
      >
        <Grid
          item
          sx={{
            minWidth: {
              lg: 900,
              xl: 900,
            },
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleNext}
            enableReinitialize={true}
            //enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, isValid, setFieldValue }) => (
              <Form id={formId} autoComplete="off">
                <Card sx={{ height: "100%", mb: 2 }}>
                  <MDBox mx={2} mt={-3}>
                    <Stepper
                      activeStep={activeStep}
                      alternativeLabel
                      sx={{
                        overflowX: "auto",
                        backgroundColor: "#324E73!important",
                        backgroundImage: "none !important",
                        boxShadow: "none",
                      }}
                    >
                      {steps.map((label) => (
                        <Step
                          key={label}
                          sx={{
                            ".Mui-disabled": {
                              color: "#FFFFFF",
                              opacity: 0.75,
                            },
                          }}
                        >
                          <StepLabel
                            sx={{
                              "& .Mui-disabled ": {
                                color: "#FFFFFF",
                              },
                              ".MuiStepIcon-root ": {
                                fill: "#FFFFFF",
                                stroke: "#FFFFFF",
                                background: "#FFFFFF",
                              },
                            }}
                          >
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </MDBox>
                  <MDBox p={3}>
                    <MDBox>
                      {getStepContent(
                        activeStep,
                        { values, touched, formField, errors, setFieldValue },
                        referenceKey
                      )}
                      <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton variant="gradient" color="light" onClick={handleBack}>
                            Back
                          </MDButton>
                        )}
                        <MDButton
                          disabled={isSubmitting || !isValid}
                          type="submit"
                          variant="gradient"
                          color="dark"
                        >
                          {isLastStep || referenceKey ? "send" : "next"}
                        </MDButton>
                      </MDBox>
                      {touched.countryCode && errors.countryCode && (
                        <MDBox mt={2} textAlign="center">
                          <MDTypography variant="button" color="error">
                            {errors.countryCode}
                          </MDTypography>
                        </MDBox>
                      )}
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
          {/*<Footer />*/}
        </Grid>
      </Grid>
      {/*
      <MDButton
        onClick={() => setOpenFeedback(true)}
        variant="contained"
        color="primary" //was feedback - add feedback color later
        sx={{
          position: "fixed",
          top: "70%",
          right: "-30px",
          zIndex: 1000,
          transform: "rotate(-90deg)",
          transformOrigin: "center",
          fontSize: "14px",
          px: 2,
          pb: 0,
          pt: 0,
          borderRadius: "8px",
          width: "92px",
          minHeight: "26px",
          maxHeight: "26px",
        }}
      >
        Feedback
      </MDButton>
      <Modal
        open={openFeedback}
        onClose={() => setOpenFeedback(false)}
        aria-labelledby="feedback-modal-title"
        aria-describedby="feedback-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Slide
          direction="left"
          in={openFeedback}
          mountOnEnter
          unmountOnExit
          style={{ transition: "transform 2s ease-in-out" }}
        >
          <div>
            <Feedback />
          </div>
        </Slide>
      </Modal>
      */}
      <MDButton
        onClick={() => navigate("/authentication/sign-in")} // Navigate to sign-in page
        variant="contained"
        color="primary"
        sx={{
          position: "fixed",
          bottom: 10,
          left: 10,
          zIndex: 1000,
        }}
      >
        To Sign In
      </MDButton>
    </SignupLayout>
  );
};

export default SignUp;
