import React, { useState, useEffect } from "react";
//import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import MDBox from "components/generic/MDBox";
import MDAvatar from "components/generic//MDAvatar";
import MDTypography from "components/generic//MDTypography";
import breakpoints from "assets/theme/base/breakpoints";
import DashboardNavbar from "components/refined//Navbars/DashboardNavbar";
import ProfilePic from "assets/images/startup-profile/startup-profile-pic.png";

interface HeaderSettingsProps {
  children: React.ReactNode;
  backgroundImage?: string;
}

const HeaderSettings: React.FC<HeaderSettingsProps> = ({
  children,
  backgroundImage = "assets/images/reisiger/bg-profile.png",
}) => {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const handleTabsOrientation = () => {
      if (window.innerWidth < breakpoints.values.sm) {
        setTabsOrientation("vertical");
      } else {
        setTabsOrientation("horizontal");
      }
    };

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation(); // Call on initial render

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, []);

  const handleSetTabValue = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <MDBox position="relative" mt={2}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="12rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      >
        <DashboardNavbar />
      </MDBox>
      <MDBox sx={{ position: "relative", mt: -8, mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} alignItems="center">
          {children}
        </Grid>
      </MDBox>
    </MDBox>
  );
};
/*
HeaderSettings.propTypes = {
  children: PropTypes.node,
  backgroundImage: PropTypes.string,
};
*/
export default HeaderSettings;
