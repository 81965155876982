import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import SignupFormField from "./SignupFormField";
import { Badge, LocationOn } from "@mui/icons-material";
import { CountrySelect } from "./CountrySelect";
import { Box } from "@mui/material";

// Define the types for the form field
interface FormField {
  name: string;
  label: string;
  type: string;
  placeholder: string;
}

// Define the type for formData prop
interface FormData {
  formField: {
    firstName: FormField;
    lastName: FormField;
    phone: FormField;
    email: FormField;
    location: FormField;
    password: FormField;
    repeatPassword: FormField;
    countryCode: FormField;
  };
  values: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    location: string;
    password: string;
    repeatPassword: string;
    countryCode: string;
  };
  errors: {
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
    location?: string;
    password?: string;
    repeatPassword?: string;
  };
  touched: {
    firstName?: boolean;
    lastName?: boolean;
    phone?: boolean;
    email?: boolean;
    location?: boolean;
    password?: boolean;
    repeatPassword?: boolean;
  };
  setFieldValue: (field: string, value: any) => void;
}

// Define the prop types for SignupUserInfo component
interface SignupUserInfoProps {
  formData: FormData;
  onCountryChange?: (code: string) => void;
}

const SignupUserInfo: React.FC<SignupUserInfoProps> = ({ formData, onCountryChange }) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { firstName, lastName, phone, email, location, password, repeatPassword, countryCode } =
    formField;
  const {
    firstName: firstNameV,
    lastName: lastNameV,
    phone: phoneV,
    email: emailV,
    location: locationV,
    password: passwordV,
    repeatPassword: repeatPasswordV,
    countryCode: countryCodeV,
  } = values;

  useEffect(() => {
    console.log("SignupUserInfo received formData:", formData);
    console.log("Values in SignupUserInfo:", values);
  }, [formData, values]);

  const handleCountryChange = (code: string) => {
    setFieldValue(countryCode.name, code);
    if (onCountryChange) onCountryChange(code);
  };

  const renderField = (
    field: FormField,
    value: string,
    error?: string,
    touched?: boolean,
    icon?: JSX.Element
  ) => (
    <SignupFormField
      type={field.type}
      label={field.label}
      name={field.name}
      value={value}
      placeholder={field.placeholder}
      error={!!error && touched}
      logo={icon}
      inputProps={{ autoComplete: "" }}
      passwordchange={field.name.includes("password")}
    />
  );

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">About me</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid
          container
          spacing={{
            xs: 1,
            sm: 1,
            lg: 3,
            xl: 3,
          }}
        >
          <Grid item xs={12} sm={6}>
            {renderField(firstName, firstNameV, errors.firstName, touched.firstName, <Badge />)}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderField(lastName, lastNameV, errors.lastName, touched.lastName)}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={{
            xs: 1,
            sm: 1,
            lg: 3,
            xl: 3,
          }}
        >
          <Grid item xs={12} sm={6}>
            <Box display={"flex"} gap={1}>
              <CountrySelect onCountryChange={handleCountryChange} value={countryCodeV} />
              <Box sx={{ width: "100%" }}>
                {renderField(phone, phoneV, errors.phone, touched.phone)}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderField(email, emailV, errors.email, touched.email)}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={{
            xs: 1,
            sm: 1,
            lg: 3,
            xl: 3,
          }}
        >
          <Grid item xs={12} sm={6}>
            {renderField(location, locationV, errors.location, touched.location, <LocationOn />)}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={{
            xs: 1,
            sm: 1,
            lg: 3,
            xl: 3,
          }}
        >
          <Grid item xs={12} sm={6}>
            {renderField(password, passwordV, errors.password, touched.password)}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderField(
              repeatPassword,
              repeatPasswordV,
              errors.repeatPassword,
              touched.repeatPassword
            )}
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default SignupUserInfo;
