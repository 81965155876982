import React, { useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Tooltip from "@mui/material/Tooltip";

import { updateTaskStatus } from "services/ventureApi";

interface Task {
  id: string;
  name: string;
  status: {
    status: string;
    color: string;
  };
  due_date: string | null;
  start_date: string | null;
  parent: string | null; // null for parent tasks, ID of the parent task for subtasks
  priority: string;
  project: {
    name: string;
  };
  assignees: {
    name: string;
  };
}

interface SummaryTableProps {
  tasks: Task[];
  onUpdateTask: (updatedTask: Task) => void;
}

interface RowProps {
  row: Task;
  subtasks: Task[];
  onUpdateTask: (updatedTask: Task) => void;
}

const Row: React.FC<RowProps> = ({ row, subtasks, onUpdateTask }) => {
  const [open, setOpen] = useState(false);
  const [actionLoading, setActionLoading] = useState<boolean>(false);

  const formatDate = (timestamp: string | null): string => {
    if (!timestamp) return "No Due Date";
    const date = new Date(parseInt(timestamp, 10));
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleStatusUpdate = async (taskId: string, status: string) => {
    try {
      setActionLoading(true);
      // Assuming taskId is a number; if it's a string, adjust accordingly
      const updatedTask = await updateTaskStatus(taskId, status);

      onUpdateTask(updatedTask);
      //toast.success(`Task marked as ${status}`);
    } catch (error: any) {
      console.error("Error updating task status:", error);
      //toast.error("Failed to update task status. Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          {subtasks.length > 0 && (
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row" style={{ marginLeft: 32 }}>
          {row.name}
        </TableCell>
        <TableCell>{row.status.status}</TableCell>
        <TableCell>{formatDate(row.due_date)}</TableCell>
        {/* Commented out Priority column */}
        {/* <TableCell>{row.priority}</TableCell> */}
        <TableCell>
          <Tooltip title="Mark as Complete">
            <IconButton
              size="small"
              color="success"
              onClick={() => handleStatusUpdate(row.id, "complete")}
              disabled={actionLoading}
            >
              <CheckCircleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Mark as ToDo">
            <IconButton
              size="small"
              color="info"
              onClick={() => handleStatusUpdate(row.id, "todo")}
              disabled={actionLoading}
            >
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Mark as In Progress">
            <IconButton
              size="small"
              color="warning"
              onClick={() => handleStatusUpdate(row.id, "in progress")}
              disabled={actionLoading}
            >
              <AutorenewIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      {subtasks.length > 0 && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1} width="100%">
                <Table size="small" aria-label="subtasks">
                  <TableHead
                    sx={{
                      display: "table-header-group",
                    }}
                  >
                    <TableRow>
                      <TableCell />
                      <TableCell style={{ paddingLeft: 38 }}>Subtask</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Due Date</TableCell>
                      {/* Commented out Priority column */}
                      {/* <TableCell>Priority</TableCell> */}
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subtasks.map((subtask) => (
                      <TableRow key={subtask.id}>
                        <TableCell />
                        <TableCell style={{ paddingLeft: 48 }} component="th" scope="row">
                          {subtask.name}
                        </TableCell>
                        <TableCell>{subtask.status.status}</TableCell>
                        <TableCell>{formatDate(row.due_date)}</TableCell>
                        {/* Commented out Priority column */}
                        {/* <TableCell>{subtask.priority}</TableCell> */}
                        <TableCell>
                          <Tooltip title="Mark as Complete">
                            <IconButton
                              size="small"
                              color="success"
                              onClick={() => handleStatusUpdate(subtask.id, "complete")}
                              disabled={actionLoading}
                            >
                              <CheckCircleIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Mark as ToDo">
                            <IconButton
                              size="small"
                              color="info"
                              onClick={() => handleStatusUpdate(subtask.id, "todo")}
                              disabled={actionLoading}
                            >
                              <AssignmentIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Mark as In Progress">
                            <IconButton
                              size="small"
                              color="warning"
                              onClick={() => handleStatusUpdate(subtask.id, "in progress")}
                              disabled={actionLoading}
                            >
                              <AutorenewIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const SummaryTable: React.FC<SummaryTableProps> = ({ tasks, onUpdateTask }) => {
  // Get only parent tasks
  const parentTasks = tasks.filter((task) => task.parent === null);

  // Function to get subtasks of a given parent task
  const getSubtasks = (parentId: string) => {
    return tasks.filter((task) => task.parent === parentId);
  };

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h6">Task Summary Table</MDTypography>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead
              sx={{
                display: "table-header-group",
              }}
            >
              <TableRow>
                <TableCell />
                <TableCell>Task</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Due Date</TableCell>
                {/* Commented out Priority column */}
                {/* <TableCell>Priority</TableCell> */}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parentTasks.map((parentTask) => (
                <Row
                  key={parentTask.id}
                  row={parentTask}
                  subtasks={getSubtasks(parentTask.id)}
                  onUpdateTask={onUpdateTask}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </MDBox>
    </Card>
  );
};

export default SummaryTable;
