import React, { useState } from "react";
import DashboardLayout from "components/refined/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/refined/Navbars/DashboardNavbar";
import Footer from "components/refined/Footer";
import MDBox from "components/generic/MDBox";
import Header from "./components/Header";
import About from "./about/About";
import Messages from "./messages/Messages";
import DataRoom from "./data-room/DataRoom";
import headerImage from "assets/images/reisiger/bg-profile.png";
import { useAuth } from "context/AuthContext";

const InvestorProfile: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const { loginResponse } = useAuth();
  const userData = loginResponse ? loginResponse.entity : null;

  const entityName = userData?.entity_name || "Investor Name";

  const renderTabContent = () => {
    switch (currentTab) {
      case 0:
        return <About />;
      /*
      case 1:
        return <Messages />; // Placeholder
      case 2:
        return <DataRoom />;
        */ // Placeholder
      default:
        return <About />;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header
        tabValue={currentTab}
        setTabValue={setCurrentTab}
        backgroundImage={headerImage}
        entityName={entityName}
      />
      {renderTabContent()}
      <Footer />
    </DashboardLayout>
  );
};

export default InvestorProfile;
