import React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import DetailCard from "./investor-view-of-business/DetailCard";
import DefaultItem from "components/refined/Items/DefaultItem";
import OneLiner from "./investor-view-of-business/OneLiner";
import FundingPieChart from "./investor-view-of-business/FundingPieChart";
import TeamMemberCard from "./investor-view-of-business/TeamMemberCard";
import avatarImage from "assets/blank_profile.png"; // Placeholder for avatar
import logo_placeholder from "assets/logo_placeholder.png"; // Placeholder for logo

interface AboutModalProps {
  open: boolean;
  handleClose: () => void;
  data: any;
  loading: boolean;
}

const constructOneLiner = (details: any): string => {
  const { businessName, problemOutline, keyOffering, targetMarket, valueProposition } = details;
  return `${businessName} is tackling the ${problemOutline} by offering ${keyOffering} which enables ${targetMarket} to ${valueProposition}.`;
};

const AboutModal: React.FC<AboutModalProps> = ({ open, handleClose, data, loading }) => {
  console.log("data", data);

  const {
    additional_info: {
      industryDetails = {},
      marketDetails = {},
      founderDetails = {},
      oneLinerDetails = {},
      entityDetails = {},
      financingDetails = {},
      new_team_members = [],
    } = {},
    logo_url,
  } = data?.entity || {};
  const industry =
    industryDetails?.industryDetails?.[0]?.industrySection || "Industry not available";
  const operationalLocations = marketDetails?.scaleOfOperations || "No data";
  const foundersCount = founderDetails?.founderDetails?.length || "N/A";
  const problemMaturity =
    oneLinerDetails?.problemMaturity === "other"
      ? oneLinerDetails?.problemMaturityOtherInput
      : oneLinerDetails?.problemMaturity || "N/A";
  const establishmentDate = entityDetails?.establishmentDate || "N/A";
  const address = `${entityDetails?.city}, ${entityDetails?.suburb}` || "No Address";
  const logoUrl = logo_url || logo_placeholder;

  const fundingBreakdown = financingDetails?.fundingBreakdown || {};
  const totalFunding = financingDetails?.totalFundingRaised || "No Funding";

  const teamMembers = new_team_members;
  const oneLinerText = constructOneLiner(oneLinerDetails);

  if (loading) {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <MDBox p={3}>
          <MDTypography variant="h6">Loading...</MDTypography>
        </MDBox>
      </Dialog>
    );
  }

  if (!loading && (!data || Object.keys(data).length === 0)) {
    return null;
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <MDBox p={3}>
        {/* Top Section: Entity Avatar and Name */}
        <MDBox display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <MDBox display="flex" alignItems="center">
            {/* Avatar */}
            <MDBox
              component="img"
              src={logoUrl || logo_placeholder}
              alt="Entity Avatar"
              borderRadius="lg"
              width="50px"
              height="50px"
            />
            <MDBox ml={2}>
              {/* Entity Name */}
              <MDTypography variant="h5" fontWeight="medium">
                {data.entity.name}
              </MDTypography>
            </MDBox>
          </MDBox>
          {/* Close Button */}
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </MDBox>

        {/* Main Grid Structure */}
        <Grid container spacing={3}>
          {/* Left Column: Placeholder for Detail Cards */}
          <Grid item xs={12} md={4}>
            <Card sx={{ p: 3, boxShadow: "none" }}>
              <MDTypography variant="h6" fontWeight="medium" mb={2}>
                About
              </MDTypography>

              <DefaultItem
                icon="agriculture"
                title="Industry"
                description={industry}
                color="primary"
              />

              <DefaultItem
                icon="place"
                title="Operational Locations"
                description={operationalLocations}
                color="primary"
              />
              <DefaultItem
                icon="group"
                title="Founders"
                description={foundersCount.toString()}
                color="primary"
              />
              <DefaultItem
                icon="assessment"
                title="Problem Maturity"
                description={problemMaturity}
                color="primary"
              />
              <DefaultItem
                icon="event"
                title="Establishment Date"
                description={establishmentDate}
                color="primary"
              />
              <DefaultItem
                icon="location_on"
                title="Physical Location"
                description={address}
                color="primary"
              />
            </Card>
          </Grid>

          {/* Middle Column: Placeholder for Profile Information */}
          <Grid item xs={12} md={4}>
            <Card sx={{ p: 3, boxShadow: "none" }}>
              <MDTypography variant="h6" fontWeight="medium" mb={2}>
                Profile Information
              </MDTypography>
              <OneLiner oneLinerText={oneLinerText} />
              <MDBox mt={4}>
                <MDTypography variant="h6" fontWeight="medium" mb={2}>
                  Funding Overview
                </MDTypography>
                <FundingPieChart totalFunding={totalFunding} fundingBreakdown={fundingBreakdown} />
              </MDBox>
            </Card>
          </Grid>

          {/* Right Column: Placeholder for Team Section */}
          <Grid item xs={12} md={4}>
            <Card sx={{ p: 3, boxShadow: "none" }}>
              <MDTypography variant="h6" fontWeight="medium" mb={2}>
                Team
              </MDTypography>
              {teamMembers.length > 0 ? (
                teamMembers.map((member: any, index: number) => (
                  <TeamMemberCard
                    key={index}
                    name={`${member.first_name} ${member.last_name}`}
                    role={member.role}
                    imgSrc={member.imgSrc || avatarImage}
                    canMessage={member.canMessage}
                    isAdmin={false}
                  />
                ))
              ) : (
                <MDTypography variant="body2">No team members available</MDTypography>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </Dialog>
  );
};

export default AboutModal;
