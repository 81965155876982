import React, { useState } from "react";
import Card from "@mui/material/Card";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/reisiger/profileBgImage.png";
import Footer from "components/refined/Footer"; //converted to TS

interface Entity {
  entity_id: number;
  entity_name: string;
  role_id: number;
  role_name: string;
}

interface EntityRoleSelectionProps {
  entities: Entity[];
  onEntityRoleSelect: (entityId: number, roleId: number) => void;
}

const EntityRoleSelection: React.FC<EntityRoleSelectionProps> = ({
  entities,
  onEntityRoleSelect,
}) => {
  const [selectedEntity, setSelectedEntity] = useState<string>("");
  const [selectedRole, setSelectedRole] = useState<string>("");

  const handleEntityChange = (event: SelectChangeEvent<string>) => {
    const entityId = event.target.value as string;
    setSelectedEntity(entityId);
    const entity = entities.find((e) => e.entity_id.toString() === entityId);
    if (entity) {
      setSelectedRole(entity.role_id.toString());
    }
  };

  const handleRoleChange = (event: SelectChangeEvent<string>) => {
    setSelectedRole(event.target.value as string);
  };

  const handleSelect = () => {
    onEntityRoleSelect(parseInt(selectedEntity), parseInt(selectedRole));
  };

  return (
    <BasicLayout image={bgImage}>
      <Card
        sx={{
          minWidth: {
            xs: undefined,
            sm: undefined,
            md: undefined,
            lg: 440,
            xl: 440,
          },
          mb: 2,
        }}
      >
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="grey"
          mx={2}
          mt={-3}
          px={2}
          py={{
            xs: 2,
            sm: 2,
            md: undefined,
            lg: 8,
            xl: 8,
          }}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Select Entity and Role
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <Select
                value={selectedEntity}
                onChange={handleEntityChange}
                fullWidth
                variant="standard"
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Entity
                </MenuItem>
                {entities.map((entity) => (
                  <MenuItem key={entity.entity_id} value={entity.entity_id.toString()}>
                    {entity.entity_name}
                  </MenuItem>
                ))}
              </Select>
            </MDBox>
            <MDBox mb={2}>
              <Select
                value={selectedRole}
                onChange={handleRoleChange}
                fullWidth
                variant="standard"
                displayEmpty
                disabled={!selectedEntity}
              >
                <MenuItem value="" disabled>
                  Select Role
                </MenuItem>
                {entities
                  .filter((entity) => entity.entity_id.toString() === selectedEntity)
                  .map((entity) => (
                    <MenuItem key={entity.role_id} value={entity.role_id.toString()}>
                      {entity.role_name}
                    </MenuItem>
                  ))}
              </Select>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="primary" fullWidth onClick={handleSelect}>
                Select
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <Footer />
    </BasicLayout>
  );
};
//Comment

export default EntityRoleSelection;
