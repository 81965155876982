import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { FormData, FinancingDetailsProps } from "../../../types";
/*
interface InvestmentDetails {
  portfolioSize?: string;
  portfolioValuation?: string;
  portfolioValuationCurrency?: string;
  funderRiskAppetite?: string;
}

interface FormData {
  investmentDetails?: InvestmentDetails;
}

interface FinancingDetailsProps {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}
*/
const FinancingDetails: React.FC<FinancingDetailsProps> = ({ formData, setFormData }) => {
  const [portfolioSize, setPortfolioSize] = useState<string>(
    formData.investmentDetails?.portfolioSize || ""
  );
  const [portfolioValuation, setPortfolioValuation] = useState<string>(
    formData.investmentDetails?.portfolioValuation || ""
  );
  const [portfolioValuationCurrency, setPortfolioValuationCurrency] = useState<string>(
    formData.investmentDetails?.portfolioValuationCurrency || "USD"
  );
  const [funderRiskAppetite, setFunderRiskAppetite] = useState<string>(
    formData.investmentDetails?.funderRiskAppetite || "Low"
  );

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      investmentDetails: {
        ...prevData.investmentDetails,
        portfolioSize,
        portfolioValuation,
        portfolioValuationCurrency,
        funderRiskAppetite,
      },
    }));
  }, [
    portfolioSize,
    portfolioValuation,
    portfolioValuationCurrency,
    funderRiskAppetite,
    setFormData,
  ]);

  const handlePortfolioSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPortfolioSize(event.target.value);
  };

  const handlePortfolioValuationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPortfolioValuation(event.target.value);
  };

  const handlePortfolioValuationCurrencyChange = (_: any, newValue: string | null) => {
    setPortfolioValuationCurrency(newValue || "USD");
  };

  const handleFunderRiskAppetiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFunderRiskAppetite(event.target.value);
  };

  const currencies = ["R", "USD", "EUR", "GBP", "JPY", "CAD", "AUD"]; // Dummy currency options

  return (
    <Grid container px={{ xs: 2, lg: 5 }} py={{ xs: 2 }} rowSpacing={4} alignItems="center">
      <Grid item xs={12} mb={7}>
        <Typography variant="h5">Fund Maturity</Typography>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Please provide the following information related to your fund maturity
        </Typography>
      </Grid>
      <Grid container mb={4} alignItems="center">
        <Grid item xs={12} lg={5}>
          <Typography sx={{ fontSize: "14px" }} variant="body1">
            How many startups/businesses are currently part of your portfolio?
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid> {/* Adding space */}
        <Grid item xs={12} lg={5}>
          <TextField
            label="Number of Portfolio Members"
            value={portfolioSize}
            onChange={handlePortfolioSizeChange}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container mb={4} alignItems="center">
        <Grid item xs={12} lg={5}>
          <Typography sx={{ fontSize: "14px" }} variant="body1">
            What was the most recent valuation of your investment portfolio?
          </Typography>
        </Grid>
        <Grid item xs={1}></Grid> {/* Adding space */}
        <Grid item display="flex" alignItems="center" gap={1} xs={12} lg={6}>
          <Autocomplete
            disableClearable
            value={portfolioValuationCurrency}
            onChange={handlePortfolioValuationCurrencyChange}
            options={currencies}
            size="small"
            sx={{ width: "60px" }}
            renderInput={(params) => <TextField {...params} />}
          />
          <TextField
            label="Please Enter Amount"
            value={portfolioValuation}
            onChange={handlePortfolioValuationChange}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container mb={4} alignItems="center">
        <Grid item xs={12}>
          <Typography sx={{ fontSize: "14px" }} variant="body1">
            What is the business&apos; risk appetite/willingness to take risks?
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <RadioGroup value={funderRiskAppetite} onChange={handleFunderRiskAppetiteChange}>
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                  color: "#7B809A",
                  fontWeight: "400",
                },
              }}
              value="Low"
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      borderRadius: "4px",
                    },
                    "&:after": {
                      borderRadius: "2px",
                    },
                  }}
                />
              }
              label="Low"
            />
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                  color: "#7B809A",
                  fontWeight: "400",
                },
              }}
              value="Moderate"
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      borderRadius: "4px",
                    },
                    "&:after": {
                      borderRadius: "2px",
                    },
                  }}
                />
              }
              label="Moderate"
            />
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                  color: "#7B809A",
                  fontWeight: "400",
                },
              }}
              value="High"
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      borderRadius: "4px",
                    },
                    "&:after": {
                      borderRadius: "2px",
                    },
                  }}
                />
              }
              label="High"
            />
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                  color: "#7B809A",
                  fontWeight: "400",
                },
              }}
              value="Venture Dependent"
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      borderRadius: "4px",
                    },
                    "&:after": {
                      borderRadius: "2px",
                    },
                  }}
                />
              }
              label="Venture Dependent"
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FinancingDetails;
