import React from "react";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import GanttChart from "./gantt/GanttChart";

interface Task {
  name: string;
  startDate?: string;
  endDate?: string;
  color: string;
}

interface GanttChartContainerProps {
  tasks: Task[];
  projectStartDate: string;
}

const GanttChartContainer: React.FC<GanttChartContainerProps> = ({ tasks, projectStartDate }) => {
  return (
    <Card sx={{ mb: 3 }}>
      <MDBox p={3}>
        <MDTypography variant="h6">Gantt Chart</MDTypography>
        <MDBox height="100%" width="100%">
          <GanttChart tasks={tasks} projectStartDate={projectStartDate} />
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default GanttChartContainer;
