import axios from "axios";
import getConfig from "../services/config";

const config = getConfig;

const axiosInstance = axios.create({
  baseURL: `${config.apiUrl}/${config.stage}`,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    // Check if there's a token in localStorage
    const token = localStorage.getItem("idToken");
    const expirationTime = localStorage.getItem("tokenExpiration");

    // If the token exists and is expired, refresh it
    if (token && expirationTime && new Date().getTime() > parseInt(expirationTime, 10)) {
      try {
        // Attempt to refresh the token
        const response = await axios.post("/auth/refresh-token", {}, { withCredentials: true }); //refactor with endpts
        const newTokens = response.data;

        // Update the token in localStorage
        //after signin/up then wipe from local storage
        localStorage.setItem("idToken", newTokens.idToken);
        localStorage.setItem("accessToken", newTokens.accessToken);
        localStorage.setItem("tokenExpiration", (new Date().getTime() + 3600000).toString());

        // Set the new token in the request header
        config.headers.Authorization = `Bearer ${newTokens.idToken}`;
      } catch (error) {
        console.error("Token refresh failed:", error);
        // Optional: Redirect to login page if refresh fails
      }
    } else if (token) {
      // If the token exists and is not expired, add it to the request headers
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axiosInstance };
//teo axios instances - initial authentication intercepter and then general interceptor - vs single conditional interceptor?
//for login - if no token, it gets set during login flow... subsequent requests will then pull it from localstorage conditionally?
