import React from "react";
import MDBox from "../../../components/generic/MDBox";
import MDTypography from "../../../components/generic/MDTypography";
import { useAuth } from "../../../context/AuthContext";
import PageLayout from "../../../components/refined/LayoutContainers/PageLayout";
import DashboardLayout from "../../../components/refined/LayoutContainers/DashboardLayout";

const HomePage: React.FC = () => {
  const { user } = useAuth();

  const renderContentBasedOnRole = () => {
    switch (user?.role) {
      case "Administrator":
        return (
          <>
            <MDTypography variant="h4" color="text">
              Welcome to the Admin Home Page
            </MDTypography>
            <MDTypography variant="body2" color="text">
              This is the home page for administrators.
            </MDTypography>
          </>
        );
      case "user":
        return (
          <>
            <MDTypography variant="h4" color="text">
              Welcome to the User Home Page
            </MDTypography>
            <MDTypography variant="body2" color="text">
              This is the home page for regular users.
            </MDTypography>
          </>
        );
      case "external":
        return (
          <>
            <MDTypography variant="h4" color="text">
              Welcome to the External User Home Page
            </MDTypography>
            <MDTypography variant="body2" color="text">
              This is the home page for external users.
            </MDTypography>
          </>
        );
      case "public":
        return (
          <>
            <MDTypography variant="h4" color="text">
              Welcome to the Public Home Page
            </MDTypography>
            <MDTypography variant="body2" color="text">
              This is the home page for public users.
            </MDTypography>
          </>
        );
      default:
        return (
          <>
            <MDTypography variant="h4" color="text">
              Welcome to the Home Page
            </MDTypography>
            <MDTypography variant="body2" color="text">
              No specific user role found.
            </MDTypography>
          </>
        );
    }
  };

  return (
    <DashboardLayout>
      <MDBox
        minHeight="75vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        px={2}
      >
        {renderContentBasedOnRole()}
      </MDBox>
    </DashboardLayout>
  );
};

export default HomePage;
