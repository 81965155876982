import React from "react";
import MDTypography from "components/generic/MDTypography";

interface OneLinerProps {
  oneLinerText: string; // Now, it just accepts the one-liner string
}

const OneLiner: React.FC<OneLinerProps> = ({ oneLinerText }) => {
  return <MDTypography variant="body2">{oneLinerText}</MDTypography>;
};

export default OneLiner;
