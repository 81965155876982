import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDAvatar from "components/generic/MDAvatar";
import MDButton from "components/generic/MDButton";

interface TeamMemberCardProps {
  name: string;
  role: string;
  imgSrc: string;
  canMessage: boolean;
  isAdmin: boolean;
  action?: {
    type: "external" | "internal";
    route: string;
    label: string;
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
  };
}

const TeamMemberCard: React.FC<TeamMemberCardProps> = ({
  name,
  role,
  imgSrc,
  canMessage,
  isAdmin,
  action,
}) => {
  return (
    <Card sx={{ boxShadow: "none", p: 1, mb: 0 }}>
      <MDBox display="flex" alignItems="center">
        {/* Avatar Column */}
        <MDBox mr={2}>
          <MDAvatar src={imgSrc} alt={name} shadow="md" />
        </MDBox>

        {/* Name and Role Column */}
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <MDTypography variant="button" fontWeight="medium">
            {name}
          </MDTypography>
          <MDTypography variant="caption" color="text">
            {role}
          </MDTypography>
        </MDBox>

        {/* Action Button */}
        {action && (
          <MDBox ml="auto">
            {action.type === "internal" ? (
              <MDButton component={Link} to={action.route} variant="text" color={action.color}>
                {action.label}
              </MDButton>
            ) : (
              <MDButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="text"
                color={action.color}
              >
                {action.label}
              </MDButton>
            )}
          </MDBox>
        )}

        {/* Message Button (Admin only) add back later
        {isAdmin && canMessage && !action && (
          <MDBox ml="auto">
            <MDButton variant="text" color="info">
              Message
            </MDButton>
          </MDBox>
        )}
        */}
      </MDBox>
    </Card>
  );
};

export default TeamMemberCard;

/* previous v
interface TeamMemberCardProps {
  name: string;
  role: string;
  imgSrc: string;
  canMessage: boolean;
  isAdmin: boolean;
}

const TeamMemberCard: React.FC<TeamMemberCardProps> = ({
  name,
  role,
  imgSrc,
  canMessage,
  isAdmin,
}) => {
  return (
    <MDBox mb={2}>
      <Grid container alignItems="center">
       
        <Grid item xs={2} container justifyContent="center" alignItems="center">
          <img
            src={imgSrc}
            alt={name}
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
        </Grid>

        
        <Grid item xs={7}>
          <MDBox display="flex" flexDirection="column" justifyContent="center">
            <MDTypography variant="button" fontWeight="bold">
              {name}
            </MDTypography>
            <MDTypography variant="caption" color="textSecondary">
              {role}
            </MDTypography>
          </MDBox>
        </Grid>

       
        <Grid item xs={3} container justifyContent="flex-end" alignItems="center">
          {isAdmin && canMessage && (
            <MDTypography variant="button" color="info.main" sx={{ cursor: "pointer" }}>
              Message
            </MDTypography>
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default TeamMemberCard;
*/
