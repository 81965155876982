import React from "react";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
import Icon from "@mui/material/Icon";
import DashboardLayout from "components/refined/LayoutContainers/DashboardLayout";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";

function OopsPage(): JSX.Element {
  const navigate = useNavigate();
  const { loginResponse } = useAuth();

  const handleGoToProfile = () => {
    if (loginResponse) {
      const userStatus = loginResponse?.user_profile?.status;
      if (userStatus === "BUSINESS_COMPLETE") {
        // Redirect to Business Profile
        navigate("/profile/business-profile");
      } else if (userStatus === "INVESTOR_COMPLETE") {
        // Redirect to Investor Profile
        navigate("/profile/investor-profile");
      } else {
        // Fallback to the sign-in page
        navigate("/authentication/sign-in");
      }
    } else {
      // If no loginResponse, redirect to sign-in
      navigate("/authentication/sign-in");
    }
  };

  return (
    <DashboardLayout>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="calc(100vh - 3.5rem)"
        px={1}
        mx="auto"
        width="100%"
        textAlign="center"
      >
        <MDBox width="100%" maxWidth="500px" textAlign="center">
          <Icon fontSize="large" color="error" sx={{ fontSize: "5rem" }}>
            error
          </Icon>
          <MDTypography variant="h2" color="error" mt={2} mb={1}>
            Oops! Something Went Wrong
          </MDTypography>
          <MDTypography variant="body1" color="textSecondary" mb={3}>
            An unexpected error occurred. Please try again or contact support.
          </MDTypography>
          {/*
          <MDButton variant="contained" color="info" onClick={handleGoToProfile} size="large">
            Go to Profile
          </MDButton>
          */}
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default OopsPage;
