import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import { BankData } from "../types";

interface BankSummaryProps {
  bankData: BankData[];
  loading: boolean;
}

const BankSummary: React.FC<BankSummaryProps> = ({ bankData, loading }) => {
  console.log("bankData in component", bankData);
  if (loading) {
    return (
      <Box sx={{ padding: 2 }}>
        <Typography variant="body2">Loading Bank Summary...</Typography>
      </Box>
    );
  }

  return (
    <Card sx={{ padding: 2 }}>
      <TableContainer>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h4" gutterBottom>
            Bank Summary
          </Typography>
          <Typography variant="subtitle2">As at 26 July 2024</Typography>
        </Box>
        <Table sx={{ tableLayout: "fixed", width: "100%" }}>
          <TableHead
            sx={{
              display: "table-header-group",
            }}
          >
            <TableRow>
              <TableCell sx={{ width: "30%", padding: "8px" }}>Account</TableCell>
              <TableCell sx={{ width: "17%", textAlign: "right", padding: "8px" }}>
                Opening Balance
              </TableCell>
              <TableCell sx={{ width: "17%", textAlign: "right", padding: "8px" }}>
                Cash Received
              </TableCell>
              <TableCell sx={{ width: "17%", textAlign: "right", padding: "8px" }}>
                Cash Spent
              </TableCell>
              <TableCell sx={{ width: "19%", textAlign: "right", padding: "8px" }}>
                Closing Balance
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bankData.map((item, index) => (
              <TableRow
                key={index}
                sx={
                  item.isHeader
                    ? { fontWeight: "bold", backgroundColor: "#f0f0f0", height: "36px" }
                    : { height: "36px" }
                }
              >
                <TableCell sx={{ width: "30%", padding: "8px" }}>{item.account}</TableCell>
                <TableCell sx={{ width: "17%", textAlign: "right", padding: "8px" }}>
                  {item.openingBalance !== null ? item.openingBalance.toFixed(2) : "-"}
                </TableCell>
                <TableCell sx={{ width: "17%", textAlign: "right", padding: "8px" }}>
                  {item.cashReceived !== null ? item.cashReceived.toFixed(2) : "-"}
                </TableCell>
                <TableCell sx={{ width: "17%", textAlign: "right", padding: "8px" }}>
                  {item.cashSpent !== null ? item.cashSpent.toFixed(2) : "-"}
                </TableCell>
                <TableCell sx={{ width: "19%", textAlign: "right", padding: "8px" }}>
                  {item.closingBalance !== null ? item.closingBalance.toFixed(2) : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default BankSummary;
