import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Autocomplete, TextField, Modal } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Delete from "@mui/icons-material/Delete";
import MDButton from "components/generic/MDButton";
import MDBox from "components/generic/MDBox";
import MDTooltip from "components/generic/MDTooltip";
import { styled } from "@mui/system";
import DataTable from "components/refined/Tables/DataTable";
import dataTableData from "data/questionnaires/dataTableData-1";
import { industriesData } from "data/questionnaires/industriesData";
import { IndustryDetailsProps, IndustryDetail } from "../../types";

const CustomAutocomplete = styled(Autocomplete<OptionType>)({
  "& .MuiOutlinedInput-root": {
    padding: "5px !important",
  },
});

interface OptionType {
  key: string;
  value: string;
}

const IndustryDetails: React.FC<IndustryDetailsProps> = ({ formData, setFormData }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [industryCount, setIndustryCount] = useState<number>(1);
  const [selectedIndustries, setSelectedIndustries] = useState<IndustryDetail[]>(
    Array.isArray(formData.industryDetails) && formData.industryDetails.length > 0
      ? formData.industryDetails
      : [
          {
            industrySection: null,
            industryDivision: null,
            industryGroup: null,
            industryClass: null,
            industrySubclass: null,
          },
        ]
  );

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      industryDetails: selectedIndustries,
    }));
  }, [selectedIndustries, setFormData]);

  const handleCloseModal = () => {
    setOpenModal(!openModal);
  };

  const handleAddIndustry = () => {
    setIndustryCount(industryCount + 1);
    setSelectedIndustries((prevState) => [
      ...prevState,
      {
        industrySection: null,
        industryDivision: null,
        industryGroup: null,
        industryClass: null,
        industrySubclass: null,
      },
    ]);
  };
  const handleRemoveIndustry = (index: number) => {
    setSelectedIndustries((prevIndustries) => prevIndustries.filter((_, i) => i !== index));
  };

  const handleIndustrySelection = (
    type: keyof IndustryDetail,
    index: number,
    value: string | null
  ) => {
    setSelectedIndustries((prevState) => {
      const updatedIndustries = [...prevState];
      updatedIndustries[index] = { ...updatedIndustries[index], [type]: value };

      // Reset subsequent selections
      if (type === "industrySection") {
        updatedIndustries[index].industryDivision = null;
        updatedIndustries[index].industryGroup = null;
        updatedIndustries[index].industryClass = null;
        updatedIndustries[index].industrySubclass = null;
      } else if (type === "industryDivision") {
        updatedIndustries[index].industryGroup = null;
        updatedIndustries[index].industryClass = null;
        updatedIndustries[index].industrySubclass = null;
      } else if (type === "industryGroup") {
        updatedIndustries[index].industryClass = null;
        updatedIndustries[index].industrySubclass = null;
      } else if (type === "industryClass") {
        updatedIndustries[index].industrySubclass = null;
      }

      return updatedIndustries;
    });
  };

  const getSectionOptions = () => {
    return Object.entries(industriesData).map(([key, value]) => ({
      key,
      value: value["Industry Section"],
    }));
  };

  const getDivisionOptions = (section: string | null) => {
    if (!section) return [];
    const sectionKey = Object.keys(industriesData).find(
      (key) => industriesData[key]["Industry Section"] === section
    );
    if (!sectionKey) return [];
    return Object.entries(industriesData[sectionKey].Divisions).map(([key, value]) => ({
      key,
      value: value["Industry Division"],
    }));
  };

  const getGroupOptions = (section: string | null, division: string | null) => {
    if (!section || !division) return [];
    const sectionKey = Object.keys(industriesData).find(
      (key) => industriesData[key]["Industry Section"] === section
    );
    if (!sectionKey) return [];
    const divisionKey = Object.keys(industriesData[sectionKey].Divisions).find(
      (key) => industriesData[sectionKey].Divisions[key]["Industry Division"] === division
    );
    if (!divisionKey) return [];
    return Object.entries(industriesData[sectionKey].Divisions[divisionKey].Groups).map(
      ([key, value]) => ({
        key,
        value: value["Industry Group"],
      })
    );
  };

  const getClassOptions = (
    section: string | null,
    division: string | null,
    group: string | null
  ) => {
    if (!section || !division || !group) return [];
    const sectionKey = Object.keys(industriesData).find(
      (key) => industriesData[key]["Industry Section"] === section
    );
    if (!sectionKey) return [];
    const divisionKey = Object.keys(industriesData[sectionKey].Divisions).find(
      (key) => industriesData[sectionKey].Divisions[key]["Industry Division"] === division
    );
    if (!divisionKey) return [];
    const groupKey = Object.keys(industriesData[sectionKey].Divisions[divisionKey].Groups).find(
      (key) =>
        industriesData[sectionKey].Divisions[divisionKey].Groups[key]["Industry Group"] === group
    );
    if (!groupKey) return [];
    return Object.entries(
      industriesData[sectionKey].Divisions[divisionKey].Groups[groupKey].Classes
    ).map(([key, value]) => ({
      key,
      value: value["Industry Class"],
    }));
  };

  const getSubclassOptions = (
    section: string | null,
    division: string | null,
    group: string | null,
    cls: string | null
  ) => {
    if (!section || !division || !group || !cls) return [];
    const sectionKey = Object.keys(industriesData).find(
      (key) => industriesData[key]["Industry Section"] === section
    );
    if (!sectionKey) return [];
    const divisionKey = Object.keys(industriesData[sectionKey].Divisions).find(
      (key) => industriesData[sectionKey].Divisions[key]["Industry Division"] === division
    );
    if (!divisionKey) return [];
    const groupKey = Object.keys(industriesData[sectionKey].Divisions[divisionKey].Groups).find(
      (key) =>
        industriesData[sectionKey].Divisions[divisionKey].Groups[key]["Industry Group"] === group
    );
    if (!groupKey) return [];
    const classKey = Object.keys(
      industriesData[sectionKey].Divisions[divisionKey].Groups[groupKey].Classes
    ).find(
      (key) =>
        industriesData[sectionKey].Divisions[divisionKey].Groups[groupKey].Classes[key][
          "Industry Class"
        ] === cls
    );
    if (!classKey) return [];
    return Object.entries(
      industriesData[sectionKey].Divisions[divisionKey].Groups[groupKey].Classes[classKey]
        .Subclasses
    ).map(([key, value]) => ({
      key,
      value,
    }));
  };

  return (
    <Grid container px={{ xs: 2, lg: 5 }} spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Industry Selection</Typography>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Please select the industry that your business primarily forms part of:
        </Typography>
        {selectedIndustries.map((industry, index) => (
          <Grid key={index} mt={8} mb={8} alignItems="center" container spacing={3}>
            <Grid item xs={12} md={2} lg={2.2}>
              <CustomAutocomplete
                disablePortal
                options={getSectionOptions()}
                value={
                  industry.industrySection ? { key: "", value: industry.industrySection } : null
                }
                onChange={(event, newValue) =>
                  handleIndustrySelection(
                    "industrySection",
                    index,
                    newValue ? newValue.value : null
                  )
                }
                getOptionLabel={(option: OptionType) => option.value || ""}
                renderInput={(params) => <TextField {...params} label="Select Industry Section" />}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </Grid>

            <Grid item xs={12} md={2} lg={2.2}>
              <CustomAutocomplete
                disablePortal
                options={getDivisionOptions(industry.industrySection)}
                value={
                  industry.industryDivision ? { key: "", value: industry.industryDivision } : null
                }
                onChange={(event, newValue) =>
                  handleIndustrySelection(
                    "industryDivision",
                    index,
                    newValue ? newValue.value : null
                  )
                }
                getOptionLabel={(option: OptionType) => option.value || ""}
                renderInput={(params) => <TextField {...params} label="Select Industry Division" />}
                isOptionEqualToValue={(option: OptionType, value: OptionType) =>
                  option.value === value.value
                }
              />
            </Grid>

            <Grid item xs={12} md={2} lg={2.2}>
              <CustomAutocomplete
                disablePortal
                options={getGroupOptions(industry.industrySection, industry.industryDivision)}
                value={industry.industryGroup ? { key: "", value: industry.industryGroup } : null}
                onChange={(event, newValue) =>
                  handleIndustrySelection("industryGroup", index, newValue ? newValue.value : null)
                }
                getOptionLabel={(option: OptionType) => option.value || ""}
                renderInput={(params) => <TextField {...params} label="Select Industry Group" />}
                isOptionEqualToValue={(option: OptionType, value: OptionType) =>
                  option.value === value.value
                }
              />
            </Grid>

            <Grid item xs={12} md={2} lg={2.2}>
              <CustomAutocomplete
                disablePortal
                options={getClassOptions(
                  industry.industrySection,
                  industry.industryDivision,
                  industry.industryGroup
                )}
                value={industry.industryClass ? { key: "", value: industry.industryClass } : null}
                onChange={(event, newValue) =>
                  handleIndustrySelection("industryClass", index, newValue ? newValue.value : null)
                }
                getOptionLabel={(option: OptionType) => option.value || ""}
                renderInput={(params) => <TextField {...params} label="Select Industry Class" />}
                isOptionEqualToValue={(option: OptionType, value: OptionType) =>
                  option.value === value.value
                }
              />
            </Grid>

            <Grid item xs={10} md={2} lg={2.2}>
              <CustomAutocomplete
                disablePortal
                options={getSubclassOptions(
                  industry.industrySection,
                  industry.industryDivision,
                  industry.industryGroup,
                  industry.industryClass
                )}
                value={
                  industry.industrySubclass ? { key: "", value: industry.industrySubclass } : null
                }
                onChange={(event, newValue) =>
                  handleIndustrySelection(
                    "industrySubclass",
                    index,
                    newValue ? newValue.value : null
                  )
                }
                getOptionLabel={(option: OptionType) => option.value || ""}
                renderInput={(params) => <TextField {...params} label="Select Industry Subclass" />}
                isOptionEqualToValue={(option: OptionType, value: OptionType) =>
                  option.value === value.value
                }
              />
            </Grid>

            <Grid item xs={2} md={1} lg={1}>
              <Delete
                style={{ cursor: "pointer" }}
                fontSize="medium"
                onClick={() => handleRemoveIndustry(index)}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <MDButton variant="outlined" color="dark" onClick={handleAddIndustry}>
          + Add Industry
        </MDButton>
      </Grid>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="upload-file-modal"
        aria-describedby="upload-file-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MDBox sx={{ width: "600px" }}>
          <DataTable table={dataTableData} handleCloseModal={handleCloseModal} canSearch />
        </MDBox>
      </Modal>
    </Grid>
  );
};

export default IndustryDetails;
