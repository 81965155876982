import Zoom from "@mui/material/Zoom";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

// Define the types for the props
const MDTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    TransitionComponent={Zoom}
    {...props}
    classes={{ popper: className }}
    arrow={false} // No arrow
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000000",
    opacity: "1",
    color: "#FFFFFF",
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    // border: '1px solid #dadde9',
  },
}));

export default MDTooltip;
