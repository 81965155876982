import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import ProgressChart from "./ProgressChart";
import TasksChart from "./TasksChart";

interface TasksProgressSectionProps {
  taskChartData: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string[];
    }[];
  };
  taskCount: {
    total: number;
    byStatus: {
      [status: string]: {
        count: number;
        color: string;
      };
    };
  };
}

const TasksProgressSection: React.FC<TasksProgressSectionProps> = ({
  taskChartData,
  taskCount,
}) => {
  const completedCount = taskCount.byStatus["complete"]?.count || 0;
  const totalTasks = taskCount.total || 1;
  const completionPercentage = (completedCount / totalTasks) * 100;
  console.log(
    "in container, completedCount, totalTasks, completionPercentage",
    completedCount,
    totalTasks,
    completionPercentage
  );

  return (
    <Grid container spacing={2} mb={3}>
      {/* Tasks Card */}
      <Grid item xs={12} md={6}>
        <Card>
          <MDBox p={3}>
            <MDTypography variant="h6">Tasks</MDTypography>
            <MDBox height="250px" display="flex" justifyContent="center" alignItems="center">
              <TasksChart chartData={taskChartData} />
            </MDBox>
          </MDBox>
        </Card>
      </Grid>

      {/* Progress Card */}
      <Grid item xs={12} md={6}>
        <Card>
          <MDBox p={3}>
            <MDTypography variant="h6">Progress</MDTypography>
            <MDBox height="250px" display="flex" justifyContent="center" alignItems="center">
              <ProgressChart percentage={completionPercentage} />
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TasksProgressSection;
