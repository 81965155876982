import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  TextField,
  FormControl,
  Avatar,
  Autocomplete,
  Radio as MRadio,
} from "@mui/material";
import MDButton from "components/generic/MDButton";
import CloseIcon from "@mui/icons-material/Close";
import { FounderDetailsProps, FounderData } from "../../types";

const entrepreneurTypes = [
  "Small Business Entrepreneur",
  "Large Company Entrepreneur",
  "Scalable Startup Entrepreneur",
  "Corporate Entrepreneur (Intrapreneur)",
  "Innovative Entrepreneur",
  "Imitator Entrepreneur",
  "Hustler Entrepreneur",
  "Buyer Entrepreneur",
  "Franchise Entrepreneur",
  "Serial Entrepreneur",
  "Solopreneur",
  "Online Entrepreneur",
  "Social Entrepreneur",
  "Other",
  "Unsure",
];

const entrepreneurExperience = [
  "None",
  "One year",
  "2-3 years",
  "4-7 years",
  "7-10 years",
  "10+ years",
];

const FounderDetails: React.FC<FounderDetailsProps> = ({ formData, setFormData, onAddFounder }) => {
  const [founders, setFounders] = useState<FounderData[]>(formData.founderDetails || []);

  const [newFounder, setNewFounder] = useState<FounderData>({
    founderName: "",
    founderSurname: "",
    birthDate: "",
    gender: "",
    ethnicity: "",
    entrepreneurType: "",
    entrepreneurExperience: "",
  });

  useEffect(() => {
    setFounders(Array.isArray(formData.founderDetails) ? formData.founderDetails : []);
  }, [formData.founderDetails]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      founderDetails: founders,
    }));
  }, [founders, setFormData]);

  const handleNewFounderChange =
    (field: keyof FounderData) =>
    (event: React.ChangeEvent<HTMLInputElement> | null, value?: string) => {
      setNewFounder((prevState) => ({
        ...prevState,
        [field]: value !== undefined ? value : event?.target.value || "",
      }));
    };

  const handleAddFounder = () => {
    const updatedFounders = [...founders, newFounder];
    setFounders(updatedFounders);
    setFormData((prevData) => ({ ...prevData, founderDetails: updatedFounders }));
    onAddFounder(true); // Notify parent component that founder has been added

    setNewFounder({
      founderName: "",
      founderSurname: "",
      birthDate: "",
      gender: "",
      ethnicity: "",
      entrepreneurType: "",
      entrepreneurExperience: "",
    });
  };

  const handleFounderDelete = (index: number) => {
    const updatedFounders = founders.filter((_, i) => i !== index);
    setFounders(updatedFounders);
  };

  return (
    <Grid container px={{ xs: 2, lg: 5 }} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Typography variant="h5">Complete the founder details</Typography>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Please complete the details of the founder
        </Typography>
      </Grid>

      {founders.length > 0 && (
        <Grid container marginY={1} spacing={2}>
          {founders.map((founder, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Grid
                container
                alignItems="center"
                gap={2}
                sx={{ position: "relative", padding: "10px", borderRadius: "5px" }}
              >
                <Avatar />
                <Typography variant="h6">
                  {founder.founderName || `Founder ${index + 1}`}
                </Typography>
                <CloseIcon
                  onClick={() => handleFounderDelete(index)}
                  sx={{ position: "absolute", top: "5px", right: "5px", cursor: "pointer" }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}

      <Grid item xs={12} sm={5}>
        <Grid item>
          <TextField
            label="Founder Name"
            value={newFounder.founderName}
            onChange={handleNewFounderChange("founderName")}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </Grid>

        <Grid item>
          <TextField
            label="Founder Surname"
            value={newFounder.founderSurname}
            onChange={handleNewFounderChange("founderSurname")}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item>
          <TextField
            label="Birth Date"
            type="date"
            value={newFounder.birthDate}
            onChange={handleNewFounderChange("birthDate")}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </Grid>

        <Grid item>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              id="entrepreneurTypes"
              options={entrepreneurTypes}
              renderInput={(params) => <TextField {...params} label="Entrepreneur Type" />}
              onChange={(event, newValue) =>
                handleNewFounderChange("entrepreneurType")(null, newValue)
              }
              value={newFounder.entrepreneurType}
              isOptionEqualToValue={(option, value) =>
                option === value || (option === null && value === "")
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={7} sx={{ mt: 2 }}>
        <Grid item mb={1}>
          <FormControl
            fullWidth
            sx={{
              border: "1px solid",
              borderRadius: "12px",
              borderColor: "#C7CCD0",
              padding: "7px",
              pl: 2,
            }}
          >
            <Typography sx={{ fontSize: "14px" }} variant="body1">
              Ethnicity
            </Typography>
            <RadioGroup
              row
              name="ethnicity"
              value={newFounder.ethnicity}
              onChange={handleNewFounderChange("ethnicity")}
            >
              {[
                "Caucasian",
                "African",
                "Coloured",
                "Indian",
                "Asian",
                "Other",
                "Prefer not to Disclose",
              ].map((option) => (
                <FormControlLabel
                  key={option}
                  sx={{
                    maxHeight: "32px",
                    "& .MuiFormControlLabel-label": {
                      fontSize: "15px",
                      color: "#7B809A",
                      fontWeight: "400",
                    },
                  }}
                  value={option}
                  control={
                    <MRadio
                      sx={{
                        "& .MuiSvgIcon-root": { borderRadius: "4px" },
                        "&:after": { borderRadius: "2px" },
                      }}
                    />
                  }
                  label={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl
            fullWidth
            sx={{
              border: "1px solid",
              borderRadius: "12px",
              borderColor: "#C7CCD0",
              padding: "7px",
              pl: 2,
            }}
          >
            <Typography sx={{ fontSize: "14px" }} variant="body1">
              Gender
            </Typography>
            <RadioGroup
              row
              name="gender"
              value={newFounder.gender}
              onChange={handleNewFounderChange("gender")}
            >
              {["male", "female", "other", "Prefer not to Disclose"].map((option) => (
                <FormControlLabel
                  key={option}
                  sx={{
                    maxHeight: "32px",
                    "& .MuiFormControlLabel-label": {
                      fontSize: "15px",
                      color: "#7B809A",
                      fontWeight: "400",
                    },
                  }}
                  value={option}
                  control={
                    <MRadio
                      sx={{
                        "& .MuiSvgIcon-root": { borderRadius: "4px" },
                        "&:after": { borderRadius: "2px" },
                      }}
                    />
                  }
                  label={option.charAt(0).toUpperCase() + option.slice(1)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item mt={1}>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              id="entrepreneurExperience"
              options={entrepreneurExperience}
              renderInput={(params) => <TextField {...params} label="Entrepreneur Experience" />}
              onChange={(event, newValue) =>
                handleNewFounderChange("entrepreneurExperience")(null, newValue)
              }
              value={newFounder.entrepreneurExperience}
              isOptionEqualToValue={(option, value) =>
                option === value || (option === null && value === "")
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid item xs={12} spacing={3} mt={{ xs: 2, lg: 8 }}>
        <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
          <MDButton color="dark" variant="outlined" onClick={handleAddFounder}>
            + Add Founder
          </MDButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FounderDetails;
