import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import ProjectCard from "./ProjectCard";

interface ProjectCardsSectionProps {
  status: string;
  lead: string;
  dueDate: string;
}

const ProjectCardsSection: React.FC<ProjectCardsSectionProps> = ({ status, lead, dueDate }) => {
  const statusIcon = {
    color: "info" as const, // You can adjust the color as needed
    component: "assignment", // Icon name for project status
  };

  const leadIcon = {
    color: "info" as const,
    component: "person", // Icon for project lead
  };

  const dueDateIcon = {
    color: "info" as const,
    component: "event", // Icon for due date
  };

  return (
    <Grid container spacing={2} mb={3}>
      <Grid item xs={12} sm={4}>
        <ProjectCard title="Project Status" value={status} icon={statusIcon} bgColor="white" />
      </Grid>

      <Grid item xs={12} sm={4}>
        <ProjectCard title="Project Lead" value={lead} icon={leadIcon} bgColor="white" />
      </Grid>

      <Grid item xs={12} sm={4}>
        <ProjectCard title="Due Date" value={dueDate} icon={dueDateIcon} bgColor="white" />
      </Grid>
    </Grid>
  );
};

export default ProjectCardsSection;
