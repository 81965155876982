type DataTableColumn = {
  Header: string;
  accessor: string;
  width: string;
};

type DataTableRow = {
  sicCode: string;
  section: string;
  division: string;
  subClass: string;
};

type DataTableData = {
  columns: DataTableColumn[];
  rows: DataTableRow[];
};

const dataTableData: DataTableData = {
  columns: [
    { Header: "SIC Code", accessor: "sicCode", width: "10%" },
    { Header: "Section", accessor: "section", width: "20%" },
    { Header: "Division", accessor: "division", width: "20%" },
    { Header: "Sub-class", accessor: "subClass", width: "20%" },
  ],

  rows: [
    {
      sicCode: "123",
      section: "Manufacturing",
      division: "Electrical Equipment",
      subClass: "Switchgear and Switchboard Apparatus Manufacturing",
    },
    {
      sicCode: "456",
      section: "Construction",
      division: "Heavy and Civil Engineering Construction",
      subClass: "Highway, Street, and Bridge Construction",
    },
    {
      sicCode: "789",
      section: "Finance",
      division: "Insurance Carriers",
      subClass: "Direct Life Insurance Carriers",
    },
    {
      sicCode: "234",
      section: "Agriculture",
      division: "Crop Production",
      subClass: "Grain Farming",
    },
    {
      sicCode: "567",
      section: "Services",
      division: "Health Services",
      subClass: "Offices and Clinics of Dentists",
    },
    {
      sicCode: "890",
      section: "Retail Trade",
      division: "Building Materials",
      subClass: "Lumber and Other Building Materials Dealers",
    },
    {
      sicCode: "345",
      section: "Transportation",
      division: "Water Transportation",
      subClass: "Deep Sea Freight Transportation",
    },
    {
      sicCode: "678",
      section: "Information",
      division: "Publishing",
      subClass: "Newspaper Publishers",
    },
    {
      sicCode: "901",
      section: "Wholesale Trade",
      division: "Groceries",
      subClass: "Wholesale Groceries and Related Products",
    },
    {
      sicCode: "123",
      section: "Manufacturing",
      division: "Chemicals and Allied Products",
      subClass: "Industrial Organic Chemicals",
    },
    // Add more rows as needed...
  ],
};

export default dataTableData;
