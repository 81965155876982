interface FormField {
  name: string;
  label: string;
  type: string;
  placeholder?: string;
  errorMsg?: string;
  invalidMsg?: string;
}

const formField: { [key: string]: FormField } = {
  firstName: {
    name: "firstName",
    label: "First Name",
    type: "text",
    placeholder: "John",
    errorMsg: "First Name is required",
  },
  lastName: {
    name: "lastName",
    label: "Last Name",
    type: "text",
    placeholder: "Doe",
    errorMsg: "Last Name is required",
  },
  phone: {
    name: "phone",
    label: "Phone Number",
    type: "text",
    placeholder: "123-456-7890",
    errorMsg: "Phone number is required",
  },
  email: {
    name: "email",
    label: "Email",
    type: "email",
    placeholder: "john.doe@example.com",
    errorMsg: "Email is required",
    invalidMsg: "Invalid email format",
  },
  location: {
    name: "location",
    label: "Location",
    type: "text",
    placeholder: "New York",
  },
  password: {
    name: "password",
    label: "Password",
    type: "password",
    placeholder: "********",
    errorMsg: "Password is required",
  },
  repeatPassword: {
    name: "repeatPassword",
    label: "Repeat Password",
    type: "password",
    placeholder: "********",
    errorMsg: "Repeat Password is required",
    invalidMsg: "Passwords must match",
  },
  joiningAs: {
    name: "joiningAs",
    label: "I am joining as",
    type: "radio",
  },
  role: {
    name: "role",
    label: "My role in the company",
    type: "radio",
  },
  seekingToConnect: {
    name: "seekingToConnect",
    label: "I am seeking to connect with",
    type: "radio",
  },
  servicesInterestedIn: {
    name: "servicesInterestedIn",
    label: "Services that I might be interested in",
    type: "checkbox",
  },
  servicesToSupply: {
    name: "servicesToSupply",
    label: "Services that I can supply",
    type: "checkbox",
  },
  address1: {
    name: "address1",
    label: "Address 1",
    type: "text",
    placeholder: "123 Main St",
  },
  address2: {
    name: "address2",
    label: "Address 2",
    type: "text",
    placeholder: "Apt 4B",
  },
  city: {
    name: "city",
    label: "City",
    type: "text",
    placeholder: "City",
  },
  zip: {
    name: "zip",
    label: "Zip",
    type: "text",
    placeholder: "12345",
  },
  twitter: {
    name: "twitter",
    label: "Twitter",
    type: "text",
    placeholder: "@twitterHandle",
  },
  facebook: {
    name: "facebook",
    label: "Facebook",
    type: "text",
    placeholder: "facebookProfile",
  },
  instagram: {
    name: "instagram",
    label: "Instagram",
    type: "text",
    placeholder: "@instagramHandle",
  },
  publicEmail: {
    name: "publicEmail",
    label: "Public Email",
    type: "text",
    placeholder: "public@example.com",
  },
  bio: {
    name: "bio",
    label: "Bio",
    type: "text",
    placeholder: "Tell us about yourself",
  },
  countryCode: {
    name: "countryCode",
    label: "Country Code",
    type: "text",
    placeholder: "+27",
    errorMsg: "Country code is required",
  },
  referenceKey: {
    name: "referenceKey",
    label: "Reference Key",
    type: "text",
  },
};

const formId = "signup-form";

export default {
  formId,
  formField,
};
