import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Modal,
  Card,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import MDButton from "components/generic/MDButton";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import { Close, HighlightOff } from "@mui/icons-material";
import icon1 from "../../../../../../assets/icons-funding/icon1.svg";
import icon2 from "../../../../../../assets/icons-funding/icon2.svg";
import icon3 from "../../../../../../assets/icons-funding/icon3.svg";
import icon4 from "../../../../../../assets/icons-funding/icon4.svg";
import icon5 from "../../../../../../assets/icons-funding/icon5.svg";
import icon6 from "../../../../../../assets/icons-funding/icon6.svg";
import icon7 from "../../../../../../assets/icons-funding/icon7.svg";
import icon8 from "../../../../../../assets/icons-funding/icon8.svg";
import icon9 from "../../../../../../assets/icons-funding/icon9.svg";
import icon10 from "../../../../../../assets/icons-funding/icon10.svg";
import { FormData, FundingMechProps } from "../../types";

const mechanismOptions: Record<string, string[]> = {
  "Early-stage Financing": ["Pre-seed Funding", "Seed Funding", "Angel Investors", "Crowdfunding"],
  "Equity Financing": [
    "Angel Investors",
    "Venture Capital Funds",
    "Private Equity Funds",
    "Strategic Partnerships",
    "Silent Partnerships",
  ],
  "Debt Financing": [
    "Debt Instruments",
    "Traditional Loans",
    "Bond Issuance",
    "Syndicated Loans",
    "Peer-to-peer Lending",
  ],
  "Alternative Financing": [
    "Mezzanine Financing",
    "Convertible Notes",
    "Revenue Sharing",
    "Royalty Based",
    "Series Funding Rounds",
  ],
  "Specialised Financing": [
    "Impact Investors",
    "Corporate Venture Capital",
    "Industry Specific Funds",
    "Technology Transfer Agreements",
  ],
  "Asset-based Financing": [
    "Asset-backed Loans",
    "Inventory Financing",
    "Equipment Financing",
    "Receivables Financing",
    "Supply Chain Finance",
  ],
  "Revolving Credit and Lines of Credit": [
    "Working Capital Lines",
    "Credit Facilities",
    "Trade Credit",
  ],
  "Government Funding": [
    "Governmental Grants",
    "Governmental Subsidies",
    "Government Incentives",
    "Government Loans and Loan Guarantees",
    "Social Impact Bonds",
    "Sponsorship and Endowments",
  ],
  "Development Financing": [
    "Development Grants",
    "Infrastructure Financing",
    "Community Development Funds",
    "Regional Development Programs",
    "Economic Stimulus Packages",
  ],
  Other: [],
};

const mechanismIcons: Record<string, string> = {
  "Early-stage Financing": icon1,
  "Equity Financing": icon2,
  "Debt Financing": icon3,
  "Alternative Financing": icon4,
  "Specialised Financing": icon5,
  "Asset-based Financing": icon6,
  "Revolving Credit and Lines of Credit": icon7,
  "Government Funding": icon8,
  "Development Financing": icon9,
  Other: icon10,
};

const FundingMech: React.FC<FundingMechProps> = ({ formData, setFormData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedMech, setSelectedMech] = useState<string | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleCloseModal = () => {
    setOpenModal(!openModal);
  };

  const handleSelectMech = (mech: string) => {
    console.log("mech selected,", mech);
    setSelectedMech(mech);
    setSelectedOptions([]);
  };

  const handleAddMechanism = () => {
    setFormData((prevData: FormData) => ({
      ...prevData,
      fundingMech: {
        ...(prevData.fundingMech || {}),
        [selectedMech as string]: selectedOptions,
      },
    }));
    setSelectedMech(null);
    setOpenModal(false);
  };

  const handleBack = () => {
    setSelectedMech(null);
  };

  const handleRemoveMech = (mech: string) => {
    setFormData((prevData: FormData) => {
      const updatedFundingMech = { ...prevData.fundingMech };
      delete updatedFundingMech[mech];
      return { ...prevData, fundingMech: updatedFundingMech };
    });
  };

  const handleOptionChange = (label: string) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.includes(label)
        ? prevOptions.filter((opt) => opt !== label)
        : [...prevOptions, label]
    );
  };

  return (
    <Grid container px={{ xs: 2, lg: 5 }} rowSpacing={5}>
      <Grid item xs={12}>
        <Typography variant="h5">Funding Mechanisms</Typography>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Please add primary funding mechanisms
        </Typography>
      </Grid>

      {formData.fundingMech && (
        <Grid item xs={12}>
          <MDBox display="flex" flexWrap="wrap" gap={3}>
            {Object.keys(formData.fundingMech).map((mech) => (
              <MDBox
                key={mech}
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="140px"
                height="98px"
                justifyContent="flex-start"
                gap={1}
                pt={3}
                pb={1.2}
                borderRadius="8px"
                variant="contained"
                color="primary"
                position="relative"
                sx={{
                  border: "2px solid",
                  color: "primary",
                  transition: "background-color 0.3s, color 0.3s",
                  "& .typography": {
                    color: "#FFFFFF",
                  },
                  backgroundColor: "primary.main",
                }}
              >
                <Close
                  onClick={() => handleRemoveMech(mech)}
                  fontSize="small"
                  sx={{
                    position: "absolute",
                    top: "6px",
                    right: "6px",
                    color: "#FFFFFF",
                    cursor: "pointer",
                  }}
                />
                <img src={mechanismIcons[mech]} alt={mech} />
                <MDTypography
                  fontSize={12}
                  maxWidth="116px"
                  textAlign="center"
                  className="typography"
                >
                  {mech}
                </MDTypography>
              </MDBox>
            ))}
          </MDBox>
        </Grid>
      )}

      <Grid item xs={12} sm={5}>
        <MDButton color="dark" variant="outlined" onClick={handleCloseModal}>
          + Add funding mechanism
        </MDButton>
      </Grid>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            minWidth: {
              lg: 800,
              xl: 1122,
            },
            maxWidth: {
              xs: 320,
              sm: 320,
              lg: 900,
              xl: 1122,
            },
          }}
        >
          <MDBox
            variant="contained"
            bgColor="primary"
            borderRadius="lg"
            //coloredShadow="primary"
            mx={{
              xs: 4,
              lg: 20,
            }}
            mt={-4}
            px={2}
            py={{
              xs: 2,
              lg: 3,
            }}
            mb={1}
            textAlign="center"
          >
            <MDTypography
              fontSize={{ md: "24px", xs: "12px" }}
              variant="h4"
              fontWeight="medium"
              color="white"
              mt={1}
            >
              Select Your Funding Mechanism
            </MDTypography>
          </MDBox>
          <MDBox
            px={12}
            display="flex"
            alignItems="center"
            justifyContent={{ xl: "flex-start", lg: "center", xs: "center" }}
            py={{
              xs: "40px",
              lg: "80px",
            }}
          >
            <HighlightOff
              onClick={handleCloseModal}
              fontSize="large"
              sx={{
                position: "absolute",
                top: { xl: "18px", xs: "14px" },
                right: { xl: "28px", xs: "4px" },
                cursor: "pointer",
                color: "#6c757d",
              }}
            />
            {selectedMech === null && (
              <MDBox
                sx={{ overflowY: "auto" }}
                maxHeight={{ xl: "100%", lg: undefined, xs: "180px" }}
                display="flex"
                flexWrap="wrap"
                gap={3}
                px={{ xl: 7, lg: 2, xs: 1 }}
              >
                {Object.keys(mechanismIcons).map((mech) => (
                  <MDBox
                    key={mech}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width="140px"
                    height="98px"
                    justifyContent="flex-start"
                    gap={1}
                    pt={3}
                    pb={1.2}
                    borderRadius="8px"
                    variant="contained"
                    color="primary"
                    sx={{
                      border: "2px solid",
                      color: "primary",
                      transition: "background-color 0.3s, color 0.3s",
                      cursor: "pointer",
                      "& .typography": {
                        color: "#344767",
                      },
                      "&:hover": {
                        backgroundColor: "primary.main",
                        color: "#FFFFFF",
                        "& svg": {
                          fill: "#FFFFFF",
                        },
                        "& .typography": {
                          color: "#FFFFFF",
                        },
                      },
                    }}
                    onClick={() => handleSelectMech(mech)}
                  >
                    <img src={mechanismIcons[mech]} alt={mech} />
                    <MDTypography
                      fontSize={12}
                      maxWidth="116px"
                      textAlign="center"
                      className="typography"
                    >
                      {mech}
                    </MDTypography>
                  </MDBox>
                ))}
              </MDBox>
            )}
            {selectedMech !== null && (
              <MDBox display="flex" flexDirection="column" gap={3} px={7}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  width="140px"
                  justifyContent="center"
                  gap={1}
                  pt={3}
                  pb={1}
                  borderRadius="8px"
                  variant="contained"
                  color="primary"
                  sx={{
                    border: "2px solid",
                    color: "primary",
                    transition: "background-color 0.3s, color 0.3s",
                    "& .typography": {
                      color: "#FFFFFF",
                    },
                    backgroundColor: "primary.main",
                  }}
                >
                  <img src={mechanismIcons[selectedMech]} alt={selectedMech} />
                  <MDTypography
                    fontSize={12}
                    maxWidth="116px"
                    textAlign="center"
                    className="typography"
                  >
                    {selectedMech}
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <FormGroup sx={{ display: "flex" }}>
                    {mechanismOptions[selectedMech as string].map(
                      (label: string, index: number) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              sx={{
                                color: "#B0B0B0", // Outer circle color (unchecked state)
                                "&.Mui-checked": {
                                  color: "#85B526", // not pulling through
                                },
                              }}
                              checked={selectedOptions.includes(label)}
                              onChange={() => handleOptionChange(label)}
                            />
                          }
                          label={label}
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "12px",
                              color: "#7B809A",
                              fontWeight: "400",
                            },
                          }}
                        />
                      )
                    )}
                  </FormGroup>
                </MDBox>
                <MDBox display="flex" flexWrap="wrap" gap={2}>
                  <MDButton variant="outlined" color="dark" onClick={handleBack}>
                    Back
                  </MDButton>
                  <MDButton variant="outlined" color="dark" onClick={handleAddMechanism}>
                    + Add Mechanism
                  </MDButton>
                </MDBox>
              </MDBox>
            )}
          </MDBox>
        </Card>
      </Modal>
    </Grid>
  );
};

export default FundingMech;
