import {
  ExecutiveSummaryData,
  KpiData,
} from "../layouts/pages/business/business-profile/financial-overview/types";

export function transformExecutiveSummaryToKpiData(
  executiveSummary: ExecutiveSummaryData
): KpiData[] {
  const kpiData: KpiData[] = [];

  // Find the report
  const report = executiveSummary.Reports.find((r) => r.ReportID === "ExecutiveSummary");
  if (!report) {
    return kpiData;
  }

  const rows = report.Rows;

  // Initialize variables to hold the values
  let grossProfitMargin: { current: string; variance: string } | null = null;
  let netProfitMargin: { current: string; variance: string } | null = null;
  let roi: { current: string; variance: string } | null = null;
  let shortTermCash: { current: string; variance: string } | null = null;

  // Traverse the rows to find the necessary data
  for (const section of rows) {
    if (section.RowType === "Section" && section.Rows) {
      if (section.Title === "Performance") {
        for (const row of section.Rows) {
          if (row.Cells && row.Cells.length >= 4) {
            const metricName = row.Cells[0].Value;
            const currentValue = row.Cells[1].Value;
            const variance = row.Cells[3].Value;

            if (metricName === "Gross profit margin") {
              grossProfitMargin = { current: currentValue, variance };
            } else if (metricName === "Net profit margin") {
              netProfitMargin = { current: currentValue, variance };
            } else if (metricName === "Return on investment (p.a.)") {
              roi = { current: currentValue, variance };
            }
          }
        }
      } else if (section.Title === "Position") {
        for (const row of section.Rows) {
          if (row.Cells && row.Cells.length >= 4) {
            const metricName = row.Cells[0].Value;
            const currentValue = row.Cells[1].Value;
            const variance = row.Cells[3].Value;

            if (metricName === "Short term cash forecast") {
              shortTermCash = { current: currentValue, variance };
            }
          }
        }
      }
    }
  }

  // Helper function to determine color based on variance
  const getColor = (variance: string) => {
    const varianceValue = parseFloat(variance.replace("%", "").replace(",", ""));
    return varianceValue >= 0 ? "success" : "error";
  };

  // Now construct the KpiData array with abbreviations
  if (grossProfitMargin) {
    kpiData.push({
      title: "Gross Profit Margin",
      abbreviation: "GPM",
      value: grossProfitMargin.current.endsWith("%")
        ? grossProfitMargin.current
        : `${parseFloat(grossProfitMargin.current).toFixed(1)}%`,
      change: `${grossProfitMargin.variance} than last month`,
      color: getColor(grossProfitMargin.variance),
    });
  }

  if (netProfitMargin) {
    kpiData.push({
      title: "Net Profit Margin",
      abbreviation: "NPM",
      value: netProfitMargin.current.endsWith("%")
        ? netProfitMargin.current
        : `${parseFloat(netProfitMargin.current).toFixed(1)}%`,
      change: `${netProfitMargin.variance} than last month`,
      color: getColor(netProfitMargin.variance),
    });
  }

  if (roi) {
    kpiData.push({
      title: "Return on Investment (p.a.)",
      abbreviation: "ROI",
      value: roi.current.endsWith("%") ? roi.current : `${parseFloat(roi.current).toFixed(1)}%`,
      change: `${roi.variance} than last month`,
      color: getColor(roi.variance),
    });
  }

  if (shortTermCash) {
    kpiData.push({
      title: "Short Term Cash Forecast",
      abbreviation: "STC",
      value: shortTermCash.current,
      change: `${shortTermCash.variance} than last month`,
      color: getColor(shortTermCash.variance),
    });
  }

  return kpiData;
}
