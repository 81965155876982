import React from "react";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
import Grid from "@mui/material/Grid";
import { string } from "yup";

interface FinancialOverviewHeaderProps {
  onRefresh: () => void;
  onManageConnection: () => void;
  entityName: string;
  financialYearEnd: string;
}

const FinancialOverviewHeader: React.FC<FinancialOverviewHeaderProps> = ({
  onRefresh,
  onManageConnection,
  entityName,
  financialYearEnd,
}) => {
  return (
    <Grid container justifyContent="space-between" alignItems="center" mb={3}>
      <Grid item>
        <MDTypography variant="h4" fontWeight="medium">
          Financial Overview
        </MDTypography>
        <MDTypography variant="body2" color="textSecondary">
          This is the financial overview of {entityName} with their financial year ending on{" "}
          {financialYearEnd}.
        </MDTypography>
      </Grid>
      <Grid item>
        <MDButton variant="gradient" color="dark" sx={{ mr: 2 }} onClick={onRefresh}>
          Refresh
        </MDButton>
        <MDButton variant="contained" color="info" onClick={onManageConnection}>
          Manage Connection
        </MDButton>
      </Grid>
    </Grid>
  );
};

export default FinancialOverviewHeader;
