import React from "react";
import MDTypography from "components/generic/MDTypography";

interface OneLinerProps {
  entityName: string;
  problemOutline: string;
  keyOffering: string;
  targetMarket: string;
  valueProposition: string;
}

const OneLiner: React.FC<OneLinerProps> = ({
  entityName,
  problemOutline,
  keyOffering,
  targetMarket,
  valueProposition,
}) => {
  return (
    <MDTypography variant="body2">
      {entityName} is tackling the{" "}
      <span style={{ fontWeight: "500", margin: "0 4px", color: "#000000" }}>{problemOutline}</span>{" "}
      by offering{" "}
      <span style={{ fontWeight: "500", margin: "0 4px", color: "#000000" }}>{keyOffering}</span>{" "}
      which enables{" "}
      <span style={{ fontWeight: "500", margin: "0 4px", color: "#000000" }}>{targetMarket}</span>{" "}
      to{" "}
      <span style={{ fontWeight: "500", margin: "0 4px", color: "#000000" }}>
        {valueProposition}
      </span>
      .
    </MDTypography>
  );
};

export default OneLiner;
