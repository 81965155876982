import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
import Icon from "@mui/material/Icon";
import { useAuth } from "context/AuthContext";
import DetailCard from "./components/DetailCard";
import OneLiner from "./components/OneLiner";
import TeamMemberCard from "./components/TeamMemberCard";
import ComingSoonCard from "./components/ComingSoonCard";
import InvestorMap from "./components/InvestorMap"; // World Map Placeholder
import FavoritedBusinessCard from "./components/FavoritedBusinessCard";
import editIcon from "assets/images/startup-profile/account-edit.svg";
import avatarImage from "assets/avatar.jpeg"; // Later to be dynamic
import blank_profile from "assets/blank_profile.png"; // Later to be dynamic
import { Twitter, Facebook, Instagram, Language } from "@mui/icons-material";
import AddTeamMemberModal from "./components/AddTeamMemberModal";
import DefaultItem from "components/refined/Items/DefaultItem";
import logoPlaceholder from "assets/images/investor-profile/logo_placeholder.png";
import threedbros from "assets/images/investor-profile/threedbros.png";
import neuralink from "assets/images/investor-profile/neuralink.png";
import agriaero from "assets/images/investor-profile/agriaero.png";
import { getBusinesses } from "services/ventureApi";
import { Business } from "types/businessTypes";
//import { useFavorites } from "context/FavoritesContext";

const placeholderBusiness = {
  logo_url: logoPlaceholder,
  entity_name: "Your Favourite Business",
  one_liner_details: {
    keyOffering: "Various solutions",
    businessName: "Your Favourite Business",
    targetMarket: "General audience",
    problemOutline: "Lack of centralization",
    valueProposition: "Bring all your favorites into one place",
  },
  action: {
    type: "internal" as const,
    route: "/investor-portfolio",
    color: "success" as const,
    label: "View Portfolio",
  },
};

interface TeamMember {
  name: string;
  role: string;
  imgSrc: string;
  canMessage: boolean;
}
interface OneLinerDetails {
  keyOffering: string;
  businessName: string;
  targetMarket: string;
  problemOutline: string;
  valueProposition: string;
}

interface Action {
  type: "internal" | "external";
  route: string;
  color:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark"
    | "white";
  label: string;
}

interface FavoritedBusiness {
  logo_url: string;
  entity_name: string;
  one_liner_details: OneLinerDetails;
  action: Action;
}

const favoritedBusinesses: FavoritedBusiness[] = [
  {
    logo_url: logoPlaceholder,
    entity_name: "Your Favourite Business",
    one_liner_details: {
      keyOffering: "Various solutions",
      businessName: "Your Favourite Business",
      targetMarket: "General audience",
      problemOutline: "Lack of centralization",
      valueProposition: "Bring all your favorites into one place",
    },
    action: {
      type: "internal",
      route: "/investor-portfolio",
      color: "success",
      label: "View Portfolio",
    },
  },
  {
    logo_url: threedbros,
    entity_name: "3D Bros",
    one_liner_details: {
      keyOffering: "Innovative 3D printing",
      businessName: "3D Bros",
      targetMarket: "Tech enthusiasts",
      problemOutline: "High cost of traditional construction",
      valueProposition: "Provide affordable housing with 3D printing",
    },
    action: {
      type: "internal",
      route: "/business/startup-1",
      color: "success",
      label: "View Business",
    },
  },
  {
    logo_url: neuralink,
    entity_name: "Neuralink",
    one_liner_details: {
      keyOffering: "Neural interface technology",
      businessName: "Neuralink",
      targetMarket: "Health and tech industry",
      problemOutline: "Bridging the gap between humans and machines",
      valueProposition: "Enable seamless human-computer interaction",
    },
    action: {
      type: "internal",
      route: "/business/startup-2",
      color: "success",
      label: "View Business",
    },
  },
  {
    logo_url: agriaero,
    entity_name: "AgriAero",
    one_liner_details: {
      keyOffering: "Drone-based agricultural analytics",
      businessName: "AgriAero",
      targetMarket: "Farmers",
      problemOutline: "Inefficient crop monitoring",
      valueProposition: "Optimize crop health management with real-time data",
    },
    action: {
      type: "internal",
      route: "/business/startup-3",
      color: "success",
      label: "View Business",
    },
  },
];

const About: React.FC = () => {
  const { loginResponse, user } = useAuth();
  //const { favoritedBusinesses } = useFavorites();
  const [favoritedBusinesses, setFavoritedBusinesses] = useState<Business[]>([]);
  const [openAddTeamModal, setOpenAddTeamModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleOpenModal = () => {
    setOpenAddTeamModal(true);
  };

  const handleCloseModal = () => {
    setOpenAddTeamModal(false);
  };

  const userData = loginResponse ? loginResponse.entity : null;
  const industry =
    userData?.additional_info?.mandateDetails?.industryDetails?.[0]?.industrySection ||
    "Loading...";
  const investmentStage =
    userData?.additional_info?.mandateDetails?.stages?.join(", ") || "Loading...";
  const investorType = userData?.additional_info?.entityDetails?.investorType || "Loading...";
  const ticketSize = `${userData?.additional_info?.mandateDetails?.ticketSizeCurrency || ""} ${
    userData?.additional_info?.mandateDetails?.ticketSizeTypical || "Unknown"
  }`; //
  const portfolioSize = userData?.additional_info?.investmentDetails?.portfolioSize || "Unknown";
  const portfolioValuation = `${
    userData?.additional_info?.investmentDetails?.portfolioValuationCurrency || ""
  } ${userData?.additional_info?.investmentDetails?.portfolioValuation || "Unknown"}`;

  const investorData = loginResponse?.entity?.additional_info?.oneLinerDetails || {};

  const location = userData?.additional_info?.entityDetails?.city || "Unknown Location";
  const establishmentDate =
    userData?.additional_info?.entityDetails?.establishmentDate || "Unknown Date";

  const investmentGeographies =
    userData?.additional_info?.mandateDetails?.investmentGeographies || [];

  const teamMembers: TeamMember[] =
    userData?.additional_info?.new_team_members?.map((member: any) => ({
      name: `${member.first_name} ${member.last_name}`,
      role: member.role,
      imgSrc: blank_profile,
      canMessage: true,
    })) || [];

  useEffect(() => {
    const fetchFavoritedBusinesses = async () => {
      try {
        if (userData?.entity_id) {
          setLoading(true);
          const response = await getBusinesses(userData.entity_id);
          console.log("Fetched Businesses:", response);

          const favoriteBusinesses = response.favourited_entities || [];
          setFavoritedBusinesses(favoriteBusinesses);
        }
      } catch (error) {
        console.error("Error fetching favorited businesses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFavoritedBusinesses();
  }, [userData?.entity_id]);

  return (
    <Card
      sx={{
        mt: -3,
        p: 1,
        mx: 3,
        mb: 2,
      }}
    >
      <Grid container spacing={2}>
        {/* Left Column: Detail Cards */}
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3, boxShadow: "none" }}>
            <MDBox>
              <MDTypography variant="h5" fontWeight="medium" mb={2}>
                About
              </MDTypography>

              <Grid container spacing={2.5}>
                <Grid item xs={12}>
                  <DefaultItem
                    icon="rocket"
                    title="Startup Stage Requirement"
                    description={investmentStage || "Not available"}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DefaultItem
                    icon="rocket"
                    title="Industry Section"
                    description={industry || "Not available"}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DefaultItem
                    icon="person"
                    title="Investor Type"
                    description={investorType || "Not available"}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DefaultItem
                    icon="money"
                    title="Typical Investment Size"
                    description={ticketSize || "Not available"}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DefaultItem
                    icon="folder"
                    title="Portfolio Size"
                    description={portfolioSize || "Not available"}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DefaultItem
                    icon="assessment"
                    title="Portfolio Valuation"
                    description={portfolioValuation || "Not available"}
                    color="primary"
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>

        {/* Middle Column: Profile Information and World Map Placeholder */}
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3, boxShadow: "none" }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium" mb={2}>
                  Profile Information
                </MDTypography>
              </Grid>
              {/*
              <Grid item>
                <MDBox component="img" src={editIcon} alt="edit" sx={{ width: 24 }} />
              </Grid>
              */}
            </Grid>
            <OneLiner
              geographicFocus={investorData.geographicFocus || "Loading..."}
              investmentStage={investorData.investmentStage || "Loading..."}
              investmentImpact={investorData.investmentImpact || "Loading..."}
              targetIndustries={investorData.targetIndustries || "Loading..."}
            />
            {/* Placeholder for World Map 
            <MDTypography variant="subtitle2" fontWeight="medium" mt={2}>
              Socials: &nbsp;
              <Language sx={{ mx: 0.5 }} />
              <Twitter sx={{ mx: 0.5 }} />
              <Instagram sx={{ mx: 0.5 }} />
              <Facebook sx={{ mx: 0.5 }} />
            </MDTypography>
            */}

            {/* Physical Location */}
            <MDTypography variant="subtitle2" fontWeight="medium" mt={1}>
              Physical Location:&nbsp;
              <MDTypography component="span" variant="body2" fontWeight="regular">
                {location}
              </MDTypography>
            </MDTypography>

            {/* Establishment Date */}
            <MDTypography variant="subtitle2" fontWeight="medium" mt={1}>
              Establishment Date:&nbsp;
              <MDTypography component="span" variant="body2" fontWeight="regular">
                {establishmentDate}
              </MDTypography>
            </MDTypography>

            {/* Investment Geographies */}
            <MDTypography variant="subtitle2" fontWeight="medium" mt={3}>
              Investment Geographies:
            </MDTypography>

            <MDBox mt={4}>
              <InvestorMap countries={investmentGeographies} />
            </MDBox>
          </Card>
        </Grid>

        {/* Right Column: Team Section */}
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3, boxShadow: "none" }}>
            <MDTypography variant="h5" fontWeight="medium" mb={2}>
              Team
            </MDTypography>
            {teamMembers.length > 0 ? (
              teamMembers.map((member: TeamMember) => (
                <TeamMemberCard
                  key={member.name}
                  name={member.name}
                  role={member.role}
                  imgSrc={member.imgSrc}
                  canMessage={member.canMessage}
                  isAdmin={user?.role === "Administrator"}
                />
              ))
            ) : (
              <MDTypography variant="body2">No team members available</MDTypography>
            )}
            {user?.role === "Administrator" && (
              <MDBox mt={3} maxWidth="60%" mx="auto">
                <MDButton variant="gradient" color="dark" fullWidth onClick={handleOpenModal}>
                  <Icon>add</Icon>
                  &nbsp;Add Team Member
                </MDButton>
              </MDBox>
            )}
          </Card>
        </Grid>
      </Grid>

      {/* Coming Soon Section */}
      <Grid container spacing={1} mt={1}>
        <Grid item xs={12}>
          <Card sx={{ p: 3, boxShadow: "none" }}>
            <MDTypography variant="h5" fontWeight="medium" mb={2}>
              Favorite Startups
            </MDTypography>
            <Grid container spacing={3}>
              {loading ? (
                // Display a loading indicator while fetching
                <Grid item xs={12}>
                  <MDTypography variant="body1">Loading favorited businesses...</MDTypography>
                </Grid>
              ) : favoritedBusinesses.length > 0 ? (
                favoritedBusinesses.map((business) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={`favorited-${business.entity_id}`}>
                    <FavoritedBusinessCard
                      logo_url={business.logo_url}
                      entity_name={business.entity_name}
                      one_liner_details={business.one_liner_details}
                      //action={business.action}
                    />
                  </Grid>
                ))
              ) : (
                // Display placeholder if no businesses are favorited
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FavoritedBusinessCard
                    logo_url={placeholderBusiness.logo_url}
                    entity_name={placeholderBusiness.entity_name}
                    one_liner_details={placeholderBusiness.one_liner_details}
                    action={placeholderBusiness.action}
                    placeholderMessage="No businesses have been favorited yet. Start exploring and favorite your preferred businesses."
                  />
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <AddTeamMemberModal open={openAddTeamModal} handleClose={handleCloseModal} />
    </Card>
  );
};

export default About;
