import React, { useState, useEffect } from "react";
import { Grid, Typography, Radio, FormControlLabel } from "@mui/material";
import MDInput from "components/generic/MDInput";
import { OnelinerDetailsProps } from "../../types";

const problemMaturityOptions = [
  "Unrecognized Problem",
  "Emerging Problem",
  "Well-defined Problem",
  "Resolved Problem",
  "Solution Defined Problem",
  "In-Progress Problem",
  "Researched Problem",
  "Continuous Improvement Problem",
  "Other",
];

const Onelinerdetails2: React.FC<OnelinerDetailsProps> = ({ formData, setFormData }) => {
  const [problemMaturity, setProblemMaturity] = useState<string>(
    formData.oneLinerDetails?.problemMaturity || ""
  );
  const [selectedOption, setSelectedOption] = useState<string>(
    formData.oneLinerDetails?.selectedOption || "No"
  );
  const [inputValue, setInputValue] = useState<string>(
    formData.oneLinerDetails?.problemMaturityOtherInput || ""
  );

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      oneLinerDetails: {
        ...prevData.oneLinerDetails,
        problemMaturity,
        selectedOption,
        problemMaturityOtherInput: inputValue,
      },
    }));
  }, [problemMaturity, selectedOption, inputValue, setFormData]);

  const handleProblemMaturityChange = (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    setProblemMaturity(value);
    if (value === "No" || value === "Other") {
      setInputValue("");
    }
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
    if (value === "No") {
      setInputValue("");
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <Grid container px={{ xs: 2, lg: 5 }} spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Problem Maturity</Typography>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Please select the option that best describes the maturity of the need / problem that you
          are trying to resolve:
        </Typography>
      </Grid>
      {problemMaturityOptions.map((option) => (
        <Grid item md={6} key={option}>
          <FormControlLabel
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "14px",
                color: "#7B809A",
                fontWeight: "400",
              },
            }}
            value={option}
            control={
              <Radio
                sx={{
                  "& .MuiSvgIcon-root": {
                    borderRadius: "4px",
                  },
                  "&:after": {
                    borderRadius: "2px",
                  },
                }}
              />
            }
            label={
              option === "Other" && selectedOption === "Other" ? (
                <MDInput value={inputValue} onChange={handleInputChange} label="Other" />
              ) : (
                option
              )
            }
            onChange={handleProblemMaturityChange}
            checked={problemMaturity === option}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Onelinerdetails2;
