export const businessPermissions: string[] = [
  "sign-in",
  "reset-password",
  "new-password",
  "reset-code",
  "home",
  "business-questionnaire",
  "business-profile",
  "user-profile-settings",
  "user-profile-settings-page",
  "divider-0",
];

export const investorPermissions: string[] = [
  "sign-in",
  "reset-password",
  "new-password",
  "reset-code",
  "home",
  "investor-questionnaire",
  "investor-profile",
  "user-profile-settings",
  "user-profile-settings-page",
  "divider-0",
  "investor-portfolio",
  "divider-investor-portfolio",
];

export const administratorPermissions: string[] = [
  "sign-in",
  "reset-password",
  "new-password",
  "reset-code",
  //"sign-up",
  "home",
  "investor-questionnaire",
  "business-questionnaire",
  "user-email-confirmation",
  "profile-building-agreement",
  "welcome-page",
  "business-profile",
  "investor-profile",
  "divider-0",
  "user-profile-settings",
  "user-profile-settings-page",
];

export const publicPermissions: string[] = [
  "sign-in",
  "reset-password",
  "new-password",
  "reset-code",
  "sign-up",
  "home",
  "investor-questionnaire",
  "business-questionnaire",
  "user-email-confirmation",
  "profile-building-agreement",
  "welcome-page",
  "business-profile",
];

const userPermissions: string[] = [
  "brooklyn-alice",
  "my-profile",
  "profile-settings",
  "logout",
  "dashboards",
  "analytics",
  "sales",
  "sign-in",
  "authentication",
  "loading",
  "home",
  "sign-out",
];

const externalPermissions: string[] = [
  "title-docs",
  "basic",
  "getting-started",
  "overview",
  "license",
  "quick-start",
  "build-tools",
  "foundation",
  "colors",
  "grid",
  "base-typography",
  "borders",
  "box-shadows",
  "functions",
  "routing-system",
  "components",
  "alerts",
  "avatar",
  "badge",
  "badge-dot",
  "box",
  "buttons",
  "date-picker",
  "dropzone",
  "editor",
  "input",
  "pagination",
  "progress",
  "snackbar",
  "social-button",
  "typography",
  "sign-in",
  "authentication",
  "dashboards",
  "analytics",
  "sales",
  "loading",
  "home",
  "sign-out",
];
/*
export const publicPermissions: string[] = [
  "changelog",
  "unauthorized-access",
  "dashboards",
  "analytics",
  "sales",
  "sign-in",
  "authentication",
  "loading",
  "home",
  "sign-out",
];
*/

export const allPermissions: string[] = [
  "brooklyn-alice",
  "my-profile",
  "profile-settings",
  "logout",
  "divider-0",
  "dashboards",
  "analytics",
  "sales",
  "title-pages",
  "pages",
  "profile",
  "profile-overview",
  "all-projects",
  "users",
  "new-user",
  "account",
  "settings",
  "billing",
  "invoice",
  "projects",
  "timeline",
  "pricing-page",
  "rtl",
  "widgets",
  "charts",
  "notifications",
  "applications",
  "kanban",
  "wizard",
  "data-tables",
  "calendar",
  "ecommerce",
  "products",
  "new-product",
  "edit-product",
  "product-page",
  "orders",
  "order-list",
  "order-details",
  "authentication",
  "sign-in",
  "basic",
  "cover",
  "illustration",
  "sign-up",
  "reset-password",
  "divider-1",
  "title-docs",
  "basic",
  "getting-started",
  "overview",
  "license",
  "quick-start",
  "build-tools",
  "foundation",
  "colors",
  "grid",
  "base-typography",
  "borders",
  "box-shadows",
  "functions",
  "routing-system",
  "components",
  "alerts",
  "avatar",
  "badge",
  "badge-dot",
  "box",
  "buttons",
  "date-picker",
  "dropzone",
  "editor",
  "input",
  "pagination",
  "progress",
  "snackbar",
  "social-button",
  "typography",
  "changelog",
  "unauthorized-access",
  "loading",
  "home",
];

export const dummyUsers = [
  {
    username: "admin",
    password: "admin",
    role: "admin",
    //permissions: ["dashboard", "profile-overview", "settings", "analytics", "sales"],
    permissions: administratorPermissions,
  },
  {
    username: "user",
    password: "user",
    role: "user",
    permissions: userPermissions,
  },
  {
    username: "external",
    password: "external",
    role: "external",
    permissions: externalPermissions,
  },
];

//all permissions in template:
