import { ReactNode } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import backgroundImage from "assets/images/reisiger/profileHeader.png";
import profileStartImg from "assets/images/reisiger/profile_start.png";
import MDTypography from "components/generic/MDTypography";
import DashboardNavbar from "components/refined/Navbars/DashboardNavbar";

interface HeaderProps {
  children?: ReactNode;
}

function Header({ children }: HeaderProps): JSX.Element {
  return (
    <MDBox position="relative" mb={2} mt={2}>
      <DashboardNavbar />
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        minHeight="15rem"
        borderRadius="xl"
        sx={{
          //backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
          backgroundColor: "#ffffff",
        }}
      >
        <MDTypography variant="h4" sx={{ color: "#386A11" }} mb={-3}>
          {" "}
          {/*color=primary/secondary??*/}
          Let’s Build Your Profile
        </MDTypography>
      </MDBox>
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: {
            xs: 1,
            lg: 3,
          },
          py: 2,
          px: {
            xs: 3,
            lg: 2,
          },
          backgroundImage: `url(${profileStartImg})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",

          overflow: "hidden",
          minHeight: {
            xs: "fit-content",
            sm: "fit-content",
            lg: "736px",
            xl: "736px",
          },
        }}
      >
        {children}
      </Card>
    </MDBox>
  );
}

Header.defaultProps = {
  children: null,
};

export default Header;
