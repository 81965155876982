interface Subtask {
  id: string;
  name: string;
  status: {
    status: string;
    color: string;
  };
  due_date: string | null;
  start_date: string | null;
}

interface Task {
  id: string;
  name: string;
  status: {
    status: string;
    color: string;
  };
  priority: string;
  due_date: string | null;
  start_date: string | null;
  //subtasks: Subtask[]; //no such property
  parent: string;
  project: {
    name: string;
  };
  assignees: {
    name: string;
  };
}

export const prepareTaskSummationData = (tasks: Task[]) => {
  // Helper function to get the total range of dates
  const getDateRange = (tasks: Task[]) => {
    const allDates = tasks.flatMap((task) => {
      const startDate = task.start_date ? new Date(parseInt(task.start_date, 10)) : null;
      const dueDate = task.due_date ? new Date(parseInt(task.due_date, 10)) : null;
      return [startDate, dueDate].filter(Boolean) as Date[];
    });

    const minDate = new Date(Math.min(...allDates.map((date) => date.getTime())));
    const maxDate = new Date(Math.max(...allDates.map((date) => date.getTime())));
    return { minDate, maxDate };
  };

  // Helper function to generate labels based on the date range
  const generateLabels = (range: { minDate: Date; maxDate: Date }, interval: string) => {
    const labels: string[] = [];
    let currentDate = new Date(range.minDate);

    while (currentDate <= range.maxDate) {
      if (interval === "day") {
        labels.push(currentDate.toISOString().split("T")[0]); // 'YYYY-MM-DD'
        currentDate.setDate(currentDate.getDate() + 1);
      } else if (interval === "week") {
        labels.push(`Week ${getISOWeek(currentDate)}`);
        currentDate.setDate(currentDate.getDate() + 7);
      } else if (interval === "month") {
        labels.push(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
    }
    return labels;
  };

  // Calculate the ISO week number for week labels
  const getISOWeek = (date: Date) => {
    const target = new Date(date.valueOf());
    const dayNumber = (date.getDay() + 6) % 7;
    target.setDate(target.getDate() - dayNumber + 3);
    const firstThursday = target.valueOf();
    target.setMonth(0, 1);
    if (target.getDay() !== 4) {
      target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
    }
    return 1 + Math.ceil((firstThursday - target.getTime()) / 604800000);
  };

  // Helper function to determine if the X-axis should be daily, weekly, or monthly
  const determineInterval = (range: { minDate: Date; maxDate: Date }) => {
    const diffInDays = (range.maxDate.getTime() - range.minDate.getTime()) / (1000 * 3600 * 24);

    if (diffInDays <= 7) return "day"; // Less than or equal to a week
    if (diffInDays <= 30) return "week"; // More than a week but less than or equal to a month
    return "month"; // More than a month
  };

  // Create a task map by project name
  const taskMapByProject: {
    [projectName: string]: {
      [key: string]: { tasks: number; subtasks: number; total: number };
    };
  } = {};

  // Track parent tasks to adjust the total
  const parentTaskSet = new Set<string>();

  tasks.forEach((task) => {
    const projectName = task.assignees?.name ? "Venture Builder" : task.project?.name;
    const startDate = task.start_date ? new Date(parseInt(task.start_date, 10)) : null;
    const dueDate = task.due_date ? new Date(parseInt(task.due_date, 10)) : null;

    if (!taskMapByProject[projectName]) {
      taskMapByProject[projectName] = {};
    }

    const handleTaskForDate = (date: Date | null, isSubtask = false) => {
      if (!date) return;
      const dateKey = date.toISOString().split("T")[0];

      if (!taskMapByProject[projectName][dateKey]) {
        taskMapByProject[projectName][dateKey] = { tasks: 0, subtasks: 0, total: 0 };
      }

      if (!isSubtask && !task.parent) {
        taskMapByProject[projectName][dateKey].tasks += 1; // Top-level task
      } else if (isSubtask) {
        taskMapByProject[projectName][dateKey].subtasks += 1; // Subtask
        // Track parent task to adjust total later
        if (task.parent) {
          parentTaskSet.add(task.parent);
        }
      }

      taskMapByProject[projectName][dateKey].total =
        taskMapByProject[projectName][dateKey].tasks +
        taskMapByProject[projectName][dateKey].subtasks;
    };

    if (!task.parent) {
      handleTaskForDate(startDate);
      handleTaskForDate(dueDate);
    } else {
      // Process the subtask and its parent's dates
      const parentTask = tasks.find((t) => t.id === task.parent);
      if (parentTask) {
        console.log(`Subtask ${task.name} will use parent ${parentTask.name} dates`);
        handleTaskForDate(new Date(parseInt(parentTask.start_date, 10)), true);
        handleTaskForDate(new Date(parseInt(parentTask.due_date, 10)), true);
      }
    }
  });

  // Adjust the total task count to exclude parent tasks that have subtasks
  parentTaskSet.forEach((parentId) => {
    tasks.forEach((task) => {
      if (task.id === parentId) {
        const projectName = task.project?.name || "Unknown Project";
        const startDate = task.start_date ? new Date(parseInt(task.start_date, 10)) : null;
        const dueDate = task.due_date ? new Date(parseInt(task.due_date, 10)) : null;

        if (startDate) {
          const dateKey = startDate.toISOString().split("T")[0];
          if (taskMapByProject[projectName][dateKey]) {
            taskMapByProject[projectName][dateKey].tasks -= 1; // Remove parent task
            taskMapByProject[projectName][dateKey].total -= 1;
          }
        }

        if (dueDate) {
          const dateKey = dueDate.toISOString().split("T")[0];
          if (taskMapByProject[projectName][dateKey]) {
            taskMapByProject[projectName][dateKey].tasks -= 1; // Remove parent task
            taskMapByProject[projectName][dateKey].total -= 1;
          }
        }
      }
    });
  });

  // Get the full date range
  const dateRange = getDateRange(tasks);
  const interval = determineInterval(dateRange); // Determine daily, weekly, or monthly
  const labels = generateLabels(dateRange, interval); // Generate the labels for the X-axis

  // Prepare datasets for each project
  const datasets = Object.keys(taskMapByProject).map((projectName) => {
    const projectData = taskMapByProject[projectName];
    const totalData = labels.map((label) => projectData[label]?.total || 0);

    return {
      label: projectName,
      color: "primary" as const, // Assuming the same color for all projects; adjust as needed
      data: totalData,
    };
  });

  return {
    labels,
    datasets,
  };
};
