import React, { useState } from "react";
//import axios from "axios"; // Temporary import; to be replaced by axiosInstance later
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "api/axiosInstance";
import endpoints from "api/endpoints";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDInput from "components/generic/MDInput";
import MDButton from "components/generic/MDButton";

// Authentication layout components
//import CoverLayout from "layouts/authentication/components/CoverLayout"; //not used in original

// Images
import bgImage from "assets/images/reisiger/profileBgImage.png";
import BasicLayout from "layouts/authentication/components/BasicLayout"; //already converted
import { ChevronLeft } from "@mui/icons-material";

// react-router-dom components
import Footer from "components/refined/Footer"; // maybe use authentication > components > footer (below) if this looks funny during testing.
//import Footer from "../components/Footer"

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const navigate = useNavigate();

  const handleResetRequest = async (email: string) => {
    try {
      console.log("Email in request:", email);
      /*
      const response = await axiosInstance.post(endpoints.auth.requestResetPassword, { email }); //establish naming of endpts later
      console.log("response.data");
      console.log(response.data);
      */
      console.log("In handle reset request - request not linked");
      // Handle success, maybe show a notification or update UI
    } catch (error: any) {
      // Handle error, maybe show a notification or error message
      console.error("Error requesting password reset:", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Wait for the reset request to complete
    await handleResetRequest(email);
    console.log("Navigating to reset code page");
    // Navigate to reset-code page with the email in the query params
    navigate(`/authentication/reset-password/reset-code?email=${encodeURIComponent(email)}`);
  };

  return (
    <BasicLayout image={bgImage}>
      <Card
        sx={{
          minWidth: {
            lg: 408, // theme.breakpoints.up('lg')
            xl: 408, // theme.breakpoints.up('xl')
          },
          mb: 2,
        }}
      >
        <MDBox
          variant="contained"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={{
            xs: 2, // theme.breakpoints.up('xs')
            sm: 2, // theme.breakpoints.up('sm')
            lg: 4.5, // theme.breakpoints.up('lg')
            xl: 4.5, // theme.breakpoints.up('xl')
          }}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          {/* <MDTypography display="block" variant="button" color="white" my={1}>
              You will receive an e-mail in maximum 60 seconds
            </MDTypography> */}
        </MDBox>
        <MDBox pt={4} pb={2} px={3}>
          <form onSubmit={handleSubmit}>
            <MDBox>
              <MDBox mb={4}>
                <MDInput
                  type="email"
                  label="Email"
                  value={email}
                  variant="standard"
                  placeholder="Type in here…"
                  fullWidth
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                />
              </MDBox>
              <MDBox mb={1}>
                <MDButton
                  //component={Link}
                  //to="/authentication/reset-password/reset-code"
                  type="submit"
                  sx={{ textTransform: "uppercase" }}
                  variant="gradient"
                  color="dark"
                  fullWidth
                >
                  send reset request
                </MDButton>
              </MDBox>
              <MDBox mt={2}>
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="text"
                  fontWeight="regular"
                  fullWidth
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ChevronLeft fontSize="medium" /> Back to Sign In
                </MDTypography>
              </MDBox>
            </MDBox>
          </form>
        </MDBox>
      </Card>
      <Footer />
    </BasicLayout>
  );
};

export default ResetPassword;
