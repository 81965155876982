import {
  ProfitAndLossApiResponse,
  FinancialSection,
  FinancialDataItem,
} from "../layouts/pages/business/business-profile/financial-overview/types";

export function transformProfitAndLossApiResponse(
  apiResponse: ProfitAndLossApiResponse
): FinancialSection[] {
  const sections: FinancialSection[] = [];
  console.log("Reports", apiResponse.Reports);

  const report = apiResponse.Reports.find((r) => r.ReportID === "ProfitAndLoss");
  console.log("report in transform:", report);
  if (!report) {
    return sections;
  }

  const rows = report.Rows;
  console.log("rows in report transform", rows);

  // Iterate over top-level rows
  for (const row of rows) {
    if (row.RowType === "Section" && row.Rows) {
      const sectionTitle = row.Title || "";
      const items: FinancialDataItem[] = [];

      // Iterate over rows within the section
      for (const subRow of row.Rows) {
        if (
          (subRow.RowType === "Row" || subRow.RowType === "SummaryRow") &&
          subRow.Cells &&
          subRow.Cells.length >= 3
        ) {
          const description = subRow.Cells[0]?.Value || "";
          const amountCurrentStr = subRow.Cells[1]?.Value || "0";
          const amountPreviousStr = subRow.Cells[2]?.Value || "0";

          const amountCurrent = parseFloat(amountCurrentStr.replace(/,/g, ""));
          const amountPrevious = parseFloat(amountPreviousStr.replace(/,/g, ""));

          const dataItem: FinancialDataItem = {
            category: sectionTitle,
            subcategory: description,
            amountCurrent: isNaN(amountCurrent) ? null : amountCurrent,
            amountPrevious: isNaN(amountPrevious) ? null : amountPrevious,
            isHeader: subRow.RowType === "SummaryRow",
          };

          items.push(dataItem);
        }
      }

      // Add the section if it has items
      if (items.length > 0) {
        sections.push({
          category: sectionTitle,
          items: items,
        });
      }
    } else if (row.RowType === "Section" && !row.Title && row.Rows) {
      // Handle sections without a title (e.g., Gross Profit, Net Profit)
      for (const subRow of row.Rows) {
        if (subRow.RowType === "Row" && subRow.Cells && subRow.Cells.length >= 3) {
          const description = subRow.Cells[0].Value;
          const amountCurrentStr = subRow.Cells[1].Value;
          const amountPreviousStr = subRow.Cells[2].Value;

          const amountCurrent = parseFloat(amountCurrentStr.replace(/,/g, ""));
          const amountPrevious = parseFloat(amountPreviousStr.replace(/,/g, ""));

          const dataItem: FinancialDataItem = {
            category: description,
            subcategory: null,
            amountCurrent: isNaN(amountCurrent) ? null : amountCurrent,
            amountPrevious: isNaN(amountPrevious) ? null : amountPrevious,
            isHeader: true,
          };

          // Create a section with the description as category
          sections.push({
            category: description,
            items: [dataItem],
          });
        }
      }
    }
  }

  return sections;
}
