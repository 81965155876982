import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addXeroToken, login } from "services/ventureApi"; // Assuming login is the API for updating session
import { useAuth } from "context/AuthContext";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import LoadingPage from "layouts/utility/loading-page"; // Existing loading component
import { useMaterialUIController } from "context";
import { businessPermissions } from "dummyUsers";

const OAuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const { dispatch } = useAuth(); // A function to dispatch loginResponse to AuthContext
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    const scope = urlParams.get("state");
    //const stateBase64 = urlParams.get("state");

    if (code && state && scope) {
      //vs state
      const parsedState = JSON.parse(decodeURIComponent(state));
      //const parsedState = JSON.parse(atob(state));
      const storedGUID = localStorage.getItem("oauthStateGUID");

      if (parsedState.guid === storedGUID) {
        console.log("OAuth state is valid. Proceeding with the integration...");

        const setupIntegration = async () => {
          try {
            //do add token call.
            //await addClickUpToken({ entity_id: parsedState.entityId, code, state: "string" });
            //console.log("post addClickUpToken");
            //init call -
            //await initialSetup(parsedState.entityId);
            //console.log("post initialSetup");
            console.log("pre add token");
            await addXeroToken(parsedState.entityId, code, "string");
            console.log("post add token");

            const newLoginResponse = await login({
              entity_id: parsedState.entityId,
              role_id: parsedState.roleId,
            });
            console.log("pre dispatch, new loginResponse", newLoginResponse);

            //dispatch login to context
            dispatch({
              type: "LOGIN",
              payload: {
                user: {
                  username: newLoginResponse.user_profile.email,
                  role: "Administrator",
                  permissions: new Set(businessPermissions),
                },
                entities: newLoginResponse.entities,
                loginResponse: newLoginResponse,
              },
            });

            localStorage.removeItem("oauthStateGUID");

            navigate(`/profile/business-profile?tab=1`); //cases for investor to add..
          } catch (error) {
            console.error("Error during integration setup:", error);
            // Handle error, maybe redirect to error page
          }
        };

        setupIntegration();
      } else {
        console.error("Invalid OAuth state GUID.");
        // oops page ?
      }
    } else {
      console.error("OAuth code or state missing.");
      // Handle missing OAuth parameters, e.g., redirect to an error page
    }
  }, [navigate]);

  if (error) {
    return (
      <MDBox px={2} py={4}>
        <MDTypography variant="h6" color="error" align="center">
          {error}
        </MDTypography>
      </MDBox>
    );
  }

  return <LoadingPage />;
};

export default OAuthCallback;
