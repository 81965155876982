import React, { useState } from "react";
import { Card, Grid, Modal, Autocomplete } from "@mui/material";
import { Close } from "@mui/icons-material";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
import MDInput from "components/generic/MDInput";
import FormField from "layouts/pages/account/components/FormField";
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import { newTeamMember } from "services/ventureApi";

interface AddTeamMemberModalProps {
  open: boolean;
  handleClose: () => void;
}

const AddTeamMemberModal: React.FC<AddTeamMemberModalProps> = ({ open, handleClose }) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [userType, setUserType] = useState<string>("Admin");

  const handleSubmit = async () => {
    const storedLoginData = JSON.parse(localStorage.getItem("loginResponse") || "{}");
    console.log("in submit - add team member");
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      role: role,
      user_type: userType,
      entity_id: storedLoginData.entity?.entity_id,
    };

    try {
      const response = await newTeamMember(data);
      console.log("response");
      console.log(response);
      handleClose();
    } catch (error) {
      console.error("Error adding new team member", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Card sx={{ minWidth: { lg: 840 }, p: 3 }}>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="primary"
          mx={3}
          mt={-6}
          px={2}
          py={3}
          mb={1}
          textAlign="center"
          position="relative"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Invite a team member
          </MDTypography>
          <MDBox
            color="white"
            sx={{ position: "absolute", right: "20px", top: "55%", transform: "translateY(-50%)" }}
          >
            <Close onClick={handleClose} sx={{ cursor: "pointer" }} fontSize="medium" />
          </MDBox>
        </MDBox>

        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="First Name"
                fullWidth
                value={firstName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Last Name"
                fullWidth
                value={lastName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email Address"
                fullWidth
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Role in Company"
                fullWidth
                value={role}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRole(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <Autocomplete
                defaultValue="Admin"
                options={selectData.role}
                renderInput={(params) => (
                  <FormField {...params} label="User Type" InputLabelProps={{ shrink: true }} />
                )}
                onChange={(event, newValue) => setUserType(newValue || "Admin")}
              />
            </MDBox>
            <MDBox display="flex" justifyContent="flex-end" mt={4}>
              <MDButton onClick={handleSubmit} variant="gradient" color="dark">
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </Modal>
  );
};

export default AddTeamMemberModal;
