import React from "react";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";

import { useMemo } from "react";
import { Line } from "react-chartjs-2";

import Icon from "@mui/material/Icon";
import configs from "components/refined/Charts/LineCharts/DefaultLineChart/configs";
import colors from "assets/theme/base/colors";

interface TaskDistributionChartProps {
  icon?: {
    color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
    component: React.ReactNode;
  };
  title?: string;
  description?: string;
  height?: string | number;
  chart: {
    labels: string[];
    datasets: {
      label: string;
      color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
      data: number[];
    }[];
  };
}

function TaskDistributionChart({
  icon,
  title = "Task Distribution",
  description = "",
  height = "19.125rem",
  chart,
}: TaskDistributionChartProps): JSX.Element {
  const chartDatasets = chart.datasets.map((dataset) => ({
    ...dataset,
    tension: 0.4, // Smoother curves
    pointRadius: 0,
    borderWidth: 4,
    backgroundColor: "transparent",
    fill: false, // Keep it line only
    pointBackgroundColor: colors[dataset.color]?.main || colors.dark.main,
    borderColor: colors[dataset.color]?.main || colors.dark.main,
    maxBarThickness: 6,
  }));
  const { data, options } = configs(chart.labels, chartDatasets);

  const customOptions = {
    ...options,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      ...options.scales,
      y: {
        ...options.scales.y,
        ticks: {
          ...options.scales.y.ticks,
          stepSize: 1, // Ensure that the Y-axis increments by 1
          callback: function (value: number | string) {
            return Number.isInteger(value) ? value : null; // Only display integers
          },
        },
      },
      x: {
        ...options.scales.x,
        ticks: {
          ...options.scales.x.ticks,
          maxRotation: 0, // Ensure horizontal labels on the X-axis
          minRotation: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          boxWidth: 3,
          usePointStyle: true,
          margin: 10,
        },
      },
      layout: {
        padding: {
          bottom: 60, // Adds gap between legend and chart
        },
      },
    },
  };

  const renderChart = (
    <MDBox py={1} pr={2} pl={icon?.component ? 1 : 2} width="100%">
      {useMemo(
        () => (
          <MDBox height={height} width="100%" px={2} py={1}>
            {" "}
            <Line data={data} options={customOptions} />
          </MDBox>
        ),
        [chart, height]
      )}
    </MDBox>
  );

  return (
    <Card sx={{ mb: 3 }}>
      <MDBox p={3}>
        {title && (
          <MDTypography variant="h6" gutterBottom>
            {title}
          </MDTypography>
        )}
        <MDBox
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          {renderChart}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default TaskDistributionChart;
