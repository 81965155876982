import React, { useEffect } from "react";
import "./GanttChart.css";

// Define the types for each task
interface Task {
  name: string;
  startDate?: string;
  endDate?: string;
  color: string;
}

// Define the props for the GanttChart component
interface GanttChartProps {
  tasks: Task[];
  projectStartDate: string;
  componentAreaBackground?: string;
  legendItemColor?: string;
  datesColor?: string;
  taskBackgroundColor?: string;
  onTaskClick?: (task: Task) => void;
}

const GanttChart: React.FC<GanttChartProps> = ({
  tasks,
  projectStartDate,
  componentAreaBackground = "#ffffff", //"linear-gradient(195deg, #00DC82, #01CF7B)"
  legendItemColor = "#C7CCD0",
  datesColor = "#C7CCD0",
  taskBackgroundColor = "#85B526",
  onTaskClick,
}) => {
  useEffect(() => {
    drawGanttChart();
  }, [tasks]);

  const parseDate = (dateString?: string): Date => {
    if (!dateString) return new Date(projectStartDate);
    const parts = dateString.split("-");
    return new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));
  };

  const formatDate = (date: Date | undefined): string => {
    if (!date) return "N/A";
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month < 10 ? "0" + month : month}/${day < 10 ? "0" + day : day}`;
  };

  const calculateBufferDays = (totalDays: number): number => {
    let bufferDays = Math.ceil(totalDays * 0.1);
    if (totalDays < 7) {
      bufferDays = Math.round(bufferDays);
    } else if (totalDays < 30) {
      bufferDays = Math.ceil(bufferDays / 7) * 7;
    } else {
      const months = Math.ceil(bufferDays / 30);
      bufferDays = months * 30;
    }
    return bufferDays;
  };

  const drawGanttChart = (): void => {
    const chart = document.getElementById("ganttChart");
    if (!chart) return;

    chart.innerHTML = "";
    chart.style.height = "auto";

    const validTasks = tasks.filter((task) => task.startDate && task.endDate);
    if (validTasks.length === 0) return;

    const startDate = new Date(
      Math.min(...validTasks.map((task) => parseDate(task.startDate).getTime()))
    );
    const endDate = new Date(
      Math.max(...validTasks.map((task) => parseDate(task.endDate).getTime()))
    );
    const totalDays = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

    const bufferDays = calculateBufferDays(totalDays);
    const extendedEndDate = new Date(endDate);
    extendedEndDate.setDate(endDate.getDate() + bufferDays);
    const totalExtendedDays =
      (extendedEndDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

    const startYear = startDate.getFullYear();
    const endYear = extendedEndDate.getFullYear();

    const yearLabel = document.getElementById("yearLabel");
    if (yearLabel) {
      yearLabel.innerHTML =
        startYear === endYear ? startYear.toString() : `${startYear} - ${endYear}`;
    }

    const taskLegends = document.getElementById("taskLegends");
    if (taskLegends) {
      taskLegends.innerHTML = "";
      validTasks.forEach((task) => {
        const taskLegend = document.createElement("div");
        taskLegend.className = "legend-item";
        taskLegend.style.height = "30px";
        taskLegend.style.lineHeight = "30px";
        taskLegend.style.borderLeftColor = task.color;
        taskLegend.style.borderTopColor = task.color;
        taskLegend.style.borderBottomColor = task.color;
        taskLegend.innerText = task.name;
        taskLegends.appendChild(taskLegend);
      });
    }

    validTasks.forEach((task, index) => {
      const taskStartDate = parseDate(task.startDate);
      const taskEndDate = parseDate(task.endDate);
      const taskDays = (taskEndDate.getTime() - taskStartDate.getTime()) / (1000 * 60 * 60 * 24);
      const taskOffset = (taskStartDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

      const trackElement = document.createElement("div");
      trackElement.className = "track";
      trackElement.style.top = `${index * 35}px`;
      trackElement.style.left = `${(taskOffset / totalExtendedDays) * 100}%`;
      trackElement.style.width = "0%";
      chart.appendChild(trackElement);

      const taskElement = document.createElement("div");
      taskElement.className = "task";
      taskElement.style.backgroundColor = taskBackgroundColor;
      taskElement.style.top = `${index * 35}px`;
      taskElement.style.left = `${(taskOffset / totalExtendedDays) * 100}%`;
      taskElement.style.width = "0%";
      taskElement.setAttribute(
        "data-tooltip",
        `${task.name}: ${formatDate(taskStartDate)} - ${formatDate(taskEndDate)}`
      );
      chart.appendChild(taskElement);

      taskElement.addEventListener("mouseover", () => {
        taskElement.classList.add("active");
        trackElement.classList.add("active");
      });
      taskElement.addEventListener("mouseout", () => {
        taskElement.classList.remove("active");
        trackElement.classList.remove("active");
      });

      setTimeout(() => {
        trackElement.style.width = `${(taskDays / totalExtendedDays) * 100}%`;
        taskElement.style.width = `${(taskDays / totalExtendedDays) * 100}%`;
      }, 100);
    });

    const dateLabels = document.getElementById("dateLabels");
    if (dateLabels) {
      dateLabels.innerHTML = "";

      const oneMonth = 30;
      const oneWeek = 7;

      if (totalDays > oneMonth) {
        const months = Math.ceil(totalExtendedDays / oneMonth);
        for (let i = 0; i < months; i++) {
          const dateLabel = document.createElement("div");
          dateLabel.className = "month";
          const date = new Date(startDate.getFullYear(), startDate.getMonth() + i, 1);
          dateLabel.style.flex = "1";
          dateLabel.innerHTML = date.toLocaleString("default", { month: "short", year: "numeric" });
          dateLabels.appendChild(dateLabel);
        }
      } else {
        const weeks = Math.ceil(totalExtendedDays / oneWeek);
        for (let i = 0; i < weeks; i++) {
          const dateLabel = document.createElement("div");
          dateLabel.className = "month";
          const date = new Date(startDate.getTime());
          date.setDate(startDate.getDate() + i * oneWeek);
          dateLabel.style.flex = "1";
          dateLabel.innerHTML = formatDate(date);
          dateLabels.appendChild(dateLabel);
        }
      }
    }
  };

  return (
    <div className="componentArea" style={{ background: componentAreaBackground }}>
      <div className="container">
        <div className="legends" id="taskLegends" style={{ color: legendItemColor }}></div>
        <div className="gantt" id="ganttChart"></div>
      </div>
      <div className="dates" style={{ color: datesColor }}>
        <div className="year" id="yearLabel"></div>
        <div className="months" id="dateLabels"></div>
      </div>
    </div>
  );
};

export default GanttChart;
