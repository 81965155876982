import React, { useEffect, useState, ChangeEvent } from "react";
import { Grid, Typography, Checkbox, FormControlLabel } from "@mui/material";
import MDInput from "components/generic/MDInput";
import { needsOptions, NeedsOptions } from "./data/needsOptions";
import { NeedsQuestionProps, NeedsDetails } from "../../types";
/*
interface NeedsDetails {
  selectedNeeds: string[];
  otherInputValue: string;
}

interface FormData {
  needsDetails?: NeedsDetails;
}

interface NeedsQuestionProps {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}
*/
const NeedsQuestion: React.FC<NeedsQuestionProps> = ({ formData, setFormData }) => {
  const [selectedNeeds, setSelectedNeeds] = useState<string[]>(
    formData.needsDetails?.selectedNeeds || []
  );
  const [otherInputValue, setOtherInputValue] = useState<string>(
    formData.needsDetails?.otherInputValue || ""
  );

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      needsDetails: {
        selectedNeeds,
        otherInputValue,
      },
    }));
  }, [selectedNeeds, otherInputValue, setFormData]);

  const handleNeedsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedNeed = event.target.value;
    const isChecked = event.target.checked;

    setSelectedNeeds((prevSelectedNeeds) => {
      if (isChecked) {
        return [...prevSelectedNeeds, selectedNeed];
      } else {
        return prevSelectedNeeds.filter((need) => need !== selectedNeed);
      }
    });

    // Clear the input value when "Other" is unchecked
    if (selectedNeed === "Other" && !isChecked) {
      setOtherInputValue("");
    }
  };

  const handleSubOptionChange = (event: ChangeEvent<HTMLInputElement>, parentOption: string) => {
    const selectedSubOption = event.target.value;
    const isChecked = event.target.checked;
    const fullOption = `${parentOption}-${selectedSubOption}`;

    setSelectedNeeds((prevSelectedNeeds) => {
      if (isChecked) {
        return [...prevSelectedNeeds, fullOption];
      } else {
        return prevSelectedNeeds.filter((need) => need !== fullOption);
      }
    });
  };

  const handleOtherInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOtherInputValue(event.target.value);
  };

  const columnSize = Math.ceil(Object.keys(needsOptions).length / 3);

  const renderOptionsColumn = (options: string[]) => (
    <Grid item md={4}>
      {options.map((option) => (
        <div key={option}>
          <FormControlLabel
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "12px",
                color: "#7B809A",
                fontWeight: "400",
              },
            }}
            control={
              <Checkbox checked={selectedNeeds.includes(option)} onChange={handleNeedsChange} />
            }
            label={option}
            value={option}
          />
          {option === "Other" && selectedNeeds.includes("Other") && (
            <MDInput
              sx={{ marginLeft: "20px" }}
              value={otherInputValue}
              onChange={handleOtherInputChange}
              label="Other"
            />
          )}
          {selectedNeeds.includes(option) &&
            needsOptions[option].length > 0 &&
            needsOptions[option].map((subOption) => (
              <FormControlLabel
                key={`${option}-${subOption}`}
                sx={{
                  marginLeft: "20px",
                  "& .MuiFormControlLabel-label": {
                    fontSize: "12px",
                    color: "#7B809A",
                    fontWeight: "400",
                  },
                }}
                control={
                  <Checkbox
                    checked={selectedNeeds.includes(`${option}-${subOption}`)}
                    onChange={(event) => handleSubOptionChange(event, option)}
                  />
                }
                label={subOption}
                value={subOption}
              />
            ))}
        </div>
      ))}
    </Grid>
  );

  return (
    <Grid container px={{ xs: 2, lg: 5 }} spacing={3}>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          What are your biggest needs or support requirements at present? (Please select all
          applicable categories)
        </Typography>
      </Grid>
      {[0, 1, 2].map((index) =>
        renderOptionsColumn(
          Object.keys(needsOptions).slice(index * columnSize, (index + 1) * columnSize)
        )
      )}
    </Grid>
  );
};

export default NeedsQuestion;
