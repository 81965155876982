import { Config } from "../types/authTypes";

const getConfig: Config = {
  apiUrl: process.env.REACT_APP_Api_Url,
  stage: process.env.REACT_APP_Stage,
  userPoolIdCognito: process.env.REACT_APP_UserPoolId_Cognito,
  clientIdCognito: process.env.REACT_APP_ClientId_Cognito,
  environment: process.env.REACT_APP_ENV,
  domainName: process.env.REACT_APP_INSTANCE,
};

console.log("Config Values:", getConfig); // Log config values to check

export default getConfig;
