import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Header from "../../../../components/shared/questionnaire/outer-page/OuterPage";
import MDBox from "components/generic/MDBox";
import MDButton from "components/generic/MDButton";
import DashboardLayout from "components/refined/LayoutContainers/DashboardLayout";
//import DashboardNavbar from "examples/Navbars/DashboardNavbar"; //not used
import StartupDetails from "layouts/pages/business/business-questionaire/sections/business-details";
import FounderDetails from "layouts/pages/business/business-questionaire/sections/founder-details";
import IndustryDetails from "layouts/pages/business/business-questionaire/sections/industry-details";
import SdgDetailsBusiness from "layouts/pages/business/business-questionaire/sections/sdg-details";
import Maturitydetails from "layouts/pages/business/business-questionaire/sections/maturity-details-1";
import Maturitydetails2 from "layouts/pages/business/business-questionaire/sections/maturity-details-2";
import Onelinerdetails from "layouts/pages/business/business-questionaire/sections/oneliner-details-1";
import Onelinerdetails2 from "layouts/pages/business/business-questionaire/sections/oneliner-details-2";
import Maketdetails from "layouts/pages/business/business-questionaire/sections/market-details";
//import FinancingDetails from "layouts/pages/profile/profile-startup-gq/components/sections/financingdetails/financingdetails1/index"; //not used
import FinancingHistory from "layouts/pages/business/business-questionaire/sections/financing-details/index";
import Needsdetails from "layouts/pages/business/business-questionaire/sections/needs-details";
//import Uploaddetails from "./components/sections/uploaddetails"; //not used
//import Feedback from "./components/Feedback"; //not used
//import Modal from "@mui/material/Modal"; //not used
import { Slide } from "@mui/material";
//import Leavepage from "./components/Leavepage"; //not used
import FinancingDetails2 from "layouts/pages/business/business-questionaire/sections/financing-details/financing-details-2";
import { useSkipStep } from "context/skipStepContext";
import Footer from "components/refined/Footer";
import FooterLogo from "../../../authentication/components/FooterLogo";
import { gqSteps } from "./../../../../services/ventureApi";
import { useNavigate } from "react-router-dom";
import { FormData } from "./types";
import { login as loginVentureApi } from "services/ventureApi";
import { useAuth } from "context/AuthContext";
import { businessPermissions } from "dummyUsers";

import { LoginResponse } from "types/authTypes";

function ProfileGQ() {
  const { loginResponse } = useAuth();
  const { dispatch } = useAuth();
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [subStepIndex, setSubStepIndex] = useState<number>(0);
  const [openFeedback, setOpenFeedback] = useState<boolean>(false);
  const [disabledval, setDisabledval] = useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    entityDetails: {
      businessName: "",
    },
    founderDetails: [],
    industryDetails: [],
    sdgDetails: [],
    maturityDetails: {},
    oneLinerDetails: {},
    marketDetails: {
      scaleOfOperations: "",
      businessMarketType: "",
      otherValue: "",
    },
    financingDetails: {
      recentFundingValuation: "",
      recentFundingValuationCurrency: "R",
      totalFundingRaised: "",
      totalFundingCurrency: "R",
      futureFundingNeed: "",
      futureFundingNeedCurrency: "R",
      futureFundingUse: "",
      futureFundingRequired: "",
      fundingReceived: "No",
      fundingBreakdown: {},
    },
    needsDetails: {
      selectedNeeds: [],
      otherInputValue: "",
    },
  });

  const [initialStep, setInitialStep] = useState<number>(0);
  const [isFounderAdded, setIsFounderAdded] = useState<boolean>(false);

  const handleNextDisabledChange = (disabled: boolean) => {
    setIsNextDisabled(disabled);
  };

  const navigate = useNavigate();

  const stepMapping: { [key: number]: { step: keyof FormData; status: string } } = {
    0: { step: "entityDetails", status: "PENDING_GQ_BUSINESS_FOUNDERDETAILS" },
    1: { step: "founderDetails", status: "PENDING_GQ_BUSINESS_INDUSTRYDETAILS" },
    2: { step: "industryDetails", status: "PENDING_GQ_BUSINESS_SDGDETAILS" },
    3: { step: "sdgDetails", status: "PENDING_GQ_BUSINESS_MATURITYDETAILS" },
    4: { step: "maturityDetails", status: "PENDING_GQ_BUSINESS_ONELINERDETAILS" },
    5: { step: "oneLinerDetails", status: "PENDING_GQ_BUSINESS_MARKETDETAILS" },
    6: { step: "marketDetails", status: "PENDING_GQ_BUSINESS_FINANCINGDETAILS" },
    7: { step: "financingDetails", status: "PENDING_GQ_BUSINESS_NEEDSDETAILS" },
    8: { step: "needsDetails", status: "BUSINESS_COMPLETED" },
  };
  //const loginResponse = localStorage.getItem("loginResponse");
  useEffect(() => {
    const initialState = localStorage.getItem("gq_state");
    //const loginResponse = localStorage.getItem("loginResponse");
    //const stateInit = loginResponse.state

    console.log("initial state", initialState);
    if (initialState) {
      const stepIndex = Object.values(stepMapping).findIndex((step) => step.step === initialState);
      console.log("stepIndex", stepIndex);
      if (stepIndex !== -1) {
        setActiveStep(stepIndex);
        setInitialStep(stepIndex); // Set the initial step
      }
    }
  }, []);

  function getSteps() {
    return [
      "STARTUP DETAILS",
      "FOUNDER DETAILS",
      "INDUSTRY",
      "SDG",
      "MATURITY",
      "ONE-LINER",
      "MARKET",
      "FINANCING",
      "NEEDS",
    ];
  }

  const getFromChild = (data: boolean) => {
    setDisabledval(data);
  };

  function getStepContent(stepIndex: number, subStepIndex: number | null = null) {
    const stepProps = {
      formData,
      setFormData,
      onAddFounder: setIsFounderAdded,
      setNextDisabled: setIsNextDisabled,
    };
    switch (stepIndex) {
      case 0:
        return <StartupDetails {...stepProps} />; //setNextDisabled={handleNextDisabledChange}
      case 1:
        return <FounderDetails {...stepProps} />;
      case 2:
        return <IndustryDetails {...stepProps} />;
      case 3:
        return <SdgDetailsBusiness {...stepProps} />;
      case 4:
        return subStepIndex === 0 ? (
          <Maturitydetails {...stepProps} />
        ) : (
          <Maturitydetails2 {...stepProps} />
        );
      case 5:
        return subStepIndex === 0 ? (
          <Onelinerdetails {...stepProps} />
        ) : (
          <Onelinerdetails2 {...stepProps} />
        );
      case 6:
        return <Maketdetails {...stepProps} />;
      case 7:
        return subStepIndex === 0 ? (
          <FinancingHistory {...stepProps} />
        ) : (
          <FinancingDetails2 {...stepProps} />
        );
      case 8:
        return <Needsdetails {...stepProps} />;
      default:
        return null;
    }
  }

  const { skipStep1, handleSkipStep } = useSkipStep();
  const [skipStep2, setSkipStep2] = useState<boolean>();

  const handleNext = async () => {
    const currentStep = stepMapping[activeStep];
    console.log(`Current Step: ${currentStep.step}, Substep: ${subStepIndex}`);
    //console.log(`Current Step: ${currentStep.step}`);
    console.log("Form Data:", formData);
    let response;
    try {
      console.log(`Data For API Call : for ${currentStep.step} =>`, formData[currentStep.step]);
      let formatData_currentStep = formData[currentStep.step];
      if (Array.isArray(formData[currentStep.step])) {
        formatData_currentStep = { [currentStep.step]: formData[currentStep.step] };
      }
      response = await gqSteps(currentStep.step, formatData_currentStep, currentStep.status);
      console.log("response from gqSteps", response);
      console.log(`Data for ${currentStep.step} sent to backend successfully.`);
    } catch (error) {
      console.error(`Error sending data for ${currentStep.step} to backend:`, error);
    }
    if (activeStep === 7 && response) {
      console.log("pre last step");
    }
    if (activeStep === 8 && response) {
      // Added check for response
      //navigate(response.landing_route);
      console.log("activeStep === 8 && response reached");
      console.log("pre navigate");
      const newLoginResponse = {
        user_profile: response.user_profile,
        entity: response.entity,
        entities: response.entities || [],
        state: response.state,
        landing_route: response.landing_route || "/profile/investor-profile",
      };
      console.log("new login response:", newLoginResponse);

      dispatch({
        type: "LOGIN",
        payload: {
          user: {
            username: response.user_profile.email,
            role: "Administrator",
            permissions: new Set(businessPermissions), // Update permissions to investor permissions
          },
          entities: newLoginResponse.entities,
          loginResponse: newLoginResponse, // Ensure loginResponse is updated with new response from gq
        },
      });

      // Navigate to the investor profile once the state is updated
      navigate("/profile/business-profile");
    }

    const hasSubsteps = activeStep === 4 || activeStep === 5 || activeStep === 7;

    if (hasSubsteps) {
      if (activeStep === 7) {
        if (formData.financingDetails.fundingReceived === "No") {
          // Skip Financing Details 2 and go to Needsdetails
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setSubStepIndex(0);
        } else if (subStepIndex === 1) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setSubStepIndex(0); // Move to Needsdetails
        } else {
          setSubStepIndex((prevSubStepIndex) => prevSubStepIndex + 1); // Go to Financing Details 2
        }
      } else if (subStepIndex === 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSubStepIndex(0); // Reset substep index
      } else {
        setSubStepIndex((prevSubStepIndex) => prevSubStepIndex + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (subStepIndex === 0 && activeStep !== 0) {
      if (activeStep > initialStep) {
        // Check if the active step is greater than the initial step
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        if (
          activeStep - 1 === 4 ||
          activeStep - 1 === 5 ||
          (skipStep1 ? activeStep - 1 === 6 : activeStep - 1 === 7)
        ) {
          setSubStepIndex(1);
        } else {
          setSubStepIndex(0);
        }
      }
    } else {
      setSubStepIndex((prevSubStepIndex) => prevSubStepIndex - 1);
    }
  };

  return (
    <DashboardLayout>
      <Header>
        <MDBox py={0} mb={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%", mt: 8 }}
          >
            <Grid sx={{ minWidth: "90%" }} item xs={12} lg={8}>
              <Card sx={{ height: "100%" }}>
                <MDBox mx={2} mt={-3}>
                  <Stepper
                    sx={{
                      overflowX: "auto",
                      backgroundColor: "#85B526 !important",
                      backgroundImage: "none !important",
                      boxShadow: "none",
                    }}
                    activeStep={activeStep}
                    alternativeLabel
                  >
                    {steps.map((label, index) => (
                      <Step
                        sx={{
                          ".Mui-disabled": {
                            color: "#FFFFFF",
                            opacity: 0.75,
                          },
                        }}
                        key={index}
                      >
                        <StepLabel
                          sx={{
                            "& .Mui-disabled ": {
                              color: "#FFFFFF",
                            },
                            ".MuiStepIcon-root ": {
                              fill: "#FFFFFF",
                              stroke: "#FFFFFF",
                              background: "#FFFFFF",
                            },
                          }}
                        >
                          {index === activeStep
                            ? index === 4
                              ? `MATURITY ${subStepIndex + 1}/2`
                              : index === 5
                                ? `ONE-LINER ${subStepIndex + 1}/2`
                                : index === 7
                                  ? `Financing ${subStepIndex + 1}/2`
                                  : label
                            : label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </MDBox>
                <MDBox p={{ xs: 1, md: 3 }}>
                  <MDBox>
                    {getStepContent(activeStep, subStepIndex)}
                    <MDBox mt={2} width="100%" display="flex" justifyContent="flex-end" gap={2}>
                      {activeStep !== 0 && activeStep !== steps.length - 1 && (
                        <MDButton variant="gradient" color="light" onClick={handleBack}>
                          Back
                        </MDButton>
                      )}
                      {activeStep !== steps.length - 1 ? (
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="dark"
                          onClick={handleNext}
                          disabled={(activeStep === 1 && !isFounderAdded) || isNextDisabled} // Disable Next if founder not added
                        >
                          {activeStep === steps.length - 2 ? "Submit" : "Next"}
                        </MDButton>
                      ) : (
                        <MDButton
                          //disabled={!disabledval}
                          type="submit"
                          variant="gradient"
                          color="info"
                          onClick={handleNext}
                        >
                          View Your Portfolio
                        </MDButton>
                      )}
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default ProfileGQ;
