export interface CountryOption {
  value: string;
  label: string;
  continent: string;
}

export const countryOptions: CountryOption[] = [
  { value: "AF", label: "Afghanistan", continent: "Asia" },
  { value: "AL", label: "Albania", continent: "Europe" },
  { value: "DZ", label: "Algeria", continent: "Africa" },
  { value: "AD", label: "Andorra", continent: "Europe" },
  { value: "AO", label: "Angola", continent: "Africa" },
  { value: "AG", label: "Antigua and Barbuda", continent: "North America" },
  { value: "AR", label: "Argentina", continent: "South America" },
  { value: "AM", label: "Armenia", continent: "Asia" },
  { value: "AU", label: "Australia", continent: "Oceania" },
  { value: "AT", label: "Austria", continent: "Europe" },
  { value: "AZ", label: "Azerbaijan", continent: "Asia" },
  { value: "BS", label: "Bahamas", continent: "North America" },
  { value: "BH", label: "Bahrain", continent: "Asia" },
  { value: "BD", label: "Bangladesh", continent: "Asia" },
  { value: "BB", label: "Barbados", continent: "North America" },
  { value: "BY", label: "Belarus", continent: "Europe" },
  { value: "BE", label: "Belgium", continent: "Europe" },
  { value: "BZ", label: "Belize", continent: "North America" },
  { value: "BJ", label: "Benin", continent: "Africa" },
  { value: "BT", label: "Bhutan", continent: "Asia" },
  { value: "BO", label: "Bolivia", continent: "South America" },
  { value: "BA", label: "Bosnia and Herzegovina", continent: "Europe" },
  { value: "BW", label: "Botswana", continent: "Africa" },
  { value: "BR", label: "Brazil", continent: "South America" },
  { value: "BN", label: "Brunei Darussalam", continent: "Asia" },
  { value: "BG", label: "Bulgaria", continent: "Europe" },
  { value: "BF", label: "Burkina Faso", continent: "Africa" },
  { value: "BI", label: "Burundi", continent: "Africa" },
  { value: "CV", label: "Cabo Verde", continent: "Africa" },
  { value: "KH", label: "Cambodia", continent: "Asia" },
  { value: "CM", label: "Cameroon", continent: "Africa" },
  { value: "CA", label: "Canada", continent: "North America" },
  { value: "CF", label: "Central African Republic", continent: "Africa" },
  { value: "TD", label: "Chad", continent: "Africa" },
  { value: "CL", label: "Chile", continent: "South America" },
  { value: "CN", label: "China", continent: "Asia" },
  { value: "CO", label: "Colombia", continent: "South America" },
  { value: "KM", label: "Comoros", continent: "Africa" },
  { value: "CG", label: "Congo", continent: "Africa" },
  { value: "CR", label: "Costa Rica", continent: "North America" },
  { value: "HR", label: "Croatia", continent: "Europe" },
  { value: "CU", label: "Cuba", continent: "North America" },
  { value: "CY", label: "Cyprus", continent: "Asia" },
  { value: "CZ", label: "Czech Republic", continent: "Europe" },
  { value: "CI", label: "Côte d'Ivoire", continent: "Africa" },
  { value: "KP", label: "Democratic People's Republic of Korea", continent: "Asia" },
  { value: "CD", label: "Democratic Republic of the Congo", continent: "Africa" },
  { value: "DK", label: "Denmark", continent: "Europe" },
  { value: "DJ", label: "Djibouti", continent: "Africa" },
  { value: "DM", label: "Dominica", continent: "North America" },
  { value: "DO", label: "Dominican Republic", continent: "North America" },
  { value: "EC", label: "Ecuador", continent: "South America" },
  { value: "EG", label: "Egypt", continent: "Africa" },
  { value: "SV", label: "El Salvador", continent: "North America" },
  { value: "GQ", label: "Equatorial Guinea", continent: "Africa" },
  { value: "ER", label: "Eritrea", continent: "Africa" },
  { value: "EE", label: "Estonia", continent: "Europe" },
  { value: "ET", label: "Ethiopia", continent: "Africa" },
  { value: "FJ", label: "Fiji", continent: "Oceania" },
  { value: "FI", label: "Finland", continent: "Europe" },
  { value: "FR", label: "France", continent: "Europe" },
  { value: "GA", label: "Gabon", continent: "Africa" },
  { value: "GM", label: "Gambia", continent: "Africa" },
  { value: "GE", label: "Georgia", continent: "Asia" },
  { value: "DE", label: "Germany", continent: "Europe" },
  { value: "GH", label: "Ghana", continent: "Africa" },
  { value: "GR", label: "Greece", continent: "Europe" },
  { value: "GD", label: "Grenada", continent: "North America" },
  { value: "GT", label: "Guatemala", continent: "North America" },
  { value: "GN", label: "Guinea", continent: "Africa" },
  { value: "GW", label: "Guinea-Bissau", continent: "Africa" },
  { value: "GY", label: "Guyana", continent: "South America" },
  { value: "HT", label: "Haiti", continent: "North America" },
  { value: "HN", label: "Honduras", continent: "North America" },
  { value: "HU", label: "Hungary", continent: "Europe" },
  { value: "IS", label: "Iceland", continent: "Europe" },
  { value: "IN", label: "India", continent: "Asia" },
  { value: "ID", label: "Indonesia", continent: "Asia" },
  { value: "IR", label: "Iran (Islamic Republic of)", continent: "Asia" },
  { value: "IQ", label: "Iraq", continent: "Asia" },
  { value: "IE", label: "Ireland", continent: "Europe" },
  { value: "IL", label: "Israel", continent: "Asia" },
  { value: "IT", label: "Italy", continent: "Europe" },
  { value: "JM", label: "Jamaica", continent: "North America" },
  { value: "JP", label: "Japan", continent: "Asia" },
  { value: "JO", label: "Jordan", continent: "Asia" },
  { value: "KZ", label: "Kazakhstan", continent: "Asia" },
  { value: "KE", label: "Kenya", continent: "Africa" },
  { value: "KI", label: "Kiribati", continent: "Oceania" },
  { value: "KW", label: "Kuwait", continent: "Asia" },
  { value: "KG", label: "Kyrgyzstan", continent: "Asia" },
  { value: "LA", label: "Lao People's Democratic Republic", continent: "Asia" },
  { value: "LV", label: "Latvia", continent: "Europe" },
  { value: "LB", label: "Lebanon", continent: "Asia" },
  { value: "LS", label: "Lesotho", continent: "Africa" },
  { value: "LR", label: "Liberia", continent: "Africa" },
  { value: "LY", label: "Libya", continent: "Africa" },
  { value: "LI", label: "Liechtenstein", continent: "Europe" },
  { value: "LT", label: "Lithuania", continent: "Europe" },
  { value: "LU", label: "Luxembourg", continent: "Europe" },
  { value: "MG", label: "Madagascar", continent: "Africa" },
  { value: "MW", label: "Malawi", continent: "Africa" },
  { value: "MY", label: "Malaysia", continent: "Asia" },
  { value: "MV", label: "Maldives", continent: "Asia" },
  { value: "ML", label: "Mali", continent: "Africa" },
  { value: "MT", label: "Malta", continent: "Europe" },
  { value: "MH", label: "Marshall Islands", continent: "Oceania" },
  { value: "MR", label: "Mauritania", continent: "Africa" },
  { value: "MU", label: "Mauritius", continent: "Africa" },
  { value: "MX", label: "Mexico", continent: "North America" },
  { value: "FM", label: "Micronesia (Federated States of)", continent: "Oceania" },
  { value: "MC", label: "Monaco", continent: "Europe" },
  { value: "MN", label: "Mongolia", continent: "Asia" },
  { value: "ME", label: "Montenegro", continent: "Europe" },
  { value: "MA", label: "Morocco", continent: "Africa" },
  { value: "MZ", label: "Mozambique", continent: "Africa" },
  { value: "MM", label: "Myanmar", continent: "Asia" },
  { value: "NA", label: "Namibia", continent: "Africa" },
  { value: "NR", label: "Nauru", continent: "Oceania" },
  { value: "NP", label: "Nepal", continent: "Asia" },
  { value: "NL", label: "Netherlands", continent: "Europe" },
  { value: "NZ", label: "New Zealand", continent: "Oceania" },
  { value: "NI", label: "Nicaragua", continent: "North America" },
  { value: "NE", label: "Niger", continent: "Africa" },
  { value: "NG", label: "Nigeria", continent: "Africa" },
  { value: "NO", label: "Norway", continent: "Europe" },
  { value: "OM", label: "Oman", continent: "Asia" },
  { value: "PK", label: "Pakistan", continent: "Asia" },
  { value: "PW", label: "Palau", continent: "Oceania" },
  { value: "PA", label: "Panama", continent: "North America" },
  { value: "PG", label: "Papua New Guinea", continent: "Oceania" },
  { value: "PY", label: "Paraguay", continent: "South America" },
  { value: "PE", label: "Peru", continent: "South America" },
  { value: "PH", label: "Philippines", continent: "Asia" },
  { value: "PL", label: "Poland", continent: "Europe" },
  { value: "PT", label: "Portugal", continent: "Europe" },
  { value: "QA", label: "Qatar", continent: "Asia" },
  { value: "KR", label: "Republic of Korea", continent: "Asia" },
  { value: "MD", label: "Republic of Moldova", continent: "Europe" },
  { value: "RO", label: "Romania", continent: "Europe" },
  { value: "RU", label: "Russian Federation", continent: "Europe" },
  { value: "RW", label: "Rwanda", continent: "Africa" },
  { value: "KN", label: "Saint Kitts and Nevis", continent: "North America" },
  { value: "LC", label: "Saint Lucia", continent: "North America" },
  { value: "VC", label: "Saint Vincent and the Grenadines", continent: "North America" },
  { value: "WS", label: "Samoa", continent: "Oceania" },
  { value: "SM", label: "San Marino", continent: "Europe" },
  { value: "ST", label: "Sao Tome and Principe", continent: "Africa" },
  { value: "SA", label: "Saudi Arabia", continent: "Asia" },
  { value: "SN", label: "Senegal", continent: "Africa" },
  { value: "RS", label: "Serbia", continent: "Europe" },
  { value: "SC", label: "Seychelles", continent: "Africa" },
  { value: "SL", label: "Sierra Leone", continent: "Africa" },
  { value: "SG", label: "Singapore", continent: "Asia" },
  { value: "SK", label: "Slovakia", continent: "Europe" },
  { value: "SI", label: "Slovenia", continent: "Europe" },
  { value: "SB", label: "Solomon Islands", continent: "Oceania" },
  { value: "SO", label: "Somalia", continent: "Africa" },
  { value: "ZA", label: "South Africa", continent: "Africa" },
  { value: "SS", label: "South Sudan", continent: "Africa" },
  { value: "ES", label: "Spain", continent: "Europe" },
  { value: "LK", label: "Sri Lanka", continent: "Asia" },
  { value: "SD", label: "Sudan", continent: "Africa" },
  { value: "SR", label: "Suriname", continent: "South America" },
  { value: "SZ", label: "Swaziland", continent: "Africa" },
  { value: "SE", label: "Sweden", continent: "Europe" },
  { value: "CH", label: "Switzerland", continent: "Europe" },
  { value: "SY", label: "Syrian Arab Republic", continent: "Asia" },
  { value: "TJ", label: "Tajikistan", continent: "Asia" },
  { value: "TH", label: "Thailand", continent: "Asia" },
  { value: "MK", label: "The former Yugoslav Republic of Macedonia", continent: "Europe" },
  { value: "TL", label: "Timor-Leste", continent: "Asia" },
  { value: "TG", label: "Togo", continent: "Africa" },
  { value: "TO", label: "Tonga", continent: "Oceania" },
  { value: "TT", label: "Trinidad and Tobago", continent: "North America" },
  { value: "TN", label: "Tunisia", continent: "Africa" },
  { value: "TR", label: "Turkey", continent: "Asia" },
  { value: "TM", label: "Turkmenistan", continent: "Asia" },
  { value: "TV", label: "Tuvalu", continent: "Oceania" },
  { value: "UG", label: "Uganda", continent: "Africa" },
  { value: "UA", label: "Ukraine", continent: "Europe" },
  { value: "AE", label: "United Arab Emirates", continent: "Asia" },
  { value: "GB", label: "United Kingdom", continent: "Europe" },
  { value: "TZ", label: "United Republic of Tanzania", continent: "Africa" },
  { value: "US", label: "United States of America", continent: "North America" },
  { value: "UY", label: "Uruguay", continent: "South America" },
  { value: "UZ", label: "Uzbekistan", continent: "Asia" },
  { value: "VU", label: "Vanuatu", continent: "Oceania" },
  { value: "VE", label: "Venezuela (Bolivarian Republic of)", continent: "South America" },
  { value: "VN", label: "Viet Nam", continent: "Asia" },
  { value: "YE", label: "Yemen", continent: "Asia" },
  { value: "ZM", label: "Zambia", continent: "Africa" },
  { value: "ZW", label: "Zimbabwe", continent: "Africa" },
  { value: "GL", label: "Greenland", continent: "North America" },
];

export const getCountryOptions = (): CountryOption[] => {
  return countryOptions;
};
