import React from "react";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import { Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import backgroundImage from "assets/images/loading-background.jpg";
//import linearGradient from "assets/theme/functions/linearGradient";

const LoadingPage: React.FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        color: "white",
        textAlign: "center",
      }}
    >
      <MDTypography variant="h2" fontWeight="bold" color="white">
        Your application is starting up...
      </MDTypography>
    </Box>
  );
};
/*
const LoadingPage: React.FC = () => {
  return (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }: Theme) =>
          backgroundImage &&
          `${linearGradient(
            rgba(gradients.dark.main, 0.4),
            rgba(gradients.dark.state, 0.4)
          )}, url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <MDBox textAlign="center" color="white">
        <MDTypography variant="h4" fontWeight="bold">
          Your application is starting up...
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};
*/

export default LoadingPage;
