import React, { useState, useEffect } from "react";
import { Grid, Typography, Radio, FormControlLabel } from "@mui/material";
import { FormData, MaturityDetailsProps } from "../../types";

const businessStages: Record<string, null> = {
  "Idea Generation / Validation Stage": null,
  "Product Development Stage": null,
  "Launch Stage": null,
  "Early Growth Stage": null,
  "Expansion Stage": null,
  "Scaling Stage": null,
  "Maturity Stage": null,
  "Pivot Stage": null,
  "Exit Stage": null,
};

const bbbeeOptions: Record<string, null> = {
  "Level 1": null,
  "Level 2": null,
  "Level 3": null,
  "Level 4": null,
  "Level 5": null,
  "Level 6": null,
  "Level 7": null,
  "Not Sure": null,
  "N/A or Based Outside of South Africa": null,
};

const MaturityDetails: React.FC<MaturityDetailsProps> = ({ formData, setFormData }) => {
  const [selectedStage, setSelectedStage] = useState<string>(
    formData.maturityDetails?.businessMaturityStage || ""
  );
  const [selectedBBBEE, setSelectedBBBEE] = useState<string>(
    formData.maturityDetails?.bbbeeLevel || ""
  );

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      maturityDetails: {
        businessMaturityStage: selectedStage,
        bbbeeLevel: selectedBBBEE,
      },
    }));
  }, [selectedStage, selectedBBBEE, setFormData]);

  const handleStageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedStage(event.target.value);
  };

  const handleBBBEEChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedBBBEE(event.target.value);
  };

  const renderRadioOptions = (
    options: Record<string, null>,
    selectedValue: string,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  ) => (
    <Grid container>
      {Object.keys(options).map((option, index) => (
        <Grid item xs={12} key={index}>
          <FormControlLabel
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: { xs: "12px", sm: "12px", lg: "14px" },
                color: "#7B809A",
                fontWeight: "400",
              },
            }}
            control={
              <Radio
                checked={selectedValue === option}
                sx={{
                  "& .MuiSvgIcon-root": { borderRadius: "4px" },
                  "&:after": { borderRadius: "2px" },
                }}
                onChange={handleChange}
                value={option}
              />
            }
            label={option}
          />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Grid container px={{ xs: 2, lg: 5 }} spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Business Development Readiness</Typography>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Please provide the following relevant startup details
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Choose the option that best describes the stage of your business / venture:
        </Typography>
        <Grid container>
          <Grid item lg={6} xs={12}>
            {renderRadioOptions(
              Object.fromEntries(Object.entries(businessStages).slice(0, 5)),
              selectedStage,
              handleStageChange
            )}
          </Grid>
          <Grid item lg={6} xs={12}>
            {renderRadioOptions(
              Object.fromEntries(Object.entries(businessStages).slice(5)),
              selectedStage,
              handleStageChange
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          What level BBBEE has your business achieved?
        </Typography>
        {renderRadioOptions(bbbeeOptions, selectedBBBEE, handleBBBEEChange)}
      </Grid>
    </Grid>
  );
};

export default MaturityDetails;
