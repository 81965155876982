import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DashboardLayout from "components/refined/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/refined/Navbars/DashboardNavbar";
import Footer from "components/refined/Footer";
import MDBox from "components/generic/MDBox";
import Card from "@mui/material/Card";
import Header from "./components/Header";
import About from "./about/About"; // create these files similarly
import FinancialOverview from "./financial-overview/FinancialOverview";
import Projects from "./projects/Projects";
import headerImage from "assets/images/reisiger/bg-profile.png";
import { useAuth } from "context/AuthContext";
//import headerImage from "targets/agrigenesis.io/src_overrides/assets/images/reisiger/bg-profile.png"; //"assets/images/reisiger/bg-profile.png";

const BusinessProfile: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const { loginResponse } = useAuth();
  const location = useLocation();
  const userData = loginResponse ? loginResponse.entity : null;

  const entityName = userData?.entity_name || "Business Name";

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");
    if (tab) {
      setCurrentTab(Number(tab));
    }
  }, [location.search]);

  const renderTabContent = () => {
    switch (currentTab) {
      case 0:
        return <About />;
      case 1:
        return <FinancialOverview />;
      case 2:
        return <Projects />;
      default:
        return <About />;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header
        tabValue={currentTab}
        setTabValue={setCurrentTab}
        backgroundImage={headerImage}
        entityName={entityName}
      />

      {renderTabContent()}

      <Footer />
    </DashboardLayout>
  );
};

export default BusinessProfile;
