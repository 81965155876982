/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Link from "@mui/material/Link";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";

// Material Dashboard 2 PRO React TS Base Styles
import typography from "assets/theme/base/typography";
import uiConfig from "assets/ui-config/ui-config"; //convert to ts

// Declaring props types for Footer
interface FooterProps {
  aboutUs?: {
    href: string;
    name: string;
  };
  license?: {
    href: string;
    name: string;
  };
  poweredBy?: {
    text: string;
    name: string;
  };
}

const Footer: React.FC<FooterProps> = ({ aboutUs, license, poweredBy }) => {
  const { href: aboutUsHref, name: aboutUsName } = aboutUs;
  const { href: licenseHref, name: licenseName } = license;
  const { text: poweredByText } = poweredBy;
  const { size } = typography;

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        {poweredByText}
      </MDBox>
      <MDBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        <MDBox key="about" component="li" px={2} lineHeight={1}>
          <Link href={aboutUsHref} target="_blank">
            <MDTypography variant="button" fontWeight="regular" color="text">
              About Us
            </MDTypography>
          </Link>
        </MDBox>
        <MDBox key="license" component="li" px={2} lineHeight={1}>
          <Link href={licenseHref} target="_blank">
            <MDTypography variant="button" fontWeight="regular" color="text">
              License
            </MDTypography>
          </Link>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

// Declaring default props for Footer
Footer.defaultProps = uiConfig.footer;

export default Footer;
