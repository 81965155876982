import React from "react";
import { Card } from "@mui/material";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import TimelineItem from "./TimelineItem"; // Ensure correct path

interface Project {
  id: string;
  name: string;
  status: {
    status: string;
    color: string;
  };
}

interface ProjectListProps {
  projects: Project[];
  selectedProject: string;
  onProjectSelect: (project: Project) => void;
}

const ProjectList: React.FC<ProjectListProps> = ({
  projects,
  selectedProject,
  onProjectSelect,
}) => {
  return (
    <MDBox p={2}>
      <MDTypography variant="h5" fontWeight="medium" mb={2}>
        Projects Overview
      </MDTypography>
      {projects.map((project, index) => (
        <TimelineItem
          key={project.id}
          color={project.status.color}
          title={project.name}
          status={project.status.status}
          isSelected={selectedProject === project.name}
          onClick={() => onProjectSelect(project)}
          lastItem={index === projects.length - 1}
        />
      ))}
    </MDBox>
  );
};

export default ProjectList;

/*
import React from "react";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";

interface ProjectListProps {
  projects: { name: string; status: { status: string; color: string } }[];
  selectedProject: string;
  onProjectSelect: (project: any) => void;
}

const ProjectList: React.FC<ProjectListProps> = ({
  projects,
  selectedProject,
  onProjectSelect,
}) => {
  return (
    <MDBox>
      {projects.map((project) => (
        <MDBox
          key={project.name}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
          onClick={() => onProjectSelect(project)}
          sx={{
            cursor: "pointer",
            backgroundColor: selectedProject === project.name ? "#f5f5f5" : "transparent",
          }}
        >
          <MDTypography variant="body1">{project.name}</MDTypography>
          <MDTypography variant="caption" color="textSecondary">
            {project.status.status}
          </MDTypography>
        </MDBox>
      ))}
    </MDBox>
  );
};

export default ProjectList;
*/
