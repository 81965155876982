import React, { useState } from "react";
import { axiosInstance } from "api/axiosInstance";
import endpoints from "api/endpoints";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDInput from "components/generic/MDInput";
import MDButton from "components/generic/MDButton";

// Authentication layout components
//import CoverLayout from "layouts/authentication/components/CoverLayout"; //not used in original

// Images
import bgImage from "assets/images/reisiger/profileBgImage.png";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { ChevronLeft, EmailOutlined } from "@mui/icons-material";

// react-router-dom components
import { useSearchParams, Link } from "react-router-dom";
import Footer from "components/refined/Footer"; // maybe use authentication > components > footer (below) if this looks funny.
//import Footer from "../components/Footer"

const ResetCode: React.FC = () => {
  const [code, setCode] = useState<string>("");
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") || "Someone@mail.com";

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      /*
      const response = await axiosInstance.post(endpoints.auth.verifyResetCode, { code }); //naming
      console.log("Code verified successfully:", response.data);
      */
      console.log("Submit clicked - request not linked");
    } catch (error: any) {
      console.error("Error verifying reset code:", error);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card
        sx={{
          minWidth: {
            lg: 485,
            xl: 485,
          },
          mb: 2,
        }}
      >
        <MDBox
          variant="contained"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={{
            xs: 2,
            sm: 2,
            lg: 4.5,
            xl: 4.5,
          }}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          {/* <MDTypography display="block" variant="button" color="white" my={1}>
              You will receive an e-mail in maximum 60 seconds
            </MDTypography> */}
        </MDBox>
        <MDBox
          pt={{
            xs: 2,
            sm: 2,
            lg: 6,
            xl: 6,
          }}
          pb={{
            xs: 2,
            sm: 2,
            lg: 5,
            xl: 5,
          }}
          px={6}
        >
          <form onSubmit={handleSubmit}>
            <MDBox
              component="form"
              role="form"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              gap={3}
              px={5}
            >
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ fontSize: 80, height: 80, overflowY: "hidden" }}
              >
                <EmailOutlined color="info" /> {/*was "black" - TS error - include black later*/}
              </MDBox>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                gap={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="black">
                  Check your inbox
                </MDTypography>
                <MDTypography display="block" variant="button" color="black">
                  Please enter the verification code sent to
                </MDTypography>
                <MDTypography variant="h5" fontWeight="medium" color="black">
                  {email}
                </MDTypography>
              </MDBox>
              <MDInput
                type="password"
                label="6 digit security code"
                fullWidth
                color="text"
                bgColor="black"
              />
              <MDTypography variant="button" color="text_inactive">
                Resend (60s)
              </MDTypography>
              <MDTypography
                component="a"
                href="https://share-eu1.hsforms.com/1tSYB4fdJTyKprNAC0as67Q2dypu8"
                target="_blank"
                rel="noopener noreferrer"
                variant="button"
                sx={{ cursor: "pointer" }}
                color="primary"
              >
                Need help?
              </MDTypography>
            </MDBox>
          </form>
          <MDBox
            mt={{
              xs: 2,
              sm: 2,
              lg: 5,
              xl: 5,
            }}
          >
            <MDButton
              component={Link}
              to="/authentication/reset-password/new-password"
              sx={{ textTransform: "uppercase" }}
              variant="gradient"
              color="info"
              fullWidth
            >
              submit
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      <Footer />
    </BasicLayout>
  );
};

export default ResetCode;
