import React, { ChangeEvent } from "react";
import { Grid, Typography, TextField } from "@mui/material";
import { FormData, OneLinerDetails, OnelinerDetailsProps } from "../../types";
/*
interface OneLinerDetails {
  targetIndustries?: string;
  geographicFocus?: string;
  investmentStage?: string;
  investmentImpact?: string;
}

interface FormData {
  oneLinerDetails?: OneLinerDetails;
}

interface OnelinerDetailsProps {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}
*/
const OnelinerDetails: React.FC<OnelinerDetailsProps> = ({ formData, setFormData }) => {
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof OneLinerDetails
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      oneLinerDetails: {
        ...prevData.oneLinerDetails,
        [field]: event.target.value,
      },
    }));
  };

  return (
    <Grid container px={{ xs: 0.5, lg: 5 }} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Investor One-Liner</Typography>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Please complete the following statement:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography textAlign="center" sx={{ fontSize: "14px" }} variant="body1">
          Our investment approach focuses on
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <TextField
          placeholder="define your target investment industries / sectors"
          variant="outlined"
          sx={{
            width: {
              xs: "99%",
              sm: "90%",
              md: "407px",
            },
            "& input": {
              textAlign: "center", // Center align text input
            },
            "& ::placeholder": {
              fontStyle: "italic",
              textAlign: "center", // Center align placeholder
              fontSize: { xs: "10px", lg: "13px" },
              width: "100%",
            },
          }}
          multiline
          rows={2}
          value={formData.oneLinerDetails?.targetIndustries || ""}
          onChange={(e) => handleChange(e, "targetIndustries")}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography textAlign="center" sx={{ fontSize: "14px" }} variant="body1">
          primarily in
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <TextField
          placeholder="define your geographical focus"
          variant="outlined"
          sx={{
            width: {
              xs: "99%",
              sm: "90%",
              md: "407px",
            },
            "& input": {
              textAlign: "center", // Center align text input
            },
            "& ::placeholder": {
              fontStyle: "italic",
              textAlign: "center", // Center align placeholder
              fontSize: { xs: "10px", lg: "13px" },
              width: "100%",
            },
          }}
          multiline
          rows={2}
          value={formData.oneLinerDetails?.geographicFocus || ""}
          onChange={(e) => handleChange(e, "geographicFocus")}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography textAlign="center" sx={{ fontSize: "14px" }} variant="body1">
          with a preference for
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <TextField
          placeholder="define your primary investment stage preference"
          variant="outlined"
          sx={{
            width: {
              xs: "99%",
              sm: "90%",
              md: "407px",
            },
            "& input": {
              textAlign: "center", // Center align text input
            },
            "& ::placeholder": {
              fontStyle: "italic",
              textAlign: "center", // Center align placeholder
              fontSize: { xs: "10px", lg: "13px" },
              width: "100%",
            },
          }}
          multiline
          rows={2}
          value={formData.oneLinerDetails?.investmentStage || ""}
          onChange={(e) => handleChange(e, "investmentStage")}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography textAlign="center" sx={{ fontSize: "14px" }} variant="body1">
          aiming to achieve
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <TextField
          placeholder="describe your investment objective or impact."
          variant="outlined"
          sx={{
            width: {
              xs: "99%",
              sm: "90%",
              md: "407px",
            },
            "& input": {
              textAlign: "center",
            },
            "& ::placeholder": {
              fontStyle: "italic",
              textAlign: "center",
              fontSize: { xs: "10px", lg: "13px" },
              width: "100%",
            },
          }}
          multiline
          rows={2}
          value={formData.oneLinerDetails?.investmentImpact || ""}
          onChange={(e) => handleChange(e, "investmentImpact")}
        />
      </Grid>
    </Grid>
  );
};

export default OnelinerDetails;
