import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Radio,
  FormControlLabel,
  TextField,
  IconButton,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MDButton from "components/generic/MDButton";
import { FinancingDetails2Props, FormData } from "../../../types";
/*
interface InvestmentDetails {
  equityInclusionCommitment: string;
  timeFrame: string;
  collaborationPartners: string[];
}

interface FormData {
  investmentDetails: InvestmentDetails;
}

interface FinancingDetails2Props {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}
*/
const FinancingDetails2: React.FC<FinancingDetails2Props> = ({ formData, setFormData }) => {
  const [equityInclusionCommitment, setEquityInclusionCommitment] = useState<string>(
    formData.investmentDetails?.equityInclusionCommitment || ""
  );
  const [timeFrame, setTimeFrame] = useState<string>(formData.investmentDetails?.timeFrame || "");
  const [collaborationPartners, setCollaborationPartners] = useState<string[]>(
    formData.investmentDetails?.collaborationPartners || [""]
  );

  useEffect(() => {
    setFormData((prevData: FormData) => ({
      ...prevData,
      investmentDetails: {
        ...prevData.investmentDetails,
        equityInclusionCommitment,
        timeFrame,
        collaborationPartners,
      },
    }));
  }, [equityInclusionCommitment, timeFrame, collaborationPartners, setFormData]);

  const handleEquityInclusionCommitmentChange = (
    event: React.SyntheticEvent<Element, Event>
    //checked: boolean
  ) => {
    setEquityInclusionCommitment((event.target as HTMLInputElement).value);
  };

  const handleTimeFrameChange = (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    setTimeFrame((event.target as HTMLInputElement).value);
  };

  const handleAddTextField = () => {
    setCollaborationPartners([...collaborationPartners, ""]);
  };

  const handleCollaborationPartnerChange = (index: number, value: string) => {
    const updatedPartners = [...collaborationPartners];
    updatedPartners[index] = value;
    setCollaborationPartners(updatedPartners);
  };

  const handleDeleteTextField = (index: number) => {
    const updatedPartners = [...collaborationPartners];
    updatedPartners.splice(index, 1);
    setCollaborationPartners(updatedPartners);
  };

  return (
    <Grid container px={{ xs: 1, lg: 5 }} spacing={3}>
      <Grid item xs={12} mb={4}>
        <Typography variant="h5">Fund Maturity</Typography>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Please provide the following information related to your fund maturity
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Grid container direction="column">
          <Typography sx={{ fontSize: "14px" }} variant="body1">
            What is the general length of engagement and funding allocation timeframe?
          </Typography>
          {[
            { label: "Once Off", value: "Once Off" },
            { label: "Short Term Only", value: "Short Term Only" },
            { label: "Long Term Only", value: "Long Term Only" },
            {
              label: "Sustained Initiatives / Mixed Terms",
              value: "Sustained Initiatives / Mixed Terms",
            },
            { label: "Until Exit", value: "Until Exit" },
          ].map((option) => (
            <Grid item key={option.value}>
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    color: "#7B809A",
                    fontWeight: "400",
                  },
                }}
                value={option.value}
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        borderRadius: "4px",
                      },
                      "&:after": {
                        borderRadius: "2px",
                      },
                    }}
                  />
                }
                label={option.label}
                onChange={handleTimeFrameChange}
                checked={timeFrame === option.value}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          What is the fund&apos;s dedication to diversity, equity, and inclusion?
        </Typography>
        {[
          { label: "None specific", value: "None" },
          { label: "Limited Scope", value: "Low" },
          { label: "Moderate Scope", value: "Moderate" },
          { label: "Strict / Strong Scope", value: "High" },
        ].map((option) => (
          <Grid item key={option.value}>
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                  color: "#7B809A",
                  fontWeight: "400",
                },
              }}
              value={option.value}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      borderRadius: "4px",
                    },
                    "&:after": {
                      borderRadius: "2px",
                    },
                  }}
                />
              }
              label={option.label}
              onChange={handleEquityInclusionCommitmentChange}
              checked={equityInclusionCommitment === option.value}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container item my={5} alignItems="center">
        <Grid item xs={12} lg={5}>
          <Typography sx={{ fontSize: "14px" }} variant="body1">
            List any investors, partners or funds that you collaborate with on deals.
          </Typography>
          {collaborationPartners.map((partner, index) => (
            <Grid item key={index} alignItems="center">
              <Box display={"flex"} alignItems="center">
                <TextField
                  placeholder="Collaboration Partner"
                  sx={{ py: 3 }}
                  fullWidth
                  multiline
                  value={partner}
                  onChange={(event) => handleCollaborationPartnerChange(index, event.target.value)}
                />
                {collaborationPartners.length === 1 ? null : (
                  <IconButton sx={{}} onClick={() => handleDeleteTextField(index)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={1}></Grid> {/* Adding space */}
        <Grid item xs={12} spacing={3}>
          <Grid item xs={12}>
            <MDButton color="dark" variant="outlined" sx={{}} onClick={handleAddTextField}>
              + Add another
            </MDButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FinancingDetails2;
