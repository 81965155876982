import React, { useState, useEffect, ChangeEvent } from "react";
import { SelectChangeEvent } from "@mui/material";
import {
  Grid,
  Typography,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Autocomplete,
  Paper,
  Button,
} from "@mui/material";
import { AutocompleteChangeReason, AutocompleteChangeDetails } from "@mui/material";
import { VectorMap } from "@react-jvectormap/core";
import { worldMerc } from "@react-jvectormap/world";
import MDBox from "components/generic/MDBox";
import { styled } from "@mui/system";
import { getCountryOptions, CountryOption } from "./data/mandate-2-countryOptionsData";
//const countries = getCountryOptions();
import { Mandate2Props, FormData } from "../../types";

const continents = [
  { value: "Africa", label: "Africa" },
  { value: "Asia", label: "Asia" },
  { value: "Europe", label: "Europe" },
  { value: "North America", label: "North America" },
  { value: "Oceania", label: "Oceania" },
  { value: "South America", label: "South America" },
];

const CustomAutocomplete = styled(Autocomplete)<{ options: CountryOption[] }>({
  "& .MuiOutlinedInput-root": {
    padding: "5px !important",
  },
});

const containerStyle = {
  width: "100%",
  height: "420px",
};

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
      maxHeight: 200,
      position: "relative" as const,
    },
  },
};
/*
interface FormData {
  mandateDetails: {
    investmentGeographies: string[];
  };
}

interface Mandate2Props {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}
*/
const Mandate2: React.FC<Mandate2Props> = ({ formData, setFormData }) => {
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [options, setOptions] = useState<CountryOption[]>(getCountryOptions());
  const [selectedContinents, setSelectedContinents] = useState<string[]>([]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      mandateDetails: {
        ...prevData.mandateDetails,
        investmentGeographies: selectedCountries,
      },
    }));
  }, [selectedCountries, setFormData]);

  const filterOptionsByContinents = (selectedContinents: string[]) => {
    const filteredOptions = getCountryOptions().filter((country) =>
      selectedContinents.includes(country.continent)
    );
    setOptions(filteredOptions);
  };

  const handleCountryChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: unknown,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<unknown>
  ) => {
    setSelectedCountries((value as CountryOption[]).map((option) => option.value));
  };

  const handleContinentChange = (event: SelectChangeEvent<string[]>) => {
    const selected = event.target.value as string[];
    setSelectedContinents(selected);
    if (selected.length > 0) {
      filterOptionsByContinents(selected);
    } else {
      setOptions(getCountryOptions());
    }
  };

  const handleSelectAll = () => {
    setSelectedCountries(options.map((option) => option.value));
  };

  const handleDeselectAll = () => {
    setSelectedCountries([]);
  };

  const handleMapClick = (e: any, countryCode: string) => {
    setSelectedCountries((prevSelected) =>
      prevSelected.includes(countryCode)
        ? prevSelected.filter((code) => code !== countryCode)
        : [...prevSelected, countryCode]
    );
  };

  const mapData = selectedCountries.reduce((acc: { [key: string]: number }, country) => {
    acc[country] = 1; // Set value to 1 for selected countries
    return acc;
  }, {});

  const series = {
    regions: [
      {
        values: mapData,
        scale: ["#e4e4e4", "#00DC82"], // Default color to selected color
        normalizeFunction: "polynomial",
      },
    ],
  };

  return (
    <Grid container px={{ xs: 2, lg: 5 }} spacing={3}>
      <Grid item mb={6} xs={12}>
        <Typography variant="h5">Investor Mandate Geographies</Typography>
        <Typography mb={1} sx={{ fontSize: "14px" }} variant="body1">
          Please select the geographies that are eligible for funding or excluded:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MDBox
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          alignItems={{ xs: "center", lg: "flex-end" }}
          justifyContent="center"
          gap={2}
        >
          <MDBox sx={{ width: { xs: "100%", lg: "320px" } }}>
            <FormControl sx={{ width: "100%", "& .MuiInputBase-root ": { height: "50px" } }}>
              <InputLabel id="continent-select-label">Continent</InputLabel>
              <Select
                label="Continent"
                labelId="continent-select-label"
                placeholder="Select Continent"
                value={selectedContinents}
                onChange={handleContinentChange}
                multiple
                MenuProps={MenuProps}
                sx={{ color: "#000000", fontSize: "14px" }}
              >
                {continents.map((continent, index) => (
                  <MenuItem key={index} value={continent.value}>
                    {continent.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MDBox>
          <MDBox sx={{ width: { xs: "100%", lg: "320px" } }}>
            <CustomAutocomplete
              multiple
              options={options} // Pass the country options
              value={options.filter((option) => selectedCountries.includes(option.value))}
              getOptionLabel={(option) => (option as CountryOption).label}
              onChange={handleCountryChange}
              renderInput={(params) => <TextField {...params} label="Eligible Countries" />}
              PaperComponent={(paperProps) => {
                const { children, ...restPaperProps } = paperProps;
                return (
                  <Paper {...restPaperProps}>
                    <MDBox
                      onMouseDown={(e) => e.preventDefault()} // prevent blur
                      pl={1}
                      py={0.5}
                    >
                      <TextField
                        placeholder="Search..."
                        variant="outlined"
                        size="small"
                        sx={{ mb: 1, width: "100%" }}
                      />
                      {children}
                      {!selectedCountries.length ? (
                        <Button onClick={handleSelectAll}>Select All</Button>
                      ) : (
                        <Button onClick={handleDeselectAll}>Deselect All</Button>
                      )}
                    </MDBox>
                  </Paper>
                );
              }}
              sx={{
                "& .MuiInputBase-root ": {
                  maxHeight: "70px",
                  flexWrap: "nowrap",
                  overflowY: "hidden",
                },
              }}
            />
          </MDBox>
        </MDBox>
      </Grid>
      <Grid item xs={12} sx={{ mt: { xs: 5, lg: 0 }, height: "600px" }}>
        <VectorMap
          map={worldMerc}
          backgroundColor="transparent"
          zoomOnScroll={false}
          containerStyle={containerStyle}
          containerClassName="map"
          onRegionClick={handleMapClick}
          regionStyle={{
            initial: {
              fill: "#e4e4e4",
              "fill-opacity": 0.9,
              stroke: "none",
              "stroke-width": 0,
              "stroke-opacity": 0,
            },
            selected: {
              fill: "#00DC82",
              "fill-opacity": 0.9,
              stroke: "none",
              "stroke-width": 0,
              "stroke-opacity": 0,
            },
          }}
          series={series}
        />
      </Grid>
    </Grid>
  );
};

export default Mandate2;
