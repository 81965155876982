import React, { useRef, useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import MDTypography from "components/generic/MDTypography";

ChartJS.register(ArcElement, Tooltip, Legend);

interface CircularProgressChartProps {
  percentage: number;
  color?: string;
  trackColor?: string;
  size?: string;
}

const CircularProgressChart: React.FC<CircularProgressChartProps> = ({
  percentage, // Providing default value for testing
  color = "#36A2EB",
  trackColor = "#E0E0E0",
  size = "200px", // Increased size for visibility
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [chartData, setChartData] = useState({
    labels: ["Progress", "Remaining"],
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [color, trackColor],
        borderWidth: 0,
        borderRadius: [15, 0], // Apply border radius to only the progress segment
      },
    ],
  });

  useEffect(() => {
    if (canvasRef.current) {
      setChartData({
        labels: ["Progress", "Remaining"],
        datasets: [
          {
            data: [percentage, 100 - percentage],
            backgroundColor: [color, trackColor],
            borderWidth: 0,
            borderRadius: [15, 0], // Apply border radius to only the progress segment
          },
        ],
      });
    }
  }, [percentage, color, trackColor]);

  const options = {
    cutout: "80%", // Adjust the cutout for the inner radius
    rotation: 0, // Start angle for the progress
    circumference: 360, // Total angle for the chart
    plugins: {
      legend: {
        display: false, // Hide default legend
      },
      tooltip: {
        enabled: true, // Enable tooltips
      },
    },
    elements: {
      arc: {
        borderWidth: 12, // Ensure the arc is thick for rounded ends
        borderRadius: 15,
      },
    },
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: size,
        height: size,
      }}
    >
      <Doughnut ref={canvasRef} data={chartData} options={options} />
      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
        }}
      >
        <MDTypography variant="h4" fontWeight="bold">
          {`${Math.round(percentage)}%`}
        </MDTypography>
        <MDTypography variant="caption" color="textSecondary">
          Complete
        </MDTypography>
      </div>
    </div>
  );
};

export default CircularProgressChart;
