import React from "react";

// Import MDBox and MDTypography
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";

// Import alpha from Material-UI styles
import { alpha } from "@mui/material/styles";

interface TimelineItemProps {
  color: string;
  title: string;
  status: string;
  isSelected: boolean;
  onClick: () => void;
  lastItem?: boolean;
}

const TimelineItem: React.FC<TimelineItemProps> = ({
  color,
  title,
  status,
  isSelected,
  onClick,
  lastItem = false,
}) => {
  return (
    <MDBox
      onClick={onClick}
      component="div" // Include component prop if required by MDBox
      sx={{
        position: "relative",
        mb: 0,
        p: 2,
        cursor: "pointer",
        backgroundColor: isSelected ? alpha(color, 0.2) : "transparent",
        borderRadius: 1,
        "&:hover": {
          backgroundColor: alpha(color, 0.1),
        },
      }}
    >
      {/* Colored Dot */}
      <MDBox
        component="div" // Include component prop if required
        sx={{
          bgcolor: color,
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          position: "absolute",
          top: "50%",
          left: "17.5px",
          transform: "translate(-50%, -50%)",
        }}
      />

      {/* Content */}
      <MDBox component="div" sx={{ ml: 4 }}>
        <MDTypography variant="subtitle1" fontWeight="medium">
          {title}
        </MDTypography>
        <MDTypography variant="caption" color="textSecondary">
          {status}
        </MDTypography>
      </MDBox>

      {/* Vertical Line (if not last item) */}
      {!lastItem && (
        <MDBox
          component="div"
          sx={{
            position: "absolute",
            top: "calc(50% + 5px)",
            left: "16px",
            width: "2px",
            height: "calc(100% + 10px)",
            bgcolor: "grey.300",
            zIndex: -1,
          }}
        />
      )}
    </MDBox>
  );
};

export default TimelineItem;
