import React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";

interface ConfirmInviteModalProps {
  open: boolean;
  handleClose: () => void;
  entityName: string;
  handleSendInvite: () => void;
}

const ConfirmInviteModal: React.FC<ConfirmInviteModalProps> = ({
  open,
  handleClose,
  entityName,
  handleSendInvite,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      {/* Green Header */}
      <MDBox
        bgColor="primary"
        borderRadius="lg"
        mx={2}
        mt={2}
        px={2}
        py={2}
        mb={1}
        textAlign="center"
        sx={{ boxShadow: 3 }}
      >
        <MDTypography variant="h5" fontWeight="medium" color="white">
          Confirm Request to Link
        </MDTypography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 32,
            top: 26,
            color: "#ffffff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </MDBox>

      {/* Modal Content */}
      <MDBox p={3} textAlign="center">
        <MDTypography variant="body1" mb={2}>
          Are you sure you want to send a request to be linked to {entityName}?
        </MDTypography>
        <MDTypography variant="body2" color="text">
          Once the request is sent, the startup will be notified of your interest.
        </MDTypography>

        <MDBox mt={3}>
          <MDButton variant="contained" color="primary" onClick={handleSendInvite}>
            Send Invitation Request
          </MDButton>
        </MDBox>

        <MDBox mt={2}>
          <MDTypography
            variant="button"
            fontWeight="medium"
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={handleClose}
          >
            &lt; Back to Startups
          </MDTypography>
        </MDBox>
      </MDBox>
    </Dialog>
  );
};

export default ConfirmInviteModal;
