// ui-config.ts

interface LinkConfig {
  href: string;
  name: string;
}

interface FooterConfig {
  aboutUs: LinkConfig;
  license: LinkConfig;
  poweredBy: {
    text: string;
    name: string;
  };
}

interface FeedbackLinksConfig {
  hubSpotLink: LinkConfig;
}

interface NeedHelpLinksConfig {
  hubSpotLink: LinkConfig;
}

interface TcDeclineLinkConfig {
  redirect: LinkConfig;
}

interface ThemePalette {
  primary: {
    main: string;
  };
  secondary: {
    main: string;
  };
}

interface ThemeConfig {
  palette: ThemePalette;
}

interface UIConfig {
  footer: FooterConfig;
  feedbackLinks: FeedbackLinksConfig;
  needHelpLinks: NeedHelpLinksConfig;
  tcDeclineLink: TcDeclineLinkConfig;
  theme: ThemeConfig;
}

const uiConfig: UIConfig = {
  // Footer
  footer: {
    aboutUs: {
      href: "https://www.agrigenesis.io",
      name: "aboutUs",
    },
    license: {
      href: "https://reisiger.io/Resources/privacy-policy",
      name: "license",
    },
    poweredBy: {
      text: `Powered by © 2021-${new Date().getFullYear()} Reisiger Ventures`,
      name: "poweredBy",
    },
  },

  // Feedback Button
  feedbackLinks: {
    hubSpotLink: {
      href: "https://share-eu1.hsforms.com/1tSYB4fdJTyKprNAC0as67Q2dypu8",
      name: "feedback",
    },
  },

  // NeedHelp Button
  needHelpLinks: {
    hubSpotLink: {
      href: "https://share-eu1.hsforms.com/1tSYB4fdJTyKprNAC0as67Q2dypu8",
      name: "needHelp",
    },
  },

  // TC Decline Button
  tcDeclineLink: {
    redirect: {
      href: "https://www.agrigenesis.io",
      name: "decline",
    },
  },

  // Theme
  theme: {
    palette: {
      primary: {
        main: "#85B526",
      },
      secondary: {
        main: "#88966E",
      },
    },
  },
};

export default uiConfig;
