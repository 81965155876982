import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/generic/MDBox";
import MDButton from "components/generic/MDButton";
import MDTypography from "components/generic/MDTypography";
import DashboardLayout from "components/refined/LayoutContainers/DashboardLayout";
import Header from "layouts/pages/profile/profile-build/welcome-page/Layout";
import logo from "../../../../../assets/images/reisiger/logo2.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Footer from "components/refined/Footer";

function WelcomePage(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Add any side-effects or subscriptions here, if needed
  }, []);

  const handleButtonClick = () => {
    const gq_state = localStorage.getItem("gq_state");
    console.log("gq_state form local,", gq_state);
    if (gq_state === "investorBasics" || gq_state === "Investor") {
      console.log("else if (gq_state === 'Investor' || 'investorBasics') reached");
      navigate("/onboarding-setup-investor");
    } else if (gq_state === "Business" || gq_state === "startupDetails") {
      console.log("else if (gq_state === 'Business' || 'startupDetails') reached");
      navigate("/onboarding-setup-business");
    }
  };

  return (
    <DashboardLayout>
      <Header>
        <MDBox mt={10} mb={5}>
          <Grid
            container
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            spacing={{
              xs: 2,
              lg: 4,
            }}
          >
            <Grid item>
              <MDBox component="img" src={logo} width="100%" height="100%" zIndex={1} />
            </Grid>
            <Grid item>
              <MDTypography variant="h4" fontWeight="bold" color="white">
                Your Journey Starts Here
              </MDTypography>
            </Grid>
            <Grid item>
              <MDButton
                variant="gradient"
                sx={{
                  backgroundColor: "#85B526", // Set button color to green
                  color: "#ffffff", // Set button text to white
                  "&:hover": {
                    backgroundColor: "#75A01C",
                    color: "#ffffff",
                  },
                }}
                fullWidth
                onClick={handleButtonClick}
              >
                START
              </MDButton>
              {/*
              <MDButton variant="gradient" color="info" fullWidth onClick={handleButtonClick}>
                START
              </MDButton>
              */}
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default WelcomePage;
