import React, { useRef, useEffect } from "react";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import { Chart, ChartData, ChartType, registerables, ChartOptions } from "chart.js";

// Register all necessary chart components
Chart.register(...registerables);

interface FundingPieChartProps {
  totalFunding: string; // Prop to receive total funding
  fundingBreakdown: {
    [key: string]: any; // Can include various breakdown categories
  }; // Funding breakdown from data
}

const FundingPieChart: React.FC<FundingPieChartProps> = ({ totalFunding, fundingBreakdown }) => {
  const hasFunding = totalFunding !== "No";
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstanceRef = useRef<Chart<"pie", number[], unknown> | null>(null);
  console.log("fundingBreakdown", fundingBreakdown);

  const fundingAmounts = Object.values(fundingBreakdown).map((item) => Number(item.fundingAmount));

  // Prepare the chart data
  const chartData: ChartData<"pie"> | null = hasFunding
    ? {
        labels: Object.keys(fundingBreakdown),
        datasets: [
          {
            label: "Funding",
            backgroundColor: ["#4CAF50", "#FFEB3B", "#2196F3", "#9E9E9E"],
            data: fundingAmounts,
          },
        ],
      }
    : null;
  console.log("Chart Data:", chartData);
  const chartOptions: ChartOptions<"pie"> = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Custom legend displayed separately
      },
    },
  };

  const legend = Object.keys(fundingBreakdown).map((category, index) => ({
    label: category,
    color: ["#4CAF50", "#FFEB3B", "#2196F3", "#9E9E9E"][index % 4], // Rotating through preset colors
  }));

  // Handle the creation and destruction of the chart
  useEffect(() => {
    if (chartRef.current && hasFunding && chartData) {
      // Destroy previous chart instance before creating a new one
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
      // Create new chart instance
      chartInstanceRef.current = new Chart(chartRef.current as HTMLCanvasElement, {
        type: "pie",
        data: chartData,
        options: chartOptions,
      });
    }

    // Cleanup function to destroy the chart when the component unmounts
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [chartData, hasFunding]); // Recreate chart only when data or funding status changes

  return (
    <MDBox mt={4}>
      <Grid container alignItems="center" spacing={3}>
        {/* Pie chart on the left */}
        <Grid item xs={12} sm={8}>
          <MDTypography variant="h5" fontWeight="medium" mb={2}>
            Funding Overview
          </MDTypography>
          <MDBox mb={1} sx={{ maxWidth: "300px", mx: "auto" }}>
            {hasFunding ? (
              <canvas ref={chartRef} /> // Render the chart on a canvas
            ) : (
              <MDTypography variant="body2" fontWeight="bold">
                No funding information available
              </MDTypography>
            )}
          </MDBox>

          {hasFunding && (
            <MDTypography variant="body2" fontWeight="bold" mt={3}>
              Total Funding Received: R {totalFunding}
            </MDTypography>
          )}
        </Grid>

        {/* Legend on the right */}
        {hasFunding && (
          <Grid item xs={12} sm={4}>
            <MDBox display="flex" flexDirection="column" mt={2}>
              {legend.map((item) => (
                <MDBox display="flex" alignItems="center" key={item.label} mb={1}>
                  <Icon
                    sx={{
                      width: 10,
                      height: 10,
                      backgroundColor: item.color,
                      borderRadius: "50%",
                      marginRight: "8px",
                    }}
                  />
                  <MDTypography variant="button" fontWeight="regular">
                    {item.label}
                  </MDTypography>
                </MDBox>
              ))}
            </MDBox>
          </Grid>
        )}
      </Grid>
    </MDBox>
  );
};

export default FundingPieChart;
