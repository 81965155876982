/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

//og imports mui: import Card from '@mui/material/Card';
//switch
//grid
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDInput from "components/generic/MDInput";
import MDButton from "components/generic/MDButton";

//refined (from og examples)
import Footer from "components/refined/Footer";
import FooterLogo from "../components/FooterLogo";
//recaptcha - not working/converted successfully yet
import ReCaptcha from "components/custom/Google/reCaptcha";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import { autoLogin, loginUser } from "services/auth";
import { login as loginVentureApi } from "services/ventureApi";

// Images
import bgImage from "assets/images/reisiger/profileBgImage.png";

// Context
import { useAuth } from "context/AuthContext";

import { LoginResponse } from "types/authTypes";
import EntityRoleSelection from "layouts/authentication/sign-in/components/EntityRoleSelection";

function SignIn(): JSX.Element {
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string>("");
  const [entities, setEntities] = useState<any[]>([]);
  const [showEntityRoleSelection, setShowEntityRoleSelection] = useState<boolean>(false);
  const [thisLoginResponse, setThisLoginResponse] = useState<LoginResponse | null>(null); //removed
  const { login, logout, entities: authEntities, loginResponse, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    // Proceed only if user is authenticated and loginResponse exists
    if (isAuthenticated && loginResponse) {
      const { state, user_profile, entity, entities } = loginResponse;

      if (entities && entities.length > 1 && !entity) {
        console.log("Multiple entities detected, showing entity selection.");
        setEntities(entities);
        setShowEntityRoleSelection(true);
        return; // break nav flow
      }

      if (
        (user_profile.status === "BUSINESS_COMPLETED" || state === "BUSINESS_COMPLETE") &&
        entity?.entity_id
      ) {
        console.log("Navigating to Business Profile");
        navigate("/profile/business-profile", {
          state: { user_profile: loginResponse.user_profile, entity: entity },
        });
        return;
      }

      if (
        (user_profile.status === "INVESTOR_COMPLETED" || state === "INVESTOR_COMPLETE") &&
        entity?.entity_id
      ) {
        console.log("Navigating to Investor Profile");
        navigate("/profile/investor-profile", {
          state: { user_profile: loginResponse.user_profile, entity: entity },
        });
        return;
      }

      let entityType = entity?.additional_info?.profile?.entityType; //discrepency in data structure pre and post onboarding, precedence to entity check
      if (!entityType) {
        entityType = user_profile?.additional_info?.profile?.entityType;
        //added reasigning logic
      }
      const entityId = entity?.entity_id;
      const roleId = entity?.role_id;
      const status = user_profile?.status; //gives issue on christelle@reisiger.org - have bsuiness_complete status under user pr

      console.log("Login State:", state);
      console.log("Entity Type:", entityType);
      console.log("Status:", status);

      // Add a small delay to ensure context is settled before navigating
      timeoutId = setTimeout(() => {
        // 1. Handle the PENDING_VERIFICATION state
        if (status === "PENDING_VERIFICATION") {
          console.log("Navigating to /profile/profile-building-agreement for PENDING_VERIFICATION");
          navigate("/profile/profile-building-agreement", {
            state: { user_profile: loginResponse.user_profile },
          });
        }
        // 2. Check for specific onboarding states like "startupDetails" or "investorBasics"
        else if (state === "startupDetails") {
          console.log("Navigating to /profile/welcome-page for 'startupDetails'");
          navigate("/profile/welcome-page");
        } else if (state === "investorBasics") {
          console.log("Navigating to /profile/welcome-page for 'investorBasics'");
          navigate("/profile/welcome-page");
        }
        // 3. Handle the Business onboarding states if entityType is Business
        else if (entityType === "Business") {
          console.log("Navigating for Business entity");
          if (
            state === "founderDetails" ||
            state === "industryDetails" ||
            state === "sdgDetails" ||
            state === "maturityDetails" ||
            state === "oneLinerDetails" ||
            state === "marketDetails" ||
            state === "financingDetails" ||
            state === "needsDetails" ||
            state === "uploadDetails"
          ) {
            console.log(`Navigating to /onboarding-setup-business for ${state}`);
            navigate("/onboarding-setup-business");
          }
          // If onboarding is completed, navigate to business profile
          else if (status === "BUSINESS_COMPLETED" || entityId) {
            console.log("Navigating to /profile/business-profile");
            navigate("/profile/business-profile");
          }
        }
        // 4. Handle the Investor onboarding states if entityType is Investor
        else if (entityType === "Investor") {
          console.log("Navigating for Investor entity");
          if (
            state === "fundingMech" ||
            state === "sdgDetails" ||
            state === "financingDetails" ||
            state === "oneLinerDetails" ||
            state === "mandateDetails" ||
            state === "needsDetails" ||
            state === "uploadDetails"
          ) {
            console.log(`Navigating to /onboarding-setup-investor for ${state}`);
            navigate("/onboarding-setup-investor");
          }
          // If onboarding is completed, navigate to investor profile
          else if (status === "INVESTOR_COMPLETED" || entityId) {
            console.log("Navigating to /profile/investor-profile");
            navigate("/profile/investor-profile");
          }
        }
        // 5. Handle any fallback case where no specific state or entityType matches
        else {
          console.log("Unhandled state or entity type, redirecting to default route.");
          navigate("/dashboards/default");
        }
      }, 500); // Introduce a delay of 500ms
    }

    // Cleanup function to clear the timeout if the component unmounts
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [loginResponse, isAuthenticated, navigate]);
  // Cleanup function to clear the timeout if the component unmounts
  //handle remember me logic later - to be integratedwith context login
  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    const rememberMeChecked = localStorage.getItem("rememberMe") === "true";

    if (rememberMeChecked && rememberedEmail) {
      setEmail(rememberedEmail);
      setRememberMe(rememberMeChecked);
    }

    // Attempt auto-login if this is a trusted device
    autoLogin();
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  useEffect(() => {
    console.log("clearing local storage");
    logout();
  }, []);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) =>
    event.preventDefault();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    console.log("in handle submit");

    try {
      // Attempt login
      await login(email, password, false, rememberMe);
      // No need to check loginResponse here immediately.
      // The `useEffect` will handle navigation based on loginResponse when it updates.
      /* moved to navigation use effect
      if (authEntities && authEntities.length > 1) {
        console.log("Multiple entities detected, showing entity selection.");
        setEntities(authEntities);
        setShowEntityRoleSelection(true);
        */
    } catch (error: any) {
      console.error("Login failed:", error);
      setMessage(error.message || "Login failed");
    }
  };

  const handleEntityRoleSelect = async (entityId: number, roleId: number) => {
    console.log("in handle entity role select");
    const selectedEntity = entities.find((e) => e.entity_id === entityId && e.role_id === roleId);
    if (selectedEntity) {
      console.log("if selectedEntity case");
      try {
        await login(email, password, false, rememberMe, entityId, roleId);
        //const response = await loginUser(email, password, false, rememberMe, entityId, roleId);
        //handleSuccessfulLogin({ ...response, entity: selectedEntity });
      } catch (error: any) {
        console.error("Login failed:", error);
        setMessage(error.message || "Login failed");
      }
    }
  };
  /*
  const handleSuccessfulLogin = (data: LoginResponse) => {
    console.log("in handleSuccessfulLogin");
    if (rememberMe) {
      localStorage.setItem("rememberedEmail", email);
      localStorage.setItem("rememberMe", "true");
    } else {
      localStorage.removeItem("rememberedEmail");
      localStorage.removeItem("rememberMe");
    }

    setMessage("Login successful!");
    if (data.entity) {
      console.log("if data.entity case");

      navigate(data.landing_route || "/", {
        state: { user_profile: data.user_profile, entity: data.entity },
      });
    } else {
      console.log("else data.entity case");
      navigate(data.landing_route || "/", { state: { user_profile: data.user_profile } });
    }
  };
  */

  const handleVerifyReCaptcha = (token: string | null) => {
    setRecaptchaToken(token || "");
  };

  return showEntityRoleSelection ? (
    <EntityRoleSelection entities={entities} onEntityRoleSelect={handleEntityRoleSelect} />
  ) : (
    <BasicLayout image={bgImage}>
      <Card
        sx={{
          minWidth: {
            xs: undefined,
            sm: undefined,
            md: undefined,
            lg: 440,
            xl: 440,
          },
          mb: 2,
        }}
      >
        <MDBox
          variant="contained"
          bgColor="primary"
          borderRadius="lg"
          //coloredShadow="primary"
          mx={2}
          mt={-3}
          px={2}
          py={{
            xs: 2,
            sm: 2,
            md: undefined,
            lg: 8,
            xl: 8,
          }}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={email}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type={showPassword ? "text" : "password"}
                label="Current Password"
                fullWidth
                value={password}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
            <Grid container justifyContent="space-between" sx={{ mt: 1, mb: 2 }}>
              <Grid item lg={5} xs={6}>
                <MDBox display="flex" alignItems="center" ml={-1}>
                  <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetRememberMe}
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Remember me
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item lg={4} xs={5}>
                <MDTypography
                  component={Link}
                  to="/authentication/reset-password"
                  variant="button"
                  color="text"
                  fontWeight="regular"
                >
                  Forgot Password?
                </MDTypography>
              </Grid>
            </Grid>
            {/*
            <MDBox mt={4} mb={1}>
              <ReCaptcha onVerify={handleVerifyReCaptcha} />
            </MDBox>
            */}
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="contained" color="primary" fullWidth>
                Sign In
              </MDButton>
            </MDBox>
            {message && (
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  {message}
                </MDTypography>
              </MDBox>
            )}
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="primary"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      {entities.length > 1 && (
        <Card
          sx={{
            minWidth: {
              xs: undefined,
              sm: undefined,
              md: undefined,
              lg: 440,
              xl: 440,
            },
            mb: 2,
          }}
        >
          <MDBox pt={4} pb={3} px={3}>
            <MDTypography variant="h5" textAlign="center">
              Select an Entity
            </MDTypography>
            {entities.map((entity) => (
              <MDButton
                key={entity.entity_id}
                fullWidth
                onClick={() => handleEntityRoleSelect(entity.entity_id, entity.role_id)}
              >
                {entity.entity_name} ({entity.role_name})
              </MDButton>
            ))}
          </MDBox>
        </Card>
      )}

      {/*
      <FooterLogo />
      <div
        style={{
          position: "fixed",
          bottom: "10px",
          left: "10px",
          zIndex: 1000,
        }}
      >
        <ReCaptcha onVerify={handleVerifyReCaptcha} />
      </div>
      */}
    </BasicLayout>
  );
}

export default SignIn;
