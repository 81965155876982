import React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/generic/MDBox";
import DashboardLayout from "components/refined/LayoutContainers/DashboardLayout";
import Footer from "components/refined/Footer";
import HeaderSettings from "./header";
import BasicInfo from "./components/BasicInfo";
import headerImage from "assets/images/startup-profile/settings-header.png";
import Sidenav from "./components/SideNav";
import Header from "./components/Header";

const UserProfileSettingsPage: React.FC = () => {
  return (
    <DashboardLayout>
      <HeaderSettings backgroundImage={headerImage}>
        <MDBox sx={{ width: "100%" }} mt={4}>
          <Grid container spacing={3}>
            {/* SideNav Column */}
            <Grid item xs={12} lg={3}>
              <Sidenav />
            </Grid>

            {/* Profile Header and Content */}
            <Grid item xs={12} lg={9}>
              <MDBox mb={3}>
                {" "}
                <Header />
              </MDBox>

              {/* Main Content */}
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <BasicInfo />
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </HeaderSettings>
      <Footer />
    </DashboardLayout>
  );
};

export default UserProfileSettingsPage;
