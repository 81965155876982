// utils.ts

import {
  BalanceSheetApiResponse,
  FinancialSection,
  FinancialDataItem,
} from "../layouts/pages/business/business-profile/financial-overview/types";

// Utility function to transform API data into the required format for Balance Sheet component
export function transformBalanceSheetApiResponse(apiResponse: BalanceSheetApiResponse): {
  balanceSheetData: FinancialSection[];
  asAtDate: string;
  currentDate: string;
  previousDate: string;
} {
  const balanceSheetData: FinancialSection[] = [];

  const report = apiResponse.Reports.find((r) => r.ReportID === "BalanceSheet");
  if (!report) {
    return { balanceSheetData, asAtDate: "N/A", currentDate: "N/A", previousDate: "N/A" };
  }

  // Extract the "As at" date from the ReportTitles (e.g., "As at 30 September 2024")
  const asAtDate = report.ReportTitles[2] || "As at N/A";

  // Extract year and month for "As at" date using string manipulation
  const dateParts = asAtDate.match(/As at (\d{1,2}) (\w+) (\d{4})/);
  if (!dateParts) {
    return { balanceSheetData, asAtDate, currentDate: "N/A", previousDate: "N/A" };
  }

  const [, day, month, year] = dateParts;
  const abbreviatedMonth = month.slice(0, 3);
  const currentDate = `${day} ${abbreviatedMonth} ${year}`; // Current year date
  const previousYear = parseInt(year) - 1;
  const previousDate = `${day} ${abbreviatedMonth} ${previousYear}`; // Previous year date

  // Loop through sections to build the balance sheet data
  for (const section of report.Rows) {
    if (section.RowType === "Section") {
      const items: FinancialDataItem[] = [];

      for (const row of section.Rows) {
        if (row.RowType === "Row") {
          // Handle Row structure with three cells: subcategory, amountCurrent, amountPrevious
          const [subcategory, amountCurrentStr, amountPreviousStr] = row.Cells.map(
            (cell) => cell?.Value
          );

          const amountCurrent = amountCurrentStr
            ? parseFloat(amountCurrentStr.replace(/,/g, ""))
            : null;
          const amountPrevious = amountPreviousStr
            ? parseFloat(amountPreviousStr.replace(/,/g, ""))
            : null;

          items.push({
            category: section.Title || "", // Use section title as the category
            subcategory: subcategory || "", // Use the first cell as subcategory
            amountCurrent: isNaN(amountCurrent) ? null : amountCurrent,
            amountPrevious: isNaN(amountPrevious) ? null : amountPrevious,
          });
          /*
        if (row.RowType === "Row") {
          // Handle Row structure with four cells: category, subcategory, amountCurrent, amountPrevious
          const [category, subcategory, amountCurrent, amountPrevious] = row.Cells.map(
            (cell) => cell.Value
          );

          items.push({
            category: category || section.Title,
            subcategory: subcategory || null,
            amountCurrent: amountCurrent ? parseFloat(amountCurrent) : null,
            amountPrevious: amountPrevious ? parseFloat(amountPrevious) : null,
          });
          */
        } else if (row.RowType === "SummaryRow") {
          // Handle SummaryRow structure with only three cells: subcategory, amountCurrent, amountPrevious
          const [subcategory, amountCurrent, amountPrevious] = row.Cells.map((cell) => cell.Value);

          items.push({
            category: section.Title, // Use section title as category for summary rows
            subcategory: subcategory || null,
            amountCurrent: amountCurrent ? parseFloat(amountCurrent) : null,
            amountPrevious: amountPrevious ? parseFloat(amountPrevious) : null,
            isHeader: true, // Mark summary rows as headers
          });
        }
      }

      balanceSheetData.push({
        category: section.Title,
        items,
      });
    }
  }

  return { balanceSheetData, asAtDate, currentDate, previousDate };
}
