import React, { useState, useEffect } from "react";
import { Grid, Typography, Radio, FormControlLabel } from "@mui/material";
import { MaturityDetails2Props } from "../../types"; // Import the props

const options = {
  willingness: [
    { label: "None", value: "None" },
    { label: "Low", value: "Low" },
    { label: "Moderate", value: "Moderate" },
    { label: "High", value: "High" },
    { label: "Unsure", value: "Unsure" },
  ],
  capacity: [
    { label: "None", value: "None" },
    { label: "Low", value: "Low" },
    { label: "Moderate", value: "Moderate" },
    { label: "High", value: "High" },
    { label: "Unsure", value: "Unsure" },
  ],
};

const MaturityDetails2: React.FC<MaturityDetails2Props> = ({ formData, setFormData }) => {
  const [riskAppetite, setRiskAppetite] = useState<string>(
    formData.maturityDetails?.riskAppetite || ""
  );
  const [riskTolerance, setRiskTolerance] = useState<string>(
    formData.maturityDetails?.riskTolerance || ""
  );

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      maturityDetails: {
        ...prevData.maturityDetails,
        riskAppetite,
        riskTolerance,
      },
    }));
  }, [riskAppetite, riskTolerance, setFormData]);

  const handleWillingnessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRiskAppetite(event.target.value);
  };

  const handleCapacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRiskTolerance(event.target.value);
  };

  return (
    <Grid container px={{ xs: 2, lg: 5 }} spacing={3}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h5">Business Risk</Typography>
        <Grid mt={5} container direction="column">
          <Typography sx={{ fontSize: "14px" }} variant="body1">
            What is the business willingness to take risk / risk appetite?
          </Typography>
          {options.willingness.map((option) => (
            <Grid item key={option.value}>
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    color: "#7B809A",
                    fontWeight: "400",
                  },
                }}
                value={option.value}
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        borderRadius: "4px",
                      },
                      "&:after": {
                        borderRadius: "2px",
                      },
                    }}
                  />
                }
                label={option.label}
                onChange={(event) =>
                  handleWillingnessChange(event as React.ChangeEvent<HTMLInputElement>)
                }
                checked={riskAppetite === option.value}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid mt={{ xs: 2, lg: 8 }} item xs={12} sm={6}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          What is the business capacity to take risk / risk tolerance?
        </Typography>
        {options.capacity.map((option) => (
          <Grid item key={option.value}>
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                  color: "#7B809A",
                  fontWeight: "400",
                },
              }}
              value={option.value}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      borderRadius: "4px",
                    },
                    "&:after": {
                      borderRadius: "2px",
                    },
                  }}
                />
              }
              label={option.label}
              onChange={(event) =>
                handleCapacityChange(event as React.ChangeEvent<HTMLInputElement>)
              }
              checked={riskTolerance === option.value}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default MaturityDetails2;
