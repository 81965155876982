import React from "react";
import MDButton from "components/generic/MDButton"; // Adjust the path if needed

const FeedbackButton: React.FC = () => {
  return (
    <MDButton
      href="https://share-eu1.hsforms.com/1tSYB4fdJTyKprNAC0as67Q2dypu8"
      target="_blank"
      rel="noopener noreferrer"
      variant="gradient"
      color="primary" // previously feedback - adjust the color later if needed
      sx={{
        position: "fixed",
        top: "70%",
        right: "-30px",
        zIndex: 1000,
        transform: "rotate(-90deg)",
        transformOrigin: "center",
        fontSize: "14px",
        px: 2,
        pb: 0,
        pt: 0,
        borderRadius: "8px",
        width: "92px",
        minHeight: "26px",
        maxHeight: "26px",
        backgroundColor: "#424141",
        color: "white",
        "&:hover": {
          backgroundColor: "#303030",
        },
      }}
    >
      Feedback
    </MDButton>
  );
};

export default FeedbackButton;
