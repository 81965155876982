import React, { useEffect, useState, FormEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDInput from "components/generic/MDInput";
import MDButton from "components/generic/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/reisiger/profileBgImage.png";
import { EmailOutlined } from "@mui/icons-material";
import { verifyEmail, sendEmailVerification, verifyEmailNewTeamMember } from "services/auth";
import uiConfig from "assets/ui-config/ui-config";
import { RegistrationData } from "types/authTypes";
import { useSearchParams } from "react-router-dom";

const UserEmailConfirmation: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [countdown, setCountdown] = useState<number>(60);
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(true);
  const navigate = useNavigate();
  const [registrationData, setRegistrationData] = useState<RegistrationData | null>(null);

  const [searchParams] = useSearchParams();
  const referenceKey = searchParams.get("referenceKey");

  useEffect(() => {
    const storedRegistrationData = localStorage.getItem("registrationData");
    if (storedRegistrationData) {
      const parsedData: RegistrationData = JSON.parse(storedRegistrationData);
      setRegistrationData(parsedData);
      if (parsedData.userInfo?.email) {
        setEmail(parsedData.userInfo.email);
      }
    }

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(timer);
          setIsResendDisabled(false);
          return 60;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      if (registrationData?.userInfo?.referenceKey) {
        console.log("registration submit reached", registrationData);
        await verifyEmailNewTeamMember(email, code, registrationData.userInfo.referenceKey);
      } else {
        await verifyEmail(email, code);
      }
      setMessage("Verification successful!");
      //navigate("/authentication/sign-in");
      //navigate("/profile/profile-building-agreement");
      console.log("reference key in email conf", referenceKey);
      navigate(
        `/profile/profile-building-agreement${referenceKey ? `?referenceKey=${referenceKey}` : ""}`
      );
    } catch (error: any) {
      setMessage("Error verifying: " + error.message);
    }
  };

  const handleResend = async () => {
    try {
      await sendEmailVerification(email);
      setMessage("Verification code resent. Please check your email.");
      setIsResendDisabled(true);
      setCountdown(60);
    } catch (error: any) {
      setMessage("Error resending verification code: " + error.message);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", my: 8 }}>
        <Card
          sx={{
            minWidth: {
              xs: undefined,
              sm: undefined,
              md: undefined,
              lg: 900,
              xl: 900,
            },
          }}
        >
          <MDBox
            variant="contained"
            bgColor="primary"
            borderRadius="lg"
            coloredShadow="primary"
            mx={2}
            mt={-3}
            mb={1}
            py={6}
            textAlign="center"
          />
          <MDBox
            sx={{ maxWidth: "390px" }}
            mx={"auto"}
            pt={{
              xs: 2,
              lg: 6,
            }}
            pb={{
              xs: 2,
              lg: 5,
            }}
            px={{
              xs: 2,
              lg: 6,
            }}
          >
            <MDBox
              component="form"
              role="form"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              gap={3}
              px={5}
              onSubmit={handleSubmit}
            >
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ fontSize: 80, height: 80, overflowY: "hide" }}
              >
                <EmailOutlined sx={{ color: "black" }} />
              </MDBox>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                gap={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="text">
                  Check your inbox
                </MDTypography>
                <MDTypography display="block" variant="button" color="text">
                  Please enter the verification code sent to your email address:
                </MDTypography>
                <MDTypography variant="h5" fontWeight="medium" color="text">
                  {email || "your-email@example.com"}
                </MDTypography>
              </MDBox>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                color="text"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              />
              <MDInput
                type="text"
                label="6-digit security code"
                fullWidth
                color="text"
                value={code}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
              />
              <MDButton
                variant="text"
                color="info"
                disabled={isResendDisabled}
                onClick={handleResend}
              >
                Resend {isResendDisabled ? `(${countdown}s)` : ""}
              </MDButton>
              <MDTypography variant="button" color="text">
                <a
                  href={uiConfig.needHelpLinks.hubSpotLink.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Need help?
                </a>
              </MDTypography>
              <MDTypography variant="button" color="text" mt={2}>
                {message}
              </MDTypography>
              <MDBox mt={5}>
                <MDButton
                  type="submit"
                  sx={{ textTransform: "uppercase" }}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Submit
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
    </BasicLayout>
  );
};

export default UserEmailConfirmation;
