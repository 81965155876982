import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
import MDTooltip from "components/generic/MDTooltip";
import Share from "@mui/icons-material/Share";
import { useAuth } from "context/AuthContext";
import DetailCard from "./components/DetailCard";
import OneLiner from "./components/OneLiner";
import FundingPieChart from "./components/FundingPieChart";
import TeamMemberCard from "./components/TeamMemberCard";
import ComingSoonCard from "./components/ComingSoonCard";
import editIcon from "assets/images/startup-profile/account-edit.svg";
import Icon from "@mui/material/Icon";
import avatarImage from "assets/avatar.jpeg"; //later ro be dynamic
import blank_profile from "assets/blank_profile.png"; //later ro be dynamic
import AddTeamMemberModal from "./components/AddTeamMemberModal";
import DefaultItem from "components/refined/Items/DefaultItem";
/*
const teamMembers = [
  { name: "Steven Blake", role: "COO", imgSrc: avatarImage, canMessage: true },
  { name: "Anne Marie", role: "Founder", imgSrc: avatarImage, canMessage: true },
  { name: "Ivanna Groop", role: "Developer", imgSrc: avatarImage, canMessage: true },
  { name: "Maddy Peterson", role: "Director", imgSrc: avatarImage, canMessage: true },
];
*/
interface TeamMember {
  name: string;
  role: string;
  imgSrc: string;
  canMessage: boolean;
}

const About: React.FC = () => {
  const { loginResponse, user } = useAuth();

  const [openAddTeamModal, setOpenAddTeamModal] = useState<boolean>(false);

  const handleOpenModal = () => {
    setOpenAddTeamModal(true);
  };

  const handleCloseModal = () => {
    setOpenAddTeamModal(false);
  };
  /*
  user.role = "Administrator"; //test
  if (!user) {
    user.role = "Administrator";
  }
  */
  const userData = loginResponse ? loginResponse.entity : null;
  //detail cards prop content
  const industry = userData
    ? userData.additional_info.industryDetails.industryDetails[0].industrySection
    : "Loading...";
  const operationalLocations = userData
    ? userData.additional_info.marketDetails.scaleOfOperations
    : "Loading...";
  const founders = userData
    ? userData.additional_info.founderDetails.founderDetails.length.toString()
    : "Loading...";
  const problemMaturity = userData
    ? userData.additional_info.oneLinerDetails.problemMaturity === "other"
      ? userData.additional_info.oneLinerDetails.problemMaturityOtherInput
      : userData.additional_info.oneLinerDetails.problemMaturity
    : "Loading...";
  const establishmentDate = userData
    ? userData.additional_info.entityDetails.establishmentDate
    : "Loading...";
  const address = userData ? userData.additional_info.entityDetails.country : "Loading...";
  //oneliner prop content
  const entityName = userData ? userData.entity_name : "Loading...";
  const problemOutline = userData
    ? userData.additional_info.oneLinerDetails.problemOutline
    : "Loading...";
  const keyOffering = userData
    ? userData.additional_info.oneLinerDetails.keyOffering
    : "Loading...";
  const targetMarket = userData
    ? userData.additional_info.oneLinerDetails.targetMarket
    : "Loading...";
  const valueProposition = userData
    ? userData.additional_info.oneLinerDetails.valueProposition
    : "Loading...";
  // Funding information placeholders (to be integrated with data)
  const totalFunding = userData?.additional_info.financingDetails.totalFundingRaised || "No";
  const fundingBreakdown = userData?.additional_info.financingDetails.fundingBreakdown || {};
  /*
  const fundingBreakdown = userData
    ? userData.additional_info.financingDetails.fundingBreakdown
    : null;
  const futureFundingNeed = userData
    ? userData.additional_info.financingDetails.futureFundingNeed
    : null;
  const totalFunding = userData
    ? userData.additional_info.financingDetails.totalFundingReceived
    : "Loading...";
    */
  //team information - dummy for now - refactor to use context data when available and testable.
  const teamMembers: TeamMember[] =
    userData?.additional_info?.new_team_members?.map((member: any) => ({
      name: `${member.first_name} ${member.last_name}`,
      role: member.role,
      imgSrc: blank_profile, // Use avatarImage as the default
      canMessage: true, // Set message button visibility here if necessary
    })) || [];
  return (
    <Card
      sx={{
        mt: -3,
        p: 1,
        mx: 3,
        mb: 2,
      }}
    >
      {/* Main Grid Structure */}
      <Grid container spacing={2}>
        {/* Left Column: Detail Cards */}
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3, boxShadow: "none" }}>
            <MDBox>
              <MDTypography variant="h5" fontWeight="medium" mb={2}>
                About
              </MDTypography>

              {/* Vertical Stack of Detail Cards */}
              <Grid container spacing={2.5}>
                <Grid item xs={12}>
                  <DefaultItem
                    icon="agriculture"
                    title="Industry"
                    description={industry}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DefaultItem
                    icon="place"
                    title="Operational Locations"
                    description={operationalLocations}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DefaultItem
                    icon="group"
                    title="Founders"
                    description={founders}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DefaultItem
                    icon="assessment"
                    title="Problem Maturity"
                    description={problemMaturity}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DefaultItem
                    icon="event"
                    title="Establishment Date"
                    description={establishmentDate}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DefaultItem
                    icon="location_on"
                    title="Physical Location"
                    description={address}
                    color="primary"
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>

        {/* Middle Column: Profile Information and Chart */}
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3, boxShadow: "none" }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium" mb={2}>
                  Profile Information
                </MDTypography>
              </Grid>
              <Grid item>
                <MDBox component="img" src={editIcon} alt="edit" sx={{ width: 24 }} />
              </Grid>
            </Grid>
            <OneLiner
              entityName={entityName}
              problemOutline={problemOutline}
              keyOffering={keyOffering}
              targetMarket={targetMarket}
              valueProposition={valueProposition}
            />
            {/* Chart */}
            <MDBox mt={4}>
              <FundingPieChart totalFunding={totalFunding} fundingBreakdown={fundingBreakdown} />
            </MDBox>
          </Card>
        </Grid>

        {/* Right Column: Team Section */}
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3, boxShadow: "none" }}>
            <MDTypography variant="h5" fontWeight="medium" mb={2}>
              Team
            </MDTypography>
            {teamMembers.length > 0 ? (
              teamMembers.map((member: TeamMember) => (
                <TeamMemberCard
                  key={member.name}
                  name={member.name}
                  role={member.role}
                  imgSrc={member.imgSrc}
                  canMessage={member.canMessage}
                  isAdmin={user?.role === "Administrator"}
                />
              ))
            ) : (
              <MDTypography variant="body2">No team members available</MDTypography>
            )}
            {user?.role === "Administrator" && (
              <MDBox mt={3} maxWidth="60%" mx="auto">
                <MDButton variant="gradient" color="dark" fullWidth onClick={handleOpenModal}>
                  <Icon>add</Icon>
                  &nbsp;Add Team Member
                </MDButton>
              </MDBox>
            )}
          </Card>
        </Grid>
      </Grid>

      {/* Coming Soon Section */}
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 3, boxShadow: "none" }}>
            <MDTypography variant="h5" fontWeight="medium" mb={2}>
              Suggested Products
            </MDTypography>
            <ComingSoonCard />
          </Card>
        </Grid>
      </Grid>
      <AddTeamMemberModal open={openAddTeamModal} handleClose={handleCloseModal} />
    </Card>
  );
};

export default About;
