import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import PropTypes from "prop-types";
import { Mandate3Props, MandateDetails } from "../../types";
/*
interface MandateDetails {
  ticketSizeMaximum?: string;
  ticketSizeTypical?: string;
  ticketSizeMinimum?: string;
  ticketSizeCurrency?: string;
  typicalInvestmentCurrency?: string;
  stages?: string[];
}

interface FormData {
  mandateDetails: MandateDetails;
}

interface Mandate3Props {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}
*/
const Mandate3: React.FC<Mandate3Props> = ({ formData, setFormData }) => {
  const [ticketSizeMaximum, setTicketSizeMaximum] = useState<string>(
    formData.mandateDetails.ticketSizeMaximum || ""
  );
  const [ticketSizeTypical, setTicketSizeTypical] = useState<string>(
    formData.mandateDetails.ticketSizeTypical || ""
  );
  const [ticketSizeMinimum, setTicketSizeMinimum] = useState<string>(
    formData.mandateDetails.ticketSizeMinimum || ""
  );

  const currencies = ["R", "USD", "EUR", "GBP", "JPY", "CAD", "AUD"];
  const [ticketSizeCurrency, setTicketSizeCurrency] = useState<string>(
    formData.mandateDetails.ticketSizeCurrency || currencies[0]
  );
  const [typicalInvestmentCurrency, setTypicalInvestmentCurrency] = useState<string>(
    formData.mandateDetails.typicalInvestmentCurrency || currencies[0]
  );

  const stages = [
    "Idea generation / Validation Stage",
    "Launch Stage",
    "Expansion Stage",
    "Maturity Stage",
    "Financial",
    "Product Development Stage",
    "Early Growth Stage",
    "Scaling Stage",
    "Pivot Stage",
  ];

  const [selectedStages, setSelectedStages] = useState<string[]>(
    formData.mandateDetails.stages || []
  );

  useEffect(() => {
    const mandateData = {
      ticketSizeMaximum,
      ticketSizeTypical,
      ticketSizeMinimum,
      ticketSizeCurrency,
      typicalInvestmentCurrency,
      stages: selectedStages,
    };
    setFormData((prevData) => ({
      ...prevData,
      mandateDetails: {
        ...prevData.mandateDetails,
        ...mandateData,
      },
    }));
  }, [
    ticketSizeMaximum,
    ticketSizeTypical,
    ticketSizeMinimum,
    ticketSizeCurrency,
    typicalInvestmentCurrency,
    selectedStages,
    setFormData,
  ]);

  const handleTicketSizeMaximumChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTicketSizeMaximum(event.target.value);
  };

  const handleTicketSizeTypicalChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTicketSizeTypical(event.target.value);
  };

  const handleTicketSizeMinimumChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTicketSizeMinimum(event.target.value);
  };

  const handleStageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.name;
    setSelectedStages((prevStages) =>
      prevStages.includes(value)
        ? prevStages.filter((stage) => stage !== value)
        : [...prevStages, value]
    );
  };

  return (
    <Grid container px={{ xs: 0.5, lg: 5 }} mb={5} spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Investor Mandate Startup Requirements</Typography>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Please complete the following information regarding your mandate in general:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MDBox sx={{ paddingTop: { xs: "4px", xl: "14px" } }}>
          <MDBox>
            <MDTypography variant="button" color="text">
              Regarding your investment mandate, businesses at which developmental stage are
              eligible for funding?
            </MDTypography>
          </MDBox>
          <Grid container p={{ xs: 0.5, lg: 2 }}>
            {stages.slice(0, 5).map((label, index) => (
              <Grid item xs={12} lg={5} key={index} display={"flex"} alignItems={"center"}>
                <FormGroup sx={{ display: "flex" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedStages.includes(label)}
                        onChange={handleStageChange}
                        name={label}
                      />
                    }
                    label={label}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "15px",
                        color: "#7B809A",
                        fontWeight: "400",
                      },
                    }}
                  />
                </FormGroup>
              </Grid>
            ))}
            {stages.slice(5).map((label, index) => (
              <Grid item xs={12} lg={5} key={index} display={"flex"} alignItems={"center"}>
                <FormGroup sx={{ display: "flex" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedStages.includes(label)}
                        onChange={handleStageChange}
                        name={label}
                      />
                    }
                    label={label}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "15px",
                        color: "#7B809A",
                        fontWeight: "400",
                      },
                    }}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </MDBox>
      </Grid>
      <Grid item xs={12} my={4}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          What is the average ticket size / investment amounts that is typically awarded?
        </Typography>
      </Grid>
      <Grid container item alignItems="center">
        <Grid item lg={2} xs={4}>
          <Typography sx={{ fontSize: "14px" }} variant="body1">
            Typical investment size
          </Typography>
        </Grid>
        <Grid item lg={2} xs={3}>
          <Autocomplete
            disableClearable
            value={typicalInvestmentCurrency}
            onChange={(event, newValue) => {
              setTypicalInvestmentCurrency(newValue);
            }}
            options={currencies}
            size="small"
            sx={{ width: "70px" }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item display="flex" alignItems="center" gap={1} lg={3} xs={5}>
          <TextField
            label="Typical investment size"
            value={ticketSizeTypical}
            onChange={handleTicketSizeTypicalChange}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item xs={12} my={4}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          What are the maximum and minimum ticket size / investment amounts awarded?*
        </Typography>
      </Grid>
      <Grid container item alignItems="center">
        <Grid item lg={2} xs={4}>
          <Typography sx={{ fontSize: "14px" }} variant="body1">
            Higher Limit
          </Typography>
        </Grid>
        <Grid item lg={2} xs={3}>
          <Autocomplete
            disableClearable
            value={ticketSizeCurrency}
            onChange={(event, newValue) => {
              setTicketSizeCurrency(newValue);
            }}
            options={currencies}
            size="small"
            sx={{ width: "70px" }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item display="flex" alignItems="center" gap={1} lg={3} xs={5}>
          <TextField
            label="Please Enter Amount"
            value={ticketSizeMaximum}
            onChange={handleTicketSizeMaximumChange}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container item alignItems="center">
        <Grid item lg={2} xs={4}>
          <Typography sx={{ fontSize: "14px" }} variant="body1">
            Lower Limit
          </Typography>
        </Grid>
        <Grid item lg={2} xs={3}>
          <Autocomplete
            disableClearable
            value={ticketSizeCurrency}
            onChange={(event, newValue) => {
              setTicketSizeCurrency(newValue);
            }}
            options={currencies}
            size="small"
            sx={{ width: "70px" }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item display="flex" alignItems="center" gap={1} lg={3} xs={5}>
          <TextField
            label="Please Enter Amount"
            value={ticketSizeMinimum}
            onChange={handleTicketSizeMinimumChange}
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Mandate3;
