import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Card,
} from "@mui/material";

interface FinancialDataItem {
  category: string;
  subcategory: string | null;
  amountCurrent: number | null;
  amountPrevious: number | null;
  isHeader?: boolean;
}

interface FinancialSection {
  category: string;
  items: FinancialDataItem[];
}

interface ProfitAndLossProps {
  profitData: FinancialSection[];
  loading: boolean;
  asAtDate: string;
  currentDate: string;
  previousDate: string;
}

const ProfitAndLoss: React.FC<ProfitAndLossProps> = ({
  profitData,
  loading,
  asAtDate,
  currentDate,
  previousDate,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const isMatch = (text: string | null) => {
    if (!text) return false;
    return text.toLowerCase().includes(searchQuery.toLowerCase());
  };

  if (loading) {
    return (
      <Box sx={{ padding: 2 }}>
        <Typography variant="body2">Loading Profit and Loss...</Typography>
      </Box>
    );
  }

  return (
    <Card sx={{ padding: 2 }}>
      <Typography variant="h4">Profit and Loss</Typography>

      {/* Search Bar and Date */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: 2,
        }}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ flexGrow: 1, marginRight: 2, paddingBottom: 2 }}
        />
        <Typography variant="subtitle2" sx={{ whiteSpace: "nowrap" }}>
          {asAtDate}
        </Typography>
      </Box>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 550 }}>
          <TableHead
            sx={{
              display: "table-header-group",
            }}
          >
            <TableRow>
              <TableCell sx={{ padding: "4px 8px" }}>Description</TableCell>
              <TableCell sx={{ padding: "4px 8px", textAlign: "right" }}>{currentDate}</TableCell>
              <TableCell sx={{ padding: "4px 8px", textAlign: "right" }}>{previousDate}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profitData.map((section) => (
              <React.Fragment key={section.category}>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold", fontSize: 14 }}
                      gutterBottom
                    >
                      {section.category}
                    </Typography>
                  </TableCell>
                </TableRow>
                {section.items.map((item, index) => {
                  // Determine if the row matches the search query
                  const matchesSearch =
                    searchQuery !== "" && (isMatch(item.category) || isMatch(item.subcategory));

                  return (
                    <TableRow
                      key={index}
                      sx={{
                        height: "36px",
                        fontWeight:
                          item.isHeader || item.subcategory?.startsWith("Total")
                            ? "bold"
                            : "normal",
                        backgroundColor: item.isHeader
                          ? "#f0f0f0"
                          : matchesSearch
                            ? "rgba(255, 235, 59, 0.5)" // Highlight color
                            : "inherit",
                      }}
                    >
                      <TableCell sx={{ padding: "4px 32px" }}>
                        {item.subcategory ? (
                          item.subcategory
                        ) : (
                          <Typography variant="body2" sx={{ fontSize: 14 }}>
                            {item.category}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell sx={{ padding: "4px 8px", textAlign: "right" }}>
                        {item.amountCurrent !== null ? item.amountCurrent.toFixed(2) : "-"}
                      </TableCell>
                      <TableCell sx={{ padding: "4px 8px", textAlign: "right" }}>
                        {item.amountPrevious !== null ? item.amountPrevious.toFixed(2) : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </React.Fragment>
            ))}
          </TableBody>
          {/* Footer Section */}
          <TableFooter>
            <TableRow>
              <TableCell colSpan={3}>{/* Footer content can go here */}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ProfitAndLoss;
