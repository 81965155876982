import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, FormControlLabel, Radio, Autocomplete } from "@mui/material";
import MDBox from "components/generic/MDBox";
import { FinancingDetailsProps } from "../../../types";

const FinancingDetails: React.FC<FinancingDetailsProps> = ({ formData, setFormData }) => {
  const [recentFundingValuation, setRecentFundingValuation] = useState<string>(
    formData.financingDetails.recentFundingValuation || ""
  );
  const [recentFundingValuationCurrency, setRecentFundingValuationCurrency] = useState<string>(
    formData.financingDetails.recentFundingValuationCurrency || "R"
  );
  const [totalFundingRaised, setTotalFundingRaised] = useState<string>(
    formData.financingDetails.totalFundingRaised || ""
  );
  const [totalFundingCurrency, setTotalFundingCurrency] = useState<string>(
    formData.financingDetails.totalFundingCurrency || "R"
  );
  const [futureFundingNeed, setFutureFundingNeed] = useState<string>(
    formData.financingDetails.futureFundingNeed || ""
  );
  const [futureFundingNeedCurrency, setFutureFundingNeedCurrency] = useState<string>(
    formData.financingDetails.futureFundingNeedCurrency || "R"
  );
  const [futureFundingUse, setFutureFundingUse] = useState<string>(
    formData.financingDetails.futureFundingUse || ""
  );
  const [futureFundingRequired, setFutureFundingRequired] = useState<string>(
    formData.financingDetails.futureFundingRequired || ""
  );

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      financingDetails: {
        recentFundingValuation,
        recentFundingValuationCurrency,
        totalFundingRaised,
        totalFundingCurrency,
        futureFundingNeed,
        futureFundingNeedCurrency,
        futureFundingUse,
        futureFundingRequired,
      },
    }));
  }, [
    recentFundingValuation,
    recentFundingValuationCurrency,
    totalFundingRaised,
    totalFundingCurrency,
    futureFundingNeed,
    futureFundingNeedCurrency,
    futureFundingUse,
    futureFundingRequired,
    setFormData,
  ]);

  const currencies = ["R", "USD", "EUR", "GBP", "JPY", "CAD", "AUD"]; // Dummy currency options

  const handleRadioChange = (event: React.SyntheticEvent, checked: boolean) => {
    const { value } = event.target as HTMLInputElement;
    setFutureFundingRequired(value);
  };

  const handleCurrencyChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (event: React.SyntheticEvent, newValue: string | null) => {
      setter(newValue || "");
    };

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
    };

  return (
    <Grid container px={{ xs: 2, xl: 3 }} rowSpacing={4} alignItems="center">
      <Grid item xs={12} md={4}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          The Valuation of the most recent funding round was ______
        </Typography>
      </Grid>
      <Grid item xs={1}></Grid> {/* Adding space */}
      <Grid mr={1} item xs={2} md={1}>
        <Autocomplete
          disableClearable
          value={recentFundingValuationCurrency}
          onChange={handleCurrencyChange(setRecentFundingValuationCurrency)}
          options={currencies}
          size="small"
          sx={{ width: "60px" }}
          renderInput={(params) => <TextField {...params} />}
        />
      </Grid>
      <Grid item xs={8} md={3} lg={4} xl={5}>
        <TextField
          label="Please Enter Amount"
          value={recentFundingValuation}
          onChange={handleInputChange(setRecentFundingValuation)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          As of today, our startup has secured a total of ________ amount of funding. (Include all
          funds)
        </Typography>
      </Grid>
      <Grid item xs={1}></Grid> {/* Adding space */}
      <Grid item mr={1} xs={2} md={1}>
        <Autocomplete
          disableClearable
          value={totalFundingCurrency}
          onChange={(event, newValue) => setTotalFundingCurrency(newValue as string)}
          options={currencies}
          size="small"
          sx={{ width: "60px" }}
          renderInput={(params) => <TextField {...params} />}
        />
      </Grid>
      <Grid item xs={8} md={3} lg={4} xl={5}>
        <TextField
          label="Please Enter Amount"
          value={totalFundingRaised}
          onChange={(event) => setTotalFundingRaised(event.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Do you have further funding needs?
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          sx={{
            "& .MuiFormControlLabel-label": {
              fontSize: "14px",
              color: "#7B809A",
              fontWeight: "400",
            },
          }}
          value="No"
          control={
            <Radio
              sx={{
                "& .MuiSvgIcon-root": { borderRadius: "4px" },
                "&:after": { borderRadius: "2px" },
              }}
            />
          }
          label="Not at the moment"
          onChange={handleRadioChange}
          checked={futureFundingRequired === "No"}
        />
        <FormControlLabel
          sx={{
            "& .MuiFormControlLabel-label": {
              fontSize: "14px",
              color: "#7B809A",
              fontWeight: "400",
            },
          }}
          value="Not Sure"
          control={
            <Radio
              sx={{
                "& .MuiSvgIcon-root": { borderRadius: "4px" },
                "&:after": { borderRadius: "2px" },
              }}
            />
          }
          label="Not sure"
          onChange={handleRadioChange}
          checked={futureFundingRequired === "Not Sure"}
        />
        <FormControlLabel
          sx={{
            "& .MuiFormControlLabel-label": {
              fontSize: "14px",
              color: "#7B809A",
              fontWeight: "400",
            },
          }}
          value="Yes"
          control={
            <Radio
              sx={{
                "& .MuiSvgIcon-root": { borderRadius: "4px" },
                "&:after": { borderRadius: "2px" },
              }}
            />
          }
          label={
            futureFundingRequired === "Yes" ? (
              <MDBox
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                alignItems="flex-start"
                justifyContent="start"
                gap={2}
                width={{ xs: "100%", md: "100%", xl: "100%" }}
              >
                <Autocomplete
                  disableClearable
                  value={futureFundingNeedCurrency}
                  onChange={handleCurrencyChange(setFutureFundingNeedCurrency)}
                  options={currencies}
                  size="small"
                  sx={{ width: "60px" }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <MDBox
                  display="flex"
                  flexDirection="column"
                  gap={0.5}
                  alignItems="flex-end"
                  justifyContent="space-between"
                  width="100%"
                >
                  <TextField
                    placeholder="Please Enter Amount"
                    value={futureFundingNeed}
                    onChange={handleInputChange(setFutureFundingNeed)}
                    fullWidth
                    InputProps={{ style: { fontSize: "11px" } }} // Custom style for the input element
                  />
                  <TextField
                    placeholder="Please specify what proceeds were used for"
                    value={futureFundingUse}
                    onChange={handleInputChange(setFutureFundingUse)}
                    fullWidth
                    InputProps={{ style: { fontSize: "11px" } }} // Custom style for the input element
                  />
                </MDBox>
              </MDBox>
            ) : (
              "Yes"
            )
          }
          onChange={handleRadioChange}
          checked={futureFundingRequired === "Yes"}
        />
      </Grid>
    </Grid>
  );
};

export default FinancingDetails;
