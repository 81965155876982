export const navigateToExternalApp = (
  returnUrl: string,
  externalAppUrl: string,
  additionalParams?: Record<string, string>
) => {
  localStorage.setItem("intendedRoute", returnUrl);

  const urlParams = new URLSearchParams({
    returnUrl,
    ...additionalParams,
  });

  const targetUrl = `${externalAppUrl}?${urlParams.toString()}`;

  // Navigate to the external app
  window.location.href = targetUrl;
};

/*
// Example
// navigateToExternalApp(
//  'http://localhost:3000',
//  'http://localhost:3002',
//  { userId: '12345', sessionToken: 'abcdef' }
);
*/

//TODOs:
//CORS configuration
//in external app we need a return handler to fetch originUrl to return to
//gee redirect url in params - nie noodwendig origin nie - external app weet reeds waar hy vandaan kom.
