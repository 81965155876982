import { useState, useEffect, ReactNode } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import backgroundImage from "assets/images/reisiger/profileHeader.png";
import profileStartImg from "assets/images/reisiger/profile_start.png";
import MDTypography from "components/generic/MDTypography";
import DashboardNavbar from "./DashboardNavbar";
import uiConfig from "assets/ui-config/ui-config";
import FooterLogo from "../../../../layouts/authentication/components/FooterLogo";

interface HeaderProps {
  children?: ReactNode;
}

const Header: React.FC<HeaderProps> = ({ children }) => {
  return (
    <MDBox position="relative" mt={2}>
      <DashboardNavbar />
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        minHeight="10rem"
        borderRadius="xl"
        sx={{
          backgroundColor: uiConfig.theme.palette.secondary.main,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: {
            xs: 1,
            lg: 3,
          },
          py: 2,
          px: {
            xs: 3,
            lg: 2,
          },
          backgroundImage: `url(${profileStartImg})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
          marginTop: "-64px",
          minHeight: {
            xs: "fit-content",
            sm: "fit-content",
            lg: "736px",
            xl: "736px",
          },
        }}
      >
        {children}
      </Card>

      <FooterLogo />
    </MDBox>
  );
};

Header.defaultProps = {
  children: null,
};

export default Header;
