export const getCurrencySymbol = (currencyCode: string): string => {
  const currencySymbols: { [key: string]: string } = {
    AUD: "A$", // Australian Dollar
    USD: "$", // Australian Dollar
    CAD: "C$", // Canadian Dollar
    EUR: "€", // Euro
    GBP: "£", // British Pound
    INR: "₹", // Indian Rupee
    JPY: "¥", // Japanese Yen
    NZD: "NZ$", // New Zealand Dollar
    AED: "د.إ", // United Arab Emirates Dirham
    AFN: "؋", // Afghan Afghani
    ALL: "L", // Albanian Lek
    AMD: "֏", // Armenian Dram
    ANG: "ƒ", // Netherlands Antillean Guilder
    AOA: "Kz", // Angolan Kwanza
    ARS: "$", // Argentine Peso
    AWG: "ƒ", // Aruban Guilder
    AZN: "₼", // Azerbaijani Manat
    BAM: "KM", // Bosnia and Herzegovina Convertible Mark
    BBD: "Bds$", // Barbadian Dollar
    BDT: "৳", // Bangladeshi Taka
    BGN: "лв", // Bulgarian Lev
    BHD: ".د.ب", // Bahraini Dinar
    BIF: "FBu", // Burundian Franc
    BMD: "$", // Bermudian Dollar
    BND: "$", // Brunei Dollar
    BOB: "Bs.", // Bolivian Boliviano
    BRL: "R$", // Brazilian Real
    BSD: "$", // Bahamian Dollar
    BTN: "Nu.", // Bhutanese Ngultrum
    BWP: "P", // Botswana Pula
    BYN: "Br", // Belarusian Ruble
    BYR: "Br", // Belarusian Ruble (expiring)
    BZD: "BZ$", // Belize Dollar
    CDF: "FC", // Congolese Franc
    CHF: "CHF", // Swiss Franc
    CLF: "UF", // Unidad de Fomento
    CLP: "$", // Chilean Peso
    CNY: "¥", // Chinese Yuan
    COP: "$", // Colombian Peso
    CRC: "₡", // Costa Rican Colón
    CUC: "$", // Cuban Convertible Peso (expiring)
    CUP: "₱", // Cuban Peso
    CVE: "$", // Cape Verdean Escudo
    CZK: "Kč", // Czech Koruna
    DJF: "Fdj", // Djiboutian Franc
    DKK: "kr", // Danish Krone
    DOP: "RD$", // Dominican Peso
    DZD: "د.ج", // Algerian Dinar
    EGP: "£", // Egyptian Pound
    ERN: "Nfk", // Eritrean Nakfa
    ETB: "Br", // Ethiopian Birr
    FJD: "$", // Fijian Dollar
    FKP: "£", // Falkland Islands Pound
    GEL: "₾", // Georgian Lari
    GHS: "GH₵", // Ghanaian Cedi
    GIP: "£", // Gibraltar Pound
    GMD: "D", // Gambian Dalasi
    GNF: "FG", // Guinean Franc
    GTQ: "Q", // Guatemalan Quetzal
    GYD: "$", // Guyanese Dollar
    HKD: "HK$", // Hong Kong Dollar
    HNL: "L", // Honduran Lempira
    HRK: "kn", // Croatian Kuna
    HTG: "G", // Haitian Gourde
    HUF: "Ft", // Hungarian Forint
    IDR: "Rp", // Indonesian Rupiah
    ILS: "₪", // Israeli New Shekel
    IQD: "ع.د", // Iraqi Dinar
    IRR: "﷼", // Iranian Rial
    ISK: "kr", // Icelandic Króna
    JMD: "J$", // Jamaican Dollar
    JOD: "د.ا", // Jordanian Dinar
    KES: "KSh", // Kenyan Shilling
    KGS: "лв", // Kyrgyzstani Som
    KHR: "៛", // Cambodian Riel
    KMF: "CF", // Comorian Franc
    KPW: "₩", // North Korean Won
    KRW: "₩", // South Korean Won
    KWD: "د.ك", // Kuwaiti Dinar
    KYD: "$", // Cayman Islands Dollar
    KZT: "₸", // Kazakhstani Tenge
    LAK: "₭", // Lao Kip
    LBP: "ل.ل", // Lebanese Pound
    LKR: "Rs", // Sri Lankan Rupee
    LRD: "$", // Liberian Dollar
    LSL: "L", // Lesotho Loti
    LYD: "ل.د", // Libyan Dinar
    MAD: "د.م.", // Moroccan Dirham
    MDL: "L", // Moldovan Leu
    MGA: "Ar", // Malagasy Ariary
    MKD: "ден", // Macedonian Denar
    MMK: "K", // Myanmar Kyat
    MNT: "₮", // Mongolian Tugrik
    MOP: "MOP$", // Macanese Pataca
    MUR: "₨", // Mauritian Rupee
    MWK: "MK", // Malawian Kwacha
    MXN: "$", // Mexican Peso
    MYR: "RM", // Malaysian Ringgit
    MZN: "MT", // Mozambican Metical
    NAD: "$", // Namibian Dollar
    NGN: "₦", // Nigerian Naira
    NOK: "kr", // Norwegian Krone
    NPR: "₨", // Nepalese Rupee
    OMR: "ر.ع.", // Omani Rial
    PAB: "B/.", // Panamanian Balboa
    PEN: "S/", // Peruvian Sol
    PGK: "K", // Papua New Guinean Kina
    PHP: "₱", // Philippine Peso
    PKR: "₨", // Pakistani Rupee
    PLN: "zł", // Polish Zloty
    PYG: "₲", // Paraguayan Guaraní
    QAR: "ر.ق", // Qatari Riyal
    RON: "lei", // Romanian Leu
    RSD: "din", // Serbian Dinar
    RUB: "₽", // Russian Ruble
    RWF: "FRw", // Rwandan Franc
    SAR: "﷼", // Saudi Riyal
    SBD: "$", // Solomon Islands Dollar
    SCR: "₨", // Seychellois Rupee
    SDG: "ج.س.", // Sudanese Pound
    SEK: "kr", // Swedish Krona
    SGD: "S$", // Singapore Dollar
    SHP: "£", // Saint Helenian Pound
    SLL: "Le", // Sierra Leonean Leone
    SOS: "S", // Somali Shilling
    SRD: "$", // Surinamese Dollar
    STD: "Db", // São Tomé and Príncipe Dobra (expiring)
    SYP: "£", // Syrian Pound
    SZL: "L", // Swazi Lilangeni
    THB: "฿", // Thai Baht
    TJS: "ЅМ", // Tajikistani Somoni
    TMT: "T", // Turkmenistani Manat
    TND: "د.ت", // Tunisian Dinar
    TOP: "T$", // Tongan Pa'anga
    TRY: "₺", // Turkish Lira
    TTD: "TT$", // Trinidad and Tobago Dollar
    TWD: "NT$", // Taiwanese New Dollar
    TZS: "TSh", // Tanzanian Shilling
    UAH: "₴", // Ukrainian Hryvnia
    UGX: "USh", // Ugandan Shilling
    UYU: "$U", // Uruguayan Peso
    UZS: "лв", // Uzbekistani Som
    VND: "₫", // Vietnamese Dong
    VUV: "VT", // Vanuatu Vatu
    WST: "T", // Samoan Tala
    XAF: "FCFA", // Central African CFA Franc
    XCD: "$", // East Caribbean Dollar
    XOF: "CFA", // West African CFA Franc
    XPF: "₣", // CFP Franc
    YER: "﷼", // Yemeni Rial
    ZAR: "R", // South African Rand
    ZMW: "ZK", // Zambian Kwacha
    ZWL: "$", // Zimbabwean Dollar
  };

  return currencySymbols[currencyCode] || currencyCode;
};
