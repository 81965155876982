import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import image1 from "../../../../../../assets/images/profile-building/sdg-goals/1.png";
import image1Active from "../../../../../../assets/images/profile-building/sdg-goals/image1-active.png";
import image2 from "../../../../../../assets/images/profile-building/sdg-goals/2.png";
import image2Active from "../../../../../../assets/images/profile-building/sdg-goals/image2-active.png";
import image3 from "../../../../../../assets/images/profile-building/sdg-goals/3.png";
import image3Active from "../../../../../../assets/images/profile-building/sdg-goals/image3-active.png";
import image4 from "../../../../../../assets/images/profile-building/sdg-goals/4.png";
import image4Active from "../../../../../../assets/images/profile-building/sdg-goals/image4-active.png";
import image5 from "../../../../../../assets/images/profile-building/sdg-goals/5.png";
import image5Active from "../../../../../../assets/images/profile-building/sdg-goals/image5-active.png";
import image6 from "../../../../../../assets/images/profile-building/sdg-goals/6.png";
import image6Active from "../../../../../../assets/images/profile-building/sdg-goals/image6-active.png";
import image7 from "../../../../../../assets/images/profile-building/sdg-goals/7.png";
import image7Active from "../../../../../../assets/images/profile-building/sdg-goals/image7-active.png";
import image8 from "../../../../../../assets/images/profile-building/sdg-goals/8.png";
import image8Active from "../../../../../../assets/images/profile-building/sdg-goals/image8-active.png";
import image9 from "../../../../../../assets/images/profile-building/sdg-goals/9.png";
import image9Active from "../../../../../../assets/images/profile-building/sdg-goals/image9-active.png";
import image10 from "../../../../../../assets/images/profile-building/sdg-goals/10.png";
import image10Active from "../../../../../../assets/images/profile-building/sdg-goals/image10-active.png";
import image11 from "../../../../../../assets/images/profile-building/sdg-goals/11.png";
import image11Active from "../../../../../../assets/images/profile-building/sdg-goals/image11-active.png";
import image12 from "../../../../../../assets/images/profile-building/sdg-goals/12.png";
import image12Active from "../../../../../../assets/images/profile-building/sdg-goals/image12-active.png";
import image13 from "../../../../../../assets/images/profile-building/sdg-goals/13.png";
import image13Active from "../../../../../../assets/images/profile-building/sdg-goals/image13-active.png";
import image14 from "../../../../../../assets/images/profile-building/sdg-goals/14.png";
import image14Active from "../../../../../../assets/images/profile-building/sdg-goals/image14-active.png";
import image15 from "../../../../../../assets/images/profile-building/sdg-goals/15.png";
import image15Active from "../../../../../../assets/images/profile-building/sdg-goals/image15-active.png";
import image16 from "../../../../../../assets/images/profile-building/sdg-goals/16.png";
import image16Active from "../../../../../../assets/images/profile-building/sdg-goals/image16-active.png";
import image17 from "../../../../../../assets/images/profile-building/sdg-goals/17.png";
import image17Active from "../../../../../../assets/images/profile-building/sdg-goals/image17-active.png";
import { SdgDetailsBusinessProps, FormData } from "../../types";

const SdgDetailsBusiness: React.FC<SdgDetailsBusinessProps> = ({ formData, setFormData }) => {
  const [selectedImages, setSelectedImages] = useState<number[]>(formData.sdgDetails || []);

  useEffect(() => {
    setFormData((prevData: any) => ({
      ...prevData,
      sdgDetails: selectedImages,
    }));
  }, [selectedImages, setFormData]);

  const images = [
    { src: image1, activeSrc: image1Active, number: 1 },
    { src: image2, activeSrc: image2Active, number: 2 },
    { src: image3, activeSrc: image3Active, number: 3 },
    { src: image4, activeSrc: image4Active, number: 4 },
    { src: image5, activeSrc: image5Active, number: 5 },
    { src: image6, activeSrc: image6Active, number: 6 },
    { src: image7, activeSrc: image7Active, number: 7 },
    { src: image8, activeSrc: image8Active, number: 8 },
    { src: image9, activeSrc: image9Active, number: 9 },
    { src: image10, activeSrc: image10Active, number: 10 },
    { src: image11, activeSrc: image11Active, number: 11 },
    { src: image12, activeSrc: image12Active, number: 12 },
    { src: image13, activeSrc: image13Active, number: 13 },
    { src: image14, activeSrc: image14Active, number: 14 },
    { src: image15, activeSrc: image15Active, number: 15 },
    { src: image16, activeSrc: image16Active, number: 16 },
    { src: image17, activeSrc: image17Active, number: 17 },
  ];

  const handleImageClick = (number: number) => {
    setSelectedImages((prevSelectedImages) =>
      prevSelectedImages.includes(number)
        ? prevSelectedImages.filter((item) => item !== number)
        : [...prevSelectedImages, number]
    );
  };

  return (
    <Grid container px={{ xs: 2, lg: 5 }} spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Sustainable Development Goals</Typography>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Which of the following Sustainable Development Goals apply most to the problem and
          solution that you are addressing?
        </Typography>
      </Grid>

      <Grid container item spacing={1}>
        {images.map((image, index) => (
          <Grid item xs={2} key={index}>
            <img
              src={selectedImages.includes(image.number) ? image.activeSrc : image.src}
              alt={`Image ${image.number}`}
              style={{
                width: "100%",
                cursor: "pointer",
                transition: "filter 0.3s ease",
              }}
              onClick={() => handleImageClick(image.number)}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default SdgDetailsBusiness;
