import React from "react";
import MDTypography from "components/generic/MDTypography";

interface OneLinerProps {
  geographicFocus: string;
  investmentStage: string;
  investmentImpact: string;
  targetIndustries: string;
}

const OneLiner: React.FC<OneLinerProps> = ({
  geographicFocus,
  investmentStage,
  investmentImpact,
  targetIndustries,
}) => {
  return (
    <MDTypography variant="body2">
      Our investment approach focuses on{" "}
      <span style={{ fontWeight: "500", margin: "0 4px", color: "#000000" }}>
        {targetIndustries}
      </span>{" "}
      in{" "}
      <span style={{ fontWeight: "500", margin: "0 4px", color: "#000000" }}>
        {geographicFocus}
      </span>
      , with a preference for{" "}
      <span style={{ fontWeight: "500", margin: "0 4px", color: "#000000" }}>
        {investmentStage}
      </span>{" "}
      startups, aiming to{" "}
      <span style={{ fontWeight: "500", margin: "0 4px", color: "#000000" }}>
        {investmentImpact}
      </span>
      .
    </MDTypography>
  );
};

export default OneLiner;
