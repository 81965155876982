import React, { useEffect, useState } from "react";
import { Grid, Typography, Radio, FormControlLabel } from "@mui/material";
import { FinancingHistoryProps } from "../../types"; // Assuming the types are in a types file
import { useSkipStep } from "context/skipStepContext";
import FinancingDetails from "./financing-details-1";

const FinancingHistory: React.FC<FinancingHistoryProps> = ({ formData, setFormData }) => {
  const [fundingReceived, setFundingReceived] = useState<string>(
    formData.financingDetails?.fundingReceived || "No"
  );
  const { handleSkipStep } = useSkipStep();
  const [showFinancingDetails, setShowFinancingDetails] = useState<boolean>(
    fundingReceived === "Yes"
  );

  useEffect(() => {
    if (fundingReceived === "No") {
      handleSkipStep(true); // Skip Financing Details if "No"
      setFormData((prevFormData) => ({
        ...prevFormData,
        financingDetails: {
          ...prevFormData.financingDetails,
          fundingReceived,
        },
      }));
    } else {
      handleSkipStep(false);
      setFormData((prevFormData) => ({
        ...prevFormData,
        financingDetails: {
          ...prevFormData.financingDetails,
          fundingReceived,
        },
      }));
    }
  }, [fundingReceived, handleSkipStep, setFormData]);

  const handleFundingReceivedChange = (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    const target = event.target as HTMLInputElement;
    setFundingReceived(target.value);
    setShowFinancingDetails(target.value === "Yes");
  };

  return (
    <Grid container px={{ xs: 2, lg: 5 }} spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Financing History</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          Have you received/secured any funding for the business to date?
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          sx={{
            "& .MuiFormControlLabel-label": {
              fontSize: "14px",
              fontWeight: "400",
              color: "#7B809A",
            },
          }}
          value="Yes"
          control={
            <Radio
              sx={{
                "& .MuiSvgIcon-root": {
                  borderRadius: "4px",
                },
                "&:after": {
                  borderRadius: "2px",
                },
              }}
            />
          }
          label="Yes"
          onChange={handleFundingReceivedChange}
          checked={fundingReceived === "Yes"}
        />
        <FormControlLabel
          sx={{
            "& .MuiFormControlLabel-label": {
              fontSize: "14px",
              color: "#7B809A",
              fontWeight: "400",
            },
          }}
          value="No"
          control={
            <Radio
              sx={{
                "& .MuiSvgIcon-root": {
                  borderRadius: "4px",
                },
                "&:after": {
                  borderRadius: "2px",
                },
              }}
            />
          }
          label="No"
          onChange={handleFundingReceivedChange}
          checked={fundingReceived === "No"}
        />
      </Grid>
      {showFinancingDetails && (
        <Grid item xs={12}>
          <FinancingDetails formData={formData} setFormData={setFormData} />
        </Grid>
      )}
    </Grid>
  );
};

export default FinancingHistory;
