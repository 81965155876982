import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Autocomplete } from "@mui/material";
import MDBox from "components/generic/MDBox";
import MDButton from "components/generic/MDButton";
import { Check, Delete } from "@mui/icons-material";
import MDTooltip from "components/generic/MDTooltip";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FinancingDetailsProps, FundingCategoryProps, FundingBreakdownType } from "../../../types";
import dayjs, { Dayjs } from "dayjs";

const FundingCategory: React.FC<FundingCategoryProps> = ({ label, formData, setFormData }) => {
  const financingDetails = formData.financingDetails.fundingBreakdown?.[label] as
    | {
        checked: boolean;
        currency: string;
        fundingAmount: string;
        fundingUse: string;
        date: Date | null;
      }
    | undefined;
  const [checked, setChecked] = useState<boolean>(financingDetails?.checked || false);
  const [mode, setMode] = useState<string>("default");
  const [currency, setCurrency] = useState<string>(financingDetails?.currency || "R");
  const [tempCurrency, setTempCurrency] = useState<string>("R");
  const [fundingAmount, setFundingAmount] = useState<string>(financingDetails?.fundingAmount || "");
  const [fundingUse, setFundingUse] = useState<string>(financingDetails?.fundingUse || "");
  const [tempFundingAmount, setTempFundingAmount] = useState<string>("");
  const [tempFundingUse, setTempFundingUse] = useState<string>("");
  /*
  const [date, setDate] = useState<Date | null>(financingDetails?.date || null);
  const [tempDate, setTempDate] = useState<Date | null>(null);
  */
  const [date, setDate] = useState<Dayjs | null>(
    financingDetails?.date ? dayjs(financingDetails?.date) : null
  );
  const [tempDate, setTempDate] = useState<Dayjs | null>(null);

  useEffect(() => {
    if (checked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        financingDetails: {
          ...prevFormData.financingDetails,
          fundingBreakdown: {
            ...prevFormData.financingDetails.fundingBreakdown,
            [label]: {
              checked: true,
              currency,
              fundingAmount,
              fundingUse,
              date: date?.toDate() || null,
            },
          },
        },
      }));
    } else {
      setFormData((prevFormData) => {
        const { fundingBreakdown = {} } = prevFormData.financingDetails || {};
        const { [label]: removed, ...rest } = fundingBreakdown;
        return {
          ...prevFormData,
          financingDetails: {
            ...prevFormData.financingDetails,
            fundingBreakdown: rest,
          },
        };
      });
    }
  }, [checked, currency, fundingAmount, fundingUse, date, label, setFormData]);

  const handleChange = () => {
    setChecked((prevChecked) => !prevChecked);
    setMode(checked ? "default" : "edit");
  };

  const handleDeleteClick = () => {
    setChecked(false);
    setFundingAmount("");
    setFundingUse("");
    setDate(null);
    setMode("default");
    setTempCurrency("R");
  };

  const handleDoneClick = () => {
    setFundingAmount(tempFundingAmount);
    setFundingUse(tempFundingUse);
    setCurrency(tempCurrency);
    setDate(tempDate);
    setMode("updated");
    setTempFundingAmount("");
    setTempFundingUse("");
    setTempDate(null);
  };

  return (
    <Grid item xs={12} md={6}>
      <MDBox width="100%" display="flex" alignItems="center" gap={1}>
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          style={{ accentColor: "#34a174", color: "white", backgroundColor: "white" }}
        />
        {mode === "edit" ? (
          <MDBox
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems="flex-start"
            justifyContent="start"
            gap={2}
            width={{ xs: "100%", md: "100%", xl: "100%" }}
          >
            <Autocomplete
              disableClearable
              value={tempCurrency}
              onChange={(event, newValue) => {
                setTempCurrency(newValue as string);
              }}
              options={["R", "USD", "EUR", "GBP", "JPY", "CAD", "AUD"]}
              size="small"
              sx={{ width: "60px" }}
              renderInput={(params) => <TextField {...params} />}
            />
            <MDBox
              display="flex"
              flexDirection="column"
              gap={0.5}
              alignItems="flex-end"
              justifyContent="space-between"
              width="100%"
            >
              <TextField
                placeholder="Please Enter Amount"
                value={tempFundingAmount}
                onChange={(event) => setTempFundingAmount(event.target.value)}
                fullWidth
                InputProps={{ style: { fontSize: "11px" } }}
              />
              <TextField
                placeholder="Please specify what proceeds were used for"
                value={tempFundingUse}
                onChange={(event) => setTempFundingUse(event.target.value)}
                fullWidth
                InputProps={{ style: { fontSize: "11px" } }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Year and month when financing was secured."
                  value={tempDate}
                  onChange={(newValue) => setTempDate(newValue)}
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                      fontWeight: "400",
                      color: "#adb5bd",
                    },
                  }}
                />
              </LocalizationProvider>
              <MDButton
                variant="text"
                color="info"
                sx={{
                  py: 0,
                  maxHeight: "30px",
                  minHeight: "30px",
                  "& .MuiTouchRipple-root ": { maxHeight: "30px" },
                }}
                onClick={handleDoneClick}
              >
                <Check sx={{ mr: 1 }} />
                Done
              </MDButton>
            </MDBox>
          </MDBox>
        ) : mode === "updated" ? (
          <MDBox width="90%" display="flex" alignItems="center" justifyContent="space-between">
            <Typography sx={{ fontSize: "14px" }} variant="h6">
              {`${label}: ${currency}${fundingAmount}`}
            </Typography>
            <MDButton
              variant="text"
              color="dark"
              sx={{
                py: 0,
                maxHeight: "30px",
                minHeight: "30px",
                "& .MuiTouchRipple-root ": { maxHeight: "30px" },
              }}
              onClick={handleDeleteClick}
            >
              <MDTooltip
                title={
                  <div id="tooltip5">
                    <p>Delete</p>
                  </div>
                }
              >
                <Delete htmlColor="#6c757d" sx={{ mr: 1 }} />
              </MDTooltip>
            </MDButton>
          </MDBox>
        ) : (
          <Typography sx={{ fontSize: "14px" }} variant="body1" color="GrayText">
            {label}
          </Typography>
        )}
      </MDBox>
    </Grid>
  );
};

const FinancingDetails2: React.FC<FinancingDetailsProps> = ({ formData, setFormData }) => {
  const fundingCategories = [
    "Boot Strapping (Pre-seed)",
    "Late Stage or Growth Equity",
    "Debt Funding",
    "Bridge Financing",
    "Seed Funding",
    "Convertible Notes and SAFEs",
    "Series A",
    "Crowdfunding",
    "Series B",
    "Venture Debt",
    "Series C",
    "Strategic Investments",
    "Series D",
    "Initial Public Offering",
    "Series E",
    "Other",
  ];

  return (
    <Grid container px={{ xs: 2, lg: 5 }} spacing={3}>
      <Grid mt={3} item xs={12}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          What type of funding have you received and/or used to fund your business to date?
        </Typography>
      </Grid>
      {fundingCategories.map((category) => (
        <FundingCategory
          key={category}
          label={category}
          formData={formData}
          setFormData={setFormData}
        />
      ))}
    </Grid>
  );
};

export default FinancingDetails2;
