import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import MDTypography from "components/generic/MDTypography";

ChartJS.register(ArcElement, Tooltip, Legend);
/*
interface TasksChartProps {
  taskData?: number[]; // Optional task data
  taskLabels?: string[]; // Optional task labels
  colors?: string[]; // Optional colors
  size?: string; // Optional size
}
*/
interface TasksChartProps {
  chartData: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
  size?: string; // Optional size
}

const TasksChart: React.FC<TasksChartProps> = ({
  chartData,
  size = "200px", // Default size
}) => {
  const totalTasks = chartData.datasets[0].data.reduce((acc, curr) => acc + curr, 0); // Total task count
  /*
  const chartData = {
    labels: taskLabels,
    datasets: [
      {
        data: taskData, // Task data
        backgroundColor: colors,
        borderWidth: 0,
        borderRadius: 0, // No border radius
      },
    ],
  };
*/
  const options = {
    cutout: "80%", // Adjust the cutout for the inner radius
    rotation: 0, // Start angle for the progress
    circumference: 360, // Total angle for the chart
    plugins: {
      legend: {
        display: false, // We will handle legend manually outside the chart
      },
      tooltip: {
        enabled: true, // Enable tooltips
      },
    },
    elements: {
      arc: {
        borderWidth: 0, // No border for the segments
      },
    },
  };

  return (
    <div
      style={{
        display: "flex", // Flex container to align chart and legend horizontally
        justifyContent: "center",
        alignItems: "center",
        width: "100%", // Make sure the container fits its parent width
      }}
    >
      {/* Doughnut chart with centered text */}
      <div
        style={{
          position: "relative",
          width: size,
          height: size,
        }}
      >
        <Doughnut data={chartData} options={options} />

        {/* Centered task count */}
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Ensures centering
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MDTypography variant="h4" fontWeight="bold">
            {totalTasks}
          </MDTypography>
          <MDTypography variant="caption" color="textSecondary">
            Tasks
          </MDTypography>
        </div>
      </div>

      {/* Custom Legend positioned to the right */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          marginLeft: "20px", // Add space between chart and legend
          textAlign: "left", // Ensure left alignment of legend items
        }}
      >
        {chartData.labels.map((label, index) => (
          <div
            key={label}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                backgroundColor: chartData.datasets[0].backgroundColor[index],
                marginRight: "8px",
              }}
            />
            <MDTypography variant="caption">{label}</MDTypography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TasksChart;
