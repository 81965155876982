import React, { MouseEvent, useState } from "react";
// formik components
import { ErrorMessage, Field } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDInput from "components/generic/MDInput";
import { IconButton, InputAdornment } from "@mui/material";
import { LockReset } from "@mui/icons-material";
import MDTooltip from "components/generic/MDTooltip";

interface SignupFormFieldProps {
  label: string;
  name: string;
  logo?: React.ReactNode;
  type?: string;
  passwordchange?: boolean;
  placeholder?: string;
  error?: boolean;
  value: string;
  inputProps?: object;
}

const SignupFormField: React.FC<SignupFormFieldProps> = ({
  label,
  name,
  logo,
  type = "text",
  passwordchange,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <MDBox mb={1.5}>
      <Field
        {...rest}
        name={name}
        as={MDInput}
        variant="standard"
        label={label}
        type={showPassword && passwordchange ? "text" : type}
        InputProps={{
          endAdornment: logo ? (
            <InputAdornment sx={{ fontSize: "20px", color: "#4F4F52" }} position="end">
              <IconButton>{logo}</IconButton>
            </InputAdornment>
          ) : passwordchange ? (
            <MDTooltip
              title={
                <>
                  <div id="tooltip11">
                    <p>View Password</p>
                  </div>
                </>
              }
            >
              <InputAdornment sx={{ fontSize: "20px" }} position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  sx={{ color: showPassword ? "#00DC82" : "#4F4F52" }}
                >
                  <LockReset />
                </IconButton>
              </InputAdornment>
            </MDTooltip>
          ) : null,
        }}
        fullWidth
      />
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};

export default SignupFormField;
