import React from "react";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import BudgetLineChart from "./BudgetLineChart";
import colors from "assets/theme/base/colors";
import { getCurrencySymbol } from "utility/getCurrencySymbol";

// Placeholder data structure
interface BudgetData {
  month: string;
  totalIncome: number;
  grossProfit: number;
  operatingExpenses: number;
  netProfit: number;
}

interface BudgetSummaryChartProps {
  budgetData: BudgetData[];
  baseCurrency: string;
}

const BudgetSummaryChart: React.FC<BudgetSummaryChartProps> = ({ budgetData, baseCurrency }) => {
  // Prepare data for the chart
  console.log("budgetDdata in component", budgetData);
  const labels = budgetData.map((data) => data.month);

  const totalIncomeData = budgetData.map((data) => data.totalIncome);
  const grossProfitData = budgetData.map((data) => data.grossProfit);
  const operatingExpensesData = budgetData.map((data) => data.operatingExpenses);
  const netProfitData = budgetData.map((data) => data.netProfit);

  const chartData = {
    labels,
    datasets: [
      {
        label: "Total Income",
        data: totalIncomeData,
        borderColor: colors.info.main,
        backgroundColor: colors.info.main,
      },
      {
        label: "Gross Profit",
        data: grossProfitData,
        borderColor: colors.error.main,
        backgroundColor: colors.error.main,
      },
      {
        label: "Operating Expenses",
        data: operatingExpensesData,
        borderColor: colors.warning.main,
        backgroundColor: colors.warning.main,
      },
      {
        label: "Net Profit",
        data: netProfitData,
        borderColor: colors.success.main,
        backgroundColor: colors.success.main,
      },
    ],
  };
  const currencySymbol = getCurrencySymbol(baseCurrency);

  return (
    <BudgetLineChart
      data={chartData}
      currencySymbol={currencySymbol}
      title="Budget Summary"
      height={400}
    />
  );
};

export default BudgetSummaryChart;
