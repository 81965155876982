import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MDButton from "components/generic/MDButton";
import MDTypography from "components/generic/MDTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import DashboardLayout from "components/refined/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/refined/Navbars/DashboardNavbar"; //not used
import Header from "layouts/pages/profile/profile-build/t-and-cs/components/Layout";
import { Modal, Slide } from "@mui/material";
import DTERMSCOND from "components/custom/DTERMSCOND";
import Footer from "components/refined/Footer";
import { confirmTC } from "services/ventureApi";
import uiConfig from "assets/ui-config/ui-config";
//import { AuthContext } from "context/AuthContext";

//uiConfig.theme.palette.primary.main

const ProfileBuildingAgreement: React.FC = () => {
  const [TCchoice, setTCchoice] = useState<boolean>(false);
  //const { loginResponse } = useContext(AuthContext);
  const [searchParams] = useSearchParams(); // Extract search parameters
  const referenceKey = searchParams.get("referenceKey");
  const navigate = useNavigate();

  const handleTCchoice = () => {
    setTCchoice(!TCchoice);
  };

  useEffect(() => {
    // why included in original?
  }, []);

  const handleAccept = async () => {
    try {
      await confirmTC();
      if (referenceKey) {
        console.log("has reference key, navigate to sign in");
        navigate("/authentication/sign-in");
      } else {
        navigate("/profile/welcome-page");
      }
    } catch (error) {
      console.error("Error during T&C confirmation", error);
    }
  };

  return (
    <DashboardLayout>
      <Header>
        <MDBox mt={{ xs: 0, lg: 5 }} mb={3}>
          <Card sx={{ maxWidth: { xs: "100%", lg: "80%" }, mt: 7, mx: "auto" }}>
            <MDBox
              variant="gradient"
              //bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              px={2}
              py={4}
              mb={1}
              textAlign="center"
              sx={{
                backgroundColor: uiConfig.theme.palette.primary.main, // Set primary color
                boxShadow: `0 4px 20px 0 ${uiConfig.theme.palette.primary.main}99`,
              }}
            ></MDBox>

            <MDBox pt={4} pb={3} px={3}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <MDTypography
                    ml={3}
                    mb={1}
                    //color="info"
                    variant="h4"
                    sx={{
                      color: uiConfig.theme.palette.primary.main,
                    }}
                  >
                    Terms and Conditions
                  </MDTypography>
                  <MDTypography ml={3} mt={1} variant="h5">
                    Your Agreement
                  </MDTypography>
                </Grid>
                <Grid item>
                  <MDBox pt={2} pb={3} px={3} style={{ maxHeight: "480px", overflowY: "auto" }}>
                    <MDTypography fontWeight="regular" style={{ textAlign: "start" }}>
                      These Terms and Conditions (`Agreement`) govern your use of Reisiger Ventures
                      (Pty) Ltd platform and any related services provided by Reisiger Ventures
                      (Pty) Ltd (`Service`). By accessing or using the Service, you agree to be
                      bound by this Agreement.
                    </MDTypography>
                    <MDTypography fontWeight="regular" mt={4} style={{ textAlign: "start" }}>
                      1. Acceptance of Terms 1.1 By accessing or using the Service, you agree to be
                      bound by this Agreement and all applicable laws and regulations. If you do not
                      agree with any part of this Agreement, you must not access or use the Service.
                    </MDTypography>
                    <MDTypography fontWeight="regular" mt={4} style={{ textAlign: "start" }}>
                      2. Confidential Information 2.1. Definition: `Confidential Information` refers
                      to any non-public information, data, materials, documents, specifications,
                      business strategies, trade secrets, customer lists, financial information,
                      technical data, software, inventions, discoveries, concepts, know-how,
                      methodologies, and other information, disclosed by the Disclosing Party to the
                      Receiving Party during this Agreement`s term. 2.2. Exclusions: Confidential
                      Information does not include information that: (i) becomes publicly available
                      through no fault of the Receiving Party; (ii) is already known to the
                      Receiving Party at the time of disclosure; (iii) is rightfully obtained by the
                      Receiving Party from a third party without an obligation of confidentiality;
                      (iv) is independently developed by the Receiving Party without reference to
                      the Confidential Information; or (v) is required to be disclosed by law,
                      regulation, or court order. However, in such cases, the Receiving Party shall
                      promptly notify the Disclosing Party to seek protective measures.
                    </MDTypography>
                    <MDTypography fontWeight="regular" mt={4} style={{ textAlign: "start" }}>
                      3. Non-Disclosure Obligations 3.1. Duty of Confidentiality: Receiving Party
                      agrees to hold all Confidential Information in strict confidence and to use it
                      solely for the Purpose. Receiving Party shall exercise the same degree of care
                      in protecting the Confidential Information as it uses for its own confidential
                      information. 3.2. No Disclosure: Receiving Party shall not disclose,
                      distribute, publish, transmit, share, or otherwise make available any
                      Confidential Information to any third party without prior written consent from
                      the Disclosing Party. 3.3. Internal Restrictions: Receiving Party shall ensure
                      that its employees, agents, consultants, contractors, or any other third party
                      with access to the Confidential Information (`Authorized Persons`) are bound
                      by written agreements or obligations prohibiting disclosure or use of the
                      Confidential Information for any purpose other than the Purpose. 3.4. Return
                      or Destruction: Upon written request by the Disclosing Party or upon
                      termination of this Agreement, Receiving Party shall promptly return or
                      destroy all tangible and electronic copies of the Confidential Information and
                      provide written certification of such action upon request.
                    </MDTypography>
                    <MDTypography fontWeight="regular" mt={4} style={{ textAlign: "start" }}>
                      4. Ownership and Rights 4.1. Ownership: The Disclosing Party retains all
                      rights, title, and interest in and to the Confidential Information. This
                      Agreement does not grant any right, title, or license to the Receiving Party,
                      except as expressly authorized for the Purpose under this Agreement. 4.2. No
                      Implied Licenses: This Agreement does not grant Receiving Party any rights or
                      licenses, by estoppel or otherwise, to any patents, trademarks, copyrights,
                      trade secrets, or any other intellectual property rights of the Disclosing
                      Party, except as expressly provided herein.
                    </MDTypography>
                    <MDTypography fontWeight="regular" mt={4} style={{ textAlign: "start" }}>
                      5. Intellectual Property 5.1. All content, trademarks, service marks, and
                      logos appearing on the Service are the property of Reisiger Ventures (Pty) Ltd
                      or its licensors. You agree not to use, reproduce, or distribute any such
                      content without the express permission of the owner.
                    </MDTypography>
                    <MDTypography fontWeight="regular" mt={4} style={{ textAlign: "start" }}>
                      6. Limitation of Liability 6.1. To the fullest extent permitted by law,
                      Reisiger Ventures (Pty) Ltd shall not be liable for any indirect, incidental,
                      special, consequential, or punitive damages, or any loss of profits or
                      revenues, whether incurred directly or indirectly, or any loss of data, use,
                      goodwill, or other intangible losses, resulting from: (i) your access to or
                      use of or inability to access or use the Service; (ii) any conduct or content
                      of any third party on the Service; (iii) any content obtained from the
                      Service; and (iv) unauthorized access, use, or alteration of your
                      transmissions or content, whether based on warranty, contract, tort (including
                      negligence), or any other legal theory, whether or not we have been informed
                      of the possibility of such damage, and even if a remedy set forth herein is
                      found to have failed of its essential purpose.
                    </MDTypography>
                    <MDTypography fontWeight="regular" mt={4} style={{ textAlign: "start" }}>
                      7. Indemnification 7.1. You agree to indemnify and hold Reisiger Ventures
                      (Pty) Ltd harmless from any and all claims, demands, losses, liabilities, and
                      expenses (including attorneys` fees) arising out of or in connection with: (i)
                      your use of the Service; (ii) your breach or violation of any term of this
                      Agreement; (iii) your violation of any third-party right, including without
                      limitation any copyright, property, or privacy right; or (iv) any claim that
                      your User Content caused damage to a third party.
                    </MDTypography>
                    <MDTypography fontWeight="regular" mt={4} style={{ textAlign: "start" }}>
                      8. Governing Law 8.1. This Agreement shall be governed by and construed in
                      accordance with the laws of South Africa, without regard to its conflict of
                      law provisions.
                    </MDTypography>
                    <MDTypography fontWeight="regular" mt={4} style={{ textAlign: "start" }}>
                      9. Changes to Terms 9.1. Reisiger Ventures (Pty) Ltd reserves the right to
                      modify or replace any part of this Agreement at any time. It is your
                      responsibility to check this Agreement periodically for changes. Your
                      continued use of or access to the Service following the posting of any changes
                      to this Agreement constitutes acceptance of those changes.
                    </MDTypography>
                    <MDTypography fontWeight="regular" mt={4} style={{ textAlign: "start" }}>
                      10. Miscellaneous 10.1. Severability: If any provision is held invalid, the
                      remaining provisions remain in effect. 10.2. Notices: All notices shall be in
                      writing and delivered personally or sent by certified mail to the addresses
                      specified by the Parties. 10.3. Entire Agreement: This Agreement constitutes
                      the entire understanding between the Parties and supersedes all prior
                      discussions or agreements. 10.4. Amendments: Any amendment must be in writing
                      and signed by authorized representatives of both Parties.
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>

            <Grid item container pr={2} pt={2} pb={2} justifyContent="flex-end" spacing={2}>
              <Grid item>
                <MDButton onClick={handleTCchoice} variant="outlined" color="primary">
                  Decline
                </MDButton>
              </Grid>
              <Grid item>
                <MDButton
                  variant="contained"
                  color="info"
                  onClick={handleAccept}
                  sx={{
                    backgroundColor: uiConfig.theme.palette.primary.main,
                    "&:hover": {
                      backgroundColor: uiConfig.theme.palette.secondary.main, // Darker shade on hover
                    },
                  }}
                >
                  Accept
                </MDButton>
              </Grid>
            </Grid>
          </Card>
        </MDBox>

        <Modal
          open={TCchoice}
          onClose={() => setTCchoice(false)}
          aria-labelledby="TCchoice-modal-title"
          aria-describedby="TCchoice-modal-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Slide
            direction="down"
            in={TCchoice}
            mountOnEnter
            unmountOnExit
            style={{ transition: "transform 2s ease-in-out" }}
          >
            <div>
              <DTERMSCOND closeAll={() => setTCchoice(false)} />
            </div>
          </Slide>
        </Modal>
      </Header>
      <Footer />
    </DashboardLayout>
  );
};

export default ProfileBuildingAgreement;
