import checkout from "./signupForm";

const {
  formField: {
    firstName,
    lastName,
    phone,
    email,
    password,
    repeatPassword,
    location,
    joiningAs,
    role,
    seekingToConnect,
    servicesInterestedIn,
    servicesToSupply,
    address1,
    address2,
    city,
    zip,
    twitter,
    facebook,
    instagram,
    publicEmail,
    bio,
    countryCode,
    referenceKey,
  },
} = checkout;

interface SignupInitialValuesType {
  [key: string]: string | string[];
}

const SignupInitialValues: SignupInitialValuesType = {
  [firstName.name]: "",
  [lastName.name]: "",
  [phone.name]: "",
  [email.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
  [location.name]: "",
  [joiningAs.name]: "",
  [role.name]: "",
  [seekingToConnect.name]: "",
  [servicesInterestedIn.name]: [], // Array for checkboxes
  [servicesToSupply.name]: [],
  [address1.name]: "",
  [address2.name]: "",
  [city.name]: "",
  [zip.name]: "",
  [twitter.name]: "",
  [facebook.name]: "",
  [instagram.name]: "",
  [publicEmail.name]: "",
  [bio.name]: "",
  [countryCode.name]: "+27", // Added country code field
  [referenceKey.name]: "", // Initialize referenceKey
};

export default SignupInitialValues;
