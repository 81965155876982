import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { GoogleMap, LoadScript, Autocomplete } from "@react-google-maps/api";
import MDInput from "components/generic/MDInput";

const libraries = ["places"];
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

const containerStyle = {
  width: "75%",
  height: "200px",
};

interface Position {
  lat: number;
  lng: number;
}

interface CombinedMapComponentProps {
  onPlaceSelected: (place: google.maps.places.PlaceResult, lat: number, lng: number) => void;
  position: Position;
}

const CombinedMapComponent: React.FC<CombinedMapComponentProps> = ({
  onPlaceSelected,
  position,
}) => {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement | null>(null);

  const onLoad = (autocompleteInstance: google.maps.places.Autocomplete) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const location = place.geometry?.location;
      if (location) {
        onPlaceSelected(place, location.lat(), location.lng());
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  useEffect(() => {
    if (
      map &&
      !marker &&
      window.google &&
      window.google.maps &&
      window.google.maps.marker &&
      window.google.maps.marker.AdvancedMarkerElement
    ) {
      const { AdvancedMarkerElement } = window.google.maps.marker;
      const advancedMarker = new AdvancedMarkerElement({
        position: position,
        map: map,
      });
      setMarker(advancedMarker);
    } else if (marker) {
      marker.position = position;
    }
  }, [map, marker, position]);

  return (
    <LoadScript googleMapsApiKey={apiKey} libraries={libraries}>
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <MDInput label="Search for Address" fullWidth variant="outlined" sx={{ width: "75%" }} />
      </Autocomplete>
      <br />
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={position}
        zoom={15}
        onLoad={(mapInstance: google.maps.Map) => setMap(mapInstance)}
      >
        {/* Marker is managed via the useEffect hook */}
      </GoogleMap>
    </LoadScript>
  );
};

// PropTypes are replaced by TypeScript interfaces and types
export default CombinedMapComponent;
