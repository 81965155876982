import React from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import { useMaterialUIController } from "context";
import { useTheme } from "@mui/material/styles"; // Import theme hook

interface SidenavItem {
  icon: string;
  label: string;
  href: string;
}

const Sidenav: React.FC = () => {
  const theme = useTheme();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const sidenavItems: SidenavItem[] = [
    { icon: "person", label: "profile", href: "profile" },
    { icon: "receipt_outlined", label: "basic info", href: "basic-info" },
  ];

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component="a"
          href={`#${href}`}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: theme.borders.borderRadius.md, // Use theme directly
            padding: `${theme.functions.pxToRem(10)} ${theme.functions.pxToRem(16)}`, // Use theme functions
            transition: theme.transitions.create("background-color", {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.shorter,
            }),
            "&:hover": {
              backgroundColor: theme.palette.light.main,
            },
          }}
        >
          <MDBox mr={1.5} lineHeight={1} color={theme.palette.mode === "dark" ? "white" : "dark"}>
            <Icon fontSize="small">{icon}</Icon>
          </MDBox>
          {label}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </MDBox>
    </Card>
  );
};

export default Sidenav;
