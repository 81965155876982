import React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
import logo_placeholder from "assets/logo_placeholder.png";

interface OneLinerDetails {
  keyOffering: string;
  businessName: string;
  targetMarket: string;
  problemOutline: string;
  valueProposition: string;
}

interface Action {
  type: "internal" | "external";
  route: string;
  color:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark"
    | "white";
  label: string;
}

interface FavoritedBusinessCardProps {
  logo_url: string;
  entity_name: string;
  one_liner_details?: OneLinerDetails;
  action?: Action;
  placeholderMessage?: string; // Optional message for the placeholder
}

const FavoritedBusinessCard: React.FC<FavoritedBusinessCardProps> = ({
  logo_url,
  entity_name,
  one_liner_details,
  action = {
    type: "internal",
    route: "/investor-portfolio",
    color: "success",
    label: "View Portfolio",
  },
  placeholderMessage,
}) => {
  console.log("card props action:", action);
  const oneLiner = `${one_liner_details.businessName} is tackling the ${one_liner_details.problemOutline} by offering ${one_liner_details.keyOffering}, which enables ${one_liner_details.targetMarket} to ${one_liner_details.valueProposition}.`;
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      <MDBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
        <CardMedia
          src={logo_url || logo_placeholder}
          component="img"
          title={entity_name}
          width="100%"
          height="150px"
          /*
          sx={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: "repeat", // This will repeat the image in both directions.
            backgroundSize: "auto", // Ensures the image is not stretched and is tiled.
            backgroundPosition: "top left", // Starts the tiling from the top-left corner.
          }}
          */
          sx={{
            //maxWidth: "100%",
            width: "100%",
            height: "150px",
            //margin: 0,
            boxShadow: ({ boxShadows: { md } }) => md,
            objectFit: "contain",
            //backgroundRepeat: "repeat",
            margin: "0 auto",
            objectPosition: "center",
          }}
        />
      </MDBox>
      <MDBox mt={1} mx={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
          {entity_name}
        </MDTypography>
        <MDBox mb={1}>
          {action.type === "internal" ? (
            <MDTypography
              component={Link}
              to={action.route}
              variant="h5"
              textTransform="capitalize"
            >
              {entity_name}
            </MDTypography>
          ) : (
            <MDTypography
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="h5"
              textTransform="capitalize"
            >
              {entity_name}
            </MDTypography>
          )}
        </MDBox>
        <MDBox mb={3} lineHeight={0}>
          {placeholderMessage ? (
            <MDTypography variant="body2" color="textSecondary">
              {placeholderMessage}
            </MDTypography>
          ) : (
            <MDTypography variant="button" fontWeight="light" color="text">
              {oneLiner}
            </MDTypography>
          )}
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          {action.type === "internal" ? (
            <MDButton
              component={Link}
              to={action.route}
              variant="outlined"
              size="small"
              color={action.color}
            >
              {action.label}
            </MDButton>
          ) : (
            <MDButton
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="outlined"
              size="small"
              color={action.color}
            >
              {action.label}
            </MDButton>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default FavoritedBusinessCard;
