// src/layouts/authentication/sign-up/schemas/validations.ts

import * as Yup from "yup";
import checkout from "./signupForm";

const {
  formField: { firstName, lastName, email, phone, password, repeatPassword, countryCode },
} = checkout;

// Define the interface for form fields that might have error messages
interface FormField {
  name: string;
  errorMsg?: string;
  invalidMsg?: string;
}

const signupValidations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [lastName.name]: Yup.string().required(lastName.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [phone.name]: Yup.string().required(phone.errorMsg),
    [countryCode.name]: Yup.string().required(countryCode.errorMsg), // Add country code validation
    [password.name]: Yup.string()
      .required(password.errorMsg)
      .min(
        8,
        "Password must be at least 8 characters long and include uppercase letters, lowercase letters, numbers, and symbols."
      ),
    [repeatPassword.name]: Yup.string()
      .required(repeatPassword.errorMsg)
      .oneOf([Yup.ref(password.name), null], repeatPassword.invalidMsg),
  }),
];

export default signupValidations;
