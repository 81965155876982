import React from "react";
import { Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary Chart.js components
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// Custom components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";

// Colors
import colors from "assets/theme/base/colors";

// Props interface
interface BudgetLineChartProps {
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      borderColor: string;
      backgroundColor: string;
    }[];
  };
  title?: string;
  height?: number;
  currencySymbol: string;
}

const BudgetLineChart: React.FC<BudgetLineChartProps> = ({
  data,
  title = "Budget Summary",
  height = 400,
  currencySymbol,
}) => {
  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          boxWidth: 12,
          boxHeight: 12,
        },
      },
      tooltip: {
        mode: "index" as const,
        intersect: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        display: true,
        title: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          callback: function (value: number | string) {
            // Format y-axis labels as currency
            return `${currencySymbol} ${value}`;
          },
        },
      },
    },
  };

  return (
    <Card sx={{ mb: 3 }}>
      <MDBox p={3}>
        {title && (
          <MDTypography variant="h6" gutterBottom>
            {title}
          </MDTypography>
        )}
        <MDBox height={height}>
          <Line data={data} options={options} />
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default BudgetLineChart;
