// src/context/FavoritesContext.tsx
import React, { createContext, useState, useContext, ReactNode } from "react";
import { Business } from "types/businessTypes";

// Define the shape of the context
interface FavoritesContextProps {
  favoritedBusinesses: Business[];
  toggleFavorite: (business: Business) => void;
  isFavorited: (business: Business) => boolean;
}

// Create the context
const FavoritesContext = createContext<FavoritesContextProps | undefined>(undefined);

// Provider component
export const FavoritesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [favoritedBusinesses, setFavoritedBusinesses] = useState<Business[]>([]);

  // Function to toggle favorite status
  const toggleFavorite = (business: Business) => {
    if (favoritedBusinesses.some((fav) => fav.entity_name === business.entity_name)) {
      // Unfavorite the business if it's already favorited
      setFavoritedBusinesses((prevFavorites) =>
        prevFavorites.filter((fav) => fav.entity_name !== business.entity_name)
      );
    } else {
      // Add to favorites if it's not already in the list
      setFavoritedBusinesses((prevFavorites) => [...prevFavorites, business]);
    }
  };

  // Function to check if a business is favorited
  const isFavorited = (business: Business) => {
    return favoritedBusinesses.some((fav) => fav.entity_name === business.entity_name);
  };

  return (
    <FavoritesContext.Provider value={{ favoritedBusinesses, toggleFavorite, isFavorited }}>
      {children}
    </FavoritesContext.Provider>
  );
};

// Hook to use the context
export const useFavorites = () => {
  const context = useContext(FavoritesContext);
  if (!context) {
    throw new Error("useFavorites must be used within a FavoritesProvider");
  }
  return context;
};
