import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
import Icon from "@mui/material/Icon";
//component inports
import FinancialOverviewHeader from "./components/FinancialOverviewHeader";
import KpiCardsSection from "./components/KpiCardsSection";
import BudgetSummaryChart from "./components/BudgetSummaryChart";
import BalanceSheetAndProfitLoss from "./components/BalanceSheetAndProfitLoss";
import BankSummary from "./components/BankSummary";
import axios from "axios";
import {
  FinancialSection,
  OrganisationData,
  FinancialData,
  KpiData,
  BudgetData,
  BalanceSheetData,
  BankData,
  ExecutiveSummaryData,
  BankSummaryApiResponse,
} from "./types";

import {
  defaultKpiData,
  defaultBudgetData,
  //defaultBalanceSheetData,
  //defaultProfitAndLossData,
  defaultBankData,
  //new
  defaultOrganisationsData,
  defaultExecutiveSummaryData,
  defaultBankSummaryData,
} from "./defaultMockData";
import { useAuth } from "context/AuthContext";
import { transformExecutiveSummaryToKpiData } from "utility/transformExecutiveDataToKpiData";
import { transformBankSummaryApiResponse } from "utility/transformBankSummaryApiResponse";
import { transformBalanceSheetApiResponse } from "utility/transformBalanceSheetApiResponse";
import { transformProfitAndLossApiResponse } from "utility/transformProfitAndLossApiResponse";
import { transformBudgetChartApiResponse } from "utility/transformBudgetChartApiResponse";
import { generateGUID } from "utility/guidGenerator";

import {
  getXeroData,
  authorizeXero,
  refreshXeroToken,
  getCombinedReport,
} from "services/ventureApi";
//import { balanceSheetDates } from "./defaultMockData"; // to be part of transform, destrucutred form there

const FinancialOverview: React.FC = () => {
  const [isXeroIntegrated, setIsXeroIntegrated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [integrationError, setIntegrationError] = useState<boolean>(false); // Determines if the integration failed
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  //Organisations data
  const [organisationData, setOrganisationData] = useState<OrganisationData>();
  const [entityName, setEntityName] = useState("");
  const [financialYearEnd, setFinancialYearEnd] = useState("");
  const [baseCurrency, setBaseCurrency] = useState("");

  //const [kpiData, setKpiData] = useState<KpiData[]>(defaultKpiData); using transformed default
  const [kpiData, setKpiData] = useState<KpiData[]>([]);
  const [budgetData, setBudgetData] = useState<BudgetData[]>();
  const [balanceSheetData, setBalanceSheetData] = useState<FinancialSection[]>();
  const [profitAndLossData, setProfitAndLossData] = useState<FinancialSection[]>();
  const [balanceSheetDatesState, setBalanceSheetDatesState] = useState({
    asAtDate: null,
    currentDate: null,
    previousDate: null,
  });
  const [bankData, setBankData] = useState<BankData[]>([]);
  const { loginResponse } = useAuth();
  const entityId = loginResponse.entity?.entity_id;
  const roleId = loginResponse.entity?.role_id;
  const handleXeroIntegration = () => {
    setIsXeroIntegrated(true);
  };

  const authorizeXeroIntegration = async () => {
    try {
      setButtonDisabled(true);
      const authResponse = await authorizeXero();
      console.log("Xero authorization URL:", authResponse);
      const { auth_url } = authResponse;
      console.log("auth_url");
      console.log(auth_url);
      const guid = generateGUID();
      localStorage.setItem("oauthStateGUID", guid);
      const state = {
        entityId,
        roleId,
        guid,
      };
      const encodedState = encodeURIComponent(JSON.stringify(state));
      const url = new URL(auth_url);
      url.searchParams.set("state", encodedState);
      const hrefUrl = url.toString();
      console.log("Updated Xero authorization URL with custom state:", hrefUrl);
      window.location.href = hrefUrl;
    } catch (error) {
      console.error("Error authorizing Xero:", error);
    }
  };

  const refreshTokenAndFetchData = async () => {
    try {
      await refreshXeroToken(entityId);
      await fetchAllFinancialData();
    } catch (error) {
      console.error("Error refreshing Xero token:", error);
    }
  };

  const fetchAllFinancialData = async (): Promise<void> => {
    try {
      setLoading(true);
      setButtonDisabled(true);
      setIntegrationError(false);
      //add api call here - create service, service created, integrate, integrated
      const toDate = new Date().toISOString().split("T")[0];
      const fromDate = new Date(new Date().setDate(new Date().getDate() - 365))
        .toISOString()
        .split("T")[0];
      console.log("fromDate", fromDate);
      console.log("toDate", toDate);
      const budgetSummaryCombinedReportData = await getCombinedReport(entityId);
      console.log("budgetSummaryCombinedReportData", budgetSummaryCombinedReportData);
      const financialData = await getXeroData(entityId, "YEAR", 1, fromDate, toDate);

      if (!financialData || !financialData.data) {
        throw new Error("No financial data found"); // Handles missing data scenario
      }

      console.log("FinancialData.data from api call", financialData.data);
      console.log("findata.data.org", financialData.data.Organisation);

      //transforms
      const transformedBudgetSummaryData = transformBudgetChartApiResponse(
        budgetSummaryCombinedReportData
      );
      console.log("transformed Budget Summary Data:", transformedBudgetSummaryData);

      const transformedProfitAndLossData = transformProfitAndLossApiResponse(
        financialData.data.Reports_ProfitAndLoss
      );
      console.log("Transformed Profit and Loss Data:", transformedProfitAndLossData);

      const { balanceSheetData, asAtDate, currentDate, previousDate } =
        transformBalanceSheetApiResponse(financialData.data.Reports_BalanceSheet);
      console.log("Transformed Balance Sheet Data:", balanceSheetData);

      console.log("Balance Sheet Dates:", { asAtDate, currentDate, previousDate });

      const transformedKpiData = transformExecutiveSummaryToKpiData(
        financialData.data.ExecutiveSummary
      );
      console.log("Transformed KPI Data:", transformedKpiData);

      const transformedBankData = transformBankSummaryApiResponse(
        financialData.data.Reports_BankSummary
      );

      console.log("Transformed Bank Data:", transformedBankData);

      //setters
      setBudgetData(transformedBudgetSummaryData);
      setKpiData(transformedKpiData);
      setBalanceSheetData(balanceSheetData);
      setProfitAndLossData(transformedProfitAndLossData);
      setBalanceSheetDatesState({ asAtDate, currentDate, previousDate });
      setBankData(transformedBankData);
      setOrganisationData(financialData.data.Organisation?.Organisations);
      const organisation = financialData.data.Organisation?.Organisations[0];
      console.log("financialData.organisation", organisation);
      if (organisation) {
        setEntityName(organisation.Name || "Unknown Entity");
        setBaseCurrency(organisation.BaseCurrency || "USD");
        console.log("base Currency", organisation.BaseCurrency);
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const day = organisation.FinancialYearEndDay || 1;
        const monthIndex = (organisation.FinancialYearEndMonth || 1) - 1;
        const monthName = monthNames[monthIndex];
        setFinancialYearEnd(`${day} ${monthName}`);
      }
      setLoading(false);
      //return data;
    } catch (error: any) {
      console.error("Error fetching financial data:", error);
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data?.detail === "Xero token not found for this entity"
      ) {
        console.log("Xero token not found for this entity. Show integration button.");
        setIntegrationError(true);
        setButtonDisabled(false);
      } else {
        console.log("Unexpected error:", error);
      }
      console.log("set integration error to true, enable button...");

      setLoading(false);

      //throw error;
    }
  };

  useEffect(() => {
    fetchAllFinancialData();
  }, []);

  return (
    <MDBox px={3}>
      {!integrationError ? (
        <>
          <FinancialOverviewHeader
            entityName={entityName}
            financialYearEnd={financialYearEnd}
            onRefresh={() => console.log("Refreshed")}
            onManageConnection={() => console.log("Manage Connection")}
          />

          {loading ? (
            <Grid container spacing={2}>
              {/* Loading placeholders */}
              {/* KPI Loading Placeholder */}
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <MDBox p={2}>
                    <MDTypography variant="body1">Loading KPI...</MDTypography>
                  </MDBox>
                </Card>
              </Grid>
              {/* Budget Loading Placeholder */}
              <Grid item xs={12}>
                <Card>
                  <MDBox p={2}>
                    <MDTypography variant="body1">Loading Budget Summary...</MDTypography>
                  </MDBox>
                </Card>
              </Grid>
              {/* Balance Sheet and Profit & Loss Loading Placeholder */}
              <Grid item xs={12}>
                <Card>
                  <MDBox p={2}>
                    <MDTypography variant="body1">Loading Financial Statements...</MDTypography>
                  </MDBox>
                </Card>
              </Grid>
              {/* Bank Summary Loading Placeholder */}
              <Grid item xs={12}>
                <Card>
                  <MDBox p={2}>
                    <MDTypography variant="body1">Loading Bank Summary...</MDTypography>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <>
              {/* KPI Cards Section */}
              <KpiCardsSection kpiData={kpiData} baseCurrency={baseCurrency} />

              {/* Budget Summary Chart*/}
              <BudgetSummaryChart budgetData={budgetData} baseCurrency={baseCurrency} />

              {/* Balance Sheet and Profit & Loss */}
              <BalanceSheetAndProfitLoss
                balanceSheetData={balanceSheetData}
                loadingBalanceSheet={loading}
                profitData={profitAndLossData}
                loadingProfitAndLoss={loading}
                asAtDate={balanceSheetDatesState.asAtDate}
                currentDate={balanceSheetDatesState.currentDate}
                previousDate={balanceSheetDatesState.previousDate}
              />

              {/* Bank Summary */}
              <BankSummary bankData={bankData} loading={loading} />
            </>
          )}
        </>
      ) : (
        <MDBox>
          <Card
            sx={{
              mt: -3,
              p: 3,
              mx: 1,
              mb: 2,
            }}
          >
            <MDBox maxWidth="60%" mx="auto">
              <MDButton
                variant="gradient"
                color="dark"
                fullWidth
                onClick={authorizeXeroIntegration}
                disabled={buttonDisabled} // Disable during initial fetch or authorization process
              >
                {buttonDisabled ? (
                  <MDTypography color="#fff" variant="body2">
                    Loading...
                  </MDTypography> // Show loader if button is disabled
                ) : (
                  "Integrate with Xero"
                )}
              </MDButton>
            </MDBox>
          </Card>
        </MDBox>
      )}
    </MDBox>
  );
};

export default FinancialOverview;
