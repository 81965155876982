import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MDBox from "components/generic/MDBox";
import Grid from "@mui/material/Grid";
import BookingCard from "components/refined/Cards/BookingCard"; // Import the custom BookingCard component
import Icon from "@mui/material/Icon";
import BusinessCard from "./BusinessCard";
import ConfirmInviteModal from "./ConfirmInviteModal";
import AboutModal from "./AboutModal";
import DetailCard from "./investor-view-of-business/DetailCard";
import OneLiner from "./investor-view-of-business/OneLiner";
import FundingPieChart from "./investor-view-of-business/FundingPieChart";
import TeamMemberCard from "./investor-view-of-business/TeamMemberCard";
import blank_profile from "assets/blank_profile.png";
import logo_placeholder from "assets/logo_placeholder.png";
import { createConnectionRequest, getBusinessProfile } from "services/ventureApi";
import { Business } from "types/businessTypes";
import { useAuth } from "context/AuthContext";

interface AddBusinessModalProps {
  open: boolean;
  handleClose: () => void;
  startups: Business[];
  //handleInviteBusiness: (business: Business) => void;
  requestorId: number;
}

const constructOneLiner = (details: any): string => {
  const { businessName, problemOutline, keyOffering, targetMarket, valueProposition } = details;
  return `${businessName} is tackling ${problemOutline} by offering ${keyOffering} which enables ${targetMarket} to ${valueProposition}.`;
};

const AddBusinessModal: React.FC<AddBusinessModalProps> = ({
  open,
  handleClose,
  startups,
  //handleInviteBusiness,
  requestorId,
}) => {
  const [confirmInviteModalOpen, setConfirmInviteModalOpen] = useState(false);
  const [aboutModalOpen, setAboutModalOpen] = useState(false);
  const [selectedEntityDetails, setSelectedEntityDetails] = useState<any>({});
  const [selectedEntity, setSelectedEntity] = useState<Business | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { loginResponse } = useAuth();
  const requestorEntityId = loginResponse?.entity?.entity_id || null;

  const handleInviteClick = (business: Business) => {
    setSelectedEntity(business);
    setConfirmInviteModalOpen(true);
  };

  const handleAboutClick = async (business: Business) => {
    try {
      setLoading(true);
      const businessProfile = await getBusinessProfile(requestorEntityId, business.entity_id);
      console.log("businessProfile");
      console.log(businessProfile);
      setSelectedEntityDetails(businessProfile);
      setSelectedEntity(business);
      setLoading(false);
      //setAboutModalOpen(true); moved to useEffect
    } catch (error) {
      console.error("Error fetching business profile:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedEntityDetails && !loading) {
      setAboutModalOpen(true);
    }
  }, [selectedEntityDetails, loading]);

  const handleSendInvite = async () => {
    if (selectedEntity) {
      try {
        const response = await createConnectionRequest(requestorId, selectedEntity.entity_id);
        console.log("Connection request created:", response.message);
        //handleInviteBusiness(selectedEntity);
      } catch (error) {
        console.error("Error creating connection request:", error);
      } finally {
        setConfirmInviteModalOpen(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <MDBox p={3} sx={{ backgroundColor: "#F0F2F5" }}>
        <DialogTitle>
          Add a Startup to Your Portfolio
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <MDBox mt={2}>
          <Grid container spacing={4}>
            {startups.map((startup) => (
              <Grid item xs={12} sm={6} md={4} key={startup.entity_id}>
                <BusinessCard
                  image={startup.logo_url || logo_placeholder}
                  entityName={startup.entity_name}
                  oneLiner={constructOneLiner(startup.one_liner_details)}
                  onInviteClick={() => handleInviteClick(startup)}
                  onAboutClick={() => handleAboutClick(startup)}
                />
              </Grid>
            ))}
          </Grid>
        </MDBox>
      </MDBox>
      <ConfirmInviteModal
        open={confirmInviteModalOpen}
        handleClose={() => setConfirmInviteModalOpen(false)}
        entityName={selectedEntity?.entity_name || ""}
        handleSendInvite={handleSendInvite}
      />
      <AboutModal
        open={aboutModalOpen}
        handleClose={() => setAboutModalOpen(false)}
        data={selectedEntityDetails}
        loading={loading}
      />
      ;
    </Dialog>
  );
};

export default AddBusinessModal;
