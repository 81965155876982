import React from "react";
import Grid from "@mui/material/Grid";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";

interface ProjectDetailsHeaderProps {
  projectName: string;
  projectContent: string;
  onRefresh: () => void;
}

const ProjectDetailsHeader: React.FC<ProjectDetailsHeaderProps> = ({
  projectName,
  projectContent,
  onRefresh,
}) => {
  return (
    <Grid container justifyContent="space-between" alignItems="center" mb={3}>
      <Grid item>
        <MDTypography variant="h4" fontWeight="medium">
          {projectName}
        </MDTypography>
        <MDTypography variant="body2" color="textSecondary">
          {projectContent}
        </MDTypography>
      </Grid>
      <Grid item>
        <MDButton variant="gradient" color="dark" sx={{ mr: 2 }} onClick={onRefresh}>
          Refresh
        </MDButton>
      </Grid>
    </Grid>
  );
};

export default ProjectDetailsHeader;
