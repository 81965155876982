import React from "react";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import {
  getCountryOptions,
  CountryOption,
} from "../../../investor-questionaire/sections/mandate/data/mandate-2-countryOptionsData";
import { VectorMap } from "@react-jvectormap/core";
import { worldMerc } from "@react-jvectormap/world";

interface InvestorMapProps {
  countries: string[]; // Array of country codes (ISO 3166-1 alpha-2 format)
}

const InvestorMap: React.FC<InvestorMapProps> = ({ countries }) => {
  console.log("countries", countries);
  // Use an object to highlight countries with the corresponding color (e.g., blue)
  const highlightedCountries = countries.reduce((acc: any, code: string) => {
    acc[code] = "#85B526"; // You can customize the color
    return acc;
  }, {});

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <VectorMap
        map={worldMerc} // The map you are using (Mercator projection)
        backgroundColor="#ffffff" // Map background color
        zoomOnScroll={false} // Disable zooming with scroll
        containerStyle={{
          width: "100%",
          height: "100%",
        }}
        containerClassName="map"
        regionStyle={{
          initial: {
            fill: "#e0e0e0", // Default color for countries
            "fill-opacity": 1,
            stroke: "none",
            "stroke-width": 0,
            "stroke-opacity": 1,
          },
          hover: {
            "fill-opacity": 0.8,
            cursor: "pointer",
          },
        }}
        series={{
          regions: [
            {
              values: highlightedCountries, // Map of country codes to colors
              attribute: "fill", // The attribute being affected (fill color)
            },
          ],
        }}
      />
    </div>
  );
};

export default InvestorMap;
